import { useEffect, useState } from "react";
import { Profile } from "../models/profile";
import { useParams } from "react-router-dom";
import requests from "../../shared/api/agent";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import ProfilesForm from "../components/ProfilesForm";

export default function EditProfilePage() {
  const [profile, setProfile] = useState<Profile>();

  const { id } = useParams<{ id: string }>();
  
  const loadProfiles = () => {
    if (id) {
      requests.get("/profiles/" + id).then((response) => {
      setProfile(response.data);
      
      
      });
    }
  };

  useEffect(() => {
    loadProfiles();
  }, [id]);

  return (
    <>
      <TopBar
        currentPageTitle={`Editar Perfil #${profile?.id}`}
        firstLevelPageTitle="Perfiles"
        firstLevelPageUrl="/perfiles"
      />
      <PageTitle title={`Editar perfil ID # ${profile?.id}`} />
      {profile && (
        <ProfilesForm isNewProfile={false} profile={profile}  />
      )}
    </>
  );
}
