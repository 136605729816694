export default function Grid() {
    return (
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform:"scale(0.4)",margin:"0px -5px 0px -10px"}}>
<path d="M26.4445 40.6667V27.7778C26.4445 27.0413 27.0413 26.4445 27.7778 26.4445H40.6667C41.4031 26.4445 42 27.0413 42 27.7778V40.6667C42 41.4031 41.4031 42 40.6667 42H27.7778C27.0413 42 26.4445 41.4031 26.4445 40.6667Z" stroke="#29A4C9" stroke-width="3.735"/>
<path d="M2 40.6667V27.7778C2 27.0413 2.59696 26.4445 3.33333 26.4445H16.2222C16.9586 26.4445 17.5556 27.0413 17.5556 27.7778V40.6667C17.5556 41.4031 16.9586 42 16.2222 42H3.33333C2.59696 42 2 41.4031 2 40.6667Z" stroke="#29A4C9" stroke-width="3.735"/>
<path d="M26.4445 16.2222V3.33333C26.4445 2.59696 27.0413 2 27.7778 2H40.6667C41.4031 2 42 2.59696 42 3.33333V16.2222C42 16.9586 41.4031 17.5556 40.6667 17.5556H27.7778C27.0413 17.5556 26.4445 16.9586 26.4445 16.2222Z" stroke="#29A4C9" stroke-width="3.735"/>
<path d="M2 16.2222V3.33333C2 2.59696 2.59696 2 3.33333 2H16.2222C16.9586 2 17.5556 2.59696 17.5556 3.33333V16.2222C17.5556 16.9586 16.9586 17.5556 16.2222 17.5556H3.33333C2.59696 17.5556 2 16.9586 2 16.2222Z" stroke="#29A4C9" stroke-width="3.735"/>
</svg>

    );
  }
  