import { Link } from "react-router-dom";
import "../styles/mbtp.css";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import MovementsForm from "../components/MovementsForm";
import { GrDocument } from "react-icons/gr";
import { useState} from "react";

export default function MovementsByThirdParties() {

    const [sendTheRequest, setSendTheRequest] = useState(false);

  return (
    <>
      <TopBar currentPageTitle="Movimientos por tercero" />
      <MovementsForm/>
    </>
  );
}
