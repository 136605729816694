export default function MagnifyingGlass() {
    return (
        <svg width={20} height={20} fill={"#FFFFFF"}
            viewBox="0 0 1009.9912 1010">
            <g>
                <g id="magnifier-magnifier">
                    <g>
                        <path fill="#FFFFFF" d="M90.5801,1010c-24.1909,0-46.9307-9.3965-64.0391-26.5049C9.4155,966.3516,0,943.6123,0,919.4023
				c0-24.1738,9.4155-46.9482,26.541-64.0576l183.1333-183.1318c17.1079-17.1084,39.8472-26.541,64.0391-26.541
				c24.1919,0,46.9482,9.4326,64.0747,26.5049c35.3105,35.3642,35.292,92.8574,0,128.1494L154.6553,983.4951
				C137.5293,1000.6035,114.7725,1010,90.5801,1010L90.5801,1010z M273.7134,682.3984c-14.3828,0-27.9043,5.5957-38.0723,15.7813
				L52.5088,881.3125c-10.1865,10.1865-15.7813,23.708-15.7813,38.0898c0,14.3829,5.5948,27.9405,15.7813,38.125
				c20.3364,20.337,55.8076,20.337,76.1797-0.0341l183.1328-183.1339c20.9814-20.9814,20.9814-55.162-0.0186-76.1797
				C301.6353,687.9941,288.1138,682.3984,273.7134,682.3984L273.7134,682.3984z"/>
                    </g>
                    <g>
                        <path fill="#FFFFFF" d="M343.2393,736.1973c-1.5957,0-3.2095-0.2149-4.8057-0.6447c-6.3486-1.7226-11.2979-6.707-12.9658-13.0556
				c-2.4922-9.5752-7.1016-17.7538-13.6651-24.3526c-6.5273-6.4922-14.7226-11.0829-24.3349-13.6299
				c-6.3482-1.6855-11.3154-6.6357-13.0371-12.9843c-1.7036-6.3477,0.1074-13.126,4.7524-17.752l58.7485-58.75
				c3.6587-3.6943,8.6973-5.667,13.898-5.3789c5.1831,0.2861,10.0244,2.7256,13.3066,6.7422
				c14.4717,17.6816,30.7735,34.001,48.4727,48.4551c4.0176,3.2998,6.456,8.1416,6.7251,13.3066
				c0.2515,5.2002-1.6856,10.2217-5.3623,13.916l-58.7485,58.7491C352.7441,734.2969,348.0459,736.1973,343.2393,736.1973
				L343.2393,736.1973z M323.9082,660.9863c5.021,3.2646,9.6655,6.9942,13.8618,11.1905c4.2144,4.2324,7.98,8.8945,11.2442,13.8808
				l26.021-26.0029c-8.7515-7.9629-17.126-16.3555-25.1065-25.1065L323.9082,660.9863L323.9082,660.9863z"/>
                    </g>
                    <g>
                        <path fill="#FFFFFF" d="M630.4912,759.0088c-209.2607,0-379.4995-170.2568-379.4995-379.5088
				C250.9917,170.2388,421.2305,0,630.4912,0s379.5,170.2388,379.5,379.5C1009.9912,588.752,839.752,759.0088,630.4912,759.0088
				L630.4912,759.0088z M630.4912,36.7271c-188.9956,0-342.7724,153.7758-342.7724,342.7729
				c0,188.9873,153.7768,342.7812,342.7724,342.7812c188.9971,0,342.7744-153.7939,342.7744-342.7812
				C973.2656,190.5029,819.4883,36.7271,630.4912,36.7271L630.4912,36.7271z"/>
                    </g>
                    <g>
                        <path fill="#FFFFFF" d="M431.8652,379.8228c-10.1499,0-18.3637-8.2134-18.3637-18.3638
				c0-129.6196,105.4292-235.0664,235.0307-235.0664c10.1494,0,18.3643,8.2129,18.3643,18.3633
				c0,10.1499-8.2149,18.3637-18.3643,18.3637c-109.3379,0-198.3037,88.9663-198.3037,198.3394
				C450.2285,371.6094,442.0147,379.8228,431.8652,379.8228L431.8652,379.8228z"/>
                    </g>
                </g>
            </g>
        </svg>

    )
}