import SystemMessage from "../common/presentation/SystemMessage";
import { useState } from "react";
import CancelRed from "../icons/CancelRed";
import requests from "../api/agent";

interface Props {
  url: string;
}
export default function GridDeleteButton({ url }: Props) {
  const [Message,setMessage] = useState<boolean>(false)
  var onClick = ()=>{
    setMessage((Message)=>!Message);
  }

  var Click = () =>{
    requests.delete(`${url}`).then((response)=>{
      console.log(`item eliminado satisfactoriamente ${response}`)
    }).catch((error)=>{
     
      console.log(`error al eliminar el item: ${error.description}`)
    })
    setMessage((Message)=>!Message);
  }
  
var message=()=>{
  if(Message){
    return(
      <SystemMessage title="¡Cuidado!" message="¿Esta seguro que desea eliminar este item permanentemente?" icon={<CancelRed/>} buttons={[
        <button
          type="button"
          className="btn btn-success py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
          onClick={()=>{onClick()}} style={{color:"white"}}>Cancelar</button>,
        <button
          type="button"
          className="btn btn-danger py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
          onClick={()=>{Click()}}>Eliminar</button>
      ]}/>
    )
  }
  return null;
}
  return (
    <div className="flex items-center mr-3">
      <div onClick={()=>onClick()}>
        <svg 
          width="24" 
          height="24" 
          viewBox="0 0 40 45" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg">
          <path d="M38.4745 11.2426C38.4335 11.2426 38.3719 11.2426 38.3104 11.2426C27.4575 10.1553 16.6252 9.74497 5.89537 10.8323L1.71015 11.2426C0.848487 11.3247 0.089403 10.7092 0.00733989 9.84755C-0.0747232 8.98589 0.54075 8.24732 1.3819 8.16526L5.56712 7.75494C16.4816 6.64709 27.5396 7.07792 38.6181 8.16526C39.4593 8.24732 40.0747 9.00641 39.9927 9.84755C39.9311 10.6477 39.2541 11.2426 38.4745 11.2426Z" fill="#DC2626"/>
          <path d="M12.832 9.17055C12.7499 9.17055 12.6678 9.17056 12.5652 9.15004C11.7446 9.00643 11.1702 8.20631 11.3138 7.38568L11.7651 4.69811C12.0934 2.7286 12.5447 0 17.3248 0H22.7C27.5007 0 27.952 2.83118 28.2597 4.71863L28.7111 7.38568C28.8547 8.22683 28.2803 9.02694 27.4596 9.15004C26.6185 9.29365 25.8184 8.71921 25.6953 7.89858L25.2439 5.23152C24.9567 3.44665 24.8952 3.09788 22.7205 3.09788H17.3453C15.1708 3.09788 15.1297 3.3851 14.822 5.21101L14.3501 7.87806C14.227 8.63715 13.5705 9.17055 12.832 9.17055Z" fill="#DC2626"/>
          <path d="M26.5974 44.1091H13.4262C6.26619 44.1091 5.97897 40.1495 5.7533 36.9491L4.41977 16.2896C4.35822 15.4485 5.01473 14.7099 5.85588 14.6484C6.71754 14.6073 7.43559 15.2433 7.49714 16.0845L8.83066 36.7439C9.05634 39.8623 9.1384 41.0317 13.4262 41.0317H26.5974C30.9057 41.0317 30.9877 39.8623 31.1929 36.7439L32.5264 16.0845C32.588 15.2433 33.3265 14.6073 34.1677 14.6484C35.0088 14.7099 35.6653 15.4279 35.6038 16.2896L34.2703 36.9491C34.0446 40.1495 33.7574 44.1091 26.5974 44.1091Z" fill="#DC2626"/>
          <path d="M23.4167 32.8252H16.585C15.7438 32.8252 15.0463 32.1276 15.0463 31.2865C15.0463 30.4453 15.7438 29.7478 16.585 29.7478H23.4167C24.2579 29.7478 24.9554 30.4453 24.9554 31.2865C24.9554 32.1276 24.2579 32.8252 23.4167 32.8252Z" fill="#DC2626"/>
          <path d="M25.1398 24.6191H14.8819C14.0408 24.6191 13.3433 23.9216 13.3433 23.0804C13.3433 22.2393 14.0408 21.5417 14.8819 21.5417H25.1398C25.981 21.5417 26.6785 22.2393 26.6785 23.0804C26.6785 23.9216 25.981 24.6191 25.1398 24.6191Z" fill="#DC2626"/>
        </svg>
      </div>
      {message()}
    </div>
  );
}
