import { useState, useEffect } from 'react';
import { Branch } from '../../branches/models/Branch';
import useGetBranch from '../../shared/hooks/useGetBranch';
import useTwoAccount from '../../shared/hooks/useTwoAccounts';
import PageTitle from '../../shared/common/presentation/PageTitle';
import TextField2 from '../../chartOfAccounts/components/TextField2';
import DateField from '../../shared/common/presentation/DateField';
import InputSelect from '../../chartOfAccounts/components/InputSelect2';
import ModalTable from '../../shared/layouts/ModalTable';
import { Link } from 'react-router-dom';
import {AiOutlineSearch, AiOutlinePrinter} from "react-icons/ai";
import TestBalanceTable from './TestBalancesTable';
import requests from '../../shared/api/agent';
import { ITestBalanceReport } from './TestBalancesTable';
import useDate from '../../shared/hooks/useDate';

export default function TestBalancesForm() {

    const [showModal, setShowModal] = useState<boolean>(false)
    const [title, setTitle] = useState<string>('')
    const [dateFrom, setDateFrom] = useState<string>('')
    const [dateUntil, setDateUntil] = useState<string>('')
    const [sendRequest, setSendRequest] = useState<boolean>(false)
    const [data, setData] = useState<ITestBalanceReport[]>([])
    const branches = useGetBranch()
    const accounts = useTwoAccount()
    const date = useDate()

    useEffect(() => {
        if (sendRequest) {
            if (dateFrom === '' || dateUntil === '' || accounts.selectedInitial === undefined || accounts.selectedFinal === undefined) {
                return
            } else if (branches.selectedBranch.id === undefined) {
                requests.get(`/reports/test-balances?InitialDate=${dateFrom}&EndDate=${dateUntil}&InitialAccount=${accounts.selectedInitial.code}&EndAccount=${accounts.selectedFinal.code}`)
                    .then((response) => setData(response.data)).catch((error) => console.log(error))
            }else if(branches.selectedBranch.id !== undefined){
                requests.get(`/reports/test-balances?InitialDate=${dateFrom}&EndDate=${dateUntil}&InitialAccount=${accounts.selectedInitial.code}&EndAccount=${accounts.selectedFinal.code}&BranchId=${branches.selectedBranch.id}`)
                    .then((response) => setData(response.data)).catch((error) => console.log(error))
            }
            setSendRequest(false)
        }
    }, [sendRequest])

    return (
        <>
            <PageTitle title="Balance de pruebas" >
                <Link
                    to={``}
                    className="btn btn-outline-secondary w-24 inline-block mr-2 row"
                >
                    <AiOutlinePrinter className="text-lg" />
                    Imprimir
                </Link>
                <Link to="" className="btn btn-warning w-40 mr-2 row" onClick={() => setSendRequest(true)}>
                    <AiOutlineSearch className="text-lg" />
                    Consultar
                </Link>
            </PageTitle>
            {showModal && <ModalTable modalData={title === 'Cuenta Inicial' ? {
                title: title,
                data: accounts.initialAccount,
                index: 2,
                anyHandler: (item: any, index: number) => accounts.handleSelectedInitial(item)
            } : {
                title: title,
                data: accounts.finalAccount,
                index: 2,
                anyHandler: (item: any, index: number) => accounts.handleSelectedFinal(item)
            }} modalHandler={() => setShowModal(!showModal)} />}
            <div className='box px-8 py-4'>
                <div className='flex flex-col gap-4'>
                    <div className="flex flex-row w-full">
                        <InputSelect data={branches.branch} key={1} name='Sucursal' onChange={(value) => branches.handleSelectedBranch(value)} />
                        <TextField2 label='Cuenta Inicial' placeholder='De click para seleccionar' value={accounts.selectedInitial?.code || ''} onClick={() => { setShowModal(true); setTitle('Cuenta Inicial') }} />
                    </div>
                    <div className='flex flex-row w-full'>
                        <div className='flex flex-row w-9/10'>
                            <DateField label='Fecha desde' onChange={(name, value) => setDateFrom(value)} name='Desde' value={dateFrom} />
                            <DateField label='Fecha hasta' onChange={(name, value) => setDateUntil(value)} name='Hasta' value={dateUntil} />
                        </div>
                        <TextField2 label='Cuenta Final' placeholder='De click para seleccionar' value={accounts.selectedFinal?.code || ''} onClick={() => { setShowModal(true); setTitle('Cuenta Final') }} />
                    </div>
                </div>
                <TestBalanceTable data={data} />
            </div>
        </>
    )
}