import { useEffect, useState } from "react";
import { AiOutlineArrowDown } from "react-icons/ai";

export default function AnySelector({
  data,
  title,
  handler,
}: {
  data: string[];
  title: string;
  handler: (value: string) => void;
}) {
  const [selected, setSelected] = useState<string>("Sin datos");
  const [dataToShow, setDataToShow] = useState<string[]>([]);
  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    setDataToShow(data);
  }, [data]);

  useEffect(() => {
    handler(selected);
  }, [selected]);

  return (
    <div className="flex flex-col gap-2 dropdown w-40">
      {title}
      <button
        className="dropdown-toggle btn btn-outline-secondary flex flex-row items-center"
        aria-expanded="false"
        data-tw-toggle="dropdown"
        onClick={() => setActive(!active)}
      >
        <p>{selected}</p>
        <AiOutlineArrowDown/>
      </button>
      {active && (
        <div className="w-40 mt-045 absolute">
          <ul className="flex flex-col gap-2 h-32 dropdown-content overflow-y-auto z-50 text-center absolute w-40 border rounded border-slate-200">
            {dataToShow.map((item) => (
              <li
                onClick={() => {
                  setSelected(item);
                  setActive(false);
                }}
                className="ml-4 border-b-2 py-2 cursor-pointer"
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
