import { useNavigate } from "react-router-dom";
import {AiOutlineRightCircle} from 'react-icons/ai';
import '../SubMenuGroup.css'

type SubMenuItemsProps = {
    title: string;
    url: string;
}

export default function SubMenuItems({title, url}: SubMenuItemsProps){
    const navigate = useNavigate();
    return(
        <div className="subItems"
        onClick={(e)=>navigate(url)}>
            <AiOutlineRightCircle className="subItems--icon"/>
            <span>{title}</span>
        </div>
    )
}