interface Props {
  date?: string | null;
  showTime: boolean;
}

export default function DateLabel({ date, showTime = false }: Props) {
  
  const dateValue = date ? new Date(date) : new Date();
  dateValue.setMinutes(dateValue.getMinutes() + dateValue.getTimezoneOffset())
  
  const dateString = new Intl.DateTimeFormat("es-CO", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })
    .format(dateValue)
    .toString();
  
  return (
    <span>
      {showTime ? dateValue.toLocaleString() : dateString}
    </span>
  );
}
