// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes moveBit {
  from {
    transform: translateX(-35px);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes restoreBit {
    from {
        transform: translateX(35px);
    }
    to {
        transform: translateX(0);
    }
}

.bitContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selectContainer {
  border: 1px solid #cccccc;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 15px;
  width: 60px;
  margin-top: 5px;
}

.selectContainer:hover{
    cursor: pointer;
}

.bgwhite {
  background-color: white;
}

.bgblue {
  background-color: blue;
}

.activeBit {
  animation: moveBit 0.5s ease-in-out;
  position: relative;
  left: 32px;
}

.unactiveBit {
  animation: restoreBit 0.5s ease-in-out;
  position: relative;
  left: 0px;
}

.circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #f2f2f2;
  border: 1px solid #cccccc;
}
`, "",{"version":3,"sources":["webpack://./src/chartOfAccounts/styles/BitSelect.css"],"names":[],"mappings":"AAAA;EACE;IACE,4BAA4B;EAC9B;EACA;IACE,wBAAwB;EAC1B;AACF;;AAEA;IACI;QACI,2BAA2B;IAC/B;IACA;QACI,wBAAwB;IAC5B;AACJ;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,wBAAmB;EAAnB,mBAAmB;EACnB,mBAAmB;EACnB,WAAW;EACX,eAAe;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,mCAAmC;EACnC,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,sCAAsC;EACtC,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB;AAC3B","sourcesContent":["@keyframes moveBit {\n  from {\n    transform: translateX(-35px);\n  }\n  to {\n    transform: translateX(0);\n  }\n}\n\n@keyframes restoreBit {\n    from {\n        transform: translateX(35px);\n    }\n    to {\n        transform: translateX(0);\n    }\n}\n\n.bitContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.selectContainer {\n  border: 1px solid #cccccc;\n  height: fit-content;\n  border-radius: 15px;\n  width: 60px;\n  margin-top: 5px;\n}\n\n.selectContainer:hover{\n    cursor: pointer;\n}\n\n.bgwhite {\n  background-color: white;\n}\n\n.bgblue {\n  background-color: blue;\n}\n\n.activeBit {\n  animation: moveBit 0.5s ease-in-out;\n  position: relative;\n  left: 32px;\n}\n\n.unactiveBit {\n  animation: restoreBit 0.5s ease-in-out;\n  position: relative;\n  left: 0px;\n}\n\n.circle {\n  width: 25px;\n  height: 25px;\n  border-radius: 50%;\n  background-color: #f2f2f2;\n  border: 1px solid #cccccc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
