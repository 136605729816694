import { Link } from "react-router-dom";
import Table from "../../shared/common/presentation/Table";
import { ExplanatoryNotesListItem } from "../models/explanatoryNoteListItem";
import DateLabel from "../../shared/common/presentation/DateLabel";
import GridEditButton from "../../shared/filtered-query/GridEditButton";
import GridStateGreen from "../../shared/filtered-query/GridStateGreen";
import GridStateRed from "../../shared/filtered-query/GridStateRed";
import GridViewButton from "../../shared/filtered-query/GridViewButton";
import GridEditInabilite from "../../shared/filtered-query/GridEditInabilite";
import GridViewInabilite from "../../shared/filtered-query/GridViewInabilite";
import ItemAnulado from "../../shared/filtered-query/ItemAnulado";
import ExplanatoryNotesMessage from "./ExplanatoryNotesMessage";
import { useState } from "react"; 

interface Props {
  data: ExplanatoryNotesListItem[];
  onSortingChange?: (columnName: string, sortOrder: "asc" | "desc") => void;
  sortedBy?: string | null;
  ascending?: boolean | null;
}

export default function ExplanatoryNotesTable({
  data,
  onSortingChange,
  sortedBy,
  ascending,
}: Props) {
  const triggerSortingChange = (
    columnName: string,
    sortOrder: "asc" | "desc"
  ) => {
    if (onSortingChange) onSortingChange(columnName, sortOrder);
  };
  const [Message,setMessage] = useState<boolean>(false)
  const [url,setUrl] = useState<string>("")
  const handleMessage = (url?:string) =>{
    if(url){
      setUrl(url)
    }
    setMessage((Message)=>!Message);
  }
  
  return (
    <>
      {Message && <ExplanatoryNotesMessage handler={handleMessage} url={url} />}
      <Table
        sortedBy={sortedBy}
        ascending={ascending}
        onSortingChange={triggerSortingChange}
        columns={[
          { isSortable: true, name: "number", title: "Consecutivo" },
          { isSortable: true, name: "date", title: "Fecha" },
          { isSortable: true, name: "nameType", title: "Tipo" },
          {
            isSortable: true,
            name: "accountInformation.code",
            title: "Cuenta",
          },
          { isSortable: false, name: "isActive", title: "Estado" },
          { isSortable: false, name: "Actions", title: "" },
        ]}
      >
        {data.map((dt) => (
          <tr className="intro-x" key={dt.id}>
            <td className={`w-40 ${dt.isActive === false && "table--anulado"}`}>
              <Link
                className="font-medium whitespace-nowrap"
                to={`/notasAclaratorias/${dt.id}`}
              >
                {dt.number}
              </Link>{" "}
            </td>
            <td className={`w-40 ${dt.isActive === false && "table--anulado"}`}>
    
              <DateLabel date={dt.date} showTime={false} />
            </td>
            <td
              className={`w-40 ${dt.isActive === false && "table--anulado"}`}
            ></td>
            <td className={`w-40 ${dt.isActive === false && "table--anulado"}`}>
              {dt.accountInformation.code}
            </td>
            <td className={`w-40 ${dt.isActive === false && "table--anulado"}`}>
              {dt.isActive ? (
                <div className="d-flex justifyC-center alignI-center">
                  <GridStateGreen
                    url={`/clarification-notes/${dt.id}`}
                    handler={handleMessage}
                  />
                </div>
              ) : (
                <div className="d-flex justifyC-center alignI-center">
                  <ItemAnulado />
                </div>
              )}
            </td>

            <td className="table-report__action w-56">
              {dt.isActive ? (
                <div className="flex justify-center items-center">
                  <GridEditButton url={`/notasAclaratorias/${dt.id}`} />
                  <GridViewButton url={`/notasAclaratorias/info/${dt.id}`} />
                </div>
              ) : (
                <div className="flex justify-center items-center">
                  <GridEditInabilite />
                  <GridViewInabilite />
                </div>
              )}
            </td>
          </tr>
        ))}
      </Table>
    </>
  );
}
