// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tpcFormContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5%;
    width: 100%;
    margin-bottom: 1.5%;
}

.ctpcFormContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.ctpcFormRow{
    display: flex;
    flex-direction: row;
}

.ctpcFormRow-1{
    gap: 5%;
    max-width: 90%;
}

.ctpcFormRow-2{
    gap: 5%;
    max-width: 100%;
}

.ctpcFormRow-3{
    gap: 5%;
    max-width: 81%;
}

.ctpcSubmitEnd{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 5%;
}
`, "",{"version":3,"sources":["webpack://./src/thirdPartyClosure/styles/thirdPartyClosureForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,OAAO;IACP,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,OAAO;IACP,cAAc;AAClB;;AAEA;IACI,OAAO;IACP,eAAe;AACnB;;AAEA;IACI,OAAO;IACP,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,qBAAqB;IACrB,cAAc;AAClB","sourcesContent":[".tpcFormContainer{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    gap: 5%;\n    width: 100%;\n    margin-bottom: 1.5%;\n}\n\n.ctpcFormContainer{\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\n.ctpcFormRow{\n    display: flex;\n    flex-direction: row;\n}\n\n.ctpcFormRow-1{\n    gap: 5%;\n    max-width: 90%;\n}\n\n.ctpcFormRow-2{\n    gap: 5%;\n    max-width: 100%;\n}\n\n.ctpcFormRow-3{\n    gap: 5%;\n    max-width: 81%;\n}\n\n.ctpcSubmitEnd{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: flex-end;\n    margin-top: 5%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
