import { useEffect, useState } from "react";
import { Company } from "../models/company";
import requests from "../../shared/api/agent";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import CompanyForm from "../components/CompanyForm";

export default function EditCompanyDate() {
  const [company, setCompany] = useState<Company>();

  const loadCompany = () => {
    
      requests.get("/company" ).then((response) => {
      setCompany(response.data);
      
      
      });
    
  };

  useEffect(() => {
    loadCompany();
  });

  return (
    <>
      <TopBar
        currentPageTitle={`Editar datos de la empresa`}
        firstLevelPageTitle="Datos de la empresa"
        firstLevelPageUrl="/empresa"
      />
      <PageTitle title={`Editar datos de la empresa`} />
      {company && (
        <CompanyForm isNewCompany={false} company={company}  />
      )}
    </>
  );
}
