// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.createContainer{
    display: flex;
    flex-direction: column;
    overflow-wrap: break-word;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
}
.insideContainer{
    display: flex;
    flex-direction: row;
    gap: 6rem;
}
.createContainer__tree{
    display: flex;
}
.createContainer__tree--title{
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.bgGreen{
    background-color: #00e64d;
    font-size: 1rem;
    border: 1px solid #00b33c;
}

.formContainer{
    
}

.margin20px{
    margin-top: 20px;
}
.formContainer--row{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-bottom: 1rem;
    width: 24vw;
    max-width: 24vw;
}
.formContainer--item2{
    margin-top: 27px;
    height: 39px;
    border-radius: 0.375rem;
    width: 100%;
}

@media screen and (max-width: 1920px) {
    .formContainer--row{
        display: flex;
        flex-direction: row;
        gap: 1.3rem;
        margin-bottom: 1rem;
        width: 20vw;
        max-width: 22vw;
    }
}  `, "",{"version":3,"sources":["webpack://./src/chartOfAccounts/styles/chartOfAccounts.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;AACA;IACI,aAAa;AACjB;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,yBAAyB;AAC7B;;AAEA;;AAEA;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,mBAAmB;IACnB,WAAW;IACX,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,YAAY;IACZ,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI;QACI,aAAa;QACb,mBAAmB;QACnB,WAAW;QACX,mBAAmB;QACnB,WAAW;QACX,eAAe;IACnB;AACJ","sourcesContent":[".createContainer{\n    display: flex;\n    flex-direction: column;\n    overflow-wrap: break-word;\n    overflow-x: hidden;\n    width: 100%;\n    height: 100%;\n    background-color: white;\n    border-radius: 10px;\n    padding: 10px;\n}\n.insideContainer{\n    display: flex;\n    flex-direction: row;\n    gap: 6rem;\n}\n.createContainer__tree{\n    display: flex;\n}\n.createContainer__tree--title{\n    font-size: 1.5rem;\n    font-weight: 600;\n    margin-bottom: 1rem;\n}\n\n.bgGreen{\n    background-color: #00e64d;\n    font-size: 1rem;\n    border: 1px solid #00b33c;\n}\n\n.formContainer{\n    \n}\n\n.margin20px{\n    margin-top: 20px;\n}\n.formContainer--row{\n    display: flex;\n    flex-direction: row;\n    gap: 2rem;\n    margin-bottom: 1rem;\n    width: 24vw;\n    max-width: 24vw;\n}\n.formContainer--item2{\n    margin-top: 27px;\n    height: 39px;\n    border-radius: 0.375rem;\n    width: 100%;\n}\n\n@media screen and (max-width: 1920px) {\n    .formContainer--row{\n        display: flex;\n        flex-direction: row;\n        gap: 1.3rem;\n        margin-bottom: 1rem;\n        width: 20vw;\n        max-width: 22vw;\n    }\n}  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
