import "../../ABook-accountRank/styles/ScrollableTable.css";
import {useState, useEffect} from "react";
import toCash from "../../ABook-accountRank/functions/toCash";
import { roundDecimals } from "../../generalBalance/components/GeneralBalanceTable";

export interface IAccounts {
  code: string;
  name: string;
  previousDebitBalance: number;
  previousCreditBalance: number;
  debitMonth: number;
  creditMonth: number;
  debitBalance: number;
  creditBalance: number;
}

export default function LedgerNBalanceTable({ data }: { data: IAccounts[] }) {

  const [sumDebitBefore, setSumDebitBefore] = useState<number>(0);
  const [sumCreditBefore, setSumCreditBefore] = useState<number>(0);
  const [sumDebitMonth, setSumDebitMonth] = useState<number>(0);
  const [sumCreditMonth, setSumCreditMonth] = useState<number>(0);
  const [sumDebit, setSumDebit] = useState<number>(0);
  const [sumCredit, setSumCredit] = useState<number>(0);

  useEffect(() => {
    data.forEach((item) => {
      setSumDebitBefore((prev) => prev + item.previousDebitBalance);
      setSumCreditBefore((prev) => prev + item.previousCreditBalance);
      setSumDebitMonth((prev) => prev + item.debitMonth);
      setSumCreditMonth((prev) => prev + item.creditMonth);
      setSumDebit((prev) => prev + item.debitBalance);
      setSumCredit((prev) => prev + item.creditBalance);
    });
  }, [data]);

  return (
    <div className="mt-8 max-height-60vh">
      <div className="px-4 w-full LedgerNBalanceSheet-structure table-header special-row">
        <p>Codigo</p>
        <p>Nombre</p>
        <p className="text-center">Saldo Debito Anterior</p>
        <p className="text-center">Saldo Credito Anterior</p>
        <p className="text-center">Debito Mes</p>
        <p className="text-center">Credito Mes</p>
        <p className="text-center">Saldo Debito</p>
        <p className="text-center">Saldo Credito</p>
      </div>
      <div className="max-w-100 flex flex-col gap-4 mt-4">
        {data.length > 0 ? data.map((item) => {
          return (
            <>
              <div className="px-4 w-full LedgerNBalanceSheet-structure table-row">
                <p>{item.code}</p>
                <p>{item.name}</p>
                <p className="text-center">{toCash(roundDecimals(item.previousDebitBalance).toString())}</p>
                <p className="text-center">{toCash(roundDecimals(item.previousCreditBalance).toString())}</p>
                <p className="text-center">{toCash(roundDecimals(item.debitMonth).toString())}</p>
                <p className="text-center">{toCash(roundDecimals(item.creditMonth).toString())}</p>
                <p className="text-center">{toCash(roundDecimals(item.debitBalance).toString())}</p>
                <p className="text-center">{toCash(roundDecimals(item.creditBalance).toString())}</p>
              </div>
            </>
          );
        }) : <p className="text-center">No hay datos</p>}
        <div className="px-4 w-full LedgerNBalanceSheet-structure table-header special-row text-center">
          <p></p>
          <p className="font-bold">Total</p>
          <p className="font-bold">{toCash(roundDecimals(sumDebitBefore).toString())}</p>
          <p className="font-bold">{toCash(roundDecimals(sumCreditBefore).toString())}</p>
          <p className="font-bold">{toCash(roundDecimals(sumDebitMonth).toString())}</p>
          <p className="font-bold">{toCash(roundDecimals(sumCreditMonth).toString())}</p>
          <p className="font-bold">{toCash(roundDecimals(sumDebit).toString())}</p>
          <p className="font-bold">{toCash(roundDecimals(sumCredit).toString())}</p>
        </div>
      </div>
    </div>
  );
}
