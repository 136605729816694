import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import IoCContainer from "../common/IoC/IoCContainer";
import UserIcon from "../icons/UserIcon";

export default function UserProfileMenu() {
  return (
    <Menu as="div" className="intro-x dropdown w-8 h-8">
      <div>
        <Menu.Button className="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in">
          <img alt="IGD Plus - Usuario" src="/images/profile-5.jpg" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dropdown-content bg-primary text-white">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  onClick={(e) => {
                    IoCContainer.getAuthManager().removeAuthentication();
                    window.location.href = "/";
                  }}
                  className={`${
                    active ? "bg-violet-500 text-white" : "text-gray-900"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                >
                  Cerrar sesión
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
