import React from 'react';
import TopBar from '../../shared/layouts/TopBar';
import CloseThirdPartiesForm from '../components/CloseThirdPartiesForm';

const CloseThirdPartiesIndex = () => {
    return (
        <>
            <TopBar currentPageTitle='Cerrar terceros'/>
            <CloseThirdPartiesForm/>
        </>
    );
};

export default CloseThirdPartiesIndex;
