import { Link } from "react-router-dom";
import SquareCheck from "../../icons/SquareCheck";

interface Props {
  url: string;
}

export default function SuccessButton({ url }: Props) {
  return (
    <Link to={url} className="btn btn-pending w-28 mr-1 mb-2">
      


      {" "} 
      <svg 
        width="18" 
        height="24" 
        viewBox="0 0 38 44" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
        <path d="M33.9363 21.9602V9.48213C33.9363 9.16451 33.8101 8.85989 33.5856 8.63528L27.3009 2.35078C27.0764 2.12617 26.7718 2 26.4542 2H3.19761C2.53619 2 2 2.53619 2 3.19761V40.7227C2 41.3842 2.53619 41.9204 3.19761 41.9204H15.9721" stroke="white" stroke-width="3.98558" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.98407 17.9681H25.9522M9.98407 9.98401H17.9681M9.98407 25.9522H15.9721" stroke="white" stroke-width="3.98558" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M29.8526 31.8193L31.8486 29.8233C32.7227 28.9492 34.1399 28.9492 35.0139 29.8233C35.888 30.6973 35.888 32.1145 35.0139 32.9886L33.0179 34.9846M29.8526 31.8193L23.8823 37.7896C23.5831 38.0888 23.3867 38.4754 23.3217 38.8934L22.8372 42L25.9436 41.5157C26.3618 41.4505 26.7482 41.2541 27.0474 40.9549L33.0179 34.9846M29.8526 31.8193L33.0179 34.9846" stroke="white" stroke-width="3.98558" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M25.9522 2V8.78646C25.9522 9.44788 26.4883 9.98407 27.1498 9.98407H33.9363" stroke="white" stroke-width="3.98558" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      {""}&nbsp; Modificar Datos
    </Link>
  );
}
