import TopBar from "../../shared/layouts/TopBar";
import LedgerNBalanceSheetForm from "../components/LegderNBalanceSheetForm";

export default function LedgerNBalanceSheetIndex() {
    return(
        <>
            <TopBar currentPageTitle="Libro Mayor y Balances" />
            <LedgerNBalanceSheetForm />
        </>
    )
}