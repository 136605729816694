import { useEffect, useState } from "react";
import { BranchCreated } from "../models/BranchCreated";
import { useParams } from "react-router-dom";
import requests from "../../shared/api/agent";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import BranchesForm from "../components/BranchesForm";


export default function InfoBranchPage() {
  const [data, setData] = useState<BranchCreated>();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      requests.get(`/Branches/${id}`).then((response) => {
        setData({
          cityId: response.data.cityId,
          stateId: response.data.stateId,
          code: response.data.code,
          name: response.data.name,
          address: response.data.address,
          phoneNumber: response.data.phoneNumber,
          stateName:response.data.stateName,
          cityName:response.data.cityName,

        });
      });
    }
  }, [id]);

  return (
    <>
      <TopBar
        currentPageTitle={`Visualizar sucursal`}
        firstLevelPageTitle="Sucursales"
        firstLevelPageUrl="/sucursales"
      />
      <PageTitle title={`Visualizar sucursal`} />
      {data && <BranchesForm isNew={false} initialData={data} id={id} disabled/>} 
    </>
  );
}
