import DateField from "../../shared/common/presentation/DateField";
import { useState, useEffect } from "react";
import TextField2 from "../../chartOfAccounts/components/TextField2";
import { IThirdPartyClosure } from "../pages/ThirdPartyClosureIndex";
import requests from "../../shared/api/agent";
import InputSelect from "../../shared/common/forms/InputSelect";

interface ITaxType {
    id: number
  code: string
  name: string
}

export default function ThirdPartyClosureForm({handler}:{handler: (data: IThirdPartyClosure) => void}) {

    const [dateFrom, setDateFrom] = useState<string>("")
    const [dateUntil, setDateUntil] = useState<string>("")
    const [taxTypes, setTaxTypes] = useState<ITaxType[]>([])
    const [searchText, setSearchText] = useState<string>("")
    const [selectedTaxType, setSelectedTaxType] = useState<string>("")

    useEffect(() => {
        requests.get("/tax-types")
        .then(response => {setTaxTypes(response.data)}).catch(error => {console.log(error)})
    }, [])

    useEffect(() => {
        handler({
            dateFrom: dateFrom,
            dateUntil: dateUntil,
            taxType: taxTypes.find(taxTypes => taxTypes.name === selectedTaxType)?.id || -1,
            searchText: searchText
        })
    }, [dateFrom, dateUntil, searchText, selectedTaxType])

    return(
        <div className="tpcFormContainer">
            <div className="tpcFormContainer">
                <DateField label="Desde" name="Desde" value={dateFrom} onChange={(name: string, value:string) => setDateFrom(value)}/>
                <DateField label="Hasta" name="Hasta" value={dateUntil} onChange={(name: string, value:string)=> setDateUntil(value)}/>
                <InputSelect data={taxTypes.map(taxType => ({label: taxType.name, value: taxType.id}))} name="Tipo de impuesto" index="2" key={2} onChange={(name: string, value: string | number)=> setSelectedTaxType(value.toString())} />
            </div>
            <TextField2 label="Search" placeholder="Ingrese un texto para buscar" value={searchText} onChange={(value:string)=> setSearchText(value.toUpperCase())} />
        </div>
    )
}