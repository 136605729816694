import TextField2 from "../../chartOfAccounts/components/TextField2";
import DateField from "../../shared/common/presentation/DateField";
import InputSelect from "../../shared/common/forms/InputSelect";
import { ReceiptType } from "../../receiptTypes/models/receiptType";
import { CostCenter } from "../../costCenters/models/costCenter";
import { Branch } from "../../branches/models/Branch";
import { PUC } from "../../general/models/puc";
import { PropItem } from "../../chartOfAccounts/components/InputSelect2";
import { useState, useEffect } from "react";
import requests from "../../shared/api/agent";
import ModalTable from "../../shared/layouts/ModalTable";
import CreateClosureTable from "./CreateClosureTable";
import PageTitle from "../../shared/common/presentation/PageTitle";
import { Link } from "react-router-dom";
import Pagination from "../../movementsByThirdParties/components/Pagination";
import SubmitButton from "../../shared/common/forms/SubmitButton";
import { AiOutlineSearch } from "react-icons/ai";
import {CiSaveDown1} from "react-icons/ci";

export interface IPUCType {
  id: number;
  code: string;
  name: string;
}

export interface Entities {
  id: number;
  document: string;
  verificationDigit: string;
  businessName: string;
  taxTypeName: string;
}

export interface IThirdPartyClosureData {
  pucId: number;
  code: string;
  account: string;
  thirdPartyId?: number;
  thirdPartyName: string;
  document: string;
  debit?: number;
  credit?: number;
}

interface IAccounts {
  thirdPartyAccounts: string[];
  debitBalance: number;
  creditBalance: number;
}

interface ISendData {
  initalDate: string;
  endDate: string;
  entityId: number;
  branchId: number;
  taxTypeId: number;
  receiptTypeId: number;
  pucId: number;
  costCenterId: number;
  details: string;
  accounts: IAccounts;
}

const initialData: IThirdPartyClosureData[] = [
  {
    pucId: 0,
    code: "",
    account: "",
    thirdPartyName: "",
    document: "NO HAY DATOS DISPONIBLES",
  },
];

export default function CreateClosureForm() {
  const [receiptTypes, setReceiptTypes] = useState<ReceiptType[]>([]);
  const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
  const [branches, setBranches] = useState<Branch[]>([]);
  const [thirdParties, setThirdParties] = useState<PUC[]>([]);
  const [pucTypes, setPUCTypes] = useState<IPUCType[]>([]);
  const [pucTypeToShow, setPUCTypeToShow] = useState<PropItem[]>([]);
  const [entities, setEntities] = useState<Entities[]>([]);
  const [entitiesToShow, setEntitiesToShow] = useState<
    { code: string; name: string }[]
  >([]);

  const [firstRequest, setFirstRequest] =
    useState<IThirdPartyClosureData[]>(initialData);
  const [debitSum, setDebitSum] = useState<number>(0);
  const [creditSum, setCreditSum] = useState<number>(0);
  const [toShow, setToShow] = useState<number>(5);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [dataToShow, setDataToShow] =
    useState<IThirdPartyClosureData[]>(initialData);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>({
    title: "",
    data: [],
    index: 2,
  });

  const [selectedCostCenter, setSelectedCostCenter] = useState<CostCenter>(
    {} as CostCenter
  );
  const [selectedReceiptType, setSelectedReceiptType] = useState<ReceiptType>(
    {} as ReceiptType
  );
  const [selectedBranch, setSelectedBranch] = useState<Branch>({} as Branch);
  const [selectedAuxiliary, setSelectedAuxiliary] = useState<PUC>({} as PUC);
  const [selectedDateFrom, setSelectedDateFrom] = useState<string>("");
  const [selectedDateTo, setSelectedDateTo] = useState<string>("");
  const [selectedProcessDate, setSelectedProcessDate] = useState<string>("");
  const [selectedPUCType, setSelectedPUCType] = useState<IPUCType>(
    {} as IPUCType
  );
  const [selectedEntity, setSelectedEntity] = useState<Entities>(
    {} as Entities
  );
  const [selectedTaxType, setSelectedTaxType] = useState<number>();

  const [consultData, setConsultData] = useState<boolean>(false);
  const [sendData, setSendData] = useState<boolean>(false);

  useEffect(() => {
    if (sendData) {
      let aux: IAccounts = {} as IAccounts;
      aux.creditBalance = creditSum;
      aux.debitBalance = debitSum;
      aux.thirdPartyAccounts = [];
      firstRequest.forEach((item) => {
        aux.thirdPartyAccounts.push(item.thirdPartyName);
      });
      let data: ISendData = {
        initalDate: selectedDateFrom,
        endDate: selectedDateTo,
        entityId: selectedEntity.id,
        branchId: selectedBranch.id,
        taxTypeId: selectedTaxType!,
        receiptTypeId: selectedReceiptType.id,
        pucId: selectedPUCType.id,
        costCenterId: selectedCostCenter.id,
        details: "Cierre de terceros",
        accounts: aux,
      };
      requests
        .post("/third-party-closures", data)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [sendData]);

  useEffect(() => {
    if (consultData) {
      if (
        selectedDateFrom !== "" &&
        selectedDateTo !== "" &&
        selectedTaxType !== undefined &&
        selectedBranch.id !== undefined
      ) {
        requests
          .get(
            `/close-third-party-accounts?InitialDate=${selectedDateFrom}&EndDate=${selectedDateTo}&TaxTypeId=${selectedTaxType}&BranchId=${selectedBranch.id}`
          )
          .then((response) => {
            setDebitSum(response.data.debitBalance);
            setCreditSum(response.data.creditBalance);
            setFirstRequest(response.data.thirdPartyAccounts);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    setConsultData(false);
  }, [consultData]);

  useEffect(() => {
    if (selectedEntity.document) {
      requests
        .get("/tax-types")
        .then((response) => {
          let aux = response.data.find(
            (item: any) => item.name === selectedEntity.taxTypeName
          );
          setSelectedTaxType(aux.id);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [selectedEntity]);

  useEffect(() => {
    requests
      .get("/Entities")
      .then((response) => {
        setEntities(response.data);
        let aux: { code: string; name: string }[] = [];
        response.data.forEach((item: Entities) => {
          aux.push({ code: item.document, name: item.taxTypeName });
        });
        setEntitiesToShow(aux);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    requests
      .get("/puc-types")
      .then((response) => {
        setPUCTypes(response.data);
        let aux: PropItem[] = [];
        response.data.forEach((item: IPUCType) => {
          aux.push({ label: item.name, value: item.code });
        });
        setPUCTypeToShow(aux);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    requests
      .get("/pucs/auxiliary?Top=10000")
      .then((response) => {
        setThirdParties(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    requests
      .get("/branches/search?Top=1000")
      .then((response) => {
        setBranches(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    requests
      .get("/receipt-types/search?Top=10000")
      .then((response) => {
        setReceiptTypes(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    requests
      .get("/cost-centers/search?Top=1000")
      .then((response) => {
        setCostCenters(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleReceiptType = (item: any, index: number) => {
    setSelectedReceiptType(item);
  };

  const handleCostCenter = (item: any, index: number) => {
    setSelectedCostCenter(item);
  };

  const handleBranch = (item: any, index: number) => {
    setSelectedBranch(item);
  };

  const handleAuxiliary = (item: any, index: number) => {
    setSelectedAuxiliary(item);
  };

  const handleEntity = (item: any, index: number) => {
    let aux = entities.find((entity) => entity.document === item.code);
    setSelectedEntity(aux!);
  };

  useEffect(() => {
    let index = 0;
    let aux: IThirdPartyClosureData[] = [];
    firstRequest.forEach((item) => {
      if (index >= currentIndex && index < currentIndex + toShow) {
        aux.push(item);
      }
      index++;
    });
    setDataToShow(aux);
  }, [firstRequest, toShow, currentIndex]);

  return (
    <>
      {showModal && (
        <ModalTable
          modalData={modalData}
          modalHandler={() => setShowModal(false)}
        />
      )}
      <PageTitle title="Anexo de terceros">
        <Link
          to={""}
          className="btn btn-warning w-40 mr-1 mb-2 row"
          onClick={() => setConsultData(!consultData)}
        >
          <AiOutlineSearch className="text-lg"/>
          Consultar
        </Link>
        <Link
          to={""}
          className="btn btn-primary w-40 mr-1 mb-2 row"
          onClick={() => setSendData(true)}
        >
          <CiSaveDown1 className="text-lg"/>
          Guardar
        </Link>
      </PageTitle>
      <div className="ctpcFormContainer">
        <div className="grid grid-cols-3 gap-4 mb-4">
          <DateField
            label="Fecha del proceso"
            name="Fecha del proceso"
            value={selectedProcessDate}
            onChange={(name: string, value: string) => {
              setSelectedProcessDate(value);
            }}
          />
          <InputSelect
            name="Tipo de proceso"
            data={pucTypeToShow}
            index="1"
            key={1}
            onChange={(name: string, value: string | number) => {
              pucTypes.find((pucType) => {
                pucType.code === value.toString() &&
                  setSelectedPUCType(pucType);
              });
            }}
          />
          <TextField2
            label="Entidad"
            placeholder="Ingrese el nombre de la entidad"
            value={selectedEntity.document}
            onClick={() => {
              let aux = modalData;
              aux.title = "Entidad";
              aux.data = entitiesToShow;
              aux.anyHandler = handleEntity;
              setModalData(aux);
              setShowModal(true);
            }}
          />
        </div>
        <div className="ctpcFormRow ctpcFormRow-1">
          <DateField
            label="Desde"
            name="Desde"
            value={selectedDateFrom}
            onChange={(name: string, value: string) => {
              setSelectedDateFrom(value);
            }}
          />
          <DateField
            label="Hasta"
            name="Hasta"
            value={selectedDateTo}
            onChange={(name: string, value: string) => {
              setSelectedDateTo(value);
            }}
          />
          <TextField2
            label="Cuenta"
            placeholder="Ingrese el nombre de la cuenta"
            value={selectedAuxiliary.name}
            onClick={() => {
              let aux = modalData;
              aux.title = "Cuenta";
              aux.data = thirdParties;
              aux.anyHandler = handleAuxiliary;
              setModalData(aux);
              setShowModal(true);
            }}
          />
        </div>
        <div className="grid grid-cols-3 gap-4 mb-4">
          <TextField2
            label="Sucursal"
            placeholder="Ingrese el nombre de la sucursal"
            value={selectedBranch.name}
            onClick={() => {
              let aux = modalData;
              aux.title = "Sucursal";
              aux.data = branches;
              aux.anyHandler = handleBranch;
              setModalData(aux);
              setShowModal(true);
            }}
          />
          <TextField2
            label="Centro de costos"
            placeholder="Ingrese el nombre del centro de costos"
            value={selectedCostCenter.name}
            onClick={() => {
              let aux = modalData;
              aux.title = "Centro de costos";
              aux.data = costCenters;
              aux.anyHandler = handleCostCenter;
              setModalData(aux);
              setShowModal(true);
            }}
          />
          <TextField2
            label="Tipo de comprobante"
            placeholder="Ingrese el tipo de comprobante"
            value={selectedReceiptType.name}
            onClick={() => {
              let aux = modalData;
              aux.title = "Tipo de comprobante";
              aux.data = receiptTypes;
              aux.anyHandler = handleReceiptType;
              setModalData(aux);
              setShowModal(true);
            }}
          />
        </div>
      </div>
      <CreateClosureTable data={dataToShow} />
      <Pagination
        currentIndex={currentIndex}
        handlePagination={(e) => setCurrentIndex(parseInt(e))}
        handleSize={(e) => setToShow(e)}
        pageSize={toShow}
        totalData={firstRequest.length}
      />
    </>
  );
}
