interface Props {
  title: string;
  children?: JSX.Element | JSX.Element[]
}

export default function PageTitle({ title, children }: Props) {
  return (
    <div className="intro-y flex flex-col sm:flex-row items-center mt-8 mb-8">
      <h2 className="text-lg font-medium mr-auto">{title}</h2>
      <div className="w-full sm:w-auto flex mt-4 sm:mt-0">
        {children}
      </div>
    </div>
  );
}
