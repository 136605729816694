import { Link } from "react-router-dom";

interface Props {
  url: string;
}

export default function GridViewButton({ url }: Props) {
  return (
    <Link className="flex items-center mr-3" to={url}>
      <svg 
          width="24" 
          height="23" 
          viewBox="0 0 40 39" 
          fill="none" 
          xmlns="http://www.w3.org/2000/svg">
          <path d="M27.5 31.6875C28.8807 31.6875 30 30.5962 30 29.25C30 27.9038 28.8807 26.8125 27.5 26.8125C26.1193 26.8125 25 27.9038 25 29.25C25 30.5962 26.1193 31.6875 27.5 31.6875Z" fill="#93D232"/>
          <path d="M37.2211 28.6144C36.4487 26.6961 35.1159 25.0409 33.3879 23.8541C31.6599 22.6672 29.6127 22.0008 27.5 21.9375C25.3873 22.0008 23.3401 22.6672 21.6121 23.8541C19.8841 25.0409 18.5513 26.6961 17.7789 28.6144L17.5 29.25L17.7789 29.8856C18.5513 31.8039 19.8841 33.4591 21.6121 34.6459C23.3401 35.8328 25.3873 36.4992 27.5 36.5625C29.6127 36.4992 31.6599 35.8328 33.3879 34.6459C35.1159 33.4591 36.4487 31.8039 37.2211 29.8856L37.5 29.25L37.2211 28.6144ZM27.5 34.125C26.5111 34.125 25.5444 33.8391 24.7221 33.3034C23.8999 32.7677 23.259 32.0064 22.8806 31.1156C22.5022 30.2248 22.4031 29.2446 22.5961 28.2989C22.789 27.3533 23.2652 26.4846 23.9645 25.8029C24.6637 25.1211 25.5546 24.6568 26.5245 24.4687C27.4945 24.2806 28.4998 24.3771 29.4134 24.7461C30.327 25.1151 31.1079 25.7399 31.6573 26.5416C32.2068 27.3433 32.5 28.2858 32.5 29.25C32.4985 30.5425 31.9712 31.7816 31.0339 32.6955C30.0965 33.6095 28.8256 34.1235 27.5 34.125Z" fill="#93D232"/>
          <path d="M15 34.1249H10V4.87493H20V12.1874C20.0019 12.8333 20.2659 13.4522 20.7343 13.9089C21.2028 14.3657 21.8375 14.6231 22.5 14.6249H30V19.4999H32.5V12.1874C32.5045 12.0272 32.4733 11.868 32.4085 11.7207C32.3438 11.5735 32.2471 11.4416 32.125 11.3343L23.375 2.80306C23.265 2.68402 23.1297 2.58968 22.9787 2.52656C22.8276 2.46343 22.6643 2.43302 22.5 2.43743H10C9.33755 2.4393 8.70277 2.6967 8.23435 3.15342C7.76592 3.61013 7.50191 4.22904 7.5 4.87493V34.1249C7.50191 34.7708 7.76592 35.3897 8.23435 35.8464C8.70277 36.3032 9.33755 36.5606 10 36.5624H15V34.1249ZM22.5 5.36243L29.5 12.1874H22.5V5.36243Z" fill="#93D232"/>
      </svg>
    </Link>
   
  );
}


