import '../styles/ScrollableTable.css'
import { useState, useEffect } from 'react'
import toCash from '../functions/toCash'

export interface IReceiptABookAccountRank {
    branchId: number
    branchCode: string
    branchName: string
    receiptDate: string
    receiptTypeCode: string
    receiptTypeName: string
    receiptNumber: string
    thirdPartyDocument: string
    thirdPartyName: string
    accountId: number
    accountCode: string
    accountName: string
    debit: number
    credit: number
    balance: number
}

export interface IABookAccountRank {
    accountCode: string
    accountName: string
    totalDebit: number
    totalCredit: number
    previousBalance: number
    receipts: IReceiptABookAccountRank[]
}

export default function TestBalanceTable({ data }: { data: IABookAccountRank[] }) {

    const [sumDebit, setSumDebit] = useState<string>('')
    const [sumCredit, setSumCredit] = useState<string>('')
    const [sumBalance, setSumBalance] = useState<string>('')
    let sumDebitAux: number = 0
    let sumCreditAux: number = 0
    let sumBalanceAux: number = 0
    let globalIndex: number = 0

    useEffect(() => {
        setSumDebit(toCash(sumDebitAux.toString()))
        setSumCredit(toCash(sumCreditAux.toString()))
        setSumBalance(toCash(sumBalanceAux.toString()))
    }, [data])

    return (
        <div className="mt-8 max-height-35vh">
            <div className="w-full structure table-header special-row">
                <p>#</p>
                <p>Sucursal</p>
                <p>Fecha</p>
                <p>Tipo</p>
                <p>Documento</p>
                <p>Nombre Tercero</p>
                <p>Cuenta</p>
                <p>Debito</p>
                <p>Credito</p>
                <p>Saldo</p>
            </div>
            <div className="max-w-100 flex flex-col">
                {data.length > 0 ? data.map((item, index) => {
                    return (
                        <>
                            <div className="structure border-t-2 py-2 special-row text-center">
                                <p>SP</p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p>{`Saldo previo de ${item.accountCode}`}</p>
                                <p></p>
                                <p></p>
                                <p></p>
                                <p>{toCash(item.previousBalance.toString())}</p>
                            </div>
                            {item.receipts.map((receipt, index) => {
                                sumDebitAux += receipt.debit
                                sumCreditAux += receipt.credit
                                sumBalanceAux += receipt.balance
                                globalIndex++
                                return (
                                    <div className="structure text-center border-t-2 py-2">
                                        <p>{globalIndex}</p>
                                        <p>{receipt.branchCode}</p>
                                        <p className="text-nowrap">{receipt.receiptDate}</p>
                                        <p>{receipt.receiptTypeCode}</p>
                                        <p>{receipt.thirdPartyDocument}</p>
                                        <p>{receipt.thirdPartyName}</p>
                                        <p>{receipt.accountCode}</p>
                                        <p>{toCash(receipt.debit.toString())}</p>
                                        <p>{toCash(receipt.credit.toString())}</p>
                                        <p>{toCash(receipt.balance.toString())}</p>
                                    </div>
                                )
                            })}
                        </>
                    )
                }) : (
                    <div className="flex flex-row items-center justify-center py-2 border-t-2">
                        <p>No hay datos para mostrar</p>
                    </div>
                )}
            </div>
            <div className="text-center structure py-2 border-t-2 table-footer special-row ">
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p>Total</p>
                <p>{`Debito: ${data.length > 0 ? sumDebit : ''}`}</p>
                <p>{`Credito: ${data.length > 0 ? sumCredit : ''}`}</p>
                <p>{`Saldo: ${data.length > 0 ? sumBalance : ''}`}</p>
            </div>
        </div>
    )
}