import axios from "axios";
import { useNavigate } from "react-router-dom";

interface Props {
  url: string;
}

export default function GridStateRed({ url }: Props) {
  const navigate = useNavigate();

  var onClick = () => {
    axios
      .put(`${url}`)
      .then((response) => {
        console.log(`estado del item cambiado satisfactoriamente: ${response}`);
      })
      .catch((error) => {
        console.log(`error al cambiar el estado del item: ${error}`);
      })
      .finally(() => {
        navigate(0);
      });
  };
  return (
    <div className="flex items-center mr-3" onClick={() => onClick()}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 18.3333L20 23.3333L33.3333 10"
          stroke="#DC2626"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M33.3334 20.0001V30.0001C33.3334 30.8841 32.9822 31.732 32.3571 32.3571C31.732 32.9822 30.8841 33.3334 30.0001 33.3334H10.0001C9.11603 33.3334 8.26818 32.9822 7.64306 32.3571C7.01794 31.732 6.66675 30.8841 6.66675 30.0001V10.0001C6.66675 9.11603 7.01794 8.26818 7.64306 7.64306C8.26818 7.01794 9.11603 6.66675 10.0001 6.66675H25.0001"
          stroke="#DC2626"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <p className="state--inactive">Inactivo</p>
    </div>
  );
}
