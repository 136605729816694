import IAuthManager from "./IAuthManager";
import User from "./models/user";

export default class CookieAuthManager implements IAuthManager {
    
    saveAuthentication(token: string, user: User): void {
        this.setCookie('auth', token, 1);
        localStorage.setItem('displayName', user.displayName);
        localStorage.setItem('pictureUrl', '/assets/img/faces/profile.jpg');
        localStorage.setItem('user', JSON.stringify(user));
    }

    removeAuthentication() {
        document.cookie = 'auth=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        localStorage.removeItem('displayName');
        localStorage.removeItem('pictureUrl');
    }

    isAuthenticated(): boolean {

        const authToken = this.getCookie('auth');

        return authToken !== '';
    }

    getUserDisplayName(): string {
        const displayName = this.isAuthenticated() ? localStorage.getItem('displayName') : 'Anónimo';

        return displayName ?? '';
    }

    getUserPictureUrl(): string {
        const pictureUrl = this.isAuthenticated() ? localStorage.getItem('pictureUrl') : '';

        return pictureUrl ?? '/assets/img/faces/profile.jpg';
    }

    getUser() : User {
        const storedUser = localStorage.getItem('user');

        const user : User = JSON.parse(storedUser ?? '');

        return user;
    }

    getToken() : string {
        return this.getCookie('auth');
    }

    getCookie(cname: string) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
            c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    setCookie(name: string, value: string, daysToExpire: number) {
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + daysToExpire);
        var c_value = escape(value) + ((daysToExpire == null) ? "" : "; expires=" + exdate.toUTCString());
        document.cookie = name + "=" + c_value;
    }

    getSelectedBranch(): number | null | undefined {
        return Number(localStorage.getItem('selectedBranchId') ?? null);
    }

    setSelectedBranch(branchId: number): void {
        localStorage.setItem('selectedBranchId', branchId.toString());
    }
}