export default function toCash(value: string): string {
    const partes = value.split('.');
    let numeroConPuntos = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    if (partes.length === 2) {
        // Si el número tiene decimales, agregamos los decimales nuevamente
        numeroConPuntos += `.${partes[1]}`;
    }

    return `$${numeroConPuntos}`;
}