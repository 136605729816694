import toCash from "../../ABook-accountRank/functions/toCash";
import { roundDecimals } from "../../generalBalance/components/GeneralBalanceTable";
import {useState, useEffect} from 'react'

export interface StatementsChangesEquityProps {
  pucId: number;
  code: string;
  previousBalance: string;
  increase: string;
  decrease: string;
  balance: string;
}

export default function StatementsChangesEquityTable({
  data,
}: {
  data: StatementsChangesEquityProps[];
}) {

  return (
    <div className="mt-8 max-height-60vh">
      <div className="px-4 w-full InventoriesNBalanceBook-structure table-header special-row">
        <p>PUC</p>
        <p>Codigo</p>
        <p className="text-center">Balance Previo</p>
        <p className="text-center">Balance</p>
        <p className="text-center">Decremento</p>
        <p className="text-center">Incremento</p>
      </div>
      <div className="max-w-100 flex flex-col gap-4 mt-4">
        {data.length > 0 ? (
          data.map((item) => {
            return (
              <>
                <div className="px-4 w-full InventoriesNBalanceBook-structure table-row">
                  <p>{item.pucId}</p>
                  <p>{item.code}</p>
                  <p className="text-center">{item.previousBalance}</p>
                  <p className="text-center">{item.balance}</p>
                  <p className="text-center">{item.decrease}</p>
                  <p className="text-center">{item.increase}</p>
                </div>
              </>
            );
          })
        ) : (
          <div className="flex flex-row items-center justify-center">
            <p>No hay datos para mostrar</p>
          </div>
        )}
      </div>
    </div>
  );
}
