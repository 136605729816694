import { useEffect, useState } from "react";
import { City }from "../models/city";
import requests from "../../../shared/api/agent";
import Select, { SingleValue } from "react-select";

interface Props {
  dpto?:number;
  name: string;
  value: number | undefined;
  onChange: (name: string, value: number) => void;
  required?: boolean | null;
}

export default function CitySelector({
  dpto,
  name,
  value,
  onChange,
  required,
}: Props) {
  const [city, setCity] = useState<City[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true);
    requests
      .get(`/Geographic/cities?stateId=${dpto}`)
      .then((response) => setCity(response.data))
      .finally(() => setIsLoading(false));
  }, [dpto]);

  const handleChange = (newValue: SingleValue<{label: string, value: number}>) => {
    onChange(name, newValue?.value ?? 0)
  }

  return (
    <div className="input-form">
      <label
        htmlFor={name}
        className="form-label w-full flex flex-col sm:flex-row"
      >
        {" "}
        Ciudades
      </label>
      <Select
        name={name}
        options={city.map((city) => ({
          label: `${city.cityName.toUpperCase()} (${city.cityCode})`,
          value: city.id,
        }))}
        className=" "
        isLoading={isLoading}
        isSearchable={true}
        placeholder="- Seleccione -"
        onChange={handleChange}
        required={required ?? false}
        value={city.filter(city => city.id === value).map(city => { return {
          label: `${city.cityName.toUpperCase()} (${city.cityCode})`,
          value:city.id,
        } as SingleValue<{label: string, value: number}>})}
      />
    </div>
  );
}
