interface Props {
  funcion:()=>void;
}

export default function Associ({funcion}: Props) {
  return (
    <div className="flex items-center mr-3" onClick={()=>funcion()}>
      <svg 
        width="24" 
        height="24" 
        viewBox="0 0 40 40" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
        <path d="M14.6339 7.86613C15.1221 8.35428 15.1221 9.14573 14.6339 9.63388L9.63388 14.6339C9.14573 15.1221 8.35428 15.1221 7.86613 14.6339L5.36613 12.1339C4.87796 11.6457 4.87796 10.8543 5.36613 10.3661C5.85428 9.87796 6.64573 9.87796 7.13388 10.3661L8.75001 11.9822L12.8661 7.86613C13.3543 7.37796 14.1457 7.37796 14.6339 7.86613Z" fill="blue"/>
        <path d="M14.6339 20.366C15.1221 20.8543 15.1221 21.6457 14.6339 22.134L9.63388 27.134C9.14573 27.622 8.35428 27.622 7.86613 27.134L5.36613 24.634C4.87796 24.1457 4.87796 23.3543 5.36613 22.866C5.85428 22.378 6.64573 22.378 7.13388 22.866L8.75001 24.4822L12.8661 20.366C13.3543 19.878 14.1457 19.878 14.6339 20.366Z" fill="blue"/>
        <path d="M17.5 11.25C17.5 10.5597 18.0597 10 18.75 10H26.25C26.9403 10 27.5 10.5597 27.5 11.25C27.5 11.9404 26.9403 12.5 26.25 12.5H18.75C18.0597 12.5 17.5 11.9404 17.5 11.25Z" fill="blue"/>
        <path d="M6.25 0C2.79822 0 0 2.79822 0 6.25V28.75C0 32.2018 2.79822 35 6.25 35H16.4993C16.0944 34.208 15.7641 33.3715 15.5176 32.5H6.25C4.17893 32.5 2.5 30.821 2.5 28.75V6.25C2.5 4.17893 4.17893 2.5 6.25 2.5H28.75C30.821 2.5 32.5 4.17893 32.5 6.25V15.5176C33.3715 15.7641 34.208 16.0944 35 16.4993V6.25C35 2.79822 32.2018 0 28.75 0H6.25Z" fill="blue"/>
        <path d="M28.75 40C34.9632 40 40 34.9632 40 28.75C40 22.5367 34.9632 17.5 28.75 17.5C22.5367 17.5 17.5 22.5367 17.5 28.75C17.5 34.9632 22.5367 40 28.75 40ZM28.75 22.5C29.4403 22.5 30 23.0598 30 23.75V27.5H33.75C34.4403 27.5 35 28.0597 35 28.75C35 29.4403 34.4403 30 33.75 30H30V33.75C30 34.4403 29.4403 35 28.75 35C28.0597 35 27.5 34.4403 27.5 33.75V30H23.75C23.0598 30 22.5 29.4403 22.5 28.75C22.5 28.0597 23.0598 27.5 23.75 27.5H27.5V23.75C27.5 23.0598 28.0597 22.5 28.75 22.5Z" fill="blue"/>
      </svg>
    </div>
  );
}
