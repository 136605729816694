import { useEffect, useState } from "react";
import { ListThird } from "../models/listThird";
import requests from "../../shared/api/agent";
import Select, { SingleValue } from "react-select";

interface Props {
  name: string;
  value: number | undefined | null;
  onChange: (name: string, value: number) => void;
  required?: boolean | null;
}

export default function ThirdPartySelector({
  name,
  value,
  onChange,
  required,
}: Props) {
  const [thirdParties, setThirdParties] = useState<ListThird[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    requests
      .get("/third-parties/search?Top=1000")
      .then((response) => setThirdParties(response.data.data))
      .finally(() => setIsLoading(false));
  }, []);

  const handleChange = (newValue: SingleValue<{label: string, value: number}>) => {
    onChange(name, newValue?.value ?? 0)
  }
  
  return (
    <div className="input-form">
      <label
        htmlFor={name}
        className="form-label w-full flex flex-col sm:flex-row"
      >
        {" "}
        Tercero
      </label>
      <Select
        name={name}
        options={thirdParties.map((thirdParty) => ({
          label: `${thirdParty.name.toUpperCase()} (${thirdParty.document})`,
          value: thirdParty.id,
        }))}
        className=" "
        isLoading={isLoading}
        isSearchable={true}
        placeholder="- Seleccione -"
        onChange={handleChange}
        required={required ?? false}
        value={thirdParties.filter(tp => tp.id === value).map(tp => { return {
          label: `${tp.name.toUpperCase()} (${tp.document})`,
          value: tp.id,
        } as SingleValue<{label: string, value: number}>})}
      />
    </div>
  );
}
