import {useEffect, useState } from "react";
import {Responsabilities} from '../models/responsabilities'
import requests from "../../../shared/api/agent";
import Select, { SingleValue } from "react-select";

interface Props {
  name: string;
  value: number | undefined;
  onChange: (name: string, value: number) => void;
  required?: boolean | null;
}

export default function ResponsabilitiesSelector({
  name,
  value,
  onChange,
  required,
}: Props) {
  const [responsabilities, setResponsabilities] = useState<Responsabilities[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    requests
      .get("/Responsibilities")
      .then((response) => setResponsabilities(response.data.data))
      .finally(() => setIsLoading(false));
  }, []);

  const handleChange = (newValue: SingleValue<{label: string, value: number}>) => {
    onChange(name, newValue?.value ?? 0)
  }

  return (
    <div className="input-form">
      <label
        htmlFor={name}
        className="form-label w-full flex flex-col sm:flex-row"
      >
        {" "}
       Responsabilidad
      </label>
      <Select
        name={name}
        options={responsabilities.map((rp) => ({
          label: `${rp.name.toUpperCase()}-(${rp.code})`,
          value: rp.id,
        }))}
        className=" "
        isLoading={isLoading}
        isSearchable={true}
        placeholder="- Seleccione -"
        onChange={handleChange}
        required={required ?? false}
        value={responsabilities.filter(rp => rp.id === value).map(rp => { return {
          label: `${rp.name.toUpperCase()}`,
          value:rp.id,
        } as SingleValue<{label: string, value: number}>})}
      />
    </div>
  );
}
