import { useState } from "react";
import { ThirdParty } from "../models/thirdParty";
import requests from "../../shared/api/agent";
import TextField from "../../shared/common/forms/TextField";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../shared/common/presentation/ErrorMessage";
import SuccessMessage from "../../shared/common/presentation/SuccessMessage";
import BackButton from "../../shared/common/forms/BackButton";
import SubmitButton from "../../shared/common/forms/SubmitButton";
import InputSelect from "../../shared/common/forms/InputSelect";
import PersonTypeSelector from "../../general/personType/components/PersonTypeSelector";
import DocumentTypePerson from "../../general/documentTypePerson/components/DocumentTypePersonSelector";
import GenderSelector from "../../general/gender/components/GenderSelector";
import DepartmentSelector from "../../general/departmentCity/components/DepartmentSelector";
import CitySelector from "../../general/departmentCity/components/CitySelector";
import CiiuSelector from "../../general/ciiu/components/CiiuSelector";
import ResponsabilitiesSelector from "../../general/responsabilities/components/ResponsabilitiesSelector";
import Regimen from "../../general/regimen/components/Regimen";



interface Props {
  isNewThird: boolean;
  thirdParty?: ThirdParty;
  disabled?:boolean
  id?:string
  
}

export default function ThirdForm({
  isNewThird,
  thirdParty: selectThird,
  disabled,
  id
 

}: Props) {
  const initialState = {
    id: 0,
    personTypeId: 0,
    personTypeName:"",
    genderId: 0,
    genderName:"",
    documentTypeId: 0,
    documentTypeName:"",
    departmentId:0,
    departmentName:"",
    cityId: 0,
    cityName:"",
    regimeId: 0,
    regimeName:"",
    ciiuId:0,
    ciiuName:"",
    document: "",
    firstName: "",
    secondName: "",
    firstLastName: "",
    secondLastName: "",
    businessName: "",
    address: "",
    neighborhood: "",
    phone: "",
    cellPhone: "",
    email: "",
    emailFe:"",
    contactFe:"",
    responsibilityId:0,
    responsability:{id:0 , name:""}

    
  
    
   };
  
 
  const [third, setThird] = useState<ThirdParty>(
    selectThird ?? initialState
  );


  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onFieldChange = (name: string, value: string | number) => {
    setThird({ ...third, [name]: value });
  };

  const navigate = useNavigate();

  const onSubmit = (event: React.FormEvent) => {
    const dataPost = {
      personTypeId: third.personTypeId,
      documentTypeId: third.documentTypeId,
      genderId: third.genderId,
      cityId: third.cityId,
      regimeId: third.regimeId,
      ciiuId:third.ciiuId,
      document: third.document,
      firstName: third.firstName,
      secondName: third.secondName,
      firstLastName: third.firstLastName,
      secondLastName: third.secondLastName,
      businessName: third.businessName,
      address: third.address,
      neighborhood: third.neighborhood,
      phone: third.phone,
      cellPhone: third.cellPhone,
      email: third.email,
      emailFe:third.emailFe,
      contactFe:third.contactFe,
      responsibilityId:third.responsibilityId,
 

    }

    const dataPut = {
      genderId: third.genderId,
      cityId: third.cityId,
      regimeId: third.regimeId,
      ciiuId:third.ciiuId,
      firstName: third.firstName,
      secondName: third.secondName,
      firstLastName: third.firstLastName,
      secondLastName: third.secondLastName,
      businessName: third.businessName,
      address: third.address,
      neighborhood: third.neighborhood,
      phone: third.phone,
      cellPhone: third.cellPhone,
      email: third.email,
      emailFe:third.emailFe,
      contactFe:third.contactFe,
      responsibilityId:third.responsibilityId,
 

    }
    event.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");
    setSuccessMessage("");  
    if (isNewThird) {
     
      requests
        .post("/third-parties", dataPost)
        .then((response) => navigate("/terceros"))
        .catch((e) => {
          setErrorMessage(e.message);
          window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
        })
        .finally(() => setIsSubmitting(false));
    } else {
      console.log(third)
      console.log("aquiiii")
      console.log(dataPut)
      requests
        .put(`/third-parties/${id}`, dataPut)
        .then((response) =>{
           setSuccessMessage(
          "Se ha actualizado el tercero."
        );
        window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
      })
      .catch((e) => {
        setErrorMessage(e.message);
        window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
      })
      .finally(() => setIsSubmitting(false));
    }
  };

 

  return (
    <>
    <div className="intro-y mb-3">
        <ErrorMessage message={errorMessage} />
        <SuccessMessage message={successMessage} />
      </div>
    <div className="intro-y box">
      <div id="form-validation" className="p-5" onSubmit={onSubmit}>
        <div className="preview" style={{ display: "block" }}>
          <form className="validate-form" onSubmit={onSubmit} method="POST">
            <div className="grid grid-cols-2 gap-4 mb-4">
                
               {
                isNewThird ?
                <PersonTypeSelector
                    name="personTypeId"
                    onChange={onFieldChange}
                    value={third.personTypeId}
                    key="personTypeId"
                    required={true}
                    
                /> :
                <TextField
                  label="Tipo persona"
                  name="personTypeId"
                  onChange={onFieldChange}
                  value={third.personTypeName}
                  key="personTypeName"
                  disabled={true}
                  required={true}
                />


               } 

                {
                  isNewThird ?
                  <DocumentTypePerson
                    name="documentTypeId"
                    onChange={onFieldChange}
                    value={third.documentTypeId}
                    key="documentTypeId"
                    required={true}
                  />
                  :
                  <TextField
                    label="Tipo Documento"
                    name="documentTypeName"
                    onChange={onFieldChange}
                    value={third.documentTypeName}
                    key="documentTypeName"
                    disabled={true}
                    required={true}
                />
                }
            </div>    
               <div className="grid grid-cols-2 gap-4 mb-4">
        
                <TextField
                  label="Documento"
                  name="document"
                  onChange={onFieldChange}
                  value={third.document}
                  key="document"
                  disabled={!isNewThird || disabled && true}
                  required={true}
                 
                />
                {
                  disabled ?
                  <TextField
                    label="Genero"
                    name="genderName"
                    onChange={onFieldChange}
                    value={third.genderName}
                    key="genderName"
                    disabled={disabled && true}
                    required={true}
                 
                />
                 :
                 <GenderSelector
                  name="genderId"
                  onChange={onFieldChange}
                  value={third.genderId}
                  key="genderId"
                  required={true}
               />

                }

             

               </div>

               <div className="grid grid-cols-2 gap-4 mb-4">
                
                <TextField
                  label="Primer Apellido"
                  name="lastName"
                  onChange={onFieldChange}
                  value={third.firstLastName}
                  disabled={third.personTypeId===2 || disabled && true }
                  key="lastName"
                  required={true}
                />
                <TextField
                  label="Segundo Apellido"
                  name="surName"
                  onChange={onFieldChange}
                  value={third.secondLastName}
                  disabled={third.personTypeId===2 || disabled && true }
                  key="surName}"
                  required={true}
                />
               

               </div>

               
               <div className="grid grid-cols-2 gap-4 mb-4">
                <TextField
                  label="Primer Nombre"
                  name="firstName"
                  onChange={onFieldChange}
                  value={third.firstName}
                  disabled={third.personTypeId===2 || disabled && true }
                  key="firstName"
                  required={true}
                />
                <TextField
                  label="Segundo Nombre"
                  name="secondName"
                  onChange={onFieldChange}
                  value={third.secondName}
                  disabled={third.personTypeId===2 || disabled && true }
                  key="secondName"
                  required={true}
                />
               

               </div>
               
           
                <div className="grid grid-cols-1 gap-4 mb-4">
                  <TextField
                    label="Razón Social"
                    name="businessName"
                    onChange={onFieldChange}
                    value={third.businessName}
                    disabled={third.personTypeId===1 || disabled && true }
                    key="businessName"
                    required={true}
                  />
                 </div>
                 
                 
                 <div className="grid grid-cols-2 gap-4 mb-1">
                 {
                  disabled ?
                  <TextField
                    label="Departamento"
                    name="departmentName"
                    onChange={onFieldChange}
                    value={third.departmentName}  
                    disabled={disabled} 
                    key="departmentName"
                    required={true}
                /> 
                   :

                   <DepartmentSelector 
                    name="departmentId"
                    onChange={onFieldChange}
                    value={third.departmentId}
                    key="departmentId"
                    required={true}
                 />

                 }
                {disabled ?
                  <TextField
                     label="Ciudad"
                     name="cityName"
                     onChange={onFieldChange}
                     disabled={disabled}
                     value={third.cityName}
                     key="cityName"
                     required={true}
                 /> 

                 :

                 <CitySelector 
                  dpto={third.departmentId}
                  name="cityId"
                  onChange={onFieldChange}
                  value={third.cityId}
                  key="cityId"
                  required={true}
               />
                } 

               
               </div>

               <div className="grid grid-cols-2 gap-4 mb-4">
                <TextField
                  label="Dirección"
                  name="address"
                  onChange={onFieldChange}
                  value={third.address}
                  disabled={disabled && true}
                  key="address"
                  required={true}
                />
                <TextField
                  label="Barrio"
                  name="neighborhood"
                  onChange={onFieldChange}
                  value={third.neighborhood}
                  disabled={disabled && true}
                  key="neighborhood"
                  required={true}
                />
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
                <TextField
                  label="Telefono"
                  name="phone"
                  onChange={onFieldChange}
                  value={third.phone}
                  disabled={disabled && true}
                  key="phone"
                  required={true}
                />
                <TextField
                  label="Celular"
                  name="cellPhone"
                  onChange={onFieldChange}
                  value={third.cellPhone}
                  disabled={disabled && true}
                  key="cellPhone"
                  required={true}
                />
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
                <TextField
                  label="Email"
                  name="email"
                  onChange={onFieldChange}
                  value={third.email}
                  disabled={disabled && true}
                  key="email"
                  required={true}
                />
                <TextField
                  label="emailFe"
                  name="emailFe"
                  onChange={onFieldChange}
                  value={third.emailFe}
                  disabled={disabled && true}
                  key="emailFe"
                  required={true}
                />
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
                <TextField
                  label="Contacto para FE"
                  name="contactFe"
                  onChange={onFieldChange}
                  value={third.contactFe}
                  disabled={disabled && true}
                  key="contactFe"
                  required={true}
                />
                {
                  disabled ?
                  <TextField
                    label="CIIU"
                    name="ciiuName"
                    onChange={onFieldChange}
                    value={third.ciiuName}
                    disabled={disabled && true}
                    key="ciiuName"
                    required={true}
                />
                :
                <CiiuSelector 
                  name="ciiuId"
                  onChange={onFieldChange}
                  value={third.ciiuId}
                  key="ciiuId"
                  required={true}
                />
                }
              

               
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
              {
                disabled ?
                <TextField
                    label="Responsabilidad"
                    name="responsability"
                    onChange={onFieldChange}
                    value={third.responsability.name}
                    disabled={disabled && true}
                    key="responsability"
                    required={true}
                />

                :

                <ResponsabilitiesSelector
                  name="responsibilityId"
                  onChange={onFieldChange}
                  value={third.responsibilityId}
                  key="responsibilityId"
                  required={true}
                />

              }
              
              
               
                {
                  disabled ?
                  <TextField
                    label="Regimen"
                    name="regimeName"
                    onChange={onFieldChange}
                    value={third.regimeName}
                    disabled={disabled && true}
                    key="regimeName"
                    required={true}
                />
                :
                <Regimen
                  name="regimeId"
                  onChange={onFieldChange}
                  value={third.regimeId}
                  key="regimeId"
                  required={true}
            />
                  
                }
               
            </div>
            

            
            
            <div className="flex flex-col sm:flex-row items-center pt-5 ">
                <SubmitButton isSubmitting={isSubmitting} />
                <BackButton url="/terceros" />
            </div>
          </form>

        </div>  
        </div>
 
    </div>
    </>
    
  );
}
