import { Link } from "react-router-dom";
import Table from "../../shared/common/presentation/Table";
import { CostCenter } from "../models/costCenter";
import GridEditButton from "../../shared/filtered-query/GridEditButton";
import GridStateGreen from "../../shared/filtered-query/GridStateGreen";
import GridStateRed from "../../shared/filtered-query/GridStateRed";
import GridAddItems from "../../shared/filtered-query/GridAddItems";
import GridDeleteButton from "../../shared/filtered-query/GridDeleteButton";
import GridEditInabilite from "../../shared/filtered-query/GridEditInabilite";
import GridAddItemInabilite from "../../shared/filtered-query/GridAddItemInabilite";
import GridDeleteInabilite from "../../shared/filtered-query/GridDeleteInabilite";
import CostCenterMessage from "./CostCenterMessage";
import {useState} from "react";

interface Props {
  data: CostCenter[];
  onSortingChange?: (columnName: string, sortOrder: "asc" | "desc") => void;
  sortedBy?: string | null;
  ascending?: boolean | null;
}

export default function CostCenterTable({
  data,
  onSortingChange,
  sortedBy,
  ascending,
}: Props) {
  const triggerSortingChange = (
    columnName: string,
    sortOrder: "asc" | "desc"
  ) => {
    if (onSortingChange) onSortingChange(columnName, sortOrder);
  };

  const [message, setMessage] = useState<boolean>(false);
  const [url, setUrl] = useState<string>("");

  const handleMessage = (url?: string) => {
    setMessage((message) => !message);
    if (url) setUrl(url);
  }

  return (
    <>
      {message ? <CostCenterMessage handler={handleMessage} url={url}/> : null}
      <Table
        sortedBy={sortedBy}
        ascending={ascending}
        onSortingChange={triggerSortingChange}
        columns={[
          { isSortable: true, name: "Id", title: "Id" },
          { isSortable: true, name: "code", title: "Codigo" },
          { isSortable: true, name: "name", title: "Descripcion" },
          { isSortable: false, name: "isActive", title: "Estado" },
          { isSortable: false, name: "Actions", title: "" },
        ]}
      >
        {data.map((costCenter) => (
          <tr className="intro-x" key={costCenter.id}>
            <td className="w-40">
              <Link
                className="font-medium whitespace-nowrap"
                to={`/profiles/${costCenter.id}`}
              >
                {costCenter.id}
              </Link>{" "}
            </td>
            <td className="w-40">{costCenter.code}</td>
            <td className="w-40">{costCenter.name}</td>
            <td className="w-40">
              {costCenter.isActive ? (
                <div className="d-flex justifyC-center alignI-center">
                  <GridStateGreen
                    url={`/cost-centers/${costCenter.id}/status/false`}
                    handler={handleMessage}
                  />
                </div>
              ) : (
                <div className="d-flex justifyC-center alignI-center">
                  <GridStateRed
                    url={`/cost-centers/${costCenter.id}/status/true`}
                  />
                </div>
              )}
            </td>
            <td className="table-report__action w-56">
              {costCenter.isActive ? (
                <div className="flex justify-center items-center">
                  <GridEditButton url={`/costoCentro/${costCenter.id}`} />
                  <GridDeleteButton url={`/cost-centers/${costCenter.id}`} />
                </div>
              ) : (
                <div className="flex justify-center items-center">
                  <GridEditInabilite />
                  <GridDeleteInabilite />
                </div>
              )}
            </td>
          </tr>
        ))}
      </Table>
    </>
  );
}
