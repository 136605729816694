import TopBar from "../../shared/layouts/TopBar";
import GeneralBalanceForm from "../components/GeneralBalanceForm";

export default function GeneralBalanceIndex(){
    return(
        <div>
            <TopBar currentPageTitle="Balance general" />
            <GeneralBalanceForm />
        </div>
    )
}