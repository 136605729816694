import { useEffect, useState } from "react";
import InputSelect from "../../chartOfAccounts/components/InputSelect2";
import TextField2 from "../../chartOfAccounts/components/TextField2";
import DateField from "../../shared/common/presentation/DateField";
import SearchButton from "./SearchButton";
import requests from "../../shared/api/agent";
import { IPropItem } from "../../chartOfAccounts/components/ChartOfAccountsForm";
import ModalTable from "../../shared/layouts/ModalTable";
import { IPUCTree } from "../../chartOfAccounts/pages/ChartOfAccountsCreate";
import FormTable from "./FormTable";
import PageTitle from "../../shared/common/presentation/PageTitle";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";
import { AiOutlinePrinter, AiOutlineSearch } from "react-icons/ai";
import { CiExport } from "react-icons/ci";
import ReceiptTypeSelector from "../../receiptTypes/components/ReceiptTypeSelector";

export interface IBranches {
  id: number;
  code: string;
  name: string;
  address: string;
  cityId: number;
  city: string;
  stateId: number;
  state: string;
  phoneNumber: string;
  status: boolean;
}

export interface IReceiptTypes {
  id: number;
  name: string;
  increment: string;
  categoryId: number;
  categoryName: string;
  documentType: string;
  code: string;
  isActive: boolean;
}

export interface IThirdParties {
  id: number;
  document: string;
  name: string;
}

interface IReceiptCategories {
  id: number;
  name: string;
  code: string;
}

export interface IReport {
  account: string;
  branch: string;
  date: string;
  type: string;
  description: string;
  debit: number;
  credit: number;
}

const data: IReport[] = [
  {
    account: "",
    branch: "",
    date: "",
    type: "",
    description: "NO HAY DATOS DISPONIBLES",
    debit: 0,
    credit: 0,
  },
];

export default function MovementsForm() {
  const [branches, setBranches] = useState<IBranches[]>([]);
  const [receiptType, setReceiptType] = useState<IReceiptTypes[]>([]);
  const [receiptCategory, setReceiptCategory] = useState<IReceiptCategories[]>(
    []
  );
  const [thirdParties, setThirdParties] = useState<IThirdParties[]>([]);
  const [auxiliary, setAuxiliary] = useState<IPUCTree[]>([]);
  const [auxiliary2, setAuxiliary2] = useState<IPUCTree[]>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<
    { name: string; code?: string; document?: string }[]
  >([]);
  const [reports, setReports] = useState<IReport[]>(data);

  const [selectedThird, setSelectedThird] = useState<IThirdParties>({
    id: -1,
    document: "",
    name: "",
  });
  const [selectedInitialAccount, setSelectedInitialAccount] =
    useState<IPUCTree>({ id: 0, code: "", name: "" });
  const [selectedFinalAccount, setSelectedFinalAccount] = useState<IPUCTree>({
    id: -1,
    code: "",
    name: "",
  });
  const [selectedBranch, setSelectedBranch] = useState<IBranches>();
  const [selectedReceiptCategory, setSelectedReceiptCategory] =
    useState<IReceiptCategories>();
  const [selectedReceiptType, setSelectedReceiptType] =
    useState<IReceiptTypes>();

  const [toShow, setToShow] = useState<number>(5);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [reportsToShow, setReportsToShow] = useState<IReport[]>(data);

  const [title, setTitle] = useState<string>("");

  const [receiptCategoryData, setReceiptCategoryData] = useState<IPropItem[]>(
    []
  );
  const [receiptTypeData, setReceiptTypeData] = useState<IPropItem[]>([]);
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateUntil, setDateUntil] = useState<string>("");
  const [sendRequest, setSendRequest] = useState<boolean>(false);

  const handleFrom = (name: string, value: string) => {
    if (name === "Desde") {
      setDateFrom(value);
      if (dateUntil !== "") {
        const date1 = new Date(value);
        const date2 = new Date(dateUntil);
        const milisecondsByDay: number = 86400000;
        const days: number = Math.floor(
          (date2.getTime() - date1.getTime()) / milisecondsByDay
        );
        if (days > 365) {
          const date = new Date(date1.getTime() + milisecondsByDay * 366);
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();
          setDateUntil(
            `${year}-${month < 10 ? "0" + month : month}-${
              day < 10 ? "0" + day : day
            }`
          );
        }
      }
    } else {
      setDateUntil(value);
      if (dateFrom !== "") {
        const date1 = new Date(dateFrom);
        const date2 = new Date(value);
        const milisecondsByDay: number = 86400000;
        const days: number = Math.floor(
          (date2.getTime() - date1.getTime()) / milisecondsByDay
        );
        if (days > 365) {
          const date = new Date(date1.getTime() + milisecondsByDay * 366);
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();
          setDateUntil(
            `${year}-${month < 10 ? "0" + month : month}-${
              day < 10 ? "0" + day : day
            }`
          );
        }
      }
    }
  };

  const anyHandler = (data: any, index: number) => {
    if (title === "Terceros") {
      let aux: IThirdParties = thirdParties.find(
        (item) => item.name === data.name
      )!;
      setSelectedThird(aux);
    } else if (title === "Cuenta Inicial") {
      let aux: IPUCTree = auxiliary.find((item) => item.code === data.code)!;
      setSelectedInitialAccount(aux);
    } else if (title === "Cuenta Final") {
      let aux: IPUCTree = auxiliary.find((item) => item.code === data.code)!;
      setSelectedFinalAccount(aux);
    }
  };

  const handleModalData = (title: string) => {
    if (title === "Terceros") {
      let aux: { name: string; document: string }[] = [];
      thirdParties.forEach((item) => {
        aux.push({ name: item.name, document: item.document });
      });
      setModalData(aux);
    } else if (title === "Cuenta1") {
      let aux: { name: string; code?: string; document?: string }[] = [];
      auxiliary.forEach((item) => {
        aux.push({ name: item.name, code: item.code });
      });
      setModalData(aux);
    } else if (title === "Cuenta2") {
      let aux: { name: string; code?: string; document?: string }[] = [];
      auxiliary2.forEach((item) => {
        aux.push({ name: item.name, code: item.code });
      });
      setModalData(aux);
    }
  };

  const handleBranch = (value: string) => {
    setSelectedBranch(branches.find((item) => item.name === value));
  };

  const handleReceiptCategory = (value: string) => {
    setSelectedReceiptCategory(
      receiptCategory.find((item) => item.name === value)
    );
  };


  useEffect(() => {
    if (selectedInitialAccount.id >= 0) {
      requests
        .get(`/third-parties/${selectedThird.id}/accounts`)
        .then((response) => {
          let aux: IPUCTree[] = [];
          response.data.data.forEach((item: any) => {
            if (
              parseInt(selectedInitialAccount.code) <=
              parseInt(item.accountCode)
            ) {
              aux.push({
                id: item.accountId,
                code: item.accountCode,
                name: item.accountName,
              });
            }
          });
          setAuxiliary2(aux);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [selectedInitialAccount]);

  useEffect(() => {
    let aux = reports.slice(0, toShow);
    setReportsToShow(aux);
  }, [toShow]);

  useEffect(() => {
    requests
      .get("/third-parties/search?Top=1000")
      .then((response) => {
        setThirdParties(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setSelectedInitialAccount({ id: -1, code: "", name: "" });
    setSelectedFinalAccount({ id: -1, code: "", name: "" });
    if (selectedThird.id >= 0) {
      requests
        .get(`/third-parties/${selectedThird.id}/accounts`)
        .then((response) => {
          let aux: IPUCTree[] = [];
          response.data.data.forEach((item: any) => {
            aux.push({
              id: item.accountId,
              code: item.accountCode,
              name: item.accountName,
            });
          });
          setAuxiliary(aux);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [selectedThird]);

  useEffect(() => {
    requests.get("/Branches/search?Top=1000").then((response) => {
      setBranches(response.data.data);
    });
  }, []);

  useEffect(() => {
    if(selectedReceiptCategory === undefined){
      return;
    }else{
      requests.get(`/receipt-types?receiptCategoryId=${selectedReceiptCategory?.id}`).then((response) => {
        setReceiptType(response.data);
      });
    }
  }, [selectedReceiptCategory]);

  useEffect(() => {
    requests
      .get("/receipt-categories")
      .then((response) => {
        setReceiptCategory(response.data);
        let aux: IPropItem[] = [];
        response.data.forEach((item: IReceiptCategories) => {
          aux.push({ name: item.name, code: item.code });
        });
        setReceiptCategoryData(aux);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (sendRequest) {
      if (
        !selectedThird ||
        !selectedInitialAccount ||
        !selectedFinalAccount ||
        !dateFrom ||
        !dateUntil
      ) {
        return;
      } else if (selectedBranch === undefined) {
        requests
          .get(
            `/reports/movements-by-third-parties?InitialDate=${dateFrom}&EndDate=${dateUntil}&InitialAccount=${selectedInitialAccount.code}&EndAccount=${selectedFinalAccount.code}&PersonId=${selectedThird.id}`
          )
          .then((response) => {
            let aux: IReport[] = [];
            response.data.forEach((item: any) => {
              aux.push({
                account: item.account,
                branch: item.branchName,
                date: item.date,
                type: item.receiptTypeCode,
                description: item.receiptName,
                debit: item.debit,
                credit: item.credit,
              })
            });
            setReports(aux);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        requests
          .get(
            `/reports/movements-by-third-parties?InitialDate=${dateFrom}&EndDate=${dateUntil}&InitialAccount=${selectedInitialAccount.code}&EndAccount=${selectedFinalAccount.code}&BranchId=${selectedBranch.id}&PersonId=${selectedThird.id}`
          )
          .then((response) => {
            let aux: IReport[] = [];
            response.data.forEach((item: any) => {
              aux.push({
                account: item.account,
                branch: item.branchName,
                date: item.date,
                type: item.receiptTypeCode,
                description: item.receiptName,
                debit: item.debit,
                credit: item.credit,
              })
            });
            setReports(aux);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    setSendRequest(false);
  }, [sendRequest]);

  const handleReports = (data?: IReport[]) => {
    if(data){
      let aux = data.slice(0, toShow);
      setReportsToShow(aux);
    }else{
      let aux = reports.slice(0, toShow);
      setReportsToShow(aux);
    }
  };

  useEffect(() => {
    handleReports();
  }, [reports]);

  useEffect(() => {
    let aux: IReport[] = [];
    let index: number = 0;
    if (currentIndex === 0) {
      reports.forEach((item) => {
        if (index < toShow) {
          aux.push(item);
          index++;
        }
      });
    } else {
      reports.forEach((item) => {
        if (
          index >= toShow * currentIndex &&
          index < toShow * (currentIndex + 1)
        ) {
          aux.push(item);
        }
        index++;
      });
    }
    setReportsToShow(aux);
  }, [toShow, currentIndex]);

  useEffect(() => {
    if(selectedReceiptType === undefined){
      return;
    }else{
      let aux: IReport[] = [];
      reports.forEach((item) => {
        if (item.type === selectedReceiptType.code) {
          aux.push(item);
        }
      });
      handleReports(aux);
    }
  }, [selectedReceiptType]);

  const onChange = (columnName: string, sortOrder: "asc" | "desc") => {
    if (columnName === "Auxiliary") {
      if (sortOrder === "asc") {
        reports.sort((a, b) => (a.account > b.account ? 1 : -1));
      } else {
        reports.sort((a, b) => (a.account < b.account ? 1 : -1));
      }
    } else if (columnName === "Branch") {
      if (sortOrder === "asc") {
        reports.sort((a, b) => (a.branch > b.branch ? 1 : -1));
      } else {
        reports.sort((a, b) => (a.branch < b.branch ? 1 : -1));
      }
    } else if (columnName === "Date") {
      if (sortOrder === "asc") {
        reports.sort((a, b) => (a.date > b.date ? 1 : -1));
      } else {
        reports.sort((a, b) => (a.date < b.date ? 1 : -1));
      }
    } else if (columnName === "Type") {
      if (sortOrder === "asc") {
        reports.sort((a, b) => (a.type > b.type ? 1 : -1));
      } else {
        reports.sort((a, b) => (a.type < b.type ? 1 : -1));
      }
    } else if (columnName === "Debit") {
      if (sortOrder === "asc") {
        reports.sort((a, b) => (a.debit > b.debit ? 1 : -1));
      } else {
        reports.sort((a, b) => (a.debit < b.debit ? 1 : -1));
      }
    } else if (columnName === "Credit") {
      if (sortOrder === "asc") {
        reports.sort((a, b) => (a.credit > b.credit ? 1 : -1));
      } else {
        reports.sort((a, b) => (a.credit < b.credit ? 1 : -1));
      }
    }
    handleReports();
  };

  const makeCash = (amount: number) => {
    let aux: string = "20000000000";
    let length: number = aux.length;
    let result: string = "";
    if (length < 3) {
      return "$" + aux;
    } else if (length > 3 && length <= 6) {
      result =
        aux.substring(0, length - 3) + "." + aux.substring(length - 3, length);
      return "$" + result;
    } else if (length > 6 && length <= 9) {
      result =
        aux.substring(0, length - 6) +
        "." +
        aux.substring(length - 6, length - 3) +
        "." +
        aux.substring(length - 3, length);
      return "$" + result;
    } else {
      result =
        aux.substring(0, length - 9) +
        "." +
        aux.substring(length - 9, length - 6) +
        "." +
        aux.substring(length - 6, length - 3) +
        "." +
        aux.substring(length - 3, length);
      return "$" + result;
    }
  };

  return (
    <div className="mbtpForm">
      <PageTitle title="Movimientos por tercero">
        <Link
          to={``}
          className="btn btn-outline-secondary w-24 inline-block mr-2 mb-2 row"
        >
          <AiOutlinePrinter className="text-lg" />
          Imprimir
        </Link>
        <Link
          to={``}
          className="btn btn-outline-secondary w-24 inline-block mr-2 mb-2 row"
        >
          <CiExport className="text-lg" />
          Exportar
        </Link>
        <Link to={``} className="btn btn-warning w-40 mr-2 mb-2 row" onClick={()=> setSendRequest(!sendRequest)}>
          <AiOutlineSearch className="text-lg" />
          Consultar
        </Link>
      </PageTitle>
      {modal && (
        <ModalTable
          modalHandler={() => setModal(false)}
          modalData={{
            title: title,
            data: modalData,
            index: 2,
            anyHandler: anyHandler,
          }}
        />
      )}
      <div className="grid grid-cols-3 gap-4 mb-4">
        <InputSelect
          data={branches}
          key={0}
          name="Sucursales"
          onChange={handleBranch}
          defaultValue="Todas las Sucursales"
        />
        <TextField2
          label="Tercero"
          placeholder="Haga click para seleccionar"
          value={selectedThird.name}
          onClick={() => {
            setModal(true);
            handleModalData("Terceros");
            setTitle("Terceros");
          }}
        />
        <TextField2
          label="Cuenta Inicial"
          placeholder="Haga click para seleccionar"
          value={selectedInitialAccount.code}
          onClick={() => {
            setModal(true);
            handleModalData("Cuenta1");
            setTitle("Cuenta Inicial");
          }}
          disable={selectedThird.id < 0}
        />
      </div>
      <div className="grid grid-cols-3 gap-4 mb-4">
        <InputSelect
          data={receiptCategoryData}
          key={1}
          name="Categoria Comprobante"
          onChange={handleReceiptCategory}
          defaultValue="Todas las Categorias"
        />
        <ReceiptTypeSelector
          name="receiptTypeId"
          receiptCategoryId={selectedReceiptCategory?.id}
          onChange={(name:string, value:number)=> setSelectedReceiptType(receiptType.find(item => item.id === value)!)}
          value={selectedReceiptType?.id}
          key="receiptTypeId"
          required={true}
        />
        <TextField2
          label="Cuenta Final"
          placeholder="Haga click para seleccionar"
          value={selectedFinalAccount.code}
          onClick={() => {
            setModal(true);
            handleModalData("Cuenta2");
            setTitle("Cuenta Final");
          }}
          disable={selectedInitialAccount.id < 0}
        />
      </div>
      <div className="mbtpForm--flex">
        <DateField
          name="Desde"
          label="Desde"
          value={dateFrom}
          onChange={handleFrom}
        />
        <DateField
          name="Hasta"
          label="Hasta"
          value={dateUntil}
          onChange={handleFrom}
        />
      </div>
      <FormTable data={reportsToShow} onSortingChange={onChange} />
      <Pagination
        pageSize={toShow}
        handleSize={(e: any) => setToShow(parseInt(e))}
        currentIndex={currentIndex}
        totalData={reports.length}
        handlePagination={(pageIndex: string) => {
          setCurrentIndex(parseInt(pageIndex));
        }}
      />
    </div>
  );
}
