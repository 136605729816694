import { useEffect, useState } from "react";
import { Receipt } from "../models/receipt";
import { useParams } from "react-router-dom";
import requests from "../../shared/api/agent";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import LedgerAccountsForm from "../components/LedgerAccountsForm";

export default function EditLedgerAccountPage() {
  const [receipt, setReceipt] = useState<Receipt>();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      requests.get("/receipt-types/" + id).then((response) => {
        setReceipt(response.data);
      });
    }
  }, [id]);

  return (
    <>
      <TopBar
        currentPageTitle={`Editar tipo comprobante #${receipt?.id}`}
        firstLevelPageTitle="Tipos de comprobantes"
        firstLevelPageUrl="/tipos-comprobantes"
      />
      <PageTitle title={`Editar tipo comprobante #${receipt?.id}`} />
      {receipt && <LedgerAccountsForm isNewReceipt={false} receipt={receipt} />}
    </>
  );
}
