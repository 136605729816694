import { useEffect, useState } from "react";
import { CostCenter } from "../models/costCenter";
import { useParams } from "react-router-dom";
import requests from "../../shared/api/agent";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import CostCenterForm from "../components/CostCenterForm";

export default function EditCostCenterPage() {
  const [costCenter, setCostCenter] = useState<CostCenter>();

  const { id } = useParams<{ id: string }>();
  
  const loadCostCenter = () => {
    if (id) {
      requests.get("/cost-centers/" + id).then((response) => {
      setCostCenter(response.data);
      
      
      });
    }
  };

  useEffect(() => {
    loadCostCenter();
  }, [id]);

  return (
    <>
      <TopBar
        currentPageTitle={`Editar Centro de costo #${costCenter?.id}`}
        firstLevelPageTitle="Centro de costos"
        firstLevelPageUrl="/"
      />
      <PageTitle title={`Editar centro de costo ID # ${costCenter?.id}`} />
      {costCenter && (
        <CostCenterForm isNewCostCenter={false} costCenter={costCenter}  /> 
      )}
    </>
  );
}
