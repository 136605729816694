import {useEffect, useState} from "react";
import { Branch } from "../../branches/models/Branch";
import requests from "../api/agent";

export default function useGetBranch(){
    const [branch, setBranch] = useState<Branch[]>([])
    const [selectedBranch, setSelectedBranch] = useState<Branch>({} as Branch)

    const handleSelectedBranch = (value: string) => {
        let newBranch = branch.find(branch => branch.name === value)
        if(newBranch){
            setSelectedBranch(newBranch)
        }
    }

    useEffect(() => {
        requests.get(`/Branches/search?Top=10000`)
        .then(response => {
            setBranch(response.data.data)
        }).catch(error => console.log(error))
    }, [])

    return {branch, setBranch, selectedBranch, setSelectedBranch, handleSelectedBranch}
}
