interface Props {
  children?: JSX.Element | JSX.Element[] | null;
}

export default function FiltersContainer({ children }: Props) {
  return (
    <div className="grid grid-cols-12 gap-6 mt-5">
      <div className="intro-y col-auto flex flex-wrap xl:flex-nowrap items-center mt-2">
        <div className="flex w-full sm:w-auto">{children}</div>
      </div>
    </div>
  );
}
