import {useState, useEffect} from 'react';
import './input.css'

export default function Input({label, onChange, placeholder, disabled }:{
    label: string,
    value: string,
    onChange: (value: string) => void,
    placeholder?: string,
    disabled?: boolean
}){

    const [inputValue, setInputValue] = useState<string>('');

    return(
        <div className='flex flex-col w-full'>
            <label className='inputLabel'>{label}</label>
            <input type="text" className='inputContainer' value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
        </div>
    )
}