import { useState } from "react";
import { Entity } from "../models/entity";
import requests from "../../shared/api/agent";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../shared/common/presentation/ErrorMessage";
import SuccessMessage from "../../shared/common/presentation/SuccessMessage";
import BackButton from "../../shared/common/forms/BackButton";
import SubmitButton from "../../shared/common/forms/SubmitButton";
import ThirdPartySelector from "../../people/components/ThirdPartySelector";
import TaxTypeSelector from "../../general/TaxType/components/TaxTypeSelector";
import TextField from "../../shared/common/forms/TextField";



interface Props {
  isNewEntity: boolean;
  entity?: Entity;
  disabled?:boolean
  id?:string
  
}

export default function EntityForm({
  isNewEntity,
  entity: selectEntity,
  disabled,
  id
 

}: Props) {
  const initialState = {
    id: 0,
    thirdPartyId: 0,
    taxTypeId: 0,
    document:"",
    verificationDigit: 0,
    businessName:"",
    taxTypeName:"",
    
    
  
    
   };
  
 
  const [entity, setEntity] = useState<Entity>(
    selectEntity ?? initialState
  );


  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onFieldChange = (name: string, value: string | number) => {
    setEntity({ ...entity, [name]: value });
  };

  const navigate = useNavigate();

  const onSubmit = (event: React.FormEvent) => {
   
    event.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");
    setSuccessMessage("");  
    if (isNewEntity) {
     
      requests
        .post("/Entities", entity)
        .then((response) => navigate("/entidades"))
        .catch((e) => {
          setErrorMessage(e.message);
          window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
        })
        .finally(() => setIsSubmitting(false));
    } else {
     
      requests
        .put(`/Entities/${id}`, entity)
        .then((response) =>{
           setSuccessMessage(
          "Se ha actualizado el tercero."
        );
        window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
      })
      .catch((e) => {
        setErrorMessage(e.message);
        window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
      })
      .finally(() => setIsSubmitting(false));
    }
  };

 

  return (
    <>
    <div className="intro-y mb-3">
        <ErrorMessage message={errorMessage} />
        <SuccessMessage message={successMessage} />
      </div>
    <div className="intro-y box">
      <div id="form-validation" className="p-5" onSubmit={onSubmit}>
        <div className="preview" style={{ display: "block" }}>
          <form className="validate-form" onSubmit={onSubmit} method="POST">
           
              {!disabled &&
                 <div className="grid grid-cols-2 gap-4 mb-4">
                    <ThirdPartySelector
                      name="thirdPartyId"
                      onChange={onFieldChange}
                      value={entity.thirdPartyId}
                      key="thirdPartyId"
                      required={true}
              
                    /> 
                    <TaxTypeSelector
                      name="taxTypeId"
                      onChange={onFieldChange}
                      value={entity.taxTypeId}
                      key="taxTypeId"
                      required={true}
              
                    />
                </div>  
              
              } 
               
           
          {disabled &&
                   <div className="grid grid-cols-2 gap-4 mb-4">
                
                     <TextField
                        label="Documento"
                        name="document"
                        onChange={onFieldChange}
                        value={entity.document}
                        key="document"
                        disabled={disabled}
                        required={true}
                      />

                        <TextField
                        label="Razón social"
                        name="businessName"
                        onChange={onFieldChange}
                        value={entity.businessName}
                        key="businessName"
                        disabled={disabled}
                        required={true}
                      />

                      <TextField
                        label="Tipo de impuesto"
                        name="taxTypeName"
                        onChange={onFieldChange}
                        value={entity.taxTypeName}
                        key="taxTypeName"
                        disabled={disabled}
                        required={true}
                      />


                     

                      
                </div>      
               }

            
            
            <div className="flex flex-col sm:flex-row items-center pt-5 ">
                <SubmitButton isSubmitting={isSubmitting} />
                <BackButton url="/entidades" />
            </div>
          </form>

        </div>  
        </div>
 
    </div>
    </>
    
  );
}
