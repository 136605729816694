interface Props {
  message: string;
}

export default function SuccessMessage({ message }: Props) {
  if (message) {
    return (
      <div
        hidden={!message}
        className="alert alert-success-soft show flex items-center m-2"
        role="alert"
      >
        {" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          icon-name="alert-circle"
          data-lucide="alert-circle"
          className="lucide lucide-alert-circle w-6 h-6 mr-2"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="12" y1="8" x2="12" y2="12"></line>
          <line x1="12" y1="16" x2="12.01" y2="16"></line>
        </svg>{" "}
        <b>{message}</b>{" "}
      </div>
    );
  } else {
    return <></>;
  }
}
