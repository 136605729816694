import { useNavigate } from "react-router-dom";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import TextField from "../../shared/common/forms/TextField";
import { ChangePasswordCreated } from "../models/ChangePasswordCreated";
import { useState } from "react";
import requests from "../../shared/api/agent";
import SystemMessage from "../../shared/common/presentation/SystemMessage";
import CancelRed from "../../shared/icons/CancelRed";
import Success from "../../shared/icons/Success";
import SubmitButton from "../../shared/common/forms/SubmitButton";

export default function ChangePassword() {
  const initialState = {
    oldPassword: "",
    newPassword: "",
    repeatNewPassword: "",
  };
  const [data, setData] = useState<ChangePasswordCreated>(initialState);
  const [Message, setMessage] = useState<number>(0);
  const [errorMessages, setErrorMessages] = useState<string>("");

  const hasSpecialCharacters = (text: string)=>  {
    const regex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  
    return regex.test(text);
  }

  const navigate = useNavigate();

  const validateErrors = () => {
    if (
      data.oldPassword === "" ||
      data.newPassword === "" ||
      data.repeatNewPassword === ""
    ) {
      return "Debe completar todos los campos";
    }
    if (data.oldPassword === data.newPassword) {
      return "La contraseña nueva debe ser diferente a la anterior";
    }
    if (data.newPassword.length < 8 || data.newPassword.length > 16) {
      return "La contraseña debe tener entre 8 y 16 caracteres";
    }
    if (data.newPassword.includes(" ")) {
      return "La contraseña no puede tener espacios";
    }
    if (data.newPassword !== data.repeatNewPassword) {
      return "Las contraseñas no coinciden";
    }
    if (!hasSpecialCharacters(data.newPassword)) {
      return "La contraseña debe tener al menos un caracter especial";
    }
    return "Without Errors";
  };

  const onFieldChange = (name: string, value: string | number) => {
    setData({ ...data, [name]: value });
  };

  const onSubmit = (event: React.FormEvent) => {
    setErrorMessages(validateErrors());
    const dataPost = {
      oldPassword:"",
      newPassword:"",
    }
    if (errorMessages === "Without Errors") {
      dataPost.oldPassword = data.oldPassword;
      dataPost.newPassword = data.newPassword;
    }
    event.preventDefault();
    requests
      .put("/accounts/change-password", dataPost)
      .then((response) => {
        console.log("success");
        setMessage(1);
      })
      .catch((e) => {
        console.log(e)
        setMessage(2);
      });
  };

  var clickCancel = () => {
    setMessage(0);
    navigate("/cambiar-contraseña")
  };
  var clickSuccess = () => {
    navigate("/");
  };

  var message = () => {
    if (Message === 1) {
      return (
        <SystemMessage
          title="Buen Trabajo!"
          message="Contraseña actualizada"
          icon={<Success />}
          buttons={[
            <button
              type="button"
              className="btn btn-success py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
              onClick={() => {
                clickSuccess();
              }}
              style={{ color: "white" }}
            >
              Aceptar
            </button>,
          ]}
        />
      );
    } else if (Message === 2) {
      return (
        <SystemMessage
          title="Error"
          message={errorMessages}
          icon={<CancelRed />}
          buttons={[
            <button
              type="button"
              className="btn btn-danger py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
              onClick={() => {
                clickCancel();
              }}
              style={{ color: "white" }}
            >
              Aceptar
            </button>,
          ]}
        />
      );
    }
    return null;
  };
  return (
    <>
      <TopBar currentPageTitle="Cambiar contraseña" />

      <PageTitle title="Cambiar contraseña" />
      <div className=" box">
        <div id="form-validation" className="p-5">
          <div className="preview" style={{ display: "block" }}>
            <form className="validate-form" method="PUT" onSubmit={onSubmit}>
              <div className="grid grid-cols-2 gap-4 mb-1">
                <TextField
                  label="Contraseña antigua"
                  name="oldPassword"
                  onChange={onFieldChange}
                  value={data.oldPassword}
                  key="oldPassword"
                  required={true}
                  placeholder={"Este campo no debe estar vacío"}
                />
              </div>
              <div className="grid grid-cols-2 gap-4 mb-1">
                <TextField
                  type="password"
                  label="Contraseña nueva"
                  name="newPassword"
                  onChange={onFieldChange}
                  value={data.newPassword}
                  key="newPassword"
                  required={true}
                  maxLength={16}
                  placeholder={
                    "Debe contener entre 8 y 16 caracteres, no puede tener espacios"
                  }
                />
              </div>
              <div className="grid grid-cols-2 gap-4 mb-1">
                <TextField
                  type="password"
                  label="Repita la contraseña"
                  name="repeatNewPassword"
                  onChange={onFieldChange}
                  value={data.repeatNewPassword}
                  key="repeatNewPassword"
                  required={true}
                  maxLength={16}
                />
              </div>
              <SubmitButton isSubmitting={Message !== 0}/>
            </form>
          </div>
        </div>
        {message()}
      </div>
    </>
  );
}
