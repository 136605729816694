import { useEffect, useState } from "react";
import { ReceiptItem } from "../models/receipt";
import requests from "../../shared/api/agent";
import { CostCenter } from "../../costCenters/models/costCenter";
import { ThirdParty } from "../../people/models/thirdParty";
import Select, { SingleValue } from "react-select";
import { PUC } from "../../general/models/puc";
import CurrencyInput from "../../shared/common/forms/CurrencyInput";
import PlusIcon from "../../shared/icons/PlusIcon";
import MinusIcon from "../../shared/icons/MinusIcon";
import TextField from "../../shared/common/forms/TextField";
import ModalTable from "../../shared/layouts/ModalTable";

interface Props {
  items: ReceiptItem[];
  onItemsChange: (items: ReceiptItem[]) => void;
  isNewReceipt?: boolean;
}

export default function ReceiptItemsEditableTable({
  items,
  onItemsChange,
  isNewReceipt = false,
}: Props) {
  const [innerItems, setInnerItems] = useState<ReceiptItem[]>(items);
  const [thirdParties, setThirdParties] = useState<ThirdParty[]>([]);
  const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
  const [pucs, setPucs] = useState<PUC[]>([]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [costCenter, setCostCenter] = useState<string[]>([""]);
  const [thirdParty, setThirdParty] = useState<string[]>([""]);
  const [puc, setPuc] = useState<string[]>([""]);

  const [modalData, setModalData] = useState<{
    title: string;
    data: any;
    costHandler?: (cost: CostCenter, index: number) => void;
    thirdHandler?: (third: ThirdParty, index: number) => void;
    pucHandler?: (puc: PUC, index: number) => void;
    index: number;
  }>({
    title: "",
    data: [],
    index: 0,
  });

  useEffect(() => {
    requests
      .get("/third-parties/search?top=1000")
      .then((response) => setThirdParties(response.data.data));
    requests
      .get("/cost-centers/search")
      .then((response) => setCostCenters(response.data.data));
    requests
      .get("/pucs/auxiliary?top=1000")
      .then((response) => setPucs(response.data.data));
  }, []);

  useEffect(() => {
    items.map((item, index) => {
      costCenter[index] =
        costCenters.filter((cost) => cost.id === item.costCenterId)[0]?.code ??
        "";
      thirdParty[index] =
        thirdParties.filter((third) => third.id === item.thirdPartyId)[0]
          ?.document ?? "";
      puc[index] = pucs.filter((puc) => puc.id === item.pucId)[0]?.code ?? "";
    });
  }, [thirdParties, costCenters, pucs]);

  const addItem = () => {
    const newItem: ReceiptItem = {
      costCenterId: 0,
      pucId: 0,
      thirdPartyId: 0,
      base: 0,
      rate: 0,
      debit: 0,
      credit: 0,
      detail: "",
    };
    const itemsCopy = [...innerItems, newItem];
    setInnerItems((prevItems) => itemsCopy);
    onItemsChange(itemsCopy);
    console.log("Item agregado");
  };

  const removeLastItem = () => {
    if (innerItems.length > 1) {
      const itemsCopy = [...innerItems];
      itemsCopy.pop();
      setInnerItems((prevItems) => itemsCopy);
      onItemsChange(itemsCopy);
    }
  };

  const updateItem = (index: number, key: keyof ReceiptItem, value: any) => {
    setInnerItems((prevItems) => {
      const updatedItems = prevItems.map((item, idx) =>
        idx === index ? { ...item, [key]: value } : item
      );
      onItemsChange(updatedItems);
      return updatedItems;
    });
  };

  const modalHandler = (index?: number, title?: string) => {
    if (title === "Centros de costo") {
      setModalData({
        title: title,
        data: costCenters,
        costHandler: handleCostCenter,
        index: index!,
      });
    } else if (title === "Terceros") {
      setModalData({
        title: title,
        data: thirdParties,
        thirdHandler: handleThirdParty,
        index: index!,
      });
    } else if (title === "PUC") {
      setModalData({
        title: title,
        data: pucs,
        pucHandler: handlePUC,
        index: index!,
      });
    } else {
      setModalData({ title: "", data: [], index: 0 });
    }
    setShowModal(!showModal);
  };

  const handleCostCenter = (cost: CostCenter, index: number) => {
    modalHandler(index, "");
    let data = costCenter.slice();
    data[index] = cost.code;
    setCostCenter(data);
    updateItem(index, "costCenterId", cost.id);
  };
  const handleThirdParty = (third: ThirdParty, index: number) => {
    modalHandler(index, "");
    let data = thirdParty.slice();
    data[index] = third.document;
    setThirdParty(data);
    updateItem(index, "thirdPartyId", third.id);
  };
  const handlePUC = (pucd: PUC, index: number) => {
    modalHandler(index, "");
    let data = puc.slice();
    data[index] = pucd.code;
    setPuc(data);
    updateItem(index, "pucId", pucd.id);
  };

  return (
    <div className="mt-6">
      <h4>Ítems</h4>
      <table className="table table-responsive table-bordered table-striped table-sm">
        <thead>
          <tr>
            <th>Centro de Costo</th>
            <th>PUC</th>
            <th>Tercero</th>
            <th>Base</th>
            <th>Tarifa</th>
            <th>Débito</th>
            <th>Crédito</th>
            <th>Detalle</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            return (
              <tr key={index}>
                <td>
                  <div className="input-form">
                    <TextField
                      name="Centro de costo"
                      value={costCenter.filter((_, i) => i === index)[0]}
                      onClick={() => {
                        modalHandler(index, "Centros de costo");
                      }}
                      required={true}
                      disabled={showModal ? true : false}
                      placeholder={"Seleccione"}
                    />
                  </div>
                </td>
                <td>
                  <TextField
                    name="PUC"
                    value={puc.filter((_, i) => i === index)[0]}
                    onClick={() => {
                      modalHandler(index, "PUC");
                    }}
                    required={true}
                    disabled={showModal ? true : false}
                    placeholder={"Seleccione"}
                  />
                </td>
                <td>
                  <TextField
                    name="Tercero"
                    value={thirdParty.filter((_, i) => i === index)[0]}
                    onClick={() => {
                      modalHandler(index, "Terceros");
                    }}
                    required={true}
                    disabled={showModal ? true : false}
                    placeholder={"Seleccione"}
                  />
                </td>
                <td>
                  <CurrencyInput
                    name="base"
                    onChange={(name, value) => updateItem(index, "base", value)}
                    size="small"
                    key="base"
                    placeholder="$ 0"
                    required={true}
                    value={innerItems[index]?.base}
                  />
                </td>
                <td>
                  <CurrencyInput
                    name="rate"
                    onChange={(name, value) => updateItem(index, "rate", value)}
                    size="small"
                    key="rate"
                    placeholder="$ 0"
                    required={true}
                    value={innerItems[index]?.rate}
                  />
                </td>
                <td>
                  <CurrencyInput
                    name="debit"
                    onChange={(name, value) =>
                      updateItem(index, "debit", value)
                    }
                    size="small"
                    key="rate"
                    placeholder="$ 0"
                    required={true}
                    value={innerItems[index]?.debit}
                    disabled={innerItems[index]?.credit !== 0 ? true : false}
                  />
                </td>
                <td>
                  <CurrencyInput
                    name="credit"
                    onChange={(name, value) =>
                      updateItem(index, "credit", value)
                    }
                    size="small"
                    key="credit"
                    placeholder="$ 0"
                    required={true}
                    value={innerItems[index]?.credit}
                    disabled={innerItems[index]?.debit !== 0 ? true : false}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={innerItems[index]?.detail}
                    className="form-control form-control-sm mt-2"
                    onChange={(e) =>
                      updateItem(index, "detail", e.target.value)
                    }
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <button
        type="button"
        onClick={(e) => addItem()}
        className="btn btn-dark btn-sm mt-2 mr-4"
      >
        <PlusIcon />
      </button>
      <button
        type="button"
        onClick={(e) => removeLastItem()}
        className="btn btn-danger btn-sm mt-2"
      >
        <MinusIcon />
      </button>
      {showModal && (
        <ModalTable modalHandler={modalHandler} modalData={modalData} />
      )}
    </div>
  );
}
