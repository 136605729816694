import Cleave from "cleave.js/react";
import { delimiter } from "path";
import { ChangeEvent, ChangeEventHandler } from "react";

interface Props {
    name: string;
    value?: number | undefined;
    onChange: (name: string, value: number) => void;
    required?: boolean | null;
    placeholder?: string | null;
    size: 'small' | 'regular';
    disabled?: boolean | null;
}

export default function CurrencyInput({
    name,
    value,
    onChange,
    required = false,
    placeholder,
    size,
    disabled = false
}: Props) {

    const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.classList.add('touched');
    };

    return (
        <Cleave
            placeholder={placeholder ?? ''}
            options={{
                numeral: true,
                numeralThousandsGroupStyle: "thousand",
                numeralDecimalMark: ',',
                delimiter: '.',
            }}
            onChange={(e: any) => onChange(name, Number(e.target.rawValue))}
            className={`form-control ${size === 'small' ? 'form-control-sm' : ''}`}
            name={name}
            onBlur={onFocus}
            required={required ?? false}
            value={value}
            disabled={disabled ?? false}
        />
    )
}