import { useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { defaultAriaLiveMessages } from "react-select/dist/declarations/src/accessibility";
import { IPropItem } from "./ChartOfAccountsForm";
import '../styles/InputSelect2.css'

export interface PropItem {
  label:string
  value: number|string;
  id?:number
}

interface Props {
  key:number|string,
  name:string
  data:IPropItem[]
  onChange: (value: string) => void;
  required?: boolean | null;
  disabled?:boolean
  defaultValue?:string
}

export default function InputSelect({
  key,
  name,
  data,
  onChange,
  required,
  disabled,
  defaultValue = "- Seleccione -",
}: Props) {

  const [options,setOptions] = useState<PropItem[]>([])

  useEffect(()=>{
    let aux:PropItem[] = [];
    data.forEach((val,ind)=>{
      aux.push({label: val.name, value: val.code})
    })
    setOptions(aux)
  },[data])

    return(
      <div className="inputSelect-form">
        <label
          htmlFor={name}
          className="form-label w-full flex flex-col sm:flex-row "
        >
          {name}
        </label>
        <Select
          isDisabled={disabled}
          key={key}
          options={options}
          placeholder={defaultValue}
          onChange={(e)=>onChange(e!.label.toString())}
          required={required ?? false}
        />
      </div>
      )
}
