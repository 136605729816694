import PageTitle from "../../shared/common/presentation/PageTitle";
import TopBar from "../../shared/layouts/TopBar";
import Profilesfrom from "../components/ProfilesForm";

export default function CreateReceiptTypePage(){
    return (
        <>
          <TopBar firstLevelPageTitle={'Perfiles'} firstLevelPageUrl={'/perfiles'} currentPageTitle="Nuevo Perfil" />
          <PageTitle title="Nuevo tipo de comprobante">
          </PageTitle>
          <Profilesfrom isNewProfile={true}/>
        </>
      );
}