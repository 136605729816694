export default function Tools() {
    return (
        <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" style={{transform:"scale(0.4)",margin:"0px -15px 0px -5px"}}>
        <path d="M17.7623 20.4149L3.2059 34.9714C1.59803 36.5792 1.59803 39.1862 3.2059 40.7939C4.81374 42.4019 7.4206 42.4019 9.02846 40.7939L23.5849 26.2376" stroke="#F5F5F5" stroke-width="3.49004" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M32.4656 26.9875L40.4495 34.9714C42.0574 36.5791 42.0574 39.1861 40.4495 40.7939C38.8417 42.4019 36.2347 42.4019 34.6269 40.7939L21.8462 28.0133" stroke="#F5F5F5" stroke-width="3.49004" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.9327 10.7339L6.56577 12.1895L2.19884 4.9113L5.11012 2L12.3884 6.36693L10.9327 10.7339ZM10.9327 10.7339L16.759 16.5602" stroke="#F5F5F5" stroke-width="3.49004" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.7624 20.415C16.0253 15.9828 16.3649 10.1673 19.9459 6.58637C23.5268 3.00541 30.1353 2.21944 33.7745 4.4029L27.5149 10.6624L26.9332 17.0668L33.3375 16.485L39.597 10.2255C41.7806 13.8646 40.9946 20.4732 37.4137 24.0541C33.8326 27.6351 28.0172 27.9747 23.5851 26.2375" stroke="#F5F5F5" stroke-width="3.49004" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        
    );
  }
  