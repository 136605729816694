// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableTitle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid black;
}

.tableTitle--text{
    margin-left: 2%;
    margin-right: 2%;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 5px;
}
.tableContent{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;
    margin-right: 20px;
    padding: 1.8%;
    gap: 10%;
}

.tableContent--nonpair{
    background-color: #f2f2f2f2;
}

.tableContent--text{
    color: black;
    font-size: 1.0rem;
    font-weight: 300;
    min-height: 5%;
    max-height: -moz-fit-content;
    max-height: fit-content;
    overflow-wrap: break-word;
    word-wrap: break-word;
    text-align: end;
    max-width: 70%;
}
.tableContent--text:hover{
    cursor: pointer;
    color: #1a1a1a;
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/shared/layouts/ModalTable.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;IAC9B,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,QAAQ;AACZ;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;IACd,4BAAuB;IAAvB,uBAAuB;IACvB,yBAAyB;IACzB,qBAAqB;IACrB,eAAe;IACf,cAAc;AAClB;AACA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".tableTitle{\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    border-bottom: 2px solid black;\n}\n\n.tableTitle--text{\n    margin-left: 2%;\n    margin-right: 2%;\n    font-size: 1.2rem;\n    font-weight: bold;\n    margin-bottom: 5px;\n}\n.tableContent{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    margin-left: 20px;\n    margin-right: 20px;\n    padding: 1.8%;\n    gap: 10%;\n}\n\n.tableContent--nonpair{\n    background-color: #f2f2f2f2;\n}\n\n.tableContent--text{\n    color: black;\n    font-size: 1.0rem;\n    font-weight: 300;\n    min-height: 5%;\n    max-height: fit-content;\n    overflow-wrap: break-word;\n    word-wrap: break-word;\n    text-align: end;\n    max-width: 70%;\n}\n.tableContent--text:hover{\n    cursor: pointer;\n    color: #1a1a1a;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
