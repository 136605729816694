import { Link } from "react-router-dom";
import withFilteredQueryRequest from "../../shared/filtered-query/withFilteredQueryRequest";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import FoliarBooksForm from "../components/FoliarBooksForm";

export default function FoliarBooksIndexPage() {

  return (
    <>
      <TopBar currentPageTitle="Foliar libros" />
      <PageTitle title="Foliar libros">
      </PageTitle>
      <FoliarBooksForm isNewReceipt={true}/>

    </>
  );
}
