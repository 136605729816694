import "../styles/treeAccounts.css";
import { IPUCTree } from "../pages/ChartOfAccountsCreate";
import { useState, useEffect } from "react";
import requests from "../../shared/api/agent";

export default function TreeAccounting({
  data,
  handler
}: {
  data: IPUCTree[];
  handler?: (data: IPUCTree) => void;
}) {
  const [searchByCode, setSearchByCode] = useState<string>("");
  const [searchByName, setSearchByName] = useState<string>("");
  const [auxiliary, setAuxiliary] = useState<IPUCTree[]>([]);

  const [activeClass, setActiveClass] = useState<number>(0);
  const [activeGroup, setActiveGroup] = useState<number>(0);
  const [activeAccount, setActiveAccount] = useState<number>(0);
  const [activeSubAccount, setActiveSubAccount] = useState<number>(0);
  const [ActiveAuxiliaryAccount, setActiveAuxiliaryAccount] =
    useState<number>(0);

  const handleSearchByCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchByCode(event.target.value);
  };

  const handleSearchByName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchByName(event.target.value.toUpperCase());
  };

  const getLevelData = (parentCode?: string) => {
    if (searchByName.length > 0) {
      let data1 = data.filter((item: IPUCTree) => {
        return item.name.toUpperCase().includes(searchByName);
      });
      let data2 = auxiliary.filter((item: IPUCTree) => {
        return item.name.toUpperCase().includes(searchByName);
      });
      return data1.concat(data2);
    }
    if (searchByCode.length === 1) {
      return data.filter(
        (item: IPUCTree) =>
          item.code.startsWith(searchByCode) &&
          (item.code.length === searchByCode.length + 1 ||
            item.code.length === searchByCode.length)
      );
    }
    if (searchByCode.length >= 2 && searchByCode.length < 4) {
      return data.filter(
        (item: IPUCTree) =>
          item.code.startsWith(searchByCode) && item.code.length === 4
      );
    }
    if (searchByCode.length >= 4 && searchByCode.length < 6) {
      return data.filter(
        (item: IPUCTree) =>
          item.code.startsWith(searchByCode) && item.code.length === 6
      );
    }
    if (searchByCode.length >= 6 && searchByCode.length <= 8) {
      return auxiliary.filter(
        (item: IPUCTree) =>
          item.code.startsWith(searchByCode) && item.code.length === 8
      );
    }
    if (!parentCode) {
      return data.filter((item: IPUCTree) => {
        return item.code.length === 1;
      });
    } else if (parentCode.length === 1) {
      return data.filter((item: IPUCTree) => {
        return item.code.length === 2 && item.code[0] === parentCode;
      });
    } else if (parentCode.length === 2) {
      return data.filter((item: IPUCTree) => {
        return item.code.length === 4 && item.code.startsWith(parentCode);
      });
    } else if (parentCode.length === 4) {
      return data.filter((item: IPUCTree) => {
        return item.code.length === 6 && item.code.startsWith(parentCode);
      });
    } else if (parentCode.length === 6) {
      return auxiliary.filter((item: IPUCTree) => {
        return item.code.length === 8 && item.code.startsWith(parentCode);
      });
    }
  };
  useEffect(() => {
    requests
      .get("/pucs/auxiliary?Top=1000&Ascending=true")
      .then((response) => {
        setAuxiliary(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="treeTable">
      <div className="treeTable--title treeTable--title">
        <p className="treeTable__data--first">Código</p>
        <p>Nombre</p>
      </div>
      <div className="treeTable--row">
        <input
          className="treeTable--input"
          type="text"
          placeholder="Buscar por código"
          onChange={handleSearchByCode}
        />
        <input
          className="treeTable--input"
          type="text"
          placeholder="Buscar por nombre"
          onChange={handleSearchByName}
        />
      </div>
      <div>
        {searchByCode.length > 0 || searchByName.length > 0
          ? getLevelData()!.map((item: IPUCTree, index) => {
              return (
                <div key={index} className="flexDirectionColumn">
                  <div className="treeTable__data">
                    <p className="treeTable__data--first">{item.code}</p>
                    <p>{item.name}</p>
                  </div>
                </div>
              );
            })
          : getLevelData()!.map((item: IPUCTree, index) => {
              if (item.code.length === 1) {
                return (
                  <div className="flexDirectionColumn">
                    <div
                      onClick={() => {
                        if (activeClass === parseInt(item.code)) {
                          setActiveClass(0);
                          setActiveGroup(0);
                          setActiveAccount(0);
                          setActiveSubAccount(0);
                          setActiveAuxiliaryAccount(0);
                        } else {
                          setActiveClass(parseInt(item.code));
                        }
                      }}
                      key={index}
                      className="treeTable__data"
                    >
                      <p className="treeTable__data--first">{item.code}</p>
                      <p>{item.name}</p>
                    </div>
                    {activeClass === parseInt(item.code) &&
                      getLevelData(item.code)!.map((item: IPUCTree, index) => {
                        return (
                          <div className="flexDirectionColumn">
                            <div
                              onClick={() => {
                                if (activeGroup === parseInt(item.code)) {
                                  setActiveGroup(0);
                                  setActiveAccount(0);
                                  setActiveSubAccount(0);
                                  setActiveAuxiliaryAccount(0);
                                } else {
                                  setActiveGroup(parseInt(item.code));
                                }
                              }}
                              key={index}
                              className="treeTable__data--2 treeTable__data"
                            >
                              <p className="treeTable__data--first">
                                {item.code}
                              </p>
                              <p>{item.name}</p>
                            </div>
                            {activeGroup === parseInt(item.code) &&
                              getLevelData(item.code)!.map(
                                (item: IPUCTree, index) => {
                                  return (
                                    <div className="flexDirectionColumn">
                                      <div
                                        onClick={() => {
                                          if (
                                            activeAccount ===
                                            parseInt(item.code)
                                          ) {
                                            setActiveAccount(0);
                                            setActiveSubAccount(0);
                                            setActiveAuxiliaryAccount(0);
                                          } else {
                                            setActiveAccount(
                                              parseInt(item.code)
                                            );
                                          }
                                        }}
                                        key={index}
                                        className="treeTable__data--3 treeTable__data"
                                      >
                                        <p className="treeTable__data--first">
                                          {item.code}
                                        </p>
                                        <p>{item.name}</p>
                                      </div>
                                      {activeAccount === parseInt(item.code) &&
                                        getLevelData(item.code)!.map(
                                          (item: IPUCTree, index) => {
                                            return (
                                              <div className="flexDirectionColumn">
                                                <div
                                                  onClick={() => {
                                                    if (
                                                      activeAccount ===
                                                      parseInt(item.code)
                                                    ) {
                                                      setActiveSubAccount(0);
                                                      setActiveAuxiliaryAccount(
                                                        0
                                                      );
                                                    } else {
                                                      setActiveSubAccount(
                                                        parseInt(item.code)
                                                      );
                                                    }
                                                  }}
                                                  key={index}
                                                  className="treeTable__data--4 treeTable__data"
                                                >
                                                  <p className="treeTable__data--first">
                                                    {item.code}
                                                  </p>
                                                  <p>{item.name}</p>
                                                </div>
                                                {activeSubAccount ===
                                                  parseInt(item.code) &&
                                                  getLevelData(item.code)!.map(
                                                    (item: IPUCTree, index) => {
                                                      return (
                                                        <div className="flexDirectionColumn">
                                                          <div
                                                            onClick={() => {
                                                              setActiveAuxiliaryAccount(
                                                                parseInt(
                                                                  item.code
                                                                )
                                                              );
                                                              if (handler) {
                                                                handler(item);
                                                              }
                                                            }}
                                                            key={index}
                                                            className="treeTable__data treeTable__data--5 "
                                                          >
                                                            <p className="treeTable__data--first">
                                                              {item.code}
                                                            </p>
                                                            <p>{item.name}</p>
                                                          </div>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                  );
                                }
                              )}
                          </div>
                        );
                      })}
                  </div>
                );
              }
            })}
      </div>
    </div>
  );
}
