import Table from "../../shared/common/presentation/Table";
import { AiOutlineFileSearch } from "react-icons/ai";
import { Link } from "react-router-dom";
import GridViewButton from "../../shared/filtered-query/GridViewButton";

interface Props {
    data: IWithholdingReport[];
    onSortingChange?: (columnName: string, sortOrder: "asc" | "desc") => void;
    sortedBy?: string | null;
    ascending?: boolean | null;
}

export interface IWithholdingReport {
    id: number
    identification: string
    peopleName: string
    accounts: Account[]
}

export interface Account {
    accountCode: string
    accountName: string
    valueRetained: string
    percentage: number
}

export default function WithholdingTable({
    data,
    onSortingChange,
    sortedBy,
    ascending,
}: Props) {
    const triggerSortingChange = (
        columnName: string,
        sortOrder: "asc" | "desc"
    ) => {
        if (onSortingChange) onSortingChange(columnName, sortOrder);
    };

    return (
        <>
            <Table
                sortedBy={sortedBy}
                ascending={ascending}
                onSortingChange={triggerSortingChange}
                columns={[
                    { isSortable: true, name: "Identification", title: "Identificación" },
                    { isSortable: false, name: "Name", title: "Nombre" },
                    { isSortable: true, name: "CodeAccount", title: "Codigo Cuenta" },
                    { isSortable: false, name: "NameAccount", title: "Nombre Cuenta" },
                    { isSortable: true, name: "Value", title: "Valor" },
                    { isSortable: false, name: "Actions", title: "Acciones" },
                ]}
            >
                {data.map((dt, index) => <>
                    {dt.accounts.map((account, index) => (
                        <tr key={index}>
                            <td className="w-60">{dt.identification}</td>
                            <td className="w-80">{dt.peopleName}</td>
                            <td className="w-60">{account.accountCode}</td>
                            <td className="w-60">{account.accountName}</td>
                            <td className="w-60">{account.valueRetained}</td>
                            <td className="flex-row w-60 mbtpForm--TableFlex">
                                <Link to='' className="green">
                                    <GridViewButton url="" />
                                </Link>
                            </td>
                        </tr>
                    ))}
                </>)}
                {data.length === 0 && (
                    <tr key={1}>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>No hay datos para mostrar</td>
                        <td></td>
                        <td></td>
                    </tr>
                )}
            </Table>
        </>
    );
}
