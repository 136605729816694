import { Link } from "react-router-dom";
import InactiveLabel from "../../shared/common/presentation/InactiveLabel";
import Table from "../../shared/common/presentation/Table";
import { ReceiptListItem } from "../models/receiptListItem";
import DateLabel from "../../shared/common/presentation/DateLabel";
import GridEditButton from "../../shared/filtered-query/GridEditButton";
import GridEditInabilite from "../../shared/filtered-query/GridEditInabilite";


interface Props {
  data: ReceiptListItem[];
  onSortingChange?: (columnName: string, sortOrder: "asc" | "desc") => void;
  sortedBy?: string | null;
  ascending?: boolean | null;
}

export default function ReceiptsTable({
  data: receipts,
  onSortingChange,
  sortedBy,
  ascending,
}: Props) {
  const triggerSortingChange = (
    columnName: string,
    sortOrder: "asc" | "desc"
  ) => {
    if (onSortingChange) onSortingChange(columnName, sortOrder);
  };

  return (
    <Table
      sortedBy={sortedBy}
      ascending={ascending}
      onSortingChange={triggerSortingChange}
      columns={[
        { isSortable: true, name: "Date", title: "Fecha" },
        { isSortable: true, name: "ThirdParty.BusinessName", title: "Tercero" },
        { isSortable: true, name: "ReceiptType.Category.Name", title: "Categoría" },
        { isSortable: true, name: "totalAmount", title: "Total" },
        { isSortable: false, name: "Actions", title: "" },
      ]}
    >
      {receipts.map((receipt) => (
        <tr className="intro-x" key={receipt.id}>
          <td className="w-40">
            <Link
              className="font-medium whitespace-nowrap"
              to={`/comprobantes/${receipt.id}`}
            >
              <DateLabel date={receipt.issuanceDate} showTime={false} />
              <div className="text-slate-500 text-xs whitespace-nowrap mt-0.5">
                {`${receipt.receiptNumber}`}
              </div>
            </Link>{" "}
          </td>
          <td className={`w-40 ${receipt.status === "I" && "table--anulado"}` } >{receipt.thirdPartyName}</td>
          <td className={`w-40 ${receipt.status === "I" && "table--anulado"}` }>{receipt.category}</td>
          <td className={`w-40 ${receipt.status === "I" && "table--anulado"}` }>{receipt.totalAmount}</td>
          <td className="table-report__action w-56">
           {
            receipt.status=== "A" ?
            <div className="flex justify-center items-center">
              <GridEditButton url={`/comprobantes/${receipt.id}`}/>
            </div>
            :
            <div className="flex justify-center items-center">
              <GridEditInabilite />
            </div>

           }
            
          </td>
        </tr>
      ))}
    </Table>
  );
}
