import TopBar from "../../shared/layouts/TopBar";
import DetailedAuxiliaryBookForm from "../components/DetailedAuxiliaryBookForm";

export default function DetailedAuxiliaryBookIndex(){
    return(
        <>
            <TopBar currentPageTitle="Libro Auxiliar Detallado" />
            <DetailedAuxiliaryBookForm />
        </>
    )
}