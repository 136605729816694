import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import { Link } from "react-router-dom";

export default function CompanyNoExist() {
  

  
  
  return (
    <>
      <TopBar
        currentPageTitle={`No existe empresa`}
        firstLevelPageTitle="No existe empresa"
        firstLevelPageUrl="/"
      />
      <PageTitle title={`Crear una empresa`} />
     
      <Link
          to="/crearEmpresa"
          className="btn btn-primary shadow-md mr-2"
        >
          Crear una empresa
    </Link>
      
    </>
  );
}
