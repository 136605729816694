import PageTitle from "../../shared/common/presentation/PageTitle";
import TopBar from "../../shared/layouts/TopBar";
import ProfilesForm from "../components/LedgerAccountsForm";

export default function CreateLedgerAccountPage(){
    return (
        <>
          <TopBar firstLevelPageTitle={'Cuentas Contables'} firstLevelPageUrl={'/cuentasContables'} currentPageTitle="Nueva cuenta contable" />
          <PageTitle title="Nueva cuenta contable">
          </PageTitle>
          <ProfilesForm isNewReceipt={true}/>
        </>
      );
}