import Select, { SingleValue } from "react-select";

interface Props {
  name: string;
  value: number | 0;
  onChange: (name: string, value: number) => void;
  required?: boolean | null;
}

export default function Regimen({
  name,
  value,
  onChange,
  required,
}: Props) {
  const options = [
    {
      label: "RESPONSABLE DE IVA(RI)",
      value: 1,
    },
    {
      label: "NO RESPONSABLE DE IVA(NI)",
      value: 2,
    }
  ];

  const handleChange = (
    newValue: SingleValue<{ label: string; value: number }>
  ) => {
    onChange(name, newValue?.value ?? 0);
  };

  return (
    <div className="input-form">
      <label
        htmlFor={name}
        className="form-label w-full flex flex-col sm:flex-row"
      >
        {" "}
       Regimen
      </label>
      <Select
        name={name}
        options={options.map((opt) => ({
          label: `${opt.label.toUpperCase()}`,
          value: opt.value,
        }))}
        className=" "
        placeholder="- Seleccione -"
        onChange={handleChange}
        required={required ?? false}
        value={options
          .filter((tp) => tp.value === value)
          .map((opt) => {
            return {
              label: `${opt.label.toUpperCase()}`,
              value: opt.value,
            };
          })}
      />
    </div>
  );
}

