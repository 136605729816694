import ReverseInlineCheckBox from "../../summaryAuxiliaryBook/components/ReverseInlineCheckBox";
import PageTitle from "../../shared/common/presentation/PageTitle";
import { useState, useEffect } from "react";
import DateSelector from "../../generalBalance/components/dateSelector";
import { years, months } from "../../generalBalance/data/date";
import useDate from "../../shared/hooks/useDate";
import DateFieldPack from "../../shared/common/forms/DateFieldPack";
import CostCenters from "../../shared/components/CostCenters";
import InputSelect from "../../chartOfAccounts/components/InputSelect2";
import useGetBranch from "../../shared/hooks/useGetBranch";
import InlineCheckBox from "../../ABook-accountRank/components/InlineCheckBox";
import { Link } from "react-router-dom";
import { AiOutlinePrinter, AiOutlineSearch } from "react-icons/ai";
import requests from "../../shared/api/agent";
import IncomeNExpenseAnalysisTable, { IncomeNExpenseAnalysis } from "./IncomeNExpenseAnalysisTable";

export default function IncomeNExpenseAnalysisForm() {
  const [option, setOption] = useState<Number>(2);
  const [year, setYear] = useState<string>("");
  const [initialDate, setInitialDate] = useState<string>("");
  const [finalDate, setFinalDate] = useState<string>("");
  const [costCenterId, setCostCenterId] = useState<string>("");
  const [allBranches, setAllBranches] = useState<boolean>(false);
  const [allCostCenter, setAllCostCenter] = useState<boolean>(false);
  const [sendRequest, setSendRequest] = useState<boolean>(false);

  const [data, setData] = useState<IncomeNExpenseAnalysis[]>([]);

  const branches = useGetBranch();

  useEffect(() => {
    if (sendRequest) {
      if(branches.selectedBranch.id === undefined){
        setAllBranches(true);
      }
      if(costCenterId === ""){
        setAllCostCenter(true);
      }
      if (allBranches && allCostCenter) {
        if (option === 1 && year !== "") {
          requests
            .get(`/reports/monthly-comparison-income-and-expenses?Year=${year}`)
            .then((response) => setData(response.data))
            .catch((error) => console.log(error));
        } else if (option === 2 && initialDate !== "" && finalDate !== "") {
          requests
            .get(
              `/reports/monthly-comparison-income-and-expenses?InitialDate=${initialDate}&FinalDate=${finalDate}`
            )
            .then((response) => setData(response.data))
            .catch((error) => console.log(error));
        }
      } else if (allBranches && !allCostCenter && costCenterId !== "") {
        if (option === 1 && year !== "") {
          requests
            .get(
              `/reports/monthly-comparison-income-and-expenses?CostCenterId=${costCenterId}&Year=${year}`
            )
            .then((response) => setData(response.data))
            .catch((error) => console.log(error));
        } else if (option === 2 && initialDate !== "" && finalDate !== "") {
          requests
            .get(
              `/reports/monthly-comparison-income-and-expenses?CostCenterId=${costCenterId}&InitialDate=${initialDate}&FinalDate=${finalDate}`
            )
            .then((response) => setData(response.data))
            .catch((error) => console.log(error));
        }
      } else if (
        !allBranches &&
        allCostCenter &&
        branches.selectedBranch.id !== undefined &&
        costCenterId !== ""
      ) {
        if (option === 1 && year !== "") {
          requests
            .get(
              `/reports/monthly-comparison-income-and-expenses?BranchId=${branches.selectedBranch.id}&CostCenterId=${costCenterId}&Year=${year}`
            )
            .then((response) => setData(response.data))
            .catch((error) => console.log(error));
        } else if (option === 2 && initialDate !== "" && finalDate !== "") {
          requests
            .get(
              `/reports/monthly-comparison-income-and-expenses?BranchId=${branches.selectedBranch.id}&CostCenterId=${costCenterId}&InitialDate=${initialDate}&FinalDate=${finalDate}`
            )
            .then((response) => setData(response.data))
            .catch((error) => console.log(error));
        }
      }
      setSendRequest(false);
    }
  }, [sendRequest]);

  return (
    <>
      <PageTitle title="Analisis de ingreso y gastos">
        <Link
          to={``}
          className="btn btn-outline-secondary w-24 inline-block mr-2 row"
        >
          <AiOutlinePrinter className="text-lg" />
          Imprimir
        </Link>
        <Link
          to=""
          className="btn btn-warning w-40 mr-2 row"
          onClick={() => setSendRequest(true)}
        >
          <AiOutlineSearch className="text-lg" />
          Consultar
        </Link>
      </PageTitle>
      <div className="box px-8 py-4 flex flex-col">
        <div className="flex flex-row">
          <div className="flex flex-col gap-6 w-1/4">
            <ReverseInlineCheckBox
              name="Cierre de periodo"
              handler={() => setOption(1)}
              value={option === 1}
            />
            <ReverseInlineCheckBox
              name="Corte de fecha"
              handler={() => setOption(2)}
              value={option === 2}
            />
          </div>
          <div className="w-1/4">
            {option === 1 ? (
              <DateSelector
                data={years}
                handler={(value) => setYear(value)}
                title="year"
              />
            ) : (
              <DateFieldPack
                dateHandler={(date) => {
                  setInitialDate(date[0]);
                  setFinalDate(date[1]);
                }}
              />
            )}
          </div>
          <div className="flex flex-row gap-2 w-3/5">
            <div className="w-1/4">
              <CostCenters handleId={(id) => setCostCenterId(id)} />
            </div>
            <ReverseInlineCheckBox
              name="Todos los centros de costo"
              handler={() => setAllCostCenter(!allCostCenter)}
            />
          </div>
        </div>
        <div className="flex flex-row gap-2 w-1/2 items-center">
          <div className="w-2/5">
            <InputSelect
              data={branches.branch}
              onChange={(value) => branches.handleSelectedBranch(value)}
              name="Sucursal"
              key={1}
              disabled={allBranches}
            />
          </div>
          <div className="mt-6">
            <ReverseInlineCheckBox
              name="Todas las sucursales"
              handler={() => setAllBranches(!allBranches)}
            />
          </div>
        </div>
        <IncomeNExpenseAnalysisTable data={data} />
      </div>
    </>
  );
}
