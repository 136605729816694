import '../../ABook-accountRank/styles/ScrollableTable.css'
import { useState, useEffect } from 'react'
import toCash from '../../ABook-accountRank/functions/toCash'

export interface ITestBalanceReport {
    pucId: number
    pucCode: string
    pucName: string
    credit: number
    debit: number
    previousBalance: number
    balance: number
    nature: string
}

export default function TestBalanceTable({ data }: { data: ITestBalanceReport[] }) {

    const [sumBefore, setSumBefore] = useState<string>('')
    const [sumDebit, setSumDebit] = useState<string>('')
    const [sumCredit, setSumCredit] = useState<string>('')
    const [sumBalance, setSumBalance] = useState<string>('')

    useEffect(() => {
        if(data.length > 0){
            let auxBefore = 0
            let auxDebit = 0
            let auxCredit = 0
            let auxBalance = 0
            data.forEach((item) => {
                auxBefore += item.previousBalance
                auxDebit += item.debit
                auxCredit += item.credit
                auxBalance += item.balance
            })
            setSumBefore(toCash(auxBefore.toString()))
            setSumDebit(toCash(auxDebit.toString()))
            setSumCredit(toCash(auxCredit.toString()))
            setSumBalance(toCash(auxBalance.toString()))
        }
    }, [data])

    return (
        <div className="mt-8 max-height-35vh">
            <div className="px-4 w-full TestBalance-structure table-header special-row">
                <p>Codigo</p>
                <p>Detalles</p>
                <p>Saldo Anterior</p>
                <p>Debito</p>
                <p>Credito</p>
                <p>Saldo</p>
                <p>Naturaleza</p>
            </div>
            <div className="max-w-100 flex flex-col">
                {data.length > 0 ? data.map((item, index) => {
                    
                    return (
                        <>
                            <div className="TestBalance-structure border-t-2 py-2 px-4">
                                <p>{item.pucCode}</p>
                                <p>{item.pucName}</p>
                                <p>{toCash(item.previousBalance.toString())}</p>
                                <p>{toCash(item.debit.toString())}</p>
                                <p>{toCash(item.credit.toString())}</p>
                                <p>{toCash(item.balance.toString())}</p>
                                <p className='text-center'>{item.nature}</p>
                            </div>
                        </>
                    )
                }) : (
                    <div className="flex flex-row items-center justify-center py-2 border-t-2">
                        <p>No hay datos para mostrar</p>
                    </div>
                )
                }
            </div>
            <div className="TestBalance-structure py-2 border-t-2 table-footer special-row ">
                <p></p>
                <p></p>
                <p><p>Anterior:</p>{sumBefore}</p>
                <p><p>Debito:</p>{sumDebit}</p>
                <p><p>Credito:</p>{sumCredit}</p>
                <p><p>Balance</p>{sumBalance}</p>
                <p></p>
            </div>
        </div>
    )
}