import { useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { defaultAriaLiveMessages } from "react-select/dist/declarations/src/accessibility";

export interface PropItem {
  label:string
  value: number|string;
}
interface Props {
  key:number|string,
  index:string
  name:string
  data:PropItem[]
  onChange?: (name:string, value: number|string) => void;
  defaultValue?:number;
  required?: boolean | null;
  disabled?:boolean
}

export default function InputSelect({
  key,
  index,
  name,
  data,
  onChange,
  defaultValue,
  required,
  disabled
}: Props) {
  const handleChange = (value: number|string|undefined) => {
    if(index!==undefined && value!==undefined && onChange!==undefined){
      onChange(index,value);
    }
  };
  if(defaultValue){
    if(defaultValue!==-1){
      const value = function (value:number):PropItem{
        return(data.filter((val,ind)=>val.value === defaultValue)[0])
      }
      return(
        <div className="input-form">
          <label
            htmlFor={name}
            className="form-label w-full flex flex-col sm:flex-row "
          >
            {name}
          </label>
          <Select
            isDisabled={disabled}
            key={key}
            options={data}
            defaultValue={value(defaultValue)}
            placeholder="- Seleccione -"
            onChange={(e)=>handleChange(e?.value)}
            required={required ?? false}
          />
        </div>)
    }else{
      return(
        <div className="input-form">
        </div>)
    }
  }else{
    return(
      <div className="input-form">
        <label
          htmlFor={name}
          className="form-label w-full flex flex-col sm:flex-row "
        >
          {name}
        </label>
        <Select
          isDisabled={disabled}
          key={key}
          options={data}
          placeholder="- Seleccione -"
          onChange={(e)=>handleChange(e?.label)}
          required={required ?? false}
        />
      </div>
      )
  }
}
