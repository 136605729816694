import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import requests from "../../shared/api/agent";
import ThirdPartyClosureForm from "../components/ThirdPartyClosureForm";
import "../styles/thirdPartyClosureForm.css";
import ThirdPartyClosureTable from "../components/ThirdPartyClosureTable";
import Pagination from "../../movementsByThirdParties/components/Pagination";
import { AiOutlinePrinter } from "react-icons/ai";

export interface IThirdPartyClosure {
  dateFrom: string;
  dateUntil: string;
  taxType: number;
  searchText: string;
}

export interface IThirdPartyClosureData {
  pucId: number | null;
  code: string;
  account: string;
  thirdPartyId: number | null;
  thirdPartyName: string;
  document: string;
  debit: number | null;
  credit: number | null;
}

export default function ThirdPartyClosureIndex() {
  const [data, setData] = useState<IThirdPartyClosure>(
    {} as IThirdPartyClosure
  );
  const [closureData, setClosureData] = useState<IThirdPartyClosureData[]>([
    {
      pucId: null,
      code: "",
      account: "",
      thirdPartyId: null,
      thirdPartyName: "NO HAY DATOS DISPONIBLES",
      document: "",
      debit: null,
      credit: null,
    },
  ]);
  const [closuredataCopy, setClosureDataCopy] = useState<IThirdPartyClosureData[]>([
    {
      pucId: null,
      code: "",
      account: "",
      thirdPartyId: null,
      thirdPartyName: "NO HAY DATOS DISPONIBLES",
      document: "",
      debit: null,
      credit: null,
    },
  ]);
  const [toShowClosureData, setToShowClosureData] = useState<
    IThirdPartyClosureData[]
  >([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(5);
  const [creditBalance, setCreditBalance] = useState<number>(0);
  const [debitBalance, setDebitBalance] = useState<number>(0);

  const handleData = (data: IThirdPartyClosure) => {
    setData(data);
  };

  useEffect(() => {
    let aux: IThirdPartyClosureData[] = [];
    let index: number = 0;
    if (currentIndex === 0) {
      closureData.forEach((element) => {
        if (index < pageSize) {
          aux.push(element);
          index++;
        }
      });
    } else {
      closureData.forEach((element) => {
        if (
          index >= pageSize * currentIndex &&
          index < pageSize * (currentIndex + 1)
        ) {
          aux.push(element);
        }
        index++;
      });
    }
    setToShowClosureData(aux);
  }, [pageSize, closureData, currentIndex]);

  useEffect(() => {
    let temp: IThirdPartyClosureData[] = closuredataCopy;
    setClosureData(temp);
    if (data.searchText !== "") {
      let aux: IThirdPartyClosureData[] = [];
      temp.forEach((element) => {
        if (
            element.code.includes(data.searchText) ||
            element.account.includes(data.searchText) ||
            element.thirdPartyName.includes(data.searchText) ||
            element.document.includes(data.searchText)
          ) {
            aux.push(element);
          }
      });
      setClosureData(aux);
      setToShowClosureData(aux);
    }
  }, [data.searchText]);

  useEffect(() => {
    if (
      data.dateFrom &&
      data.dateUntil &&
      data.taxType &&
      data.dateFrom !== "" &&
      data.dateUntil !== "" &&
      data.taxType >= 0 &&
      data.searchText === ""
    ) {
      setCurrentIndex(0);
      requests
        .get(
          `/close-third-party-accounts?InitialDate=${data.dateFrom}&EndDate=${data.dateUntil}&TaxTypeId=${data.taxType}`
        )
        .then((response) => {
          setDebitBalance(response.data.debitBalance);
          setCreditBalance(response.data.creditBalance);
          if (response.data.thirdPartyAccounts.length === 0) {
            setClosureData([
              {
                pucId: null,
                code: "",
                account: "",
                thirdPartyId: null,
                thirdPartyName: "NO HAY DATOS DISPONIBLES",
                document: "",
                debit: null,
                credit: null,
              },
            ]);
          } else {
            setClosureData(response.data.thirdPartyAccounts);
          }
          setClosureDataCopy(response.data.thirdPartyAccounts);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [data.dateFrom, data.dateUntil, data.taxType, data.searchText]);

  return (
    <>
      <TopBar currentPageTitle="Cierre de terceros" />
      <PageTitle title="Cierre de terceros">
        <Link
          to="/Consultas/Cierre-De-Terceros"
          className="btn btn-warning shadow-md mr-2 row"
        >
          <AiOutlinePrinter className="text-lg" />
          Imprimir
        </Link>
        <Link
          to="/Consultas/Cierre-De-Terceros/crear"
          className="btn btn-primary shadow-md mr-2"
        >
          Crear Cierre de terceros
        </Link>
      </PageTitle>
      <ThirdPartyClosureForm handler={handleData} />
      <ThirdPartyClosureTable data={toShowClosureData} />
      <Pagination
        currentIndex={currentIndex}
        handlePagination={(pageIndex: string) => {
          setCurrentIndex(parseInt(pageIndex));
        }}
        handleSize={(pageSize: number) => {
          setPageSize(pageSize);
        }}
        pageSize={pageSize}
        totalData={closureData.length}
      />
    </>
  );
}
