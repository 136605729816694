import { useState, useEffect } from "react";
import "../styles/chartOfAccounts.css";
import TextField2 from "./TextField2";
import InputSelect2 from "./InputSelect2";
import { IPUCTree } from "../pages/ChartOfAccountsCreate";
import BitSelect from "./BitSelect";
import requests from "../../shared/api/agent";

export interface IPropItem {
  name: string;
  code: string;
  id?: number;
  document?: string;
}

const Nature:IPropItem[]= [
  { name: "DEBITO", code: "D" },
  { name: "CREDITO", code: "C" },
];

export default function ChartOfAccountsForm({
  data,
  handlerData,
  handleOthers,
  refresh
}: {
  data: IPUCTree[];
  handlerData: (code: string, name?: string) => void;
  handleOthers: (name: string, value: string) => void;
  refresh?: boolean;
}) {
  const [PUCData, setPUCData] = useState<IPUCTree[]>(data);
  const [inputData, setInputData] = useState<string>("");
  const [inputName, setInputName] = useState<string>("");
  const [base, setBase] = useState<boolean>(false);
  const [niif, setNiif] = useState<boolean>(false);
  const [TaxType, setTaxType] = useState<IPropItem[]>([]);
  const [type, setType] = useState<IPropItem[]>([]);

  useEffect(() => {
    requests.get("/puc-types").then((response) => {
      setType(response.data);
    });
  }, []);

  useEffect(() => {
    requests.get("/tax-types").then((response) => {
      setTaxType(response.data);
    });
  }, []);

  useEffect(() => {
    if(PUCData[3].code.length === 0){
      setInputData("");
    }else{
      setInputData(PUCData[3].code);
    }
  }, [refresh]);

  const auxHandler = (value: string) => {
    if (value.length <= 8) {
      let aux: IPUCTree[] = PUCData;
      if (value.length === 0) {
        aux[0].code = "";
        aux[1].code = "";
        aux[2].code = "";
        aux[3].code = "";
        aux[4].code = "";
      } else if (value.length === 1) {
        aux[0].code = value[0];
        aux[1].code = "";
        aux[1].name = "";
      } else if (value.length === 2) {
        aux[1].code = value[0].concat(value[1]);
        aux[2].code = "";
        aux[2].name = "";
      } else if (value.length === 4) {
        aux[2].code = value[0].concat(value[1], value[2], value[3]);
        aux[3].code = "";
        aux[3].name = "";
      } else if (value.length === 6) {
        aux[3].code = value[0].concat(
          value[1],
          value[2],
          value[3],
          value[4],
          value[5]
        );
        aux[4].code = "";
        aux[4].name = "";
      } else if (value.length === 8) {
        aux[4].code = value[0].concat(
          value[1],
          value[2],
          value[3],
          value[4],
          value[5],
          value[6],
          value[7]
        );
      }
      setInputData(value);
    }
    if (value.length === 8) {
      handlerData(value);
    }
  };

  const handleType = (value: string) => {
    handleOthers("Tipo", value);
  };

  const handleTaxType = (value: string) => {
    handleOthers("Impuesto", value);
  };

  const handleNature = (value: string) => {
    handleOthers("Naturaleza", value);
  };

  const handleBase = () => {
    handleOthers("Base", base ? "false" : "true");
    setBase(!base);
  };

  const handleNiif = () => {
    handleOthers("Niif", niif ? "false" : "true");
    setNiif(!niif);
  };

  const handleInputName = (data: string) => {
    handlerData(inputData, data);
  };

  return (
    <div className="formContainer">
      <div className="formContainer--row">
        <TextField2
          required
          label="Clase"
          value={PUCData[0].code || ""}
          placeholder="Codigo Clase"
        />
        <input
          required
          placeholder="Nombre Clase"
          className="form-control formContainer--item2"
          type="text"
          value={PUCData[0].name || ""}
        />
      </div>
      <div className="formContainer--row">
        <TextField2
          required
          label="Grupo"
          value={PUCData[1].code || ""} 
          placeholder="Codigo Grupo"
        />
        <input
          required
          placeholder="Nombre Grupo"
          className="form-control formContainer--item2"
          type="text"
          value={PUCData[1].name || ""}
        />
      </div>
      <div className="formContainer--row">
        <TextField2
          required
          label="Cuenta"
          value={PUCData[2].code || ""}
          placeholder="Codigo Cuenta"
        />
        <input
          required
          placeholder="Nombre Cuenta"
          className="form-control formContainer--item2"
          type="text"
          value={PUCData[2].name || ""}
        />
      </div>
      <div className="formContainer--row">
        <TextField2
          required
          label="SubCuenta"
          value={PUCData[3].code || ""}
          placeholder="Codigo SubCuenta"
        />
        <input
          required
          placeholder="Nombre SubCuenta"
          className="form-control formContainer--item2"
          type="text"
          value={PUCData[3].name}
        />
      </div>
      <div className="formContainer--row">
        <TextField2
          onChange={auxHandler}
          label="Auxiliar"
          value={inputData}
          placeholder="Codigo Auxiliar"
        />
        <input
          required
          placeholder="Nombre Auxiliar"
          className="form-control formContainer--item2"
          type="text"
          value={inputName}
          onChange={(e) => {
            setInputName(e.target.value);
            handleInputName(e.target.value);
          }}
        />
      </div>
      <div className="formContainer--row">
        <InputSelect2
          key={1}
          onChange={handleType}
          required
          name="Tipo"
          data={type}
        />
        <InputSelect2
          key={2}
          onChange={handleTaxType}
          required
          name="Tipo de Impuesto"
          data={TaxType}
        />
      </div>
      <div className="formContainer--row">
        <BitSelect handler={handleBase}
        value={base} title="Cuenta Requiere Base" />
        <BitSelect
            handler={handleNiif}
          value={niif}
          title="Cuenta diferencia niff o aporte fiscal"
        />
      </div>
      <div className="formContainer--row">
        <InputSelect2
          key={3}
          onChange={handleNature}
          required
          name="Naturaleza"
          data={Nature}
        />
      </div>
    </div>
  );
}
