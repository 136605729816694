import PageTitle from "../../shared/common/presentation/PageTitle";
import TopBar from "../../shared/layouts/TopBar";
import ExplanatoryNotesForm from "../components/ExplanatoryNotesForm";


export default function CreateExplanatoryNotePage(){
    return (
        <>
          <TopBar firstLevelPageTitle={'Notas Aclaratorias'} firstLevelPageUrl={'/notasAclaratorias'} currentPageTitle="Nueva nota aclaratoria" />
          <PageTitle title="Nueva nota aclaratoria">
          </PageTitle>
          <ExplanatoryNotesForm isNewNote={true}/>
        </>
      );
}