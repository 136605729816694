import PageTitle from "../../shared/common/presentation/PageTitle";
import TopBar from "../../shared/layouts/TopBar";
import "../styles/chartOfAccounts.css";
import TreeAccounting from "../components/TreeAccounting";
import { useState, useEffect } from "react";
import requests from "../../shared/api/agent";
import EditChartOfAccountsForm from "../components/EditChartOfAccountsForm";
import { PropItem } from "../../shared/common/forms/InputSelect";
import SubmitButton from "../../shared/common/forms/SubmitButton";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import Message from "../components/Message";

export interface IPUCTree {
  id: number;
  code: string;
  name: string;
  isBase?: boolean;
  isNature?: string;
}

export interface IPUCPUTRequest{
    name: string
    pucTypeId: number
    taxTypeId: number
    isBase: boolean
    adjustmentNiif: boolean
    isNature: string
}

const dataToSubmit: IPUCPUTRequest = {
  name: '',
  pucTypeId: 0,
  taxTypeId: 0,
  isBase: false,
  adjustmentNiif: false,
  isNature: "",
};

export default function ChartOfAccountsIndex() {
  const navigate = useNavigate();
    const location = useLocation();

  const [PUCData, setPUCData] = useState<IPUCTree[]>([]);
  const [name, setName] = useState<string>("");
  let selectedData: IPUCTree[] = [
    { id: 0, code: "", name: "" },
    { id: 0, code: "", name: "" },
    { id: 0, code: "", name: "" },
    { id: 0, code: "", name: "" },
    { id: 0, code: "", name: "", isBase: false, isNature: "" },
  ];
  const [selectedAux, setSelectedAux] = useState<IPUCTree>();
  const [message, setMessage] = useState<string>("");
  const [typeMessage, setTypeMessage] = useState<number>(0);
  const [auxiliary, setAuxiliary] = useState<IPUCTree[]>([]);
  const [sendRequest, setSendRequest] = useState<boolean>(false)

  useEffect(() => {
    if(sendRequest){
        console.log(dataToSubmit)
        if(dataToSubmit.name === ""){
            setMessage("Debe realizar minimo un cambio para guardar")
            setTypeMessage(2)
        }else{
            requests.put(`/pucs/${location.pathname.split("/")[2]}`,dataToSubmit)
            .then((response)=>{
                console.log(response)
                setMessage("PUC Contable editado correctamente")
                setTypeMessage(1)
                setSendRequest(false)
            })
            .catch((error)=>{
                setMessage("Error al editar PUC Contable")
                setTypeMessage(2)
                setSendRequest(false)
            })
        }
    }
    setSendRequest(false)
  }, [sendRequest]);

  useEffect(() => {
      requests
        .get("/pucs/parent")
        .then((response) => {
          setPUCData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
  },[]);

  useEffect(() => {
    requests
      .get("/pucs/auxiliary?Top=1000&Ascending=true")
      .then((response) => {
        setAuxiliary(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if(auxiliary.length > 0){
      setSelectedAux(auxiliary.filter((val) => val.id.toString() === location.pathname.split("/")[2])[0])
    }
  }, [auxiliary]);

  useEffect(() => {
    if (selectedAux) {
      let aux: IPUCTree[] = [];
      aux = PUCData.filter(
        (val) => val.code.length === 1 && val.code === selectedAux.code[0]
      );
      selectedData[0] = aux[0];
      aux = PUCData.filter(
        (val) => val.code.length === 2 && selectedAux.code.startsWith(val.code)
      );
      selectedData[1] = aux[0];
      aux = PUCData.filter(
        (val) => val.code.length === 4 && selectedAux.code.startsWith(val.code)
      );
      selectedData[2] = aux[0];
      aux = PUCData.filter(
        (val) => val.code.length === 6 && selectedAux.code.startsWith(val.code)
      );
      selectedData[3] = aux[0];
      selectedData[4] = selectedAux;
    }
  }, [selectedAux]);

  const getType = () => {
    const temp = PUCData.filter((val) => val.code.length === 4);
    const data: PropItem[] = [];
    temp.forEach((val) => {
      data.push({ label: val.name, value: val.id });
    });
    return data;
  };

  const handleSelectedData = (data: IPUCTree) => {
    setSelectedAux(data);
    console.log(data.id);
  };

  const handlerMessage = (val: string) => {
    if (val === "") {
      setTypeMessage(0);
      setMessage("");
      navigate(0);
    }
  };

  const handlerRequest = (data: IPUCPUTRequest) => {
    dataToSubmit.name = data.name
    dataToSubmit.pucTypeId = data.pucTypeId
    dataToSubmit.taxTypeId = data.taxTypeId
    dataToSubmit.isBase = data.isBase
    dataToSubmit.adjustmentNiif = data.adjustmentNiif
    dataToSubmit.isNature = data.isNature
  }

  return (
    <>
      <TopBar currentPageTitle="PUC Contable" />
      <PageTitle title="PUC Contable">
        <Link
          to="/PUC-Contable/crear"
          className="btn btn-primary shadow-md mr-2"
        >
          Agregar PUC Contable
        </Link>
      </PageTitle>
      {typeMessage !== 0 && (
        <Message
          type={typeMessage}
          message={message}
          handler={handlerMessage}
        />
      )}
      <div className="createContainer">
        <div className="insideContainer">
          <div className="createContainer__tree">
            <div>
              <h2 className="createContainer__tree--title">Cuenta Contable</h2>
              <TreeAccounting data={PUCData} />
            </div>
          </div>
          <EditChartOfAccountsForm data={selectedData} handler={handlerRequest}/>
        </div>
        <div className="margin20px" onClick={()=>setSendRequest(true)}>
          <SubmitButton isSubmitting={false}/>
        </div>
      </div>
    </>
  );
}
