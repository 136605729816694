import SystemMessage from "../../shared/common/presentation/SystemMessage"
import CancelRed from "../../shared/icons/CancelRed"
import requests from "../../shared/api/agent"
import { useNavigate } from "react-router-dom"

export default function ExplanatoryNotesMessage({handler, url}:{handler:()=>void, url:string}){
    const navigate = useNavigate()
    return(
        <SystemMessage title="¡Cuidado!" message="¿Esta seguro de desactivar este item?" icon={<CancelRed/>} buttons={[
            <button
              type="button"
              className="btn btn-success py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
              onClick={()=>{handler()}} style={{color:"white"}}>Cancelar</button>,
            <button
              type="button"
              className="btn btn-danger py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
              onClick={()=>{
                requests.delete(`${url}`).then((response)=>{
                    console.log(`Nota anulada satisfactoriamente ${response}`)
                    handler()
                    navigate(0)
                  }).catch((error)=>{
                    console.log(`error al cambiar el estado del item: ${error}`)
                    handler()
                  })
              }}>Desactivar</button>
          ]}/>
    )
}