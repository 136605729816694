import { Link } from "react-router-dom";
import withFilteredQueryRequest from "../../shared/filtered-query/withFilteredQueryRequest";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import BranchesTable from "../components/BranchesTable";

export default function BranchesIndexPage() {
  const DataList = withFilteredQueryRequest(
    "Branches/search/",
    BranchesTable
  );

  return (
    <>
      <TopBar currentPageTitle="Sucursales" />
      <PageTitle title="Sucursales">
        <Link
          to="/sucursales/crear"
          className="btn btn-primary shadow-md mr-2"
        >
          Agregar Sucursal
        </Link>
      </PageTitle>
      <DataList />
    </>
  );
}
