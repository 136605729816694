import PageTitle from "../../shared/common/presentation/PageTitle";
import TopBar from "../../shared/layouts/TopBar";
import FoliarBooksForm from "../components/FoliarBooksForm";

export default function CreateFoliarBookPage(){
    return (
        <>
          <TopBar firstLevelPageTitle={'Foliar Libros'} firstLevelPageUrl={'/foliarLibros'} currentPageTitle="Nuevo foliar libro" />
          <PageTitle title="Nuevo foliar libro">
            
          </PageTitle>
          <FoliarBooksForm isNewReceipt={true}/>
        </>
      );
}