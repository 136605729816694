import PageTitle from "../../shared/common/presentation/PageTitle";
import TopBar from "../../shared/layouts/TopBar";
import "../styles/chartOfAccounts.css";
import TreeAccounting from "../components/TreeAccounting";
import { useState, useEffect } from "react";
import requests from "../../shared/api/agent";
import ShowChartOfAccountsForm from "../components/ShowChartOfAccountsForm";
import { PropItem } from "../../shared/common/forms/InputSelect";
import DeleteButton from "../../shared/common/forms/DeleteButton";
import SuccessButton from "../../shared/common/forms/SuccessButton";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Message from "../components/Message";
import ChangeButton from "../components/ChangeState";

export interface IPUCTree {
  id: number;
  code: string;
  name: string;
  isBase?: boolean;
  isNature?: string;
}

export default function ChartOfAccountsIndex() {
  const navigate = useNavigate();

  const [PUCData, setPUCData] = useState<IPUCTree[]>([]);
  const selectedData: IPUCTree[] = [
    { id: 0, code: "", name: "" },
    { id: 0, code: "", name: "" },
    { id: 0, code: "", name: "" },
    { id: 0, code: "", name: "" },
    { id: 0, code: "", name: "", isBase: false, isNature: "" },
  ];
  const [selectedAux, setSelectedAux] = useState<IPUCTree>();
  const [message, setMessage] = useState<string>("");
  const [typeMessage, setTypeMessage] = useState<number>(0);

  useEffect(() => {
      requests
        .get("/pucs/parent")
        .then((response) => {
          setPUCData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
  },[]);

  useEffect(() => {
    if (selectedAux) {
      let aux: IPUCTree[] = [];
      aux = PUCData.filter(
        (val) => val.code.length === 1 && val.code === selectedAux.code[0]
      );
      selectedData[0] = aux[0];
      aux = PUCData.filter(
        (val) => val.code.length === 2 && selectedAux.code.startsWith(val.code)
      );
      selectedData[1] = aux[0];
      aux = PUCData.filter(
        (val) => val.code.length === 4 && selectedAux.code.startsWith(val.code)
      );
      selectedData[2] = aux[0];
      aux = PUCData.filter(
        (val) => val.code.length === 6 && selectedAux.code.startsWith(val.code)
      );
      selectedData[3] = aux[0];
      selectedData[4] = selectedAux;
    }
  }, [selectedAux]);

  const getType = () => {
    const temp = PUCData.filter((val) => val.code.length === 4);
    const data: PropItem[] = [];
    temp.forEach((val) => {
      data.push({ label: val.name, value: val.id });
    });
    return data;
  };

  const handleSelectedData = (data: IPUCTree) => {
    setSelectedAux(data);
    console.log(data.id);
  };

  const deleteAux = () => {
    if (selectedData[4].code === "") {
      alert("Seleccione un auxiliar contable");
      return;
    }
    requests
      .delete(`/pucs/${selectedData[4].id}`)
      .then((response) => {
        setTypeMessage(1);
        setMessage("Auxiliar Contable eliminado correctamente");
      })
      .catch((error) => {
        console.log(error);
        setTypeMessage(2);
        setMessage(
          "La cuenta auxiliar tiene movimientos asociados, no se puede eliminar"
        );
      });
  };

  const handlerMessage = (val: string) => {
    if (val === "") {
      setTypeMessage(0);
      setMessage("");
      navigate(0);
    }
  };

  const changeState = () => {
    if (selectedData[4].code === "") {
      setMessage("Seleccione un auxiliar contable");
      setTypeMessage(2);
      return;
    }
    console.log("ID:" + selectedData[4].id)
    requests
      .put(`/pucs/${selectedData[4].id}/status/${true}`, {})
      .then((response) => {
        setTypeMessage(1);
        setMessage("Estado cambiado correctamente");
      })
      .catch((error) => {
        console.log(error);
        setTypeMessage(2);
        setMessage("No se pudo cambiar el estado");
      });
  }

  return (
    <>
      <TopBar currentPageTitle="PUC Contable" />
      <PageTitle title="PUC Contable">
        <Link
          to="/PUC-Contable/crear"
          className="btn btn-primary shadow-md mr-2"
        >
          Agregar PUC Contable
        </Link>
      </PageTitle>
      {typeMessage !== 0 && (
        <Message
          type={typeMessage}
          message={message}
          handler={handlerMessage}
        />
      )}
      <div className="createContainer">
        <div className="insideContainer">
          <div className="createContainer__tree">
            <div>
              <h2 className="createContainer__tree--title">Cuenta Contable</h2>
              <TreeAccounting handler={handleSelectedData} data={PUCData} />
            </div>
          </div>
          <ShowChartOfAccountsForm data={selectedData} />
        </div>
        <div className="margin20px">
          <SuccessButton url={`/PUC-Contable/`.concat(selectedAux?.id.toString() || '0')} />
          <DeleteButton handler={deleteAux} />
          <ChangeButton handler={changeState} />
        </div>
      </div>
    </>
  );
}
