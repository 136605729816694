import TextField2 from "../../chartOfAccounts/components/TextField2";
import useCostCenters from "../hooks/useCostCenters";
import ModalTable from "./ModalTable";
import { useEffect, useState } from "react";

export default function CostCenters({handleId}: {handleId: (id: string) => void}) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [data1, setData1] = useState<string[]>([]);
  const [data2, setData2] = useState<string[]>([]);
  const costCenters = useCostCenters();

  useEffect(() => {
    setData1(costCenters.costCenters.map((costCenter) => costCenter.code));
    setData2(costCenters.costCenters.map((costCenter) => costCenter.name));
  }, [costCenters.costCenters]);

  useEffect(()=>{
    if(costCenters.selectedCostCenter.id !== undefined){
      handleId(costCenters.selectedCostCenter.id.toString())
    }
  },[costCenters.selectedCostCenter])

  return (
    <>
      {showModal && (
        <ModalTable
          data1={data1}
          data2={data2}
          title="Centro de costos"
          handler={() => setShowModal(false)}
          datahandler={(data) => costCenters.handleCostCenter(data)}
        />
      )}
      <TextField2
        label="Centros de costo"
        placeholder="Haga click para seleccionar"
        value={costCenters.selectedCostCenter.name || ""}
        onClick={() => setShowModal(true)}
      />
    </>
  );
}
