import '../../ABook-accountRank/styles/ScrollableTable.css'
import { useState, useEffect } from 'react'
import toCash from '../../ABook-accountRank/functions/toCash'

export interface IDetailedAuxiliaryBookReport {
    totalDebit: number
    totalCredit: number
    receipts: Receipt[]
}

export interface Receipt {
    date: string
    consecutive?: string
    accountCode?: string
    accountName: string
    debit: number
    credit: number
}

export default function DetailedAuxiliaryBookTable({ data }: { data: IDetailedAuxiliaryBookReport }) {

    const [sumDebit, setSumDebit] = useState<string>('')
    const [sumCredit, setSumCredit] = useState<string>('')
    let globalID: number = 1

    useEffect(() => {
        if (data.totalCredit && data.totalDebit) {
            setSumDebit(toCash(data.totalDebit.toString()))
            setSumCredit(toCash(data.totalCredit.toString()))
        } else {
            setSumDebit('')
            setSumCredit('')
        }
    }, [data])

    return (
        <div className="mt-8 max-height-60vh">
            <div className="w-full DetailedAuxBook-structure table-header special-row">
                <p>#</p>
                <p>Fecha</p>
                <p>Cuenta</p>
                <p>Nombre Cuenta</p>
                <p>Comprobante</p>
                <p>Debitos</p>
                <p>Creditos</p>
            </div>
            <div className="max-w-100 flex flex-col">
                {data.totalDebit > 0 || data.totalCredit > 0 ? data.receipts.map((item, index) => {
                    return (
                        <>
                            {item.accountName === "Total Comprobante:" ? (<><div>
                                <div key={index} className="DetailedAuxBook-structure text-center border-t-2 py-2 special-row2">
                                    <p></p>
                                    <p className='text-nowrap'>{ }</p>
                                    <p>{item.accountCode || ''}</p>
                                    <p>{item.accountName}</p>
                                    <p>{item.consecutive}</p>
                                    <p>{toCash(item.debit.toString())}</p>
                                    <p>{toCash(item.credit.toString())}</p>
                                </div>
                            </div></>) : globalID++ &&
                            (
                                <>
                                    <div>
                                        <div key={index} className="DetailedAuxBook-structure text-center border-t-2 py-2">
                                            <p>{globalID}</p>
                                            <p className='text-nowrap'>{item.date}</p>
                                            <p>{item.accountCode || ''}</p>
                                            <p>{item.accountName}</p>
                                            <p>{item.consecutive}</p>
                                            <p>{toCash(item.debit.toString())}</p>
                                            <p>{toCash(item.credit.toString())}</p>
                                        </div>
                                    </div>
                                </>)}
                        </>
                    )
                }) : (
                    <div className="flex flex-row items-center justify-center py-2 border-t-2">
                        <p>No hay datos para mostrar</p>
                    </div>
                )}
            </div>
            <div className="flex flex-row DetailedAuxBook-structure text-center items-center justify-center py-2 border-t-2 table-footer special-row">
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p>Total</p>
                <p>{`Debito: ${sumDebit}`}</p>
                <p>{`Credito: ${sumCredit}`}</p>
            </div>
        </div>
    )
}