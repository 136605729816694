interface Props {
    name: string;
    label: string;
    value: string;
    onChange: (name: string, value: string) => void;
    required?: boolean | null;
    disabled?:boolean
}

export default function DateField({
    name,
    label,
    value,
    onChange,
    required = false,
    disabled
}: Props) {

  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.classList.add('touched');
};

  if (value && value.includes('T'))
        value = value.split('T')[0];

  return (
    <div className="input-form">
      <label
        htmlFor={name}
        className="form-label w-full flex flex-col sm:flex-row"
      >
        {" "}
        {label}
      </label>
      <input
        id={name}
        type="date"
        name={name}
        onBlur={onFocus}
        className="form-control"
        required={required ?? false}
        value={value}
        disabled={disabled}
        onChange={e => onChange(e.target.name, e.target.value)}
      />
    </div>
  );
}
