import "../../ABook-accountRank/styles/ScrollableTable.css";
import { useState, useEffect } from "react";
import toCash from "../../ABook-accountRank/functions/toCash";
import { roundDecimals } from "../../generalBalance/components/GeneralBalanceTable";
export interface IAccounts {
  accountCode: string;
  accountName: string;
  debitBalancePreviousYear: string;
  creditBalancePreviousYear: string;
  debitBalanceCurrentMonth: string;
  creditBalanceCurrentMonth: string;
}

export default function InventoriesNBalanceBookTable({
  data,
}: {
  data: IAccounts[];
}) {
  const [sumDebitBefore, setSumDebitBefore] = useState<number>(0);
  const [sumCreditBefore, setSumCreditBefore] = useState<number>(0);
  const [sumDebitMonth, setSumDebitMonth] = useState<number>(0);
  const [sumCreditMonth, setSumCreditMonth] = useState<number>(0);

  useEffect(() => {
    data.forEach((item) => {
      setSumDebitBefore(
        (prev) => prev + parseInt(item.debitBalancePreviousYear)
      );
      setSumCreditBefore(
        (prev) => prev + parseInt(item.creditBalancePreviousYear)
      );
      setSumDebitMonth(
        (prev) => prev + parseInt(item.debitBalanceCurrentMonth)
      );
      setSumCreditMonth(
        (prev) => prev + parseInt(item.creditBalanceCurrentMonth)
      );
    });
  }, [data]);

  return (
    <div className="mt-8 max-height-60vh">
      <div className="px-4 w-full InventoriesNBalanceBook-structure table-header special-row">
        <p>Codigo</p>
        <p>Nombre</p>
        <p className="text-center">Saldo Debito Anterior</p>
        <p className="text-center">Saldo Credito Anterior</p>
        <p className="text-center">Debito Mes</p>
        <p className="text-center">Credito Mes</p>
      </div>
      <div className="max-w-100 flex flex-col gap-4 mt-4">
        {data.length > 0 ? (
          data.map((item) => {
            return (
              <>
                <div className="px-4 w-full InventoriesNBalanceBook-structure table-row">
                  <p>{item.accountCode}</p>
                  <p>{item.accountName}</p>
                  <p className="text-center">
                    {toCash(
                      roundDecimals(
                        parseInt(item.debitBalancePreviousYear)
                      ).toString()
                    )}
                  </p>
                  <p className="text-center">
                    {toCash(
                      roundDecimals(
                        parseInt(item.creditBalancePreviousYear)
                      ).toString()
                    )}
                  </p>
                  <p className="text-center">
                    {toCash(
                      roundDecimals(
                        parseInt(item.debitBalancePreviousYear)
                      ).toString()
                    )}
                  </p>
                  <p className="text-center">
                    {toCash(
                      roundDecimals(
                        parseInt(item.creditBalanceCurrentMonth)
                      ).toString()
                    )}
                    </p>
                </div>
              </>
            );
          })
        ) : (
          <p className="text-center">No hay datos</p>
        )}
        <div className="px-4 w-full InventoriesNBalanceBook-structure table-header special-row text-center">
          <p></p>
          <p className="font-bold">Total</p>
          <p className="font-bold">
            {toCash(roundDecimals(sumDebitBefore).toString())}
          </p>
          <p className="font-bold">
            {toCash(roundDecimals(sumCreditBefore).toString())}
          </p>
          <p className="font-bold">
            {toCash(roundDecimals(sumDebitMonth).toString())}
          </p>
          <p className="font-bold">
            {toCash(roundDecimals(sumCreditMonth).toString())}
          </p>
        </div>
      </div>
    </div>
  );
}
