import TopBar from "../../shared/layouts/TopBar";
import TestBalancesForm from "../components/TestBalancesForm";

export default function TestBalancesIndex() {
  return (
    <>
        <TopBar currentPageTitle="Balance de pruebas" />
        <TestBalancesForm />
    </>
  );
}