import React from "react";

interface Props {
  value: string;
  handleChange: (event: React.ChangeEvent) => void;
}

export default function SearchInput({ value, handleChange }: Props) {
  return (
    <>
      <input
        type="search"
        value={value}
        onChange={handleChange}
        className="form-control w-56 box pr-10"
        placeholder="Buscar..."
      />
    </>
  );
}
