import "../../ABook-accountRank/styles/ScrollableTable.css";
import { roundDecimals } from "../../generalBalance/components/GeneralBalanceTable";

export interface IncomeNExpenseAnalysis {
  accountCode: string;
  accountName: string;
  total: string;
  percent: number;
  months: IMonth[];
}

interface IMonth {
  monthCode: number;
  monthName: string;
  balance: string;
  percent: number;
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "Septemper",
  "October",
  "November",
  "December",
];

export default function IncomeNExpenseAnalysisTable({
  data,
}: {
  data: IncomeNExpenseAnalysis[];
}) {
  const getValues = (item: IMonth[], month: string) => {
    const balance = item.find((aux) => aux.monthName === month);
    if (balance) {
      return balance.balance;
    } else {
      return "";
    }
  };

  const getPercent = (item: IMonth[], month: string) => {
    const balance = item.find((aux) => aux.monthName === month);
    if (balance) {
      return balance.percent;
    } else {
      return "";
    }
  };

  return (
    <div className="mt-4 max-height-60vh flex flex-col gap-4">
      <div className="table-header special-row px-2 IncomeNExpenseAnalysis-structure">
        <p>Cuenta</p>
        <p>Descripciòn</p>
        <p>Total</p>
        <p>Enero</p>
        <p>%</p>
        <p>Febrero</p>
        <p>%</p>
        <p>Marzo</p>
        <p>%</p>
        <p>Abril</p>
        <p>%</p>
        <p>Mayo</p>
        <p>%</p>
        <p>Junio</p>
        <p>%</p>
        <p>Julio</p>
        <p>%</p>
        <p>Agosto</p>
        <p>%</p>
        <p>Septiembre</p>
        <p>%</p>
        <p>Octubre</p>
        <p>%</p>
        <p>Noviembre</p>
        <p>%</p>
        <p>Diciembre</p>
        <p>%</p>
      </div>
      <div className="flex flex-col gap-2 ml-2 mr-2 w-fit">
        {data.map((item, index) => {
          return (
            <>
              <div className="IncomeNExpenseAnalysis-structure">
                <div className="sticky-columns sticky-container">
                  <p>{item.accountCode}</p>
                  <p>{item.accountName}</p>
                  <p>{item.total}</p>
                </div>
                <p></p>
                <p></p>
                {months.map((aux) => {
                  return (
                    <>
                      <p>{getValues(item.months, aux)}</p>
                      <p>{getPercent(item.months, aux)}</p>
                    </>
                  );
                })}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}
