interface Props {
  totalRecords: number;
  filteredRecords: number;
  currentPageIndex: number;
  pageSize: number;
  visible?: boolean;
  handleChange: (page: number) => void;
}

export default function Pager({
  totalRecords,
  pageSize,
  handleChange,
  currentPageIndex,
  filteredRecords,
  visible = true,
}: Props) {
  //Rounding up total pages
  const totalPages = parseInt(Math.ceil(filteredRecords / pageSize).toFixed(0));
  const startingRecord = currentPageIndex * pageSize + 1;
  const endingRecord = startingRecord + pageSize - 1;

  //Creating array of pages based on total pages
  const pages = Array.from(Array(totalPages).keys());

  return (
    <div className="intro-y col-span-3 lg:col-span-3">
      {totalPages < 10 ? (
        <nav className="w-full sm:w-auto sm:mr-auto">
          <ul className="pagination ">
            {pages.map((page) => (
              <li
                key={page}
                className={
                  page === currentPageIndex ? "page-item active" : "page-item"
                }
              >
                <button
                  onClick={(e) => handleChange(page)}
                  className="page-link"
                  data-page={page}
                >
                  {page + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      ) : (
        <div className="w-full sm:w-auto sm:mr-auto">
          <label>Ir a página: </label>
          <select
            value={currentPageIndex}
            onChange={(e) => handleChange(parseInt(e.target.value))}
            className="w-20 form-select box mt-3 sm:mt-0"
            style={{ width: 75, display: "inline" }}
          >
            {pages.map((page) => (
              <option
                key={page}
                value={page}
                selected={page == currentPageIndex}
              >
                {page + 1}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
}
