interface Props {
  name: string;
  label: string;
  value: string;
  onChange: (name: string, value: string) => void;
  required?: boolean | null;
  maxLength?: number | undefined;
  placeholder?: string | null;
  disabled?:boolean;
}

export default function TextAreaField({
  name,
  label,
  value,
  onChange,
  required = false,
  maxLength,
  placeholder,
  disabled
}: Props) {
  const onFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    event.target.classList.add("touched");
  };

  return (
    <div className="input-form">
      <label
        htmlFor={name}
        className="form-label w-full flex flex-col sm:flex-row"
      >
        {" "}
        {label}
      </label>
      <textarea
        id={name}
        value={value}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        className="form-control"
        onBlur={onFocus}
        name={name}
        placeholder={placeholder ?? ""}
        maxLength={maxLength}
        disabled={disabled}
        required={required ?? false}
      ></textarea>
    </div>
  );
}
