import Table from "../../shared/common/presentation/Table";
import GridEditButton from "../../shared/filtered-query/GridEditButton";
import { User } from "../models/User";
import GridStateGreen from "../../shared/filtered-query/GridStateGreen";
import GridStateRed from "../../shared/filtered-query/GridStateRed";
import GridViewInabilite from "../../shared/filtered-query/GridViewInabilite";
import GridViewButton from "../../shared/filtered-query/GridViewButton";
import GridEditInabilite from "../../shared/filtered-query/GridEditInabilite";
import GridDeleteInabilite from "../../shared/filtered-query/GridDeleteInabilite";
import { useState } from "react";
import AssociateBranch from "./AssociateBranch";
import AssociateBranchIcon from "../../shared/filtered-query/AssociateBranchIcon";

interface Props {
  data: User[];
  onSortingChange?: (columnName: string, sortOrder: "asc" | "desc") => void;
  sortedBy?: string | null;
  ascending?: boolean | null;
}

export default function UsersTable({
  data,
  onSortingChange,
  sortedBy,
  ascending,
}: Props) {
  const [selectedUser, setSelectedUser] = useState<number | null>(null);
  const [branch, setBranch] = useState<boolean>(false);
  const triggerSortingChange = (
    columnName: string,
    sortOrder: "asc" | "desc"
  ) => {
    if (onSortingChange) onSortingChange(columnName, sortOrder);
  };
  function change(): void {
    setBranch(!branch);
  }

  return (
    <Table
      sortedBy={sortedBy}
      ascending={ascending}
      onSortingChange={triggerSortingChange}
      columns={[
        { isSortable: true, name: "Consecutive", title: "Cedula" },
        { isSortable: true, name: "ThirdParty.BusinessName", title: "Nombre" },
        { isSortable: true, name: "Year", title: "Nombre de usuario" },
        { isSortable: true, name: "Month", title: "Rol" },
        { isSortable: false, name: "Date", title: "Estado" },
        { isSortable: false, name: "Actions", title: "Acciones" },
      ]}
    >
      {data.map((dt) => (
        <tr key={dt.id}>
          <td className="w-40">
            <div>
              {dt.document}
            </div>
          </td>
          <td className="w-40"><div>{dt.name}</div></td>
          <td className="w-40"><div>{dt.name}</div></td>
          <td className="w-40"><div>{dt.role}</div></td>
          {dt.isStatus ?
            <td className="w-40">
              <GridStateGreen url={`accounts/${dt.id}/status/false`} />
            </td>
            :
            <td className="w-40">
              <GridStateRed url={`accounts/${dt.id}/status/true`} />
            </td>
          }
          <td className="table-report__action w-56">
            {
              dt.isStatus ?
                <div className="flex justify-center items-center">
                  <GridEditButton url={`/usuarios/${dt.id}`} />

                  <GridViewButton url={`/usuarios/info/${dt.id}`} />
                  <AssociateBranchIcon funcion={()=>{
                    console.log("idUsuario "+ dt.id+ "Documento Usuario " + dt.document)
                    setSelectedUser(dt.id)
                    change()
                  }} />
                </div> :
                <div className="flex justify-center items-center">
                  <GridEditInabilite />
                  <GridViewInabilite />
                  <GridDeleteInabilite />
                </div>
            }
            {branch ? <AssociateBranch idUser={selectedUser!} funcion={change} /> : null}
          </td>
        </tr>
      ))}
    </Table>
  );
}
