import { useState } from "react";
import { Receipt } from "../models/receipt";
import requests from "../../shared/api/agent";
import ReceiptTypeSelector from "../../receiptTypes/components/ReceiptTypeSelector";
import ThirdPartySelector from "../../people/components/ThirdPartySelector";
import TextField from "../../shared/common/forms/TextField";
import TextAreaField from "../../shared/common/forms/TextAreaField";

interface Props {
  isNewReceipt: boolean;
  receipt?: Receipt;
}

export default function LedgerAccountsForm({
  isNewReceipt,
  receipt: selectedReceipt,
}: Props) {
  const initialState = {
    id: 0,
    receiptTypeId: 0,
    thirdPartyId: 0,
    date: "",
    document: "",
    detail: "",
    items: [{
        costCenterId: 0,
        pUCId: 0,
        thirdPartyId: 0,
        base: 0,
        rate: 0,
        debit: 0,
        credit: 0,
        detail: "",
      }],
  };

  const [receipt, setReceipt] = useState<Receipt>(
    selectedReceipt ?? initialState
  );
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onFieldChange = (name: string, value: string | number) => {
    setReceipt({ ...receipt, [name]: value });
  };

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (isNewReceipt) {
      requests
        .post("/receipts", receipt)
        .then((response) => console.log("success"))
        .catch((e) => setErrorMessage(e.message));
    } else {
      requests
        .put("/receipts" + receipt.id, receipt)
        .then((response) => console.log("success"))
        .catch((e) => setErrorMessage(e.message));
    }
  };

  return (
    <div className="intro-y box">
      <div id="form-validation" className="p-5" onSubmit={onSubmit}>
        <div className="preview" style={{ display: "block" }}>
          <form className="validate-form" onSubmit={onSubmit} method="POST">
            <div className="grid grid-cols-2 gap-4 mb-4">
              <ThirdPartySelector
                name="thirdPartyId"
                onChange={onFieldChange}
                value={receipt.thirdPartyId}
                key="thirdPartyId"
                required={true}
              />
              <TextField
                label="Documento"
                name="document"
                onChange={onFieldChange}
                value={receipt.document}
                key="document"
                required={true}
              />
            </div>
            <div className="grid grid-cols-1 gap-4">
              <TextAreaField
                label="Detalle"
                name="detail"
                onChange={onFieldChange}
                value={receipt.detail}
                key="detail"
                required={false}
              />
            </div>
            <button type="submit" className="btn btn-primary mt-5">
              Guardar
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
