import PageTitle from "../../shared/common/presentation/PageTitle";
import TopBar from "../../shared/layouts/TopBar";
import CostCenterForm from "../components/CostCenterForm";

export default function CreateCostCenterPage(){
    return (
        <>
          <TopBar firstLevelPageTitle={'Centros de costos'} firstLevelPageUrl={'/costoCentro'} currentPageTitle="Nuevo Centro de costos" />
          <PageTitle title="Nuevo centro de costos">
          </PageTitle>
          <CostCenterForm isNewCostCenter={true}/>
        </>
      );
}
