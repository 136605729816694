import { Link } from "react-router-dom";
import Table from "../../shared/common/presentation/Table";
import { ListedReceiptType } from "../models/listedReceiptType";
import DateLabel from "../../shared/common/presentation/DateLabel";
import GridStateGreen from "../../shared/filtered-query/GridStateGreen";
import GridStateRed from "../../shared/filtered-query/GridStateRed";
import GridEditButton from "../../shared/filtered-query/GridEditButton";
import GridDeleteButton from "../../shared/filtered-query/GridDeleteButton";

interface Props {
  data: ListedReceiptType[];
  onSortingChange?: (columnName: string, sortOrder: "asc" | "desc") => void;
  sortedBy?: string | null;
  ascending?: boolean | null;
}

export default function ReceiptTypesTable({
  data: receiptTypes,
  onSortingChange,
  sortedBy,
  ascending,
}: Props) {
  const triggerSortingChange = (
    columnName: string,
    sortOrder: "asc" | "desc"
  ) => {
    if (onSortingChange) onSortingChange(columnName, sortOrder);
  };

  return (
    <Table
      sortedBy={sortedBy}
      ascending={ascending}
      onSortingChange={triggerSortingChange}
      columns={[
        { isSortable: true, name: "Code", title: "Código" },
        { isSortable: true, name: "Name", title: "Nombre" },
        { isSortable: true, name: "Increment", title: "Incremento" },
        { isSortable: true, name: "Category.Name", title: "Categoría" },
        { isSortable: false, name: "DocumentType", title: "Tipo" },
        { isSortable: false, name: "isActive", title: "Estado" },
        { isSortable: false, name: "Actions", title: "" }, 
      ]}
    >
      {receiptTypes.map((receiptType) => (
        <tr className="intro-x" key={receiptType.id}>
          <td className="w-40">
            <Link
              className="font-medium whitespace-nowrap"
              to={`/tipos-comprobantes/${receiptType.id}`}
            >
              {receiptType.code}
            </Link>{" "}
          </td>
          <td className="w-40">{receiptType.name}</td>
          <td className="w-40">{receiptType.increment}</td>
          <td className="w-40">{receiptType.categoryName}</td>
          <td className="w-40">
            {receiptType.documentType}
          </td>
          <td className="w-40">
            
            {receiptType.isActive? 
            <div className="d-flex justifyC-center alignI-center"><GridStateGreen url={`/receipt-types/${receiptType.id}/status/false`}/></div>
            :
            <div className="d-flex justifyC-center alignI-center"><GridStateRed url={`/receipt-types/${receiptType.id}/status/true`}/></div>}
         
        </td>
          <td className="table-report__action w-56">
            <div className="flex justify-center items-center">
                <GridEditButton url={`/tipos-comprobantes/${receiptType.id}`}/>
                <GridDeleteButton url={`/tipos-comprobantes/${receiptType.id}`} />
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
}
