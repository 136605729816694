import SystemMessage from "../../shared/common/presentation/SystemMessage";
import CancelRed from "../../shared/icons/CancelRed";
import Success from "../../shared/icons/Success";

export default function Message({
  type,
  message,
  handler,
}: {
  type: number;
  message: string;
  handler: (val: string) => void;
}) {
  if (type === 1) {
    return (
      <SystemMessage
        title="Buen Trabajo!"
        message={message}
        icon={<Success />}
        buttons={[
          <button
            type="button"
            className="btn btn-success py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
            onClick={() => {
              handler("");
            }}
            style={{ color: "white" }}
          >
            Aceptar
          </button>,
        ]}
      />
    );
  } else if (type === 2) {
    return (
      <SystemMessage
        title="Error"
        message={message}
        icon={<CancelRed />}
        buttons={[
          <button
            type="button"
            className="btn btn-danger py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
            onClick={() => {
              handler("");
            }}
            style={{ color: "white" }}
          >
            Aceptar
          </button>,
        ]}
      />
    );
  }
  return null;
}
