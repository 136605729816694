import { useEffect, useState } from "react";
import { ThirdParty } from "../models/thirdParty";
import requests from "../../shared/api/agent";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import ThirdForm from "../components/ThirdForm";
import { useParams } from "react-router-dom"


export default function InfoThirdPage() {
  const [third, setThird] = useState<ThirdParty>();

  const { id } = useParams<{ id: string }>();

  const loadThird = () => {
    if(id){
        requests.get(`/third-parties/${id}`).then((response) => {
        setThird(response.data);
      });
    }
      
  };

  useEffect(() => {
    loadThird();
  });

  return (
    <>
      <TopBar
        currentPageTitle={`Información del tercero`}
        firstLevelPageTitle="Infomración del tercero"
        firstLevelPageUrl="/"
      />
      <PageTitle title={`Información del tercero`} />
      {third && (
        <ThirdForm isNewThird={false} thirdParty={third}  disabled/>
      )}
    </>
  );
}
