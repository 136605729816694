import React from "react";

interface Column {
  name: string;
  title: string;
  isSortable: boolean;
}

interface Props {
  columnNames?: string[];
  columns?: Column[];
  children: React.ReactNode;
  onSortingChange?: (columnName: string, sortOrder: "asc" | "desc") => void;
  sortedBy?: string | null;
  ascending?: boolean | null;
}

export default function Table({
  columnNames,
  children,
  columns,
  onSortingChange,
  sortedBy,
  ascending,
}: Props) {
  const handleSortableColumnClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    column: Column
  ) => {
    if (column.isSortable && onSortingChange) {
      const isAscending: boolean = !(
        event.target as Element
      ).classList.contains("sorting_asc");

      const otherColumns = document.body.querySelectorAll("th");

      otherColumns.forEach((element) => {
        if (
          element.classList.contains("sorting_asc") ||
          element.classList.contains("sorting_desc")
        ) {
          element.classList.add("sorting");
        }

        (element as Element).classList.remove("sorting_asc");
        (element as Element).classList.remove("sorting_desc");
        (element as Element).classList.add("sort");
      });

      (event.target as Element).classList.add(
        isAscending ? "sorting_asc" : "sorting_desc"
      );

      onSortingChange(column.name, isAscending ? "asc" : "desc");
    }
  };

  return (
      <table className="table table-report -mt-2" style={{top: -30,position:"static"}}>
        <thead>
          <tr>
            {columnNames &&
              columnNames.map((col) => (
                <th className="whitespace-nowrap" key={col} scope="col">
                  <div>
                  {col.toUpperCase()}
                  </div>
                </th>
              ))}
            {columns &&
              columns.map((col) => (
                <th
                  scope="col"
                  key={col.name}
                  onClick={(e) => handleSortableColumnClick(e, col)}
                  className={`whitespace-nowrap ${
                    col.isSortable
                      ? sortedBy === col.name
                        ? ascending
                          ? "sorting sorting_asc"
                          : "sorting sorting_desc"
                        : "sort"
                      : "not-sorting"
                  } `}
                >
                  <div>
                    {col.title.toUpperCase()}
                  </div>
                </th>
              ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    
  );
}
