import { Children, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import React from "react";
import { BsCircle } from "react-icons/bs";
import Circle from "../../icons/Circle";

interface Props {
  url?: string;
  title: string;
  icon?: JSX.Element;
  children?: JSX.Element | JSX.Element[];
}
interface ChildrenProps {
  url?: string;
  title: string;
  children?: JSX.Element | JSX.Element[];
}
function list(bol : boolean, children? :JSX.Element | JSX.Element[]){
  const arrayChildren = Children.toArray(children);
  if(!bol || arrayChildren.length===0){
    return(<div className="side-subMenu__container__disactive"></div>)
  }else{
    return(
        <div className="side-subMenu__container mt-2">
          {
              Children.map(arrayChildren, (child, index) => {
                  if (React.isValidElement<ChildrenProps>(child)){
                      return (child)
                  }
              })
          }
        </div>
    )
  }
}
export default function MenuItem({ url, title, children, icon}: Props) {
  const arrayChildren = Children.toArray(children)
  const [active, setActive] = useState(false);

  function onActive(){
    console.log("active")
    setActive(!active)
  }
  if(url){
    return(
      <Link
          to={url}
          className={`side-menu childMenu`}
        >
          <div className="sideMenuTitle"><Circle/> <p className="ml-2">{title}</p></div>
        </Link>
    )
  }else{
    return(
      <div
        className={`side-menu  menuContainer`}
      >
        <div onClick={onActive}
         className="side-menu__title side-menu__title_child cPointer" > {icon} <p className="ml-2">{title}</p>
        {active?
        <IoIosArrowUp style={{
          position:"absolute",
          right:"15px"
          }}/>
          :
          <IoIosArrowDown style={{
            position:"absolute",
            right:"15px"
            }}/>
          }
          </div>
        {active && list(true,children)}
      </div>
    )
  }
}
