import './App.css';
import '../styles/global.css';
import IAuthManager from '../common/security/IAuthManager';
import IoCContainer from '../common/IoC/IoCContainer';
import AuthenticatedLayout from './AuthenticatedLayout';
import UnauthenticatedLayout from './UnauthenticatedLayout';

function App() {
  const authManager: IAuthManager = IoCContainer.getAuthManager();
  return (
    <>
      {authManager.isAuthenticated() ? <AuthenticatedLayout /> : <UnauthenticatedLayout /> }
    </>
  );
}

export default App;
