import Table from "../../shared/common/presentation/Table";
import { IAnnexItems } from "./AnnexForm";

interface Props {
  data: IAnnexItems[];
  onSortingChange?: (columnName: string, sortOrder: "asc" | "desc") => void;
  sortedBy?: string | null;
  ascending?: boolean | null;
}

export default function AnnexTable({
  data,
  onSortingChange,
  sortedBy,
  ascending,
}: Props) {
  const triggerSortingChange = (
    columnName: string,
    sortOrder: "asc" | "desc"
  ) => {
    if (onSortingChange) onSortingChange(columnName, sortOrder);
  };

  return (
    <Table
      sortedBy={sortedBy}
      ascending={ascending}
      onSortingChange={triggerSortingChange}
      columns={[
        { isSortable: true, name: "document", title: "Documento" },
        { isSortable: true, name: "name", title: "Nombre" },
        { isSortable: true, name: "debit", title: "Debito" },
        { isSortable: true, name: "credit", title: "Credito" },
        { isSortable: true, name: "previousBalance", title: "Saldo Anterior" },
        { isSortable: true, name: "currentBalance", title: "Saldo Actual" },
      ]}
    >
      {data.map((dt, index) => (
        <tr key={index}>
          <td className="w-60">{dt.document}</td>
          <td className="w-60">{dt.name}</td>
          <td className="w-40">{dt.debit || "HO HAY DATOS"}</td>
          <td className="w-40">{dt.credit}</td>
          <td className="w-40">{dt.previousBalance}</td>
          <td className="w-40">{dt.currentBalance}</td>
        </tr>
      ))}
    </Table>
  );
}
