import { useEffect, useState } from "react";
import { ExplanatoryNote } from "../models/explanatoryNote";
import requests from "../../shared/api/agent";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import ExplanatoryNotesForm from "../components/ExplanatoryNotesForm";
import { useParams } from "react-router-dom";


export default function InfoExplanatoryNotePage() {
  const [note, setNote] = useState<ExplanatoryNote>();
  const { id } = useParams<{ id: string }>();

  const loadNote = () => {
      requests.get(`/clarification-notes/${id}`).then((response) => {
      setNote(response.data);
      
      
      });
    
  };

  useEffect(() => {
    loadNote();
  });

  return (
    <>
      <TopBar
        currentPageTitle={`Información de Notas Aclaratorias`}
        firstLevelPageTitle="Infomración de Notas Aclaratorias"
        firstLevelPageUrl="/"
      />
      <PageTitle title={`Información de Notas Aclaratorias`} />
      {note && (
        <ExplanatoryNotesForm isNewNote={false} explanatoryNote={note}  disabled/>
      )}
    </>
  );
}
