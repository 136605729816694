import { Link } from "react-router-dom";
import Table from "../../shared/common/presentation/Table";
import { ListThird } from "../models/listThird";
import GridEditButton from "../../shared/filtered-query/GridEditButton";
import GridDeleteButton from "../../shared/filtered-query/GridDeleteButton";
import GridEditInabilite from "../../shared/filtered-query/GridEditInabilite";
import GridDeleteInabilite from "../../shared/filtered-query/GridDeleteInabilite";
import GridViewButton from "../../shared/filtered-query/GridViewButton";
import GridViewInabilite from "../../shared/filtered-query/GridViewInabilite";


interface Props {
  data: ListThird[];
  onSortingChange?: (columnName: string, sortOrder: "asc" | "desc") => void;
  sortedBy?: string | null;
  ascending?: boolean | null;
}

export default function ThirdTable({
  data,
  onSortingChange,
  sortedBy,
  ascending,
}: Props) {
  const triggerSortingChange = (
    columnName: string,
    sortOrder: "asc" | "desc"
  ) => {
    if (onSortingChange) onSortingChange(columnName, sortOrder);
  };

  return (
    <Table
      sortedBy={sortedBy}
      ascending={ascending}
      onSortingChange={triggerSortingChange}
      columns={[
        { isSortable: true, name: "document", title: "Cedula" },
        { isSortable: true, name: "name", title: "Nombre" },
        { isSortable: false, name: "Actions", title: "" },
      ]}
    >
      {data.map((third) => (
        <tr className="intro-x" key={third.id}>
           <td className="w-40">
            <Link
              className="font-medium whitespace-nowrap"
              to={`/profiles/${third.id}`}
            >
              {third.document}
            </Link>{" "}
          </td>
          <td className="w-40">{third.name}</td>
         

          <td className="table-report__action w-56">
            {
              third.id?
              <div className="flex justify-center items-center">
                <GridEditButton url={`/terceros/${third.id}`}/>
                <GridViewButton url={`/terceros/info/${third.id}`} />
                <GridDeleteButton url={`/third-parties/${third.id}`} />
              </div> :
              <div className="flex justify-center items-center">
                <GridEditInabilite />
                <GridViewInabilite />
                <GridDeleteInabilite />
            </div>

            }
            
           
          </td>
        </tr>
      ))}
    </Table>
  );
}
