// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.father{
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    margin-top: 13px;
    margin-left: 11px;
    font-size: 1rem;
}

.father:hover{
    cursor: pointer;
}

.subChildrens{
    margin-left: 20px;
    font-size: 0.9rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.subItems{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.subItems:hover{
    cursor: pointer;
}

.subItems--icon{
    width: 20px;
}`, "",{"version":3,"sources":["webpack://./src/shared/navigation/SubMenuGroup.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".father{\n    display: flex;\n    flex-direction: row;\n    gap: 15px;\n    align-items: center;\n    margin-top: 13px;\n    margin-left: 11px;\n    font-size: 1rem;\n}\n\n.father:hover{\n    cursor: pointer;\n}\n\n.subChildrens{\n    margin-left: 20px;\n    font-size: 0.9rem;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    margin-top: 10px;\n}\n\n.subItems{\n    display: flex;\n    flex-direction: row;\n    gap: 10px;\n    align-items: center;\n}\n\n.subItems:hover{\n    cursor: pointer;\n}\n\n.subItems--icon{\n    width: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
