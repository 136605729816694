import { useState } from "react";
import { BranchOffice } from "../../shared/common/security/models/user";

interface Props {
  show: boolean;
  onHide: (selectedBranchId: number) => void;
  branches: BranchOffice[];
}

export default function BranchSelectorModal({ show, onHide, branches }: Props) {
  const [selectedBranchId, setSelectedBranchId] = useState<number>(0);
  const [hasError, setHasError] = useState<boolean>(false);

  const onSelectedBranch = (branchId: number) => {
    setSelectedBranchId(branchId);
  };

  const onOkClick = () => {
    setHasError(false)
    if (selectedBranchId === 0) {
      setHasError(true);
    } else {
      onHide(selectedBranchId);
    }
  };

  if (show) {
    return (
      <div
        id="static-backdrop-modal-preview"
        className={`modal overflow-y-auto show`}
        style={{ marginTop: 0, marginLeft: 0, paddingLeft: 0, zIndex: 10000 }}
        data-tw-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body px-5 py-10">
              <div hidden={!hasError} className="alert alert-danger-soft mb-2">
                Debe seleccionar una sucursal para poder continuar.
              </div>
              <div className="text-center">
                <div className="mb-5">
                  <select
                    value={selectedBranchId}
                    onChange={(e) => onSelectedBranch(Number(e.target.value))}
                  >
                    <option>- Seleccione la sucursal -</option>
                    {branches.map((branch) => (
                      <option value={branch.id}>{branch.name}</option>
                    ))}
                  </select>
                </div>{" "}
                <button
                  type="button"
                  data-tw-dismiss="modal"
                  className="btn btn-primary w-24"
                  onClick={(e) => onOkClick()}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}
