import { Link } from "react-router-dom";
import withFilteredQueryRequest from "../../shared/filtered-query/withFilteredQueryRequest";
import ThirdTables from "../components/ThirdTables";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import ThirdTable from "../components/ThirdTables";


export default function ThirdIndex() {
  const ThirdList = withFilteredQueryRequest(
    "/third-parties/search",
    ThirdTable
  );

  return (
    <>
      <TopBar currentPageTitle="Terceros" />
      <PageTitle title="Terceros">
        <Link
          to="/terceros/crear"
          className="btn btn-primary shadow-md mr-2"
        >
          Agregar un tercero
        </Link>
      </PageTitle>
      <ThirdList />
    </>
  );
}
