import {useEffect, useState} from 'react'

export default function useDate() {

    const [dateFrom, setDateFrom] = useState<string>('')
    const [dateUntil, setDateUntil] = useState<string>('')

    const verifyToday = (date: string): boolean => {
        if(date !== ''){
            let firstDate = new Date(date)
            let today = new Date()
            if(firstDate > today){
                return false
            }
            return true
        }
        return false
    }
    const verifyWhatIsFirst = (date1: string, date2: string): boolean => {
        if(date1 !== '' && date2 !== ''){
            let firstDate = new Date(date1)
            let secondDate = new Date(date2)
            if(firstDate < secondDate){
                return true
            }else if(firstDate === secondDate){
                return true
            }
        }
        return false;
    }
    const verifySameYear = (date1: string, date2: string): boolean => {
        if(date1 !== '' && date2 !== ''){
            let firstDate = new Date(date1)
            let secondDate = new Date(date2)
            if(firstDate.getFullYear() === secondDate.getFullYear()){
                return true
            }
        }
        return false;
    }
    const verifyMaxAYearDiference = (date1: string, date2: string): boolean => {
        if(date1 !== '' && date2 !== ''){
            const firstDate: Date = new Date(date1)
            const secondDate: Date = new Date(date2)
        }
        return false;
    }

    return {dateFrom, dateUntil, verifyToday}

}