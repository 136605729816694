import './modal.css'

type Props = {
    children: React.ReactNode;
    title: string;
    modalHandler: () => void;
    inputHandler?: (value: string) => void;
    index?: boolean;
}

export default function Modal ({children, title, modalHandler, inputHandler, index}:Props){
    return(
        <div className={index ? 'modalContainer2':'modalContainer'}>
            <div className='modalHeader'>
                <p className='modalHeader--title'>{title}</p>
                <p className='modalHeader--closeIcon' onClick={modalHandler}>x</p>
            </div>
            <div className='modalSearch'>
                <input placeholder='Filtro' onChange={(e)=> {if(inputHandler){ inputHandler(e.target.value)}}} className='modalSearch--input' type='text' id={title} name={title} ></input>
            </div>
            <div className='modalContainer--content'>
                {children}
            </div>
        </div>
    )
}