import { useEffect, useState } from "react";
import { Branch } from "../models/Branch";
import { useParams } from "react-router-dom";
import requests from "../../shared/api/agent";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import BranchesForm from "../components/BranchesForm";
import { BranchCreated } from "../models/BranchCreated";

export default function EditBranchPage() {
  const [data, setData] = useState<BranchCreated>();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      requests.get("/Branches/" + id).then((response) => {
        console.log(response.data)
        setData({
          cityId: response.data.cityId,
          stateId: response.data.stateId,
          code: response.data.code,
          name: response.data.name,
          address: response.data.address,
          phoneNumber: response.data.phoneNumber,
          cityName:response.data.cityName,
          stateName:response.data.stateName
        });
      });
    }
  }, [id]);

  return (
    <>
      <TopBar
        currentPageTitle={`Editar sucursal`}
        firstLevelPageTitle="Sucursales"
        firstLevelPageUrl="/sucursales"
      />
      <PageTitle title={`Editar sucursal`} />
      {data && <BranchesForm isNew={false} initialData={data} id={id}/>}
    </>
  );
}
