import TopBar from "../../shared/layouts/TopBar";
import SummaryAuxiliaryBookForm from "../components/SummaryAuxiliaryBookForm";

export default function SummaryAuxiliaryBookIndex(){
    return(
        <>
            <TopBar currentPageTitle="Libro auxiliar de resumen" />
            <SummaryAuxiliaryBookForm />
        </>
    )
}