import { Link } from "react-router-dom";



export default function GridEditInabilite() {
  return (
    <div className="flex items-center mr-3">
      {" "}
      <svg 
        width="24" 
        height="24" 
        viewBox="0 0 40 40"
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
        <path d="M15 18.3333L20 23.3333L33.3333 10" stroke="#838181" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M33.3333 19.9998V29.9998C33.3333 30.8839 32.9821 31.7317 32.357 32.3569C31.7319 32.982 30.884 33.3332 30 33.3332H9.99996C9.1159 33.3332 8.26806 32.982 7.64294 32.3569C7.01782 31.7317 6.66663 30.8839 6.66663 29.9998V9.99984C6.66663 9.11578 7.01782 8.26794 7.64294 7.64281C8.26806 7.01769 9.1159 6.6665 9.99996 6.6665H25" stroke="#838181" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>

     
      {" "}
    </div>
      
      
    
  );
}
