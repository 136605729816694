import { CostCenter } from "../../costCenters/models/costCenter";
import {useState, useEffect} from "react";
import requests from "../api/agent";

export default function useCostCenters(){
    const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
    const [selectedCostCenter, setSelectedCostCenter] = useState<CostCenter>({} as CostCenter);

    const handleCostCenter = (data: string) => {
        setSelectedCostCenter(costCenters.find((costCenter) => costCenter.code === data)!)
    };
    
    useEffect(() => {
        requests.get(`/cost-centers/search?Top=10000`)
        .then((response) => setCostCenters(response.data.data)).catch((error) => console.log(error))
    }, [])

    return {costCenters, selectedCostCenter, handleCostCenter}

}