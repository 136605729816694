import { useState, useEffect } from "react";
import "../styles/chartOfAccounts.css";
import TextField2 from "./TextField2";
import InputSelect2 from "./InputSelect2";
import { IPUCTree } from "../pages/ChartOfAccountsCreate";
import BitSelect from "./BitSelect";
import requests from "../../shared/api/agent";
import { IPUCPUTRequest } from "../pages/ChartOfAccountsEdit";
import { IPropItem } from "./ChartOfAccountsForm";
import { useLocation } from "react-router-dom";

interface IData {
  id: number;
  code: string;
  name: string;
  isAuxiliary: boolean;
  pucTypeId: number;
  pucTypeName: string;
  taxTypeId: number;
  taxTypeName: string;
  isBase: boolean;
  isNiifAdjustment: boolean;
  isNature: string;
  isActive: boolean;
}

const Nature:IPropItem[]= [
    { name: "DEBITO", code: "D" },
    { name: "CREDITO", code: "C" },
  ];

export default function EditChartOfAccountsForm({
  data,
  handler,
}: {
  data: IPUCTree[];
  handler: (data: IPUCPUTRequest) => void;
}) {

    const location = useLocation();

  const [PUCData, setPUCData] = useState<IPUCTree[]>(data);
  const [AditionalData, setAditionalData] = useState<IData>({} as IData);
  const [auxName, setAuxName] = useState<string>(data[4].name);
  const [PUTData, setPUTData] = useState<IPUCPUTRequest>({} as IPUCPUTRequest);
  const [TaxType, setTaxType] = useState<IPropItem[]>([]);
  const [type, setType] = useState<IPropItem[]>([]);

  useEffect(() => {
    setAuxName(data[4].name);
  },[data[4].name] )

  useEffect(() => {
    requests.get("/puc-types").then((response) => {
      setType(response.data);
    });
  }, []);

  useEffect(() => {
    requests.get("/tax-types").then((response) => {
      setTaxType(response.data);
    });
  }, []);

  useEffect(() => {
    setPUCData(data);
  }, [data]);

  useEffect(() => {
    requests
      .get("pucs/".concat(location.pathname.split("/")[2]))
      .then((response) => {
        setAditionalData(response.data);
        let aux: IPUCPUTRequest = PUTData;
        aux.name = data[4].name;
        aux.pucTypeId = response.data.pucTypeId;
        aux.taxTypeId = response.data.taxTypeId;
        aux.isBase = response.data.isBase;
        aux.adjustmentNiif = response.data.isNiifAdjustment;
        aux.isNature = response.data.isNature[0];
        setPUTData(aux);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [PUCData[4].code]);

  const handleBase = (value: string) => {
    let aux: IPUCTree[] = PUCData;
    aux[4].isBase = value === "true" ? true : false;
    setPUCData(aux);
    let aux2: IPUCPUTRequest = PUTData;
    aux2.isBase = value === "true" ? true : false;
    setPUTData(aux2);
    handler(PUTData);
  };

  const handleNiff = (value: string) => {
    let aux: IData = AditionalData;
    aux.isNiifAdjustment = value === "true" ? true : false;
    setAditionalData(aux);
    let aux2: IPUCPUTRequest = PUTData;
    aux2.adjustmentNiif = value === "true" ? true : false;
    setPUTData(aux2);
    handler(PUTData);
  };

  const handleAuxName = (value: string) => {
    setAuxName(value);
    let aux: IPUCTree[] = PUCData;
    aux[4].name = value;
    setPUCData(aux);
    let aux2: IPUCPUTRequest = PUTData;
    aux2.name = value;
    setPUTData(aux2);
    handler(PUTData);
  };

  return (
    <div className="formContainer">
      <div className="formContainer--row">
        <TextField2
          required
          label="Clase"
          value={PUCData[0].code}
          placeholder="Codigo Clase"
          onChange={(e) => {}}
        />
        <input
          required
          placeholder="Nombre Clase"
          className="form-control formContainer--item2"
          type="text"
          value={PUCData[0].name}
        />
      </div>
      <div className="formContainer--row">
        <TextField2
          label="Grupo"
          value={PUCData[1].code}
          placeholder="Codigo Grupo"
        />
        <input
          placeholder="Nombre Grupo"
          className="form-control formContainer--item2"
          type="text"
          value={PUCData[1].name}
        />
      </div>
      <div className="formContainer--row">
        <TextField2
          label="Cuenta"
          value={PUCData[2].code}
          placeholder="Codigo Cuenta"
        />
        <input
          placeholder="Nombre Cuenta"
          className="form-control formContainer--item2"
          type="text"
          value={PUCData[2].name}
        />
      </div>
      <div className="formContainer--row">
        <TextField2
          label="SubCuenta"
          value={PUCData[3].code}
          placeholder="Codigo SubCuenta"
        />
        <input
          placeholder="Nombre SubCuenta"
          className="form-control formContainer--item2"
          type="text"
          value={PUCData[3].name}
        />
      </div>
      <div className="formContainer--row">
        <TextField2
          label="Auxiliar"
          value={PUCData[4].code}
          placeholder="Codigo Auxiliar"
        />
        <input
          required
          placeholder="Nombre Auxiliar"
          className="form-control formContainer--item2"
          type="text"
          value={auxName}
          onChange={(e) => handleAuxName(e.target.value)}
        />
      </div>
      <div className="formContainer--row">
        <InputSelect2
          key={0}
          name="Tipo de PUC"
          data={type}
          onChange={(val:string) => {
            let temp: number = type.filter((val2) => val2.name === val)[0].id || 0;
            let aux = PUTData;
            aux.pucTypeId = temp;
            setPUTData(aux);
            handler(PUTData);
          }}
          defaultValue={AditionalData.pucTypeName}
        />
        <InputSelect2
            key={1}
            name="Tipo de Impuesto"
            data={TaxType}
            onChange={(val:string) => {
                let temp: number = TaxType.filter((val2) => val2.name === val)[0].id || 0;
                let aux = PUTData;
                aux.taxTypeId = temp;
                setPUTData(aux);
                handler(PUTData);
            }}
            defaultValue={AditionalData.taxTypeName}
        />
      </div>
      <div className="formContainer--row">
        <BitSelect
          value={PUCData[4].isBase! || false}
          title="Cuenta Requiere Base"
          handler={handleBase}
        />
        <BitSelect
          value={AditionalData.isNiifAdjustment! || false}
          title="Cuenta diferencia niff o aporte fiscal"
          handler={handleNiff}
        />
      </div>
      <div className="formContainer--row">
        <InputSelect2
            key={2}
            name="Naturaleza"
            data={Nature}
            onChange={(val:string) => {
                let temp: string = Nature.filter((val2) => val2.name === val)[0].code || "";
                let aux = PUTData;
                aux.isNature = temp;
                setPUTData(aux);
                handler(PUTData);
            }}
            defaultValue={AditionalData.isNature}
        />
      </div>
    </div>
  );
}
