import { Route, Routes } from "react-router-dom";
import LoginPage from "../../login/pages/LoginPage";
import ErrorPage from "../../notFound/page/ErrorPage";

export default function UnauthenticatedLayout(){
    return(
        <>
            <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="*" element={<ErrorPage />} />
            </Routes>
        </>
    )
}