import Pager from "../../shared/filtered-query/Pager"
import PageSizeSelector from "../../shared/filtered-query/PageSizeSelector"

interface Props {
    pageSize: number;
    handleSize: (pageSize: number) => void;
    totalData: number;
    handlePagination: (pageIndex: string) => void;
    currentIndex: number;
}


export default function Pagination({pageSize, handleSize, totalData, handlePagination, currentIndex}: Props){
    return(
        <div className="grid grid-cols-4 gap-4">
          <Pager
            visible={true}
            handleChange={(e: any) => handlePagination(e)}
            currentPageIndex={currentIndex}
            pageSize={pageSize}
            filteredRecords={totalData}
            totalRecords={totalData}
          />
          <PageSizeSelector
                initialPageSize={pageSize}
                handleChange={(e: any) => handleSize(parseInt(e.target.value))}
              />
        </div>
    )
}