import TopBar from "../../shared/layouts/TopBar";
import StatementsChangesEquityForm from "../components/StatementsChangesEquityForm";

export default function StatementsChangesEquityIndex(){
    return(
        <>
            <TopBar currentPageTitle="Estados de cambios en el patrimonio" />
            <StatementsChangesEquityForm />
        </>
    )
}