import { Link } from "react-router-dom";
import withFilteredQueryRequest from "../../shared/filtered-query/withFilteredQueryRequest";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import UsersTable from "../components/UsersTable";

export default function UsersIndexPage() {
  const DataList = withFilteredQueryRequest(
    "/accounts",
    UsersTable
  );

  return (
    <>
      <TopBar currentPageTitle="Usuarios" />
      <PageTitle title="Usuarios">
        <Link
          to="/usuarios/crear"
          className="btn btn-primary shadow-md mr-2"
        >
          Agregar Usuario
        </Link>
      </PageTitle>
      <DataList />
    </>
  );
}
