import TopBar from "../../shared/layouts/TopBar";
import AnnexForm from "../components/AnnexForm";
import '../../movementsByThirdParties/styles/mbtp.css';

export default function ThirdPartiesAnnex(){
    return(
        <>
            <TopBar currentPageTitle="Anexo de terceros" />
            <AnnexForm/>
        </>
    )
}
