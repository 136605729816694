import { useState, useEffect } from "react";
import "../styles/chartOfAccounts.css";
import TextField from "../../shared/common/forms/TextField";
import { IPUCTree } from "../pages/ChartOfAccountsCreate";
import BitSelect from "./BitSelect";
import requests from "../../shared/api/agent";

interface IData{
  id: number
  code: string
  name: string
  isAuxiliary: boolean
  pucTypeId: number
  pucTypeName: string
  taxTypeId: number
  taxTypeName: string
  isBase: boolean
  isNiifAdjustment: boolean
  isNature: string
  isActive: boolean
}


export default function ShowChartOfAccountsForm({
  data,
}: {
  data: IPUCTree[];
}) {
  const [PUCData, setPUCData] = useState<IPUCTree[]>(data);
  const [AditionalData, setAditionalData] = useState<IData>({} as IData);

  useEffect(() => {
    setPUCData(data);
  }, [data]);

  useEffect(() => {
    requests.get('pucs/'.concat(PUCData[4].id.toString()))
    .then(response => {
      setAditionalData(response.data);
    })
    .catch(error => {
      console.log(error);
    });
  }, [PUCData[4].code]);

  return (
    <div className="formContainer">
      <div className="formContainer--row">
        <TextField
          required
          label="Clase"
          name="Clase"
          value={PUCData[0].code}
          placeholder="Codigo Clase"
        />
        <input
          required
          placeholder="Nombre Clase"
          className="form-control formContainer--item2"
          type="text"
          value={PUCData[0].name}
        />
      </div>
      <div className="formContainer--row">
        <TextField
          label="Grupo"
            name="Grupo"
          value={PUCData[1].code}
          placeholder="Codigo Grupo"
        />
        <input
          placeholder="Nombre Grupo"
          className="form-control formContainer--item2"
          type="text"
          value={PUCData[1].name}
        />
      </div>
      <div className="formContainer--row">
        <TextField
          label="Cuenta"
            name="Cuenta"
          value={PUCData[2].code}
          placeholder="Codigo Cuenta"
        />
        <input
          placeholder="Nombre Cuenta"
          className="form-control formContainer--item2"
          type="text"
          value={PUCData[2].name}
        />
      </div>
      <div className="formContainer--row">
        <TextField
          label="SubCuenta"
            name="SubCuenta"
          value={PUCData[3].code}
          placeholder="Codigo SubCuenta"
        />
        <input
          placeholder="Nombre SubCuenta"
          className="form-control formContainer--item2"
          type="text"
          value={PUCData[3].name}
        />
      </div>
      <div className="formContainer--row">
        <TextField
          label="Auxiliar"
            name="Auxiliar"
          value={PUCData[4].code}
          placeholder="Codigo Auxiliar"
        />
        <input
          required
          placeholder="Nombre Auxiliar"
          className="form-control formContainer--item2"
          type="text"
          value={PUCData[4].name}
        />
      </div>
      <div className="formContainer--row">
        <TextField
          label="Tipo"
          name="Tipo"
          value={AditionalData.pucTypeName}
        />
        <TextField
          label="Tipo de Impuesto"
          name="Tipo de Impuesto"
          value={AditionalData.taxTypeName}
        />
      </div>
      <div className="formContainer--row">
        <BitSelect
        disabled={true}
        value={PUCData[4].isBase!} title="Cuenta Requiere Base" />
        <BitSelect
        disabled={true}
          value={AditionalData.isNiifAdjustment! || false}
          title="Cuenta diferencia niff o aporte fiscal"
        />
      </div>
      <div className="formContainer--row">
        <TextField
          label="Naturaleza"
          name="Naturaleza"
          value={PUCData[4].isNature! === '' ? '' : PUCData[4].isNature === 'D' ? 'Debito' : 'Credito'}
        />
      </div>
    </div>
  );
}
