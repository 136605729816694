import {useState, useEffect} from 'react'
import {MdCheckBoxOutlineBlank} from 'react-icons/md'
import {IoMdCheckboxOutline} from 'react-icons/io'

export default function InlineCheckBox({name, handler}:{name:string, handler: ()=>void}){
    const [state, setState] = useState<boolean>(false)

    return(
        <div className='flex flex-row items-center' onClick={handler}>
            <p className='text-sm'>{name}</p>
            {state ? <IoMdCheckboxOutline className='scale-150' onClick={() => setState(!state)} /> : <MdCheckBoxOutlineBlank className='scale-150' onClick={() => setState(!state)} />}
        </div>
    )
}