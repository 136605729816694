import { useState, useEffect } from "react";
import { ExplanatoryNote} from "../models/explanatoryNote";
import requests from "../../shared/api/agent";
import { PUC } from "../../general/models/puc";
import TextField from "../../shared/common/forms/TextField";
import TextAreaField from "../../shared/common/forms/TextAreaField";
import DateField from "../../shared/common/presentation/DateField";
import TypeSelector from "./TypeSelector";
import ModalTable from "../../shared/layouts/ModalTable";
import ErrorMessage from "../../shared/common/presentation/ErrorMessage";
import SuccessMessage from "../../shared/common/presentation/SuccessMessage";
import BackButton from "../../shared/common/forms/BackButton";
import SubmitButton from "../../shared/common/forms/SubmitButton";
import { useNavigate } from "react-router-dom";

interface Props {
  isNewNote: boolean;
  explanatoryNote?: ExplanatoryNote;
  id?:string;
  disabled?: boolean;
}

export default function ExplanatoryNotesForm({
  isNewNote,
  id,
  disabled = false,
  explanatoryNote: selectedNote,
}: Props) {
  const initialState = {
    id:0,
    date:"",
    noteTypeId: 0,
    pucId:0,
    title:"",
    detail:"",
    noteTypeName:"",
    accountInformation:{
      id:0,
      name:"",
      code:""
  }

  };

  const [note, setNote] = useState<ExplanatoryNote>(
    selectedNote ?? initialState
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [puc, setPuc] = useState<string[]>(['']);
  const [pucs, setPucs] = useState<PUC[]>([]);
  const [pucParent, setPucParent] = useState<PUC[]>([]);
  const [modalData, setModalData] = useState<{
    title: string,
    data: any,
    pucHandler?: (puc: PUC, index: number) => void
    index: number
  }>({
    title: '',
    data: [],
    index: 0
  });

  useEffect(() => {
    requests
      .get("/pucs/auxiliary?top=1000")
      .then((response) => setPucs(response.data.data));
  }, []);

  useEffect(() => {
    requests
      .get("pucs/parent?status=true")
      .then((response) => setPucParent(response.data))
      .catch((e) => console.log(e));
  }, []);

  const modalHandler = (index?: number, title?: string) => {
   if (title === 'PUC') {
      if(note.noteTypeId === 2){
        setModalData({ title: title, data: pucs, pucHandler: handlePUC, index: index! })
      }else if(note.noteTypeId === 3){
        setModalData({ title: "Seleccione cuentas grupos", data: pucParent, pucHandler: handlePUC, index: index! })
      }
    } else {
      setModalData({ title: '', data: [], index: 0 })
    }
    setShowModal(!showModal)
  }
 
  const handlePUC = (pucd: PUC, index: number) => {
    modalHandler(index, "")
    let data = puc.slice()
    data[index] = pucd.code
    setPuc(data)
  }

  const onFieldChange = (name: string, value: string | number) => {
    setNote({ ...note, [name]: value });
  };

  const navigate = useNavigate();
  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");
    setSuccessMessage(""); 
    let aux = note
    let tempPuc:string = puc.find((i) => i === puc[0]) || ''
    if(tempPuc === '')return;
    aux.pucId = pucs.find((i) => i.code === tempPuc)?.id || 0
    if (isNewNote) {
      requests
        .post("/clarification-notes", aux)
        .then((response) => {
          setNote(initialState);
          navigate("/notasAclaratorias")
        })
        .catch((e) => {
          setErrorMessage(e.message);
          window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
        })
        .finally(() => setIsSubmitting(false));
    } else {
      requests
        .put(`/clarification-notes/${id}`, aux)
        .then((response) =>{
          setSuccessMessage(
         "Se ha actualizado correctamente la nota."
       );
       window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
     })
     .catch((e) => {
       setErrorMessage(e.message);
       window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
     })
     .finally(() => setIsSubmitting(false));
    }
  };

  return (
    <>
        <div className="intro-y mb-3">
          <ErrorMessage message={errorMessage} />
          <SuccessMessage message={successMessage} />
        </div>

        <div className="intro-y box">
          <div id="form-validation" className="p-5" onSubmit={onSubmit}>
            <div className="preview" style={{ display: "block" }}>
              <form className="validate-form" onSubmit={onSubmit} method="POST">
                <div className="grid grid-cols-2 gap-4 mb-4">
                <DateField
                  label="Fecha"
                  name="date"
                  onChange={onFieldChange}
                  value={note.date}
                  required={true}
                  disabled={disabled && true}
                  
                />
                {
                  disabled ?
                    <TextField
                      label="Tipo de Nota"
                      name='noteTypeName'
                      value={note.noteTypeName}
                      required={true}
                      disabled={true}
                      
                    />

                    :

                    <TypeSelector
                      name="noteTypeId"
                      onChange={onFieldChange}
                      value={note.noteTypeId}
                      key="noteTypeId"
                      required={true}
                    
                  />
                }
            </div>
            <div className="grid grid-cols-2 gap-4">
              {
               
                note.noteTypeId === 1 && disabled === false ?

                
                  <TextField
                    label="No seleccione cuenta"
                    name='PUC'
                    value={""}
                    onClick={() => { modalHandler(0, 'PUC') }}
                    required={true}
                    disabled={true}
                    placeholder={"Seleccione"}
                 />
                 : note.noteTypeId===2  && disabled === false?
                 <TextField
                    label="Seleccione Cuenta Auxiliar"
                    name='PUC'
                    value={puc.filter((i) => i === puc[0])[0]}
                    onClick={() => { modalHandler(0, 'PUC') }}
                    required={true}
                    disabled={showModal ? true : false}
                    placeholder={"Seleccione"}
                  /> : note.noteTypeId=== 3  && disabled === false &&

                  <TextField
                    label="Seleccione Grupo"
                    name='PUC'
                    value={puc.filter((i) => i === puc[0])[0]}
                    onClick={() => { modalHandler(0, 'PUC') }}
                    required={true}
                    disabled={showModal ? true : false}
                    placeholder={"Seleccione"}
                  />

                }

                {
                   disabled &&

                   <TextField
                     label="Cuenta"
                     name="accounting.code"
                     value={note.accountInformation.code}
                     required={true}
                     disabled={true}
                 
                   />
                }
              <TextField
                label="Titulo"
                name="title"
                onChange={onFieldChange}
                value={note.title}
                key="title"
                disabled={disabled && true}
                required={false}
              />
            </div>
            <div className="grid grid-cols-1 gap-4">
              <TextAreaField
                label="Detalle"
                name="detail"
                onChange={onFieldChange}
                value={note.detail}
                key="detail"
                disabled={disabled && true}
                required={false}
              />
            </div>
            <div  className="pt-5">
              {disabled?<BackButton url="/notasAclaratorias" />
              :<div className="flex flex-col sm:flex-row items-center pt-5">
                    <SubmitButton isSubmitting={isSubmitting} />
                    <BackButton url="/notasAclaratorias" />
                </div>}
              
            </div>
            {showModal && <ModalTable modalHandler={modalHandler} modalData={modalData} />}
          </form>
        </div>
      </div>
    </div>
    </>
    
  );
}
