import { Link } from "react-router-dom";

interface Props {
  url: string;
}

export default function GridEditButton({ url }: Props) {
 
  return (
    <Link className="flex items-center mr-3" to={url}>
      <svg 
        width="24" 
        height="24" 
        viewBox="0 0 40 43" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_156_1624)">
          <path d="M15 18.3333L20 23.3333L33.3333 10" stroke="#1E40AF" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M33.3334 20.0001V30.0001C33.3334 30.8841 32.9822 31.732 32.3571 32.3571C31.732 32.9822 30.8841 33.3334 30.0001 33.3334H10.0001C9.11603 33.3334 8.26818 32.9822 7.64306 32.3571C7.01794 31.732 6.66675 30.8841 6.66675 30.0001V10.0001C6.66675 9.11603 7.01794 8.26818 7.64306 7.64306C8.26818 7.01794 9.11603 6.66675 10.0001 6.66675H25.0001" stroke="#1E40AF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </g>
        <defs>
          <filter id="filter0_d_156_1624" x="-4" y="0" width="48" height="48" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="2"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_156_1624"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_156_1624" result="shape"/>
          </filter>
        </defs>
      </svg>
    </Link>
  );
}
