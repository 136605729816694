// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes moveBit {
    from {
      transform: translateX(-35px);
    }
    to {
      transform: translateX(1);
    }
  }
  
  @keyframes restoreBit {
      from {
          transform: translateX(35px);
      }
      to {
          transform: translateX(0);
      }
  }
  
  .bitTitle{
    font-size: 0.9rem;
    text-align: center;
    width: -moz-fit-content;
    width: fit-content;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }
  .bitContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .selectContainer {
    border: 1px solid black;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 15px;
    width: 60px;
    margin-top: 5px;
  }
  
  .selectContainer:hover{
      cursor: pointer;
  }
  
  .bgwhite {
    background-color: white;
  }
  
  .bgblue {
    background-color: blue;
  }
  
  .activeBit {
    animation: moveBit 0.5s ease-in-out;
    position: relative;
    left: 32px;
  }
  
  .unactiveBit {
    animation: restoreBit 0.5s ease-in-out;
    position: relative;
    left: 0px;
  }
  
  .circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #f2f2f2;
    border: 1px solid #cccccc;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/shared/styles/bitSelect.css"],"names":[],"mappings":"AAAA;IACI;MACE,4BAA4B;IAC9B;IACA;MACE,wBAAwB;IAC1B;EACF;;EAEA;MACI;UACI,2BAA2B;MAC/B;MACA;UACI,wBAAwB;MAC5B;EACJ;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;IAClB,uBAAkB;IAAlB,kBAAkB;IAClB,uBAAuB;IACvB,iBAAiB;EACnB;EACA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,uBAAuB;IACvB,wBAAmB;IAAnB,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,eAAe;EACjB;;EAEA;MACI,eAAe;EACnB;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,mCAAmC;IACnC,kBAAkB;IAClB,UAAU;EACZ;;EAEA;IACE,sCAAsC;IACtC,kBAAkB;IAClB,SAAS;EACX;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,yBAAyB;EAC3B","sourcesContent":["@keyframes moveBit {\n    from {\n      transform: translateX(-35px);\n    }\n    to {\n      transform: translateX(1);\n    }\n  }\n  \n  @keyframes restoreBit {\n      from {\n          transform: translateX(35px);\n      }\n      to {\n          transform: translateX(0);\n      }\n  }\n  \n  .bitTitle{\n    font-size: 0.9rem;\n    text-align: center;\n    width: fit-content;\n    text-overflow: ellipsis;\n    text-wrap: nowrap;\n  }\n  .bitContainer {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .selectContainer {\n    border: 1px solid black;\n    height: fit-content;\n    border-radius: 15px;\n    width: 60px;\n    margin-top: 5px;\n  }\n  \n  .selectContainer:hover{\n      cursor: pointer;\n  }\n  \n  .bgwhite {\n    background-color: white;\n  }\n  \n  .bgblue {\n    background-color: blue;\n  }\n  \n  .activeBit {\n    animation: moveBit 0.5s ease-in-out;\n    position: relative;\n    left: 32px;\n  }\n  \n  .unactiveBit {\n    animation: restoreBit 0.5s ease-in-out;\n    position: relative;\n    left: 0px;\n  }\n  \n  .circle {\n    width: 25px;\n    height: 25px;\n    border-radius: 50%;\n    background-color: #f2f2f2;\n    border: 1px solid #cccccc;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
