import PageTitle from "../../shared/common/presentation/PageTitle";
import TopBar from "../../shared/layouts/TopBar";
import ThirdForm from "../components/ThirdForm";

export default function CreateThird(){
    return (
        <>
          <TopBar firstLevelPageTitle={'Tercero'} firstLevelPageUrl={'/tercero'} currentPageTitle="Nuevo Tercero" />
          <PageTitle title="Nuevo tercero">
          </PageTitle>
          <ThirdForm isNewThird={true}/>
        </>
      );
}
