import { Children} from "react";
import '../../styles/fixes.css'
interface Props {
    icon: JSX.Element;
    title: string;
    message: string;
    buttons: JSX.Element | JSX.Element[];
  }
  
  export default function SystemMessage({ icon,title,message ,buttons}: Props) {
    
  const arrayChildren = Children.toArray(buttons);
      return (
        <div className="d-flex justifyC-center alignI-center vh100 vw100 sMContainer place">
          <div className="d-flex justifyC-center alignI-center sMMensaje">
            <div className="mb-5 sMIconContainer" >
              {icon}
            </div>
            <h1 className="mb-3 fz-5 text-align-center">{title}</h1>
            <p className="mb-5 text-align-center">{message}</p>
            <div className="sMBtnsContainer">{
                Children.map(arrayChildren, (child, index) => {
                        return (
                            child
                            )
                })
                }</div>
          </div>
        </div>
      );
  }
  