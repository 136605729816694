import TopBar from "../../shared/layouts/TopBar"
import ABookAccountRankForm from "../components/ABookAccountRankForm"

export default function ABookAccountRankIndex(){
    return(
        <>
            <TopBar currentPageTitle="Libro auxiliar por rango de cuenta" />
            <ABookAccountRankForm />
        </>
    )
}