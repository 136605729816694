import { useState, useEffect } from "react";
import PageTitle from "../../shared/common/presentation/PageTitle";
import { Link } from "react-router-dom";
import { AiOutlineSearch, AiOutlinePrinter } from 'react-icons/ai';
import InputSelect from "../../chartOfAccounts/components/InputSelect2";
import ReceiptCategorySelector from "../../receiptCategories/components/ReceiptCategorySelector";
import ReceiptTypeSelector from "../../receiptTypes/components/ReceiptTypeSelector";
import DateFieldPack from "../../shared/common/forms/DateFieldPack";
import { Branch } from "../../branches/models/Branch";
import requests from "../../shared/api/agent";
import ABookAccountRankTable from "./ABookAccountRankTable";
import { IPUCTree } from "../../chartOfAccounts/pages/ChartOfAccountsCreate";
import ModalTable from "../../shared/layouts/ModalTable";
import TextField2 from "../../chartOfAccounts/components/TextField2";
import InlineCheckBox from "./InlineCheckBox";
import { IReceiptABookAccountRank, IABookAccountRank } from "./ABookAccountRankTable";


export default function ABookAccountRankForm() {

    const [branches, setBranches] = useState<Branch[]>([]);
    const [selectedBranche, setSelectedBranche] = useState<Branch>();
    const [selectedCategory, setSelectedCategory] = useState<number>();
    const [selectedType, setSelectedType] = useState<number>();
    const [hide, setHide] = useState<boolean>(false);
    const [closingReceipt, setClosingReceipt] = useState<boolean>(false);
    const [dateFrom, setDateFrom] = useState<string>('');
    const [dateUntil, setDateUntil] = useState<string>('');
    const [initialAccount, setInitialAccount] = useState<IPUCTree[]>();
    const [selectedInitial, setSelectedInitial] = useState<IPUCTree>();
    const [finalAccount, setFinalAccount] = useState<IPUCTree[]>();
    const [selectedFinal, setSelectedFinal] = useState<IPUCTree>();
    const [modal, setModal] = useState<boolean>(false);
    const [title, setTitle] = useState<string>('');
    const [sendRequest, setSendRequest] = useState<boolean>(false);

    //Request Data
    const [data, setData] = useState<IABookAccountRank[]>([])

    useEffect(() => {
        requests.get(`/pucs/auxiliary?Top=10000`)
            .then((response) => {
                setInitialAccount(response.data.data)
                setFinalAccount(response.data.data)
            }).catch((error) => console.log(error))
    }, [])

    useEffect(() => {
        if (selectedInitial === undefined && selectedFinal === undefined) {
            setFinalAccount(initialAccount)
        } else if (selectedInitial !== undefined && selectedFinal === undefined) {
            let aux = finalAccount
            let index: number = aux?.findIndex((item) => item.id === selectedInitial.id)!
            aux = aux?.slice(index + 1, aux.length)
            setFinalAccount(aux)
        } else if (selectedFinal !== undefined && selectedInitial === undefined) {
            let aux = initialAccount
            let index: number = aux?.findIndex((item) => item.id === selectedFinal.id)!
            aux = aux?.slice(0, index)
            setInitialAccount(aux)
        }
    }, [selectedInitial, selectedFinal])

    useEffect(() => {
        if (sendRequest === true) {
            if (dateFrom === '' && dateUntil === '' && selectedInitial === undefined && selectedFinal === undefined) {
                return;
            } else if (dateFrom !== '' && dateUntil !== '' && selectedInitial && selectedFinal && selectedBranche && selectedCategory && selectedType) {
                requests.get(`/reports/auxiliary-book?BranchId=${selectedBranche.id}&InitialAccount=${selectedInitial.code}&FinalAccount=${selectedFinal.code}&ReceiptCategoryId=${selectedCategory}&ReceiptTypeId=${selectedType}&InitialDate=${dateFrom}&FinalDate=${dateUntil}&IncludeReversals=${hide}&IncludeClosure=${closingReceipt}`)
                    .then((response) => setData(response.data))
                    .catch((error) => console.log(error))
            } else if (dateFrom !== '' && dateUntil !== '' && selectedInitial && selectedFinal && selectedCategory && selectedType) {
                requests.get(`/reports/auxiliary-book?InitialAccount=${selectedInitial.code}&FinalAccount=${selectedFinal.code}&ReceiptCategoryId=${selectedCategory}&ReceiptTypeId=${selectedType}&InitialDate=${dateFrom}&FinalDate=${dateUntil}&IncludeReversals=${hide}&IncludeClosure=${closingReceipt}`)
                    .then((response) => setData(response.data))
                    .catch((error) => console.log(error))
            } else if (dateFrom !== '' && dateUntil !== '' && selectedInitial && selectedFinal && selectedCategory && selectedType) {
                requests.get(`/reports/auxiliary-book?InitialAccount=${selectedInitial.code}&FinalAccount=${selectedFinal.code}&ReceiptCategoryId=${selectedCategory}&ReceiptTypeId=${selectedType}&InitialDate=${dateFrom}&FinalDate=${dateUntil}&IncludeReversals=${hide}&IncludeClosure=${closingReceipt}`)
                    .then((response) => setData(response.data))
                    .catch((error) => console.log(error))
            } else if (dateFrom !== '' && dateUntil !== '' && selectedInitial && selectedFinal && selectedBranche) {
                requests.get(`/reports/auxiliary-book?BranchId=${selectedBranche.id}&InitialAccount=${selectedInitial.code}&FinalAccount=${selectedFinal.code}&InitialDate=${dateFrom}&FinalDate=${dateUntil}&IncludeReversals=${hide}&IncludeClosure=${closingReceipt}`)
                    .then((response) => setData(response.data))
                    .catch((error) => console.log(error))
            } else if (dateFrom !== '' && dateUntil !== '' && selectedInitial && selectedFinal) {
                requests.get(`/reports/auxiliary-book?InitialAccount=${selectedInitial.code}&FinalAccount=${selectedFinal.code}&InitialDate=${dateFrom}&FinalDate=${dateUntil}&IncludeReversals=${hide}&IncludeClosure=${closingReceipt}`)
                    .then((response) => setData(response.data))
                    .catch((error) => console.log(error))
            }
            setSendRequest(false)
        }
    }, [sendRequest])

    useEffect(() => {
        requests.get(`/Branches/search`).then((response) => setBranches(response.data.data))
            .catch((error) => console.log(error))
    }, [])

    const handleBranche = (value: string) => { setSelectedBranche(branches.find(branch => branch.name === value)!) }

    return (
        <>
            <PageTitle title="Libro auxiliar por rango de cuenta">
                <Link
                    to={``}
                    className="btn btn-outline-secondary w-24 inline-block mr-2 row"
                >
                    <AiOutlinePrinter className="text-lg" />
                    Imprimir
                </Link>
                <Link to="" className="btn btn-warning w-40 mr-2 row" onClick={() => setSendRequest(true)}>
                    <AiOutlineSearch className="text-lg" />
                    Consultar
                </Link>
            </PageTitle>
            {modal && (<ModalTable modalData={title === 'Cuenta Inicial' ? {
                title: title,
                data: initialAccount,
                index: 2,
                anyHandler: (item: any, index: number) => { setSelectedInitial(item) }
            } : {
                title: title,
                data: finalAccount,
                index: 2,
                anyHandler: (item: any, index: number) => { setSelectedFinal(item) }
            }} modalHandler={() => setModal(false)} />)}
            <div className="box px-8 py-4">
                <div className="grid grid-cols-3 gap-4 mb-4">
                    <InputSelect data={branches} key={1} name="Sucursal" onChange={handleBranche} defaultValue="Todas las sucursales" />
                    <ReceiptCategorySelector name="Categoria comprobante" onChange={(name: string, value: number) => setSelectedCategory(value)} value={selectedCategory} />
                    <TextField2 label="Cuenta Inicial" placeholder="De click para seleccionar" value={selectedInitial?.code || ''} onClick={() => { setModal(true); setTitle('Cuenta Inicial') }} />
                </div>
                <div className="grid grid-cols-3 gap-4 mb-4">
                    <div className="flex flex-col mt-2 justify-center items-end gap-4 mr-14">
                        <InlineCheckBox name="Ocultar Anulaciones" handler={() => setHide(!hide)} />
                        <InlineCheckBox name="Comprobantes de cierre" handler={() => setClosingReceipt(!closingReceipt)} />
                    </div>
                    <ReceiptTypeSelector name="Tipos de comprobantes" onChange={(name: string, value: number) => setSelectedType(value)} receiptCategoryId={selectedCategory} value={selectedType} />
                    <TextField2 label="Cuenta Final" placeholder="De click para seleccionar" value={selectedFinal?.code || ''} onClick={() => { setModal(true); setTitle('Cuenta Final') }} />
                </div>
                <div className="w-2/3">
                    <DateFieldPack dateHandler={(date: string[]) => {
                        setDateFrom(date[0])
                        setDateUntil(date[1])
                    }} />
                </div>
                <ABookAccountRankTable data={data} />
            </div>
        </>
    )
}