import { useState } from "react";
import { Profile } from "../models/profile";
import requests from "../../shared/api/agent";
import TextField from "../../shared/common/forms/TextField";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../shared/common/presentation/ErrorMessage";
import SuccessMessage from "../../shared/common/presentation/SuccessMessage";
import BackButton from "../../shared/common/forms/BackButton";
import SubmitButton from "../../shared/common/forms/SubmitButton";


interface Props {
  isNewProfile: boolean;
  profile?: Profile;

}

export default function ProfilesForm({
  isNewProfile,
  profile: selectProfile,

}: Props) {
  const initialState = {
    id:0,
    name:"",
    state:true
    
   };
  
 
  const [profile, setProfile] = useState<Profile>(
    selectProfile ?? initialState
  );


  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onFieldChange = (name: string, value: string | number) => {
    setProfile({ ...profile, [name]: value });
  };

  const navigate = useNavigate();

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");
    setSuccessMessage("");  
    if (isNewProfile) {
      requests
        .post("/profiles", profile)
        .then((response) => navigate("/perfiles"))
        .catch((e) => {
          setErrorMessage(e.message);
          window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
        })
        .finally(() => setIsSubmitting(false));
    } else {
      const data = {
        name: profile.name
      }
      requests
        .put(`/profiles/${profile.id}`, profile)
        .then((response) =>{
           setSuccessMessage(
          "Se ha actualizado correctamente el Perfil."
        );
        window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
      })
      .catch((e) => {
        setErrorMessage(e.message);
        window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
      })
      .finally(() => setIsSubmitting(false));
    }
  };

 

  return (
    <>
    <div className="intro-y mb-3">
        <ErrorMessage message={errorMessage} />
        <SuccessMessage message={successMessage} />
      </div>
    <div className="intro-y box">
      <div id="form-validation" className="p-5" onSubmit={onSubmit}>
        <div className="preview" style={{ display: "block" }}>
          <form className="validate-form" onSubmit={onSubmit} method="POST">
            <div className="grid grid-cols-2 gap-4 mb-4">
             
              <TextField
                label="Descripción"
                name="name"
                onChange={onFieldChange}
                value={profile.name}
                key="name"
                required={true}
              />
            </div>
            
            
            <div className="flex flex-col sm:flex-row items-center pt-5 ">
                <SubmitButton isSubmitting={isSubmitting} />
                <BackButton url="/perfiles" />
            </div>
          </form>
        </div>
      </div>
    </div>
    </>
    
  );
}
