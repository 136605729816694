import { Link } from "react-router-dom";
import PageTitle from "../../shared/common/presentation/PageTitle";
import {
  AiOutlineCloudUpload,
  AiOutlinePrinter,
  AiOutlineSearch,
} from "react-icons/ai";
import { useState, useEffect } from "react";
import InputSelect from "../../chartOfAccounts/components/InputSelect2";
import TextField2 from "../../chartOfAccounts/components/TextField2";
import DateFieldPack from "../../shared/common/forms/DateFieldPack";
import ReceiptCategorySelector from "../../receiptCategories/components/ReceiptCategorySelector";
import ReceiptTypeSelector from "../../receiptTypes/components/ReceiptTypeSelector";
import TaxTypeSelector from "../../general/TaxType/components/TaxTypeSelector";
import useGetBranch from "../../shared/hooks/useGetBranch";
import requests from "../../shared/api/agent";
import CloseThirdPartiesTable from "./CloseThirdPartiesTable";
import { ICloseThirdParties } from "./CloseThirdPartiesTable";
import { Entity } from "../../entitys/models/entity";
import ModalTable from "../../shared/layouts/ModalTable";
import { CostCenter } from "../../costCenters/models/costCenter";
import { IPUCTree } from "../../chartOfAccounts/pages/ChartOfAccountsCreate";
import SuccessMessage from "../../shared/common/presentation/SuccessMessage";
import ErrorMessage from "../../shared/common/presentation/ErrorMessage";


const CloseThirdPartiesForm = () => {
  const [sendRequest, setSendRequest] = useState<boolean>(false);
  const [sendData, setSendData] = useState<boolean>(false);
  const [block, setBlock] = useState<boolean>(true);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any[]>([]);
  const branches = useGetBranch();
  const [entities, setEntities] = useState<Entity[]>([]);
  const [costCenters, setCostCenters] = useState<CostCenter[]>([]);
  const [auxiliaries, setAuxiliaries] = useState<IPUCTree[]>([]);
  const [taxTypes, setTaxTypes] = useState<number>(0);
  const [initialDate, setInitialDate] = useState<string>("");
  const [finalDate, setFinalDate] = useState<string>("");
  const [receiptCategoryId, setReceiptCategoryId] = useState<number>(0);
  const [receiptTypeId, setReceiptTypeId] = useState<number>(0);
  const [entity, setEntity] = useState<Entity>();
  const [puc, setPuc] = useState<IPUCTree>();
  const [costCenter, setCostCenter] = useState<CostCenter>();
  const [data, setData] = useState<ICloseThirdParties>(
    {} as ICloseThirdParties
  );

  //Message
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  //Modal
  const [modalEntities, setModalEntities] = useState<
    { name: string; code: string }[]
  >([]);
  const [modalPUC, setModalPUC] = useState<{ name: string; code: string }[]>(
    []
  );
  const [modalCostCenters, setModalCostCenters] = useState<
    { name: string; code: string }[]
  >([]);

  useEffect(() => {
    if (sendRequest) {
      if (
        taxTypes > 0 &&
        branches.selectedBranch.id > -1 &&
        initialDate !== "" &&
        finalDate !== ""
      ) {
        requests
          .get(
            `/close-third-party-accounts?InitialDate=${initialDate}&EndDate=${finalDate}&TaxTypeId=${taxTypes}&BranchId=${branches.selectedBranch.id}`
          )
          .then((response) => setData(response.data))
          .catch((error) => console.log(error))
          .finally(() => setBlock(false));
      }
      setSendRequest(false);
    }
  }, [sendRequest]);

  useEffect(() => {
    if (sendData) {
      if (entity && puc && costCenter) {
        let requestData = {
          initalDate: initialDate,
          endDate: finalDate,
          entityId: entity.id,
          branchId: branches.selectedBranch.id,
          taxTypeId: taxTypes,
          receiptTypeId: receiptTypeId,
          pucId: puc.id,
          costCenterId: costCenter.id,
          details: "cierre de terceros",
          accounts: data,
        };
        requests
          .post(`/close-third-party-accounts`, requestData)
          .then((response) => {setShowMessage(true); setError(false)})
          .catch((error) => {console.log(error); setError(true); setShowMessage(true)});
      }
      setSendData(false);
    }
  }, [sendData]);

  useEffect(() => {
    requests
      .get(`/entities`)
      .then((response) => {
        setEntities(response.data);
        setModalEntities(
          response.data.map((entity: Entity) => {
            return { name: entity.businessName, code: entity.document };
          })
        );
      })
      .catch((error) => console.log(error));
    requests
      .get("/pucs/auxiliary?Top=10000")
      .then((response) => {
        setAuxiliaries(response.data.data);
        setModalPUC(
          response.data.data.map((aux: IPUCTree) => {
            return { name: aux.name, code: aux.code };
          })
        );
      })
      .catch((error) => console.log(error));
    requests
      .get("/cost-centers/search?Top=10000")
      .then((response) => {
        setCostCenters(response.data.data);
        setModalCostCenters(
          response.data.data.map((cost: CostCenter) => {
            return { name: cost.name, code: cost.code };
          })
        );
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      {showMessage && (
        <div>
          {error ? (
            <ErrorMessage message="Ha ocurrido un error, verifique que todos los datos estan en orden" />
          ) : (
            <SuccessMessage message="Cierre de tercero realizado con exito" />
          )}
        </div>
      )}
      <PageTitle title="Cerrar terceros">
        <Link
          to={``}
          className="btn btn-outline-secondary w-24 inline-block mr-2 row"
        >
          <AiOutlinePrinter className="text-lg" />
          Imprimir
        </Link>
        <Link
          to=""
          className="btn btn-warning w-40 mr-2 row"
          onClick={() => setSendRequest(true)}
        >
          <AiOutlineSearch className="text-lg" />
          Consultar
        </Link>
        <Link
          to={""}
          className={`btn ${
            block ? "btn-outline-secondary" : "btn-success"
          } w-40 mr-2 row`}
          onClick={() => {
            if (!block) {
              setSendData(true);
            }
          }}
        >
          <AiOutlineCloudUpload className="text-lg" />
          Cargar
        </Link>
      </PageTitle>
      {showModal && (
        <ModalTable
          modalData={
            modalTitle === "Entidad"
              ? {
                  title: modalTitle,
                  data: modalEntities,
                  index: 2,
                  anyHandler: (item: any, index: number) =>
                    setEntity(
                      entities.find((entity) => {
                        return entity.document === item.code;
                      })
                    ),
                }
              : modalTitle === "Cuenta"
              ? {
                  title: modalTitle,
                  data: modalPUC,
                  index: 2,
                  pucHandler: (item: any, index: number) =>
                    setPuc(
                      auxiliaries.find((aux) => {
                        return aux.code === item.code;
                      })
                    ),
                }
              : {
                  title: modalTitle,
                  data: modalCostCenters,
                  index: 2,
                  costHandler: (item: any, index: number) =>
                    setCostCenter(
                      costCenters.find((cost) => {
                        return cost.code === item.code;
                      })
                    ),
                }
          }
          modalHandler={() => setShowModal(false)}
        />
      )}
      <div className="box px-8 py-4 flex flex-col gap-4">
        <div className="grid grid-cols-3 gap-4">
          <TaxTypeSelector
            name="Tipo de impuesto"
            onChange={(name: string, value: number) => setTaxTypes(value)}
            value={taxTypes}
          />
          <TextField2
            label="Entidad"
            placeholder="Click para buscar"
            value={entity?.document || ""}
            disable={block}
            onClick={() => {
              setModalTitle("Entidad");
              setShowModal(true);
            }}
          />
          <TextField2
            label="Cuenta"
            placeholder="Click para buscar"
            value={puc?.code || ""}
            disable={block}
            onClick={() => {
              setModalTitle("Cuenta");
              setShowModal(true);
            }}
          />
        </div>
        <div className="grid grid-cols-3 gap-4">
          <DateFieldPack
            dateHandler={(value: string[]) => {
              setInitialDate(value[0]);
              setFinalDate(value[1]);
            }}
            key={1}
          />
          <InputSelect
            data={branches.branch}
            key={2}
            name="Sucursal"
            onChange={(value) => branches.handleSelectedBranch(value)}
          />
          <TextField2
            label="Centro de costos"
            placeholder="Click para buscar"
            value={costCenter?.code || ""}
            disable={block}
            onClick={() => {
              setModalTitle("Centro de costos");
              setShowModal(true);
            }}
          />
        </div>
        <div className="grid grid-cols-3 gap-4 mb-4">
          <ReceiptCategorySelector
            name="Categoria comprobante"
            onChange={(name: string, value: number) =>
              setReceiptCategoryId(value)
            }
            value={receiptCategoryId}
            disabled={block}
          />
          <ReceiptTypeSelector
            name="Tipos de comprobantes"
            onChange={(name: string, value: number) => setReceiptTypeId(value)}
            receiptCategoryId={receiptCategoryId}
            value={receiptTypeId}
          />
        </div>
        <CloseThirdPartiesTable data={data} />
      </div>
    </>
  );
};

export default CloseThirdPartiesForm;
