interface Props {
    name: string;
    label: string;
    value: string;
    onChange: (name: string, value: string) => void;
  }
  
  export default function TextFilter({ name, label, value, onChange }: Props) {
    return (
      <div className="w-48 relative text-slate-500 mr-5">
          <label htmlFor={name}>{label}:</label>
        <input
          type="text"
          className="form-control w-48 box pr-10"
          value={value}
          onChange={e => onChange(name, e.target.value)}
        />
      </div>
    );
  }
  