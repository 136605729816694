import { Link } from "react-router-dom";
import withFilteredQueryRequest from "../../shared/filtered-query/withFilteredQueryRequest";
import ProfilesTable from "../components/ProfilesTable";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";


export default function ProfilesIndexPage() {
  const ProfilesList = withFilteredQueryRequest(
    "/profiles",
    ProfilesTable 
  );

  return (
    <>
      <TopBar currentPageTitle="Perfiles" />
      <PageTitle title="Perfiles">
        <Link
          to="/perfiles/crear"
          className="btn btn-primary shadow-md mr-2"
        >
          Agregar Perfil
        </Link>
      </PageTitle>
      <ProfilesList />
    </>
  );
}
