import { Link } from "react-router-dom";
import Table from "../../shared/common/presentation/Table";
import { Profile } from "../models/profile";
import GridEditButton from "../../shared/filtered-query/GridEditButton";
import GridStateGreen from "../../shared/filtered-query/GridStateGreen";
import GridStateRed from "../../shared/filtered-query/GridStateRed";
import GridAddItems from "../../shared/filtered-query/GridAddItems";
import GridDeleteButton from "../../shared/filtered-query/GridDeleteButton";
import GridEditInabilite from "../../shared/filtered-query/GridEditInabilite";
import GridAddItemInabilite from "../../shared/filtered-query/GridAddItemInabilite";
import GridDeleteInabilite from "../../shared/filtered-query/GridDeleteInabilite";


interface Props {
  data: Profile[];
  onSortingChange?: (columnName: string, sortOrder: "asc" | "desc") => void;
  sortedBy?: string | null;
  ascending?: boolean | null;
}

export default function ProfilesTable({
  data,
  onSortingChange,
  sortedBy,
  ascending,
}: Props) {
  const triggerSortingChange = (
    columnName: string,
    sortOrder: "asc" | "desc"
  ) => {
    if (onSortingChange) onSortingChange(columnName, sortOrder);
  };

  return (
    <Table
      sortedBy={sortedBy}
      ascending={ascending}
      onSortingChange={triggerSortingChange}
      columns={[
        { isSortable: true, name: "Id", title: "Id" },
        { isSortable: true, name: "name", title: "Descripcion" },
        { isSortable: false, name: "state", title: "Estado" },
        { isSortable: false, name: "Actions", title: "" },
      ]}
    >
      {data.map((profile) => (
        <tr className="intro-x" key={profile.id}>
           <td className="w-40">
            <Link
              className="font-medium whitespace-nowrap"
              to={`/profiles/${profile.id}`}
            >
              {profile.id}
            </Link>{" "}
          </td>
          <td className="w-40">{profile.name}</td>
          <td className="w-40">
            
              {profile.state? 
              <div className="d-flex justifyC-center alignI-center"><GridStateGreen url={`/profiles/${profile.id}/status/false`}/></div>
              :
              <div className="d-flex justifyC-center alignI-center"><GridStateRed url={`/profiles/${profile.id}/status/true`}/></div>}
           
          </td>

          <td className="table-report__action w-56">
            {
              profile.state?
              <div className="flex justify-center items-center">
                <GridEditButton url={`/perfiles/${profile.id}`}/>
                <GridAddItems url={`/profiles/${profile.id}`}/>
                <GridDeleteButton url={`/profiles/${profile.id}`} />
              </div> :
              <div className="flex justify-center items-center">
                <GridEditInabilite />
                <GridAddItemInabilite />
                <GridDeleteInabilite />
            </div>

            }
            
           
          </td>
        </tr>
      ))}
    </Table>
  );
}
