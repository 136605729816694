import TopBar from "../../shared/layouts/TopBar"
import WithholdingForm from "../components/WithholdingForm"

export default function WithholdingCertificateindex() {
    return(
        <>
            <TopBar currentPageTitle="Certificado de retenciones" />
            <WithholdingForm />
        </>
    )
}