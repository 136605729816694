import { useEffect, useState } from "react";
import { ThirdParty } from "../models/thirdParty";
import { useParams } from "react-router-dom";
import requests from "../../shared/api/agent";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import ThirdForm from "../components/ThirdForm";

export default function EditThird() {
  const [third, setThird] = useState<ThirdParty>();

  const { id } = useParams<{ id: string }>();
  
  const loadThird = () => {
 
    if (id) {
      requests.get(`/third-parties/${id}`).then((response) => {
      setThird(response.data);
      
      
      });
    }
  };

  useEffect(() => {
    loadThird();
  }, [id]);

  return (
    <>
      <TopBar
        currentPageTitle={`Editar Tercero #${id}`}
        firstLevelPageTitle="Tercero"
        firstLevelPageUrl="/terceros"
      />
      <PageTitle title={`Editar Tercero con documento # ${id}`} />
      {third && (
        <ThirdForm isNewThird={false} thirdParty={third} id={id} />
      )}
      
    </>
  );
}
