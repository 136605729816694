import { Link } from "react-router-dom";
import withFilteredQueryRequest from "../../shared/filtered-query/withFilteredQueryRequest";
import requests from "../../shared/api/agent";
import EntityTables from "../components/EntityTables";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import ThirdTable from "../components/EntityTables";
import { useEffect, useState } from "react";
import { EntityList } from "../models/entityList";


export default function EntityIndex() {

  const [data, setData] = useState<EntityList[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    requests
      .get("/Entities")
      .then((response) => setData(response.data))
      .finally(() => setIsLoading(false));
  }, []);
   
   
  

  return (
    <>
      <TopBar currentPageTitle="Entidades" />
      <PageTitle title="Entidades">
        <Link
          to="/entidades/crear"
          className="btn btn-primary shadow-md mr-2"
        >
          Agregar una entidad
        </Link>
      </PageTitle>
      <EntityTables data={data} />
    </>
  );
}
