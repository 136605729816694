import PageTitle from "../../shared/common/presentation/PageTitle";
import TopBar from "../../shared/layouts/TopBar";
import EntityForm from "../components/EntityForm";

export default function CreateEntity(){
    return (
        <>
          <TopBar firstLevelPageTitle={'Tercero'} firstLevelPageUrl={'/tercero'} currentPageTitle="Nuevo Tercero" />
          <PageTitle title="Nuevo tercero">
          </PageTitle>
          <EntityForm isNewEntity={true}/>
        </>
      );
}
