interface Props {
  currentPageIndex: number;
  visible: boolean;
  pageSize: number;
  filteredRecords: number;
  totalRecords: number;
}

export default function PagingInfo({
  currentPageIndex,
  visible,
  pageSize,
  filteredRecords,
}: Props) {
  const startingRecord = currentPageIndex * pageSize + 1;
  const endingRecord = startingRecord + pageSize - 1;
  return (
    <div className="md:block mr-auto text-slate-500" hidden={!visible}>
      Mostrando {startingRecord} a {endingRecord} de {filteredRecords} registros
    </div>
  );
}
