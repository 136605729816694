import { Link } from "react-router-dom";
import PageTitle from "../../shared/common/presentation/PageTitle";
import { AiOutlineSearch, AiOutlinePrinter } from "react-icons/ai";
import { useEffect, useState } from "react";
import useGetBranch from "../../shared/hooks/useGetBranch";
import DateSelector from "./dateSelector";
import { years, months } from "../data/date";
import InputSelect from "../../chartOfAccounts/components/InputSelect2";
import ReverseInlineCheckBox from "../../summaryAuxiliaryBook/components/ReverseInlineCheckBox";
import requests from "../../shared/api/agent";
import GeneralBalanceTable from "./GeneralBalanceTable";
import { IGeneralBalanceTableProps, ICumulativeResult, IExerciseResults } from "./GeneralBalanceTable";

export default function GeneralBalanceForm() {
  const [sendRequest, setSendRequest] = useState<boolean>(false);
  const branches = useGetBranch();
  const [showClose, setShowClose] = useState<boolean>(false);
  const [showReversion, setShowReversion] = useState<boolean>(false);
  const [year, setYear] = useState<string>("");
  const [month, setMonth] = useState<string>("");
  const [data, setData] = useState<IGeneralBalanceTableProps[]>([]);
  const [cumulativeResult, setCumulativeResult] = useState<ICumulativeResult>({} as ICumulativeResult);
  const [exerciseResults, setExerciseResults] = useState<IExerciseResults>({} as IExerciseResults);

  useEffect(() => {
    if(sendRequest){
        if(branches.selectedBranch.id === undefined){
            requests.get(`/reports/financial-statement?Year=${year}&Month=${month}&ShowClosure=${showClose}&ShowReversals=${showReversion}`)
            .then((response) =>  setData(response.data)).catch((error) => console.log(error))
            requests.get(`/reports/cumulative-result?Year=${year}&ShowClosure=${showClose}&ShowReversals=${showReversion}`)
            .then((response) =>  setCumulativeResult(response.data)).catch((error) => console.log(error))
            requests.get(`/reports/current-exercise-result?Year=${year}&Month=${month}&ShowReversals=${showReversion}`)
            .then((response) => {setExerciseResults(response.data); console.log(response.data)}).catch((error) => console.log(error))
        }else{
            requests.get(`/reports/financial-statement?Year=${year}&Month=${month}&BranchId=${branches.selectedBranch.id}&ShowClosure=${showClose}&ShowReversals=${showReversion}`)
            .then((response) =>  setData(response.data)).catch((error) => console.log(error))
            requests.get(`/reports/cumulative-result?Year=${year}&BranchId=${branches.selectedBranch.id}&ShowClosure=${showClose}&ShowReversals=${showReversion}`)
            .then((response) =>  setCumulativeResult(response.data)).catch((error) => console.log(error))
            requests.get(`/reports/current-exercise-result?Year=${year}&Month=${month}&BranchId=${branches.selectedBranch.id}&ShowReversals=${showReversion}`)
            .then((response) => setExerciseResults(response.data)).catch((error) => console.log(error))
        }
        setSendRequest(false)
    }
  }, [sendRequest]);

  return (
    <>
      <PageTitle title="Balance General">
        <Link
          to={``}
          className="btn btn-outline-secondary w-24 inline-block mr-2 row"
        >
          <AiOutlinePrinter className="text-lg" />
          Imprimir
        </Link>
        <Link
          to=""
          className="btn btn-warning w-40 mr-2 row"
          onClick={() => setSendRequest(true)}
        >
          <AiOutlineSearch className="text-lg" />
          Consultar
        </Link>
      </PageTitle>
      <div className="box px-8 py-4 flex flex-col gap-6">
        <div className="flex flex-row gap-2">
          <InputSelect
            data={branches.branch}
            key={1}
            name="Sucursal"
            onChange={(value) => branches.handleSelectedBranch(value)}
          />
          <div className="flex flex-row">
            <DateSelector
              data={years}
              title="year"
              handler={(value) => setYear(value)}
            />
            <DateSelector
              data={months}
              title="month"
              handler={(value) => setMonth(value)}
            />
          </div>
          <div className="flex flex-col w-full gap-6 mt-2">
            <ReverseInlineCheckBox
              name="Mostrar cierre"
              handler={() => setShowClose(!showClose)}
            />
            <ReverseInlineCheckBox
              name="Mostrar reversiones"
              handler={() => setShowClose(!showClose)}
            />
          </div>
        </div>
        <GeneralBalanceTable data={data} cumulativeResult={cumulativeResult} exerciseResults={exerciseResults}/>
      </div>
    </>
  );
}
