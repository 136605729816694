import "../styles/BitSelect.css";
import { useState } from "react";
export default function BitSelect({
  value,
  title,
  handler,
  disabled = false,
}: {
  value: boolean;
  title: string;
  handler?: (value: string) => void;
  disabled?: boolean;
}) {
  const [firstTime, setFirstTime] = useState<boolean>(true);
  const [data, setData] = useState<boolean>(value);

  return (
    <div className="bitContainer">
      <label className="formContainer--item1">{title}</label>
      <div
        onClick={() => {
          if (firstTime) {
            setFirstTime(false);
          }
          if (handler) {
            setData(!data);
            handler(data ? "false" : "true");
          }
        }}
        className={data ? "selectContainer bgblue" : "selectContainer bgwhite"}
      >
        <div
          className={
            data
              ? "circle activeBit"
              : firstTime
              ? "circle"
              : "circle unactiveBit"
          }
        ></div>
      </div>
    </div>
  );
}
