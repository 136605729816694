import { useEffect, useState } from "react";
import { User } from "../models/User";
import { useParams } from "react-router-dom";
import requests from "../../shared/api/agent";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import UsersForm from "../components/UsersForm";
import { UserCreated } from "../models/UserCreated";

export default function EditUserPage() {
  const [data, setData] = useState<UserCreated>();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      requests.get("/accounts/" + id).then((response) => {
        console.log(response.data)
        setData(
          {genderId: response.data.genderId,
            documentTypeId: response.data.documentTypeId,
            stateId:response.data.stateId,
            cityId: response.data.cityId,
            roleId: response.data.roleId,
            document: response.data.document,
            firstLastName: response.data.lastName,
            secondName: response.data.middleName,
            firstName: response.data.firstName,
            surName: response.data.surName,
            email: response.data.email,
            phoneNumber: response.data.phoneNumber,
            username: response.data.userName,
            address: response.data.address,
            isSupervisor: response.data.isSupervisor,
            cityName:response.data.cityName,
            stateName:response.data.stateName,
            roleName:response.data.roleName,
            documentTypeName:response.data.documentTypeName,
            genderName:response.data.genderName
          }
        );
      });
    }
  }, [id]);

  return (
    <>
      <TopBar
        currentPageTitle={`Editar usuario`}
        firstLevelPageTitle="Usuarios"
        firstLevelPageUrl="/usuarios"
      />
      <PageTitle title={`Editar usuario`} />
      {data && <UsersForm isNew={false} InitialData={data} id={id}/>}
    </>
  );
}
