import { useState } from "react";
import requests from "../../shared/api/agent";
import TextField from "../../shared/common/forms/TextField";
import { BranchCreated } from "../models/BranchCreated";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../shared/common/presentation/ErrorMessage";
import SuccessMessage from "../../shared/common/presentation/SuccessMessage";
import BackButton from "../../shared/common/forms/BackButton";
import SubmitButton from "../../shared/common/forms/SubmitButton";
import DepartmentSelector from "../../general/departmentCity/components/DepartmentSelector";
import CitySelector from "../../general/departmentCity/components/CitySelector";

interface Props {
  isNew: boolean;
  initialData?: BranchCreated;
  id?:number|string;
  disabled?:boolean; 
}

export default function BranchesForm({
  isNew,
  initialData,
  id,
  disabled
}: Props) {
  const initialState = {
    cityId: 0,
    stateId: 0,
    code: "",
    name: "",
    address: "",
    phoneNumber: "",
    cityName:"",
    stateName:""
  };


  
  const [data, setData] = useState<BranchCreated>(
    initialData ?? initialState
  );

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onFieldChange = (name: string, value: string | number) => {
    setData({ ...data, [name]: value });
  };

  const onSubmit = (event: React.FormEvent) => {
    const {
      cityId,
      stateId,
      code,
      name,
      address,
      phoneNumber,
      cityName,
      stateName,
    }
    = data
    const dataPost = {
      cityId,
      code,
      name,
      address,
      phoneNumber
    }
    const dataPut = {
      cityId,
      name,
      address,
      phoneNumber
    }
   
    event.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");
    setSuccessMessage("");
      if (isNew) {
        requests
          .post("/Branches", dataPost)
          .then((response) => navigate("/sucursales"))
          .catch((e) => {
            setErrorMessage(e.message);
              window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
          })
          .finally(() => setIsSubmitting(false));
        }else{
          requests
            .put("/Branches/"+id, dataPut)
            .then((response) =>{
              setSuccessMessage("Se ha actualizado correctamente la sucursal.");
              window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
            })
            .catch((e) => {
              setErrorMessage(e.message);
              window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
            })
            .finally(() => setIsSubmitting(false));
        }
  }    
  
  
  const navigate = useNavigate();
  const onSubmitInfo = ()=>{
    navigate("/sucursales")
  }
  

 
  
  return (
   <>
     <div className="intro-y mb-3">
        <ErrorMessage message={errorMessage} />
        <SuccessMessage message={successMessage} />
      </div>
       <div className="box">
      <div id="form-validation" className="p-5" onSubmit={disabled?onSubmitInfo:onSubmit}>
        <div className="preview" style={{ display: "block" }}>
          <form className="validate-form" onSubmit={disabled?onSubmitInfo:onSubmit} method="POST">
            <div className="grid grid-cols-2 gap-4 mb-1">
              <TextField
                label="Nombre"
                name="name"
                onChange={onFieldChange}
                value={data.name}
                key="name"
                required={true}
                disabled={disabled}
              />
              <TextField
                label="Codigo"
                name="code"
                onChange={onFieldChange}
                value={data.code}
                key="code"
                required={true}
                disabled={disabled}
              />
            </div>
            <div className="grid grid-cols-2 gap-4 mb-1">
            
              {
                disabled?
                  <TextField
                    label="Departamento"
                    name="stateName"
                    onChange={onFieldChange}
                    value={data.stateName}
                    key="stateName"
                    required={true}
                    disabled={disabled}
                  />:
                <DepartmentSelector 
                  name="departmentId"
                  onChange={onFieldChange}
                  value={data.stateId}
                  key="departmentId"
                  required={true}
                />

            }
              

            {
              disabled?
              <TextField
                label="Ciudad"
                name="cityName"
                onChange={onFieldChange}
                value={data.cityName}
                key="cityName"
                required={true}
                disabled={disabled}
            />:
            <CitySelector 
              dpto={data.stateId}
              name="cityId"
              onChange={onFieldChange}
              value={data.cityId}
              key="cityId"
              required={true}
         />
            }   
            
            </div>
            <div className="grid grid-cols-2 gap-4">
              <TextField
                label="Direccion"
                name="address"
                onChange={onFieldChange}
                value={data.address}
                key="address"
                required={true}
                disabled={disabled}
              />
              <TextField
                label="Telefono"
                name="phoneNumber"
                onChange={onFieldChange}
                value={data.phoneNumber}
                key="phoneNumber"
                maxLength={10}
                required={true}
                disabled={disabled}
              />
            </div>
           
            <div  className="pt-5">
              {disabled?<BackButton url="/sucursales" />
              :<div className="flex flex-col sm:flex-row items-center pt-5">
                    <SubmitButton isSubmitting={isSubmitting} />
                    <BackButton url="/sucursales" />
                </div>}
              
            </div>
          </form>
        </div>
      </div>
   
    </div>
   </>
  );
}
