interface Props {
  message: string;
}

export default function ErrorMessage({ message }: Props) {
  if (message) {
    return (
      <div
        className="alert alert-danger-soft flex items-center m-3"
        role="alert"
      >
        {" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          icon-name="alert-triangle"
          data-lucide="alert-triangle"
          className="lucide lucide-alert-triangle w-6 h-6 mr-2"
        >
          <path d="M10.29 3.86L1.82 18a2 2 0 001.71 3h16.94a2 2 0 001.71-3L13.71 3.86a2 2 0 00-3.42 0z"></path>
          <line x1="12" y1="9" x2="12" y2="13"></line>
          <line x1="12" y1="17" x2="12.01" y2="17"></line>
        </svg>{" "}
        <b>{message}</b>{" "}
      </div>
    );
  } else {
    return <></>;
  }
}
