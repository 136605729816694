import PageTitle from "../../shared/common/presentation/PageTitle";
import TopBar from "../../shared/layouts/TopBar";
import "../styles/chartOfAccounts.css";
import ToCreateTree from "../components/ToCreateTree";
import { useState, useEffect } from "react";
import requests from "../../shared/api/agent";
import ChartOfAccountsForm from "../components/ChartOfAccountsForm";
import { useNavigate } from "react-router-dom";
import SubmitButton from "../../shared/common/forms/SubmitButton";
import Message from "../components/Message";

export interface IPUCTree {
  id: number;
  code: string;
  name: string;
  isBase?: boolean;
  isNature?: string;
}

interface IPostPUCDescription {
  code: string;
  description: string;
}

interface ITaxType {
  id: number;
  code: string;
  name: string;
}

interface IPostPUC {
  code: string;
  name: string;
  pucTypeId: number;
  taxTypeId: number;
  isBase: boolean;
  niifAdjustment: boolean;
  isNature: string;
  descriptions?: IPostPUCDescription[];
}

export default function ChartOfAccountsCreate() {
  const navigate = useNavigate();
  const [sendRequest, setSendRequest] = useState(false);
  const [PUCData, setPUCData] = useState<IPUCTree[]>([]);
  const [auxiliary, setAuxiliary] = useState<IPUCTree[]>([]);
  const [selectedData, setSelectedData] = useState<IPUCTree[]>([
    { id: 0, code: "", name: "" },
    { id: 0, code: "", name: "" },
    { id: 0, code: "", name: "" },
    { id: 0, code: "", name: "" },
    { id: 0, code: "", name: "" },
  ]);
  const [submitRequest, setSubmitRequest] = useState(false);
  const [requestData, setRequestData] = useState<IPostPUC>({} as IPostPUC);
  const [taxType, setTaxType] = useState<ITaxType[]>([]);
  const [PUCTypes, setPUCTypes] = useState<ITaxType[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [typeMessage, setTypeMessage] = useState<number>(0);

  useEffect(() => {
    if(submitRequest){
      let aux = requestData;
      aux.code = selectedData[4].code;
      aux.name = selectedData[4].name;
      if(aux.isNature === 'D'){
        aux.isNature = 'Debit';
      }else{
        aux.isNature = 'Credit';
      }
      if(aux.descriptions === undefined){
        aux.descriptions = [];
      }
      requests.post("/pucs", aux)
      .then((response) => {
        setTypeMessage(1);
        setMessage("Cuenta creada satisfactoriamente");
      })
      .catch((error) => {
        setTypeMessage(2);
        setMessage("Error al crear la cuenta");
      });
      setSubmitRequest(false);
    }
  }, [submitRequest]);

  useEffect(() => {
    if (sendRequest) {
      requests
        .get("/pucs/parent")
        .then((response) => {
          setPUCData(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    setSendRequest(true);
  }, [sendRequest]);

  useEffect(() => {
    requests.get("/puc-types")
    .then((response) => {
      setPUCTypes(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
  }, []);

  useEffect(() => {
    requests.get("/tax-types")
    .then((response) => {
      setTaxType(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
  }, []);

  useEffect(() => {
    requests
      .get("/pucs/auxiliary?Top=1000&Ascending=true")
      .then((response) => {
        setAuxiliary(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  const handlerSelectedDataWithCode = (code: string, name?: string) => {
    let aux: IPUCTree[] = selectedData;
    aux[0] = PUCData.filter(
      (val) => val.code.length === 1 && code.startsWith(val.code)
    )[0];
    aux[1] = PUCData.filter(
      (val) => val.code.length === 2 && code.startsWith(val.code)
    )[0];
    if (
      PUCData.filter(
        (val) => val.code.length === 4 && code.startsWith(val.code)
      )
    ) {
      aux[2] = PUCData.filter(
        (val) => val.code.length === 4 && code.startsWith(val.code)
      )[0];
    }
    if (
      PUCData.filter(
        (val) => val.code.length === 6 && code.startsWith(val.code)
      )
    ) {
      aux[3] = PUCData.filter(
        (val) => val.code.length === 6 && code.startsWith(val.code)
      )[0];
      if (aux[3] === undefined) {
        aux[3] = { id: 0, code: code.substring(0, 6), name: "" };
      }
    }
    if (
      auxiliary.filter(
        (val) => val.code.length === 8 && code.startsWith(val.code)
      )
    ) {
      aux[4] = auxiliary.filter(
        (val) => val.code.length === 8 && code.startsWith(val.code)
      )[0];
      if (aux[4] === undefined) {
        aux[4] = { id: 0, code: code, name: name || "" };
      }
    }
    setSelectedData(aux);
  };

  const handlerOthers = (name: string, value: string) => {
    if (name === "Tipo") {
      let aux: IPostPUC = requestData;
      aux.pucTypeId = PUCTypes.filter((val) => val.name === value)[0].id;
      setRequestData(aux);
    } else if (name === "Impuesto") {
      let aux: IPostPUC = requestData;
      aux.taxTypeId = taxType.filter((val) => val.name === value)[0].id;
      setRequestData(aux);
    } else if (name === "Naturaleza") {
      let aux: IPostPUC = requestData;
      aux.isNature = value[0];
      setRequestData(aux);
    } else if (name === "Base") {
      let aux: IPostPUC = requestData;
      aux.isBase = value === "true" ? true : false;
      setRequestData(aux);
    } else if (name === "Niif") {
      let aux: IPostPUC = requestData;
      aux.niifAdjustment = value === "true" ? true : false;
      console.log(aux);
      setRequestData(aux);
    }
  };

  const handlerMessage = (val: string) => {
    if (val === "") {
      setTypeMessage(0);
      setMessage("");
      navigate(0);
    }
  };

  const hanlderTree = (item: IPUCTree) => {
    if(item.code.length <= 7){
      let aux: IPUCTree[] = selectedData;
      if (item.code.length === 1) {
        aux[0] = item;
        aux[1] = { id: 0, code: "", name: "" };
        aux[2] = { id: 0, code: "", name: "" };
        aux[3] = { id: 0, code: "", name: "" };
        aux[4] = { id: 0, code: "", name: "" };
      } else if (item.code.length === 2) {
        aux[1] = item;
        aux[2] = { id: 0, code: "", name: "" };
        aux[3] = { id: 0, code: "", name: "" };
        aux[4] = { id: 0, code: "", name: "" };
      } else if (item.code.length === 4) {
        aux[2] = item;
        aux[3] = { id: 0, code: "", name: "" };
        aux[4] = { id: 0, code: "", name: "" };
      } else if (item.code.length === 6) {
        aux[3] = item;
        aux[4] = { id: 0, code: item.code, name: "" };
      }
      setSelectedData(aux);
    }
    setRefresh(!refresh);
  }

  return (
    <div>
      <TopBar currentPageTitle="Crear" firstLevelPageTitle="PUC Contable" />
      <PageTitle title="PUC Contable"></PageTitle>
      {typeMessage !== 0 && (
        <Message
          type={typeMessage}
          message={message}
          handler={handlerMessage}
        />
      )}
      <div className="createContainer">
        <div className="insideContainer">
          <div className="createContainer__tree">
            <div>
              <h2 className="createContainer__tree--title">Cuenta Contable</h2>
              <ToCreateTree data={PUCData} handler={hanlderTree} />
            </div>
          </div>
          <ChartOfAccountsForm
            data={selectedData}
            handlerData={handlerSelectedDataWithCode}
            handleOthers={handlerOthers}
            refresh={refresh}
          />
        </div>
        <div onClick={(e) => setSubmitRequest(true)}>
          <SubmitButton isSubmitting={false} />
        </div>
      </div>
    </div>
  );
}
