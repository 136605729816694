import "./ModalTable.css"
import {useState, useEffect} from 'react'
import Modal from './Modal'
import { CostCenter } from "../../costCenters/models/costCenter"
import { ThirdParty } from "../../people/models/thirdParty"
import { PUC } from "../../general/models/puc"
import { IThirdParties } from "../../movementsByThirdParties/components/MovementsForm"

type Props = {
    modalHandler: () => void;
    modalData: {
        title: string,
        data: any,
        costHandler?: (cost: CostCenter, index:number) => void,
        thirdHandler?: (third: ThirdParty, index: number) => void,
        anyHandler?: (item: any, index: number) => void,
        pucHandler?: (puc: PUC, index: number) => void
        index: number
    }
}

export default function ModalTable ({modalHandler, modalData}:Props){

    const handler = (item: any)=>{
        if(modalData.costHandler){
            modalData.costHandler(item, modalData.index)
            modalHandler()
        }else if(modalData.thirdHandler){
            modalData.thirdHandler(item, modalData.index)
            modalHandler()
        }else if(modalData.pucHandler){
            modalData.pucHandler(item, modalData.index) 
            modalHandler()
        }else if(modalData.anyHandler){
            modalData.anyHandler(item, modalData.index)
            modalHandler()
        }
    }

    const [data, setData] = useState<any[]>(modalData.data)
    const [lastValue, setLastValue] = useState<string>('')

    const inputHandler = (value: string) => {
        if(value === ''){
            setData(modalData.data)
            return
        }
        if(value.length < lastValue.length){
            setData(modalData.data)
            setLastValue(value)
            return
        }
        const newData = modalData.data.filter((item: any) => {
            if(modalData.title === 'Terceros'){
                return item.document.includes(value) || item.name.includes(value)
            }else{
                return item.code.includes(value) || item.name.includes(value)
            }
        })
        setLastValue(value)
        setData(newData)
    }

    return(
        <Modal title={modalData.title} modalHandler={modalHandler} inputHandler={inputHandler} index={modalData.index === 2}>
            <div className="tableTitle">
                <p className="tableTitle--text">Codigo</p>
                <p className="tableTitle--text">Nombre</p>
            </div>
            {data.map((item: any, index: number) => {
                return(
                    <div key={index} className={index % 2 === 0 ? 'tableContent': "tableContent tableContent--nonpair"}>
                        <p onClick={() => {handler(item)}} className='tableContent--text'>
                            {modalData.title === 'Terceros' ? item.document : item.code}
                        </p>
                        <p onClick={() => {handler(item)}} className='tableContent--text'>{item.name}</p>
                    </div>
                )
            })}
        </Modal>
    )
}