import { useState } from "react";
import requests from "../../shared/api/agent";
import IoCContainer from "../../shared/common/IoC/IoCContainer";
import IAuthManager from "../../shared/common/security/IAuthManager";
import { Credentials } from "../models/credentials";
import { Link, useNavigate } from "react-router-dom";
import ErrorIcon from "../../shared/icons/ErrorIcon";
import BranchSelectorModal from "./BranchSelectorModal";
import { BranchOffice } from "../../shared/common/security/models/user";

export default function Login() {
  const authManager: IAuthManager = IoCContainer.getAuthManager();

  const [credentials, setCredentials] = useState<Credentials>({
    username: "",
    password: "",
  });


  const [hasError, setHasError] = useState<string>(" ");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [branches, setBranches] = useState<BranchOffice[]>([]);
  const [showsBranchSelectorModal, setShowsBranchSelectorModal] =
    useState<boolean>(false);

  const onHideBranchSelectorModal = (selectedBranchId: number) => {
    authManager.setSelectedBranch(selectedBranchId);
    setShowsBranchSelectorModal(false);
    window.location.href = '/';
  };

  const onInputChange = (name: string, value: string) => {
    setCredentials({ ...credentials, [name]: value });
  };

  const onSubmit = async () => {
    if(credentials.password.length<=16){
      if(credentials.password.length>=8){
        if(!credentials.password.includes(" ")){
          setIsLoading(true);
          setShowsBranchSelectorModal(false);
          try {
            const authInfo = await requests.post(process.env.REACT_APP_API_URL+"/accounts/login", credentials);

            authManager.saveAuthentication(authInfo.data.token, authInfo.data);
            setBranches(authInfo.data.branches);
            setShowsBranchSelectorModal(true);
            setHasError(" ");
          } catch (error) {
            console.log(error)
            setHasError("Nombre de usuario o contraseña incorrectos");
            setIsLoading(false);
          }
        }
        else{
          setHasError("La contraseña no debe contener espacios");
        }
      }
      else{
        setHasError("La longitud de la contraseña debe ser menor o igual a 8");
      }
    }
    else{
      setHasError("La longitud de la contraseña debe ser mayor o igual a 16");
    }    
  };

  return (
    <div className="h-screen xl:h-auto flex py-0 xl:py-0 my-0 xl:my-0 h100">
      <BranchSelectorModal
        show={showsBranchSelectorModal}
        onHide={onHideBranchSelectorModal}
        branches={branches}
      />
      <div className="my-auto mx-auto xl:ml-20 bg-white dark:bg-darkmode-600 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto">
        <h2 className="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left">
          Iniciar sesión
        </h2>
        <div className="intro-x mt-2 text-slate-400 xl:hidden text-center">
          Unos cuantos clics más para iniciar sesión
        </div>
        <div className="intro-x mt-8">
          <div className="alert alert-danger-soft mb-3" hidden={hasError ===" "}>
            {hasError}
          </div>
          <input
            type="text"
            name="username"
            className="intro-x login__input form-control py-3 px-4 block"
            placeholder="Nombre de usuario"
            onChange={(e) => onInputChange(e.target.name, e.target.value)}
            value={credentials.username}
          />
          <input
            type="password"
            name="password"
            className="intro-x login__input form-control py-3 px-4 block mt-4"
            placeholder="Contraseña"
            onChange={(e) => onInputChange(e.target.name, e.target.value)}
            value={credentials.password}
          />
        </div>
        <div className="intro-x flex text-slate-600 dark:text-slate-500 text-xs sm:text-sm mt-4">
          <div className="flex items-center mr-auto"></div>
          <a href="/recuperar-contrase">¿Olvidó su contraseña?</a>
        </div>
        <div className="intro-x mt-5 xl:mt-8 text-center xl:text-left">
          <button
            onClick={onSubmit}
            disabled={isLoading}
            type="button"
            className="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
          >
            {isLoading ? " Iniciando..." : "Iniciar sesión"}
          </button>
        </div>
      </div>
    </div>
  );
}
