import React from "react";
import { Children, useState } from "react"
import Grid from "../../icons/Grid";

interface Props {
    title: string;
    isDefaultActive?: boolean | null;
    children?: JSX.Element | JSX.Element[];
}

interface MenuItemProps {
    url?: string;
    title: string;
    children?: JSX.Element | JSX.Element[];
}

export default function MenuGroup({ title, children, isDefaultActive = false }: Props){

    const arrayChildren = Children.toArray(children)
    const [isActive, setIsActive] = useState<boolean>(isDefaultActive ?? false)

    return(
        <li>
          <a href="javascript:;.html" className={`side-menu ${isActive ? 'side-menu--active' : ''}`}>
            <div className="side-menu__icon">
              {" "}
              <i data-lucide="home"></i>{" "}
            </div>
            <div className="side-menu__title">
              <Grid/>
              {title}
              <div className="side-menu__sub-icon transform rotate-180">
                {" "}
                <i data-lucide="chevron-down"></i>{" "}
              </div>
            </div>
          </a>
          <ul className="side-menu__sub-open">
            {
                Children.map(arrayChildren, (child, index) => {
                    if (React.isValidElement<MenuItemProps>(child)){
                        return (child)
                    }
                })
            }
          </ul>
        </li>
    )
}