import '../../styles/bitSelect.css'
import { useState, useEffect } from 'react';
export default function BitSelect({
  value,
  title,
  handler
}: {
  value: boolean;
  title: string;
  handler?: (value: boolean) => void;
}) {

    const [selected, setSelected] = useState<boolean>(value);
    const [firstTime, setFirstTime] = useState<boolean>(true);

    useEffect(() => {
        if(handler){
            handler(selected);
        }
    }, [selected]);

  return (
    <div className="bitContainer">
      <label className="bitTitle">{title}</label>
      <div onClick={()=> {
        if(firstTime){
            setFirstTime(false);
        }
        setSelected(!selected)
      }} className={selected ? "selectContainer bgblue":"selectContainer bgwhite"}>
          <div className={selected ? "circle activeBit": firstTime ? "circle":"circle unactiveBit"}></div>
      </div>
    </div>
  );
}
