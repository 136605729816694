import { useState } from "react";

export default function TextField2({
  label,
  value,
  placeholder,
  required,
  onChange,
  onClick,
  disable
}: {
  label: string;
  value: string;
  placeholder: string;
  required?: boolean;
  onChange?: (value: string) => void;
  onClick?: () => void;
  disable?: boolean;
}) {

  return (
    <div className="inputSelect-form" onClick={onClick} >
      <label
        htmlFor={label}
        className="form-label w-full flex flex-col sm:flex-row"
      >
        {" "}
        {label}
      </label>
      <input
        id={label}
        type="text"
        name={label}
        className="form-control"
        placeholder={placeholder}
        required={required ?? false}
        value={value}
        onChange={(e) => {if(onChange) onChange(e.target.value);}}
        disabled={disable || false}
      />
    </div>
  );
}
