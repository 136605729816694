import '../../ABook-accountRank/styles/ScrollableTable.css'
import { useState, useEffect } from 'react'
import toCash from '../../ABook-accountRank/functions/toCash'

export interface ISummaryAuxiliaryBookReport {
    receiptCode: string
    receiptName: string
    receipts: IReceipt[]
    subTotals: ISubTotals
}

interface IReceipt {
    date: string
    accountName: string
    accountCode: string
    debit: string
    credit: string
}

interface ISubTotals {
    credit: string
    debit: string
}

export default function SummaryAuxiliaryBookTable({ data }: { data: ISummaryAuxiliaryBookReport[] }) {

    const [sumDebit, setSumDebit] = useState<string>('')
    const [sumCredit, setSumCredit] = useState<string>('')
    const [sumBalance, setSumBalance] = useState<string>('')
    let sumDebitAux: number = 0
    let sumCreditAux: number = 0
    let sumBalanceAux: number = 0
    let globalIndex: number = 0

    useEffect(() => {
        setSumDebit(toCash(sumDebitAux.toString()))
        setSumCredit(toCash(sumCreditAux.toString()))
        setSumBalance(toCash(sumBalanceAux.toString()))
    }, [data])

    return (
        <div className="mt-8 max-height-60vh">
            <div className="w-full SummaryAuxBook-structure table-header special-row">
                <p>#</p>
                <p>Fecha</p>
                <p>Cuenta</p>
                <p>Descripcion</p>
                <p>Debitos</p>
                <p>Creditos</p>
            </div>
            <div className="max-w-100 flex flex-col">
                {data.length > 0 ? data.map((item, index) => {
                    return (
                        <div>
                            {item.receipts.map((receipt, index) => {
                                sumDebitAux += parseFloat(receipt.debit)
                                sumCreditAux += parseFloat(receipt.credit)
                                globalIndex++
                                return (
                                    <div key={globalIndex} className="SummaryAuxBook-structure text-center border-t-2 py-2">
                                        <p>{globalIndex}</p>
                                        <p className='text-nowrap'>{receipt.date}</p>
                                        <p>{receipt.accountCode}</p>
                                        <p>{receipt.accountName}</p>
                                        <p>{toCash(receipt.debit.toString())}</p>
                                        <p>{toCash(receipt.credit.toString())}</p>
                                    </div>
                                )
                            })}
                            <div key={index} className="SummaryAuxBook-structure text-center py-2 text-nowrap special-row border-t-2">
                                <p></p>
                                <p></p>
                                <p></p>
                                <p>{`SubTotal Comprobantes ` + item.receiptCode}</p>
                                <p>{toCash(item.subTotals.debit)}</p>
                                <p>{toCash(item.subTotals.credit)}</p>
                            </div>
                        </div>
                    )
                }) : (
                    <div className="flex flex-row items-center justify-center py-2 border-t-2">
                        <p>No hay datos para mostrar</p>
                    </div>
                )}
            </div>
            <div className="flex flex-row SummaryAuxBook-structure text-center items-center justify-center py-2 border-t-2 table-footer special-row">
                <p></p>
                <p></p>
                <p></p>
                <p>Total</p>
                <p>{`Debito: ${data.length > 0 ? sumDebit : ''}`}</p>
                <p>{`Credito: ${data.length > 0 ? sumCredit : ''}`}</p>
            </div>
        </div>
    )
}