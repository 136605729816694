import { Link } from "react-router-dom";
import PageTitle from "../../shared/common/presentation/PageTitle";
import { AiOutlinePrinter, AiOutlineSearch } from "react-icons/ai";
import { useState, useEffect } from "react";
import requests from "../../shared/api/agent";
import { years } from "../../generalBalance/data/date";
import { months } from "../../generalBalance/data/date";
import useGetBranch from "../../shared/hooks/useGetBranch";
import BitSelect from "../../shared/common/forms/BitSelect";
import InputSelect from "../../chartOfAccounts/components/InputSelect2";
import DateSelector from "../../generalBalance/components/dateSelector";
import AnySelector from "../../ledgerNBalanceSheet/components/anySelector";
import InventoriesNBalanceBookTable, {IAccounts} from "./InventoriesNBalanceBookTable";

export default function InventoriesNBalanceBookForm() {
  const [sendRequest, setSendRequest] = useState(false);
  const branches = useGetBranch();
  const [year, setYear] = useState<string>("");
  const [month, setMonth] = useState<string>("");
  const [level, setLevel] = useState<string>("");
  const [showClose, setShowClose] = useState<boolean>(false);
  const [showReversion, setShowReversion] = useState<boolean>(false);

  const [data, setData] = useState<IAccounts[]>([]);

  useEffect(() => {
    if(sendRequest){
      if(year === "" || month === "" || level === ""){
        setSendRequest(false)
        return;
      }
      if(branches.selectedBranch.id === undefined){
        requests.get(`/reports/balance-sheet-ledger?Year=${year}&Month=${month}&Level=${level}&ShowClosure=${showClose}&ShowReversals=${showReversion}`)
        .then((response) => setData(response.data)).catch((error) => console.log(error))
      }else if(branches.selectedBranch !== undefined){
        requests.get(`/reports/balance-sheet-ledger?Year=${year}&Month=${month}&BranchId=${branches.selectedBranch.id}&Level=${level}&ShowClosure=${showClose}&ShowReversals=${showReversion}`)
        .then((response) => setData(response.data)).catch((error) => console.log(error))
      }
      setSendRequest(false)
    }
  }, [sendRequest]);

  useEffect(() => {
    if(level !== ""){
      if(level === "Clase"){
        setLevel("1")
      }else if(level === "Grupo"){
        setLevel("2")
      }else if(level === "Cuenta"){
        setLevel("3")
      }else if(level === "SubCuenta"){
        setLevel("4")
      }else if(level === "Auxiliar"){
        setLevel("5")
      }
    }
  },[level])

  return (
    <>
      <PageTitle title="Libro inventarios y balance">
        <Link
          to={``}
          className="btn btn-outline-secondary w-24 inline-block mr-2 row"
        >
          <AiOutlinePrinter className="text-lg" />
          Imprimir
        </Link>
        <Link
          to=""
          className="btn btn-warning w-40 mr-2 row"
          onClick={() => setSendRequest(true)}
        >
          <AiOutlineSearch className="text-lg" />
          Consultar
        </Link>
      </PageTitle>
      <div className="box px-8 py-4 flex flex-col gap-6">
        <div className="flex flex-row gap-2">
          <InputSelect
            data={branches.branch}
            key={1}
            name="Sucursales"
            onChange={(value) => branches.handleSelectedBranch(value)}
          />
          <div className="flex flex-row gap-2">
            <DateSelector data={years} handler={(value) => setYear(value)} title="year" />
            <DateSelector data={months} handler={(value) => setMonth(value)} title="month" />
            <AnySelector data={['Clase','Grupo','Cuenta','SubCuenta','Auxiliar']} handler={(value) => setLevel(value)} title="Nivel" />
          </div>
          <div className="flex flex-row gap-2">
            <BitSelect title="Mostrar cierre" value={showClose} handler={(value) => setShowClose(value)} />
            <BitSelect title="Mostrar reversiones" value={showReversion} handler={(value) => setShowReversion(value)}/>
          </div>
        </div>
        <InventoriesNBalanceBookTable data={data} />
      </div>
    </>
  );
}
