import { useEffect, useState } from "react";
import { Receipt } from "../models/receipt";
import { useParams } from "react-router-dom";
import requests from "../../shared/api/agent";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import ReceiptForm from "../components/ReceiptForm";
import VoidReceiptConfirmModal from "../components/VoidReceiptModal";
import LoadingIcon from "../../shared/icons/LoadingIcon";
import SuccessMessage from "../../shared/common/presentation/SuccessMessage";
import ErrorMessage from "../../shared/common/presentation/ErrorMessage";

export default function EditReceiptPage() {
  const [receipt, setReceipt] = useState<Receipt>();
  const [isVoidReceiptModalOpen, setIsVoidReceiptModalOpen] =
    useState<boolean>(false);
  const [isVoiding, setIsVoiding] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');

  const { id } = useParams<{ id: string }>();

  const loadReceipt = () => {
    if (id) {
      requests.get("/receipts/" + id).then((response) => {
        setReceipt(response.data);
      });
    }
  };

  useEffect(() => {
    loadReceipt();
  }, [id]);

  const onVoidReceipt = () => {
    if (id) {
      setIsVoiding(true);
      setIsVoidReceiptModalOpen(false);
      setErrorMessage('');
      setSuccessMessage('');
      requests.post(`/receipts/${id}/void`, {}).then((response) => {
        loadReceipt();
        setSuccessMessage('Se ha anulado el comprobante exitosamente');
      })
      .catch(error => setErrorMessage(error.message))
      .finally(() => setIsVoiding(false));
    }
  };

  return (
    <>
      <VoidReceiptConfirmModal
        isVisible={isVoidReceiptModalOpen}
        onHide={() => setIsVoidReceiptModalOpen(false)}
        onOk={() => onVoidReceipt()}
      />
      <TopBar
        currentPageTitle={`Editar comprobante #${receipt?.consecutive}`}
        firstLevelPageTitle="Comprobantes"
        firstLevelPageUrl="/comprobantes"
      />
      <PageTitle title={`Editar comprobante #${receipt?.consecutive}`}>
        <button
          type="button"
          className="btn btn-danger"
          hidden={!receipt || receipt.status !== "A"}
          disabled={isVoiding}
          onClick={() => setIsVoidReceiptModalOpen(true)}
        >
          {isVoiding && <LoadingIcon />}
          Anular
        </button>
      </PageTitle>
      <div className="intro-y mb-3">
        <ErrorMessage message={errorMessage} />
        <SuccessMessage message={successMessage} />
      </div>
      {receipt && <ReceiptForm isNewReceipt={false} receipt={receipt} />}
    </>
  );
}
