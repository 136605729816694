import { useEffect, useState } from "react";
import { ReceiptType } from "../models/receiptType";
import { useParams } from "react-router-dom";
import requests from "../../shared/api/agent";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import ReceiptTypeForm from "../components/ReceiptTypeForm";

export default function EditReceiptTypePage() {
  const [receiptType, setReceiptType] = useState<ReceiptType>();

  const { id } = useParams<{ id: string }>();

  const loadReceiptType = () => {
    if (id) {
      requests.get("/receipt-types/" + id).then((response) => {
        setReceiptType(response.data);
      });
    }
  };

  useEffect(() => {
    loadReceiptType();
  }, [id]);

  return (
    <>
      <TopBar
        currentPageTitle={`Editar tipo comprobante #${receiptType?.code}`}
        firstLevelPageTitle="Tipos de comprobantes"
        firstLevelPageUrl="/tipos-comprobantes"
      />
      <PageTitle title={`Editar tipo comprobante #${receiptType?.code}`} />
      {receiptType && (
        <ReceiptTypeForm isNewReceiptType={false} receiptType={receiptType} />
      )}
    </>
  );
}
