import HomePage from "../../home/pages/HomePage";
import CreateReceiptPage from "../../receipts/pages/CreateReceiptPage";
import ReceiptsIndexPage from "../../receipts/pages/ReceiptsIndex";
import Header from "./Header";
import MobileMenu from "./MobileMenu";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { BranchOffice } from "../common/security/models/user";
import IoCContainer from "../common/IoC/IoCContainer";
import BranchSelectorModal from "../../login/components/BranchSelectorModal";
import ReceiptTypesIndexPage from "../../receiptTypes/pages/ReceiptTypesIndex";
import CreateReceiptTypePage from "../../receiptTypes/pages/CreateReceiptTypePage";
import EditReceiptTypePage from "../../receiptTypes/pages/EditReceiptTypePage";
import EditReceiptPage from "../../receipts/pages/EditReceiptPage";
import ProfilesIndexPage from "../../profiles/pages/ProfilesIndex";
import CreateProfilePage from "../../profiles/pages/CreateProfilePage";
import ExplanatoryNotesIndexPage from "../../explanatoryNotes/pages/ExplanatoryNotesIndex";
import CreateExplanatoryNotePage from "../../explanatoryNotes/pages/CreateExplanatoryNotePage";
import LedgerAccountsIndexPage from "../../ledgerAccounts/pages/LedgerAccountsIndex";
import CreateLedgerAccountPage from "../../ledgerAccounts/pages/CreateLedgerAccountPage";
import FoliarBooksIndexPage from "../../foliarBooks/pages/FoliarBooksIndex";
import CreateFoliarBookPage from "../../foliarBooks/pages/CreateFoliarBookPage";
import EditProfilePage from "../../profiles/pages/EditProfilePage";
import EditExplanatoryNotesPage from "../../explanatoryNotes/pages/EditExplanatoryNotesPage";
import EditLedgerAccountPage from "../../ledgerAccounts/pages/EditLedgerAccountPage";
import BranchesIndexPage from "../../branches/pages/BranchesIndex";
import CreateBranchPage from "../../branches/pages/CreateBranchPage";
import EditBranchPage from "../../branches/pages/EditBranchPage";
import UsersIndexPage from "../../users/pages/UsersIndex";
import CreateUserPage from "../../users/pages/CreateUserPage";
import EditUserPage from "../../users/pages/EditUserPage";
import CostCenterIndexPage from "../../costCenters/pages/CostCenterIndexPage";
import EditCostCenterPage from "../../costCenters/pages/EditCostCenterPage";
import CreateCostCenterPage from "../../costCenters/pages/CreateCostCenterPage";
import InfoUserPage from "../../users/pages/InfoUserPage";
import InfoBranchPage from "../../branches/pages/InfoBranchPage";
import InfoCompanyPage from "../../companyDate/pages/InfoCompanyPage";
import EditCompanyDate from "../../companyDate/pages/EditCompanyDate";
import CompanyNoExist from "../../companyDate/pages/CompanyNoExist";
import ChangePassword from "../../changePassword/pages/ChangePassword";
import ErrorPage from "../../notFound/page/ErrorPage";
import ThirdIndex from "../../people/pages/ThirdIndex";
import CreateThird from "../../people/pages/CreateThird";
import EditThird from "../../people/pages/EditThird";
import InfoThirdPage from "../../people/pages/InfoThirdPage";
import InfoExplanatoryNotePage from "../../explanatoryNotes/pages/InfoExplanatoryNotePage";
import ChartOfAccountsIndex from "../../chartOfAccounts/pages/ChartOfAccountsIndex";
import ChartOfAccountsCreate from "../../chartOfAccounts/pages/ChartOfAccountsCreate";
import ChartOfAccountsEdit from "../../chartOfAccounts/pages/ChartOfAccountsEdit";
import EntityIndex from "../../entitys/pages/EntityIndex";
import CreateEntity from "../../entitys/pages/CreateEntity";
import EditEntity from "../../entitys/pages/EditEntity";
import ViewEntity from "../../entitys/pages/ViewEntity";
import MovementsByThirdParties from "../../movementsByThirdParties/pages/MovementsByThirdParties";
import ThirdPartiesAnnex from "../../thirdPartyAnnex/pages/ThirdPartyAnnex";
import ThirdPartyClosureIndex from "../../thirdPartyClosure/pages/ThirdPartyClosureIndex";
import CreateThirdPartyClosure from "../../thirdPartyClosure/pages/CreateThirdPartyClosure";
import WithholdingCertificateindex from "../../withholdingCertificate/pages/WithholdingCertificateindex";
import ABookAccountRankIndex from "../../ABook-accountRank/page/ABookAccountRankIndex";
import SummaryAuxiliaryBookIndex from "../../summaryAuxiliaryBook/pages/SummaryAuxiliaryBookIndex";
import DetailedAuxiliaryBookIndex from "../../detailedAuxiliaryBook/pages/DetailedAuxiliaryBookIndex";
import TestBalancesIndex from "../../testBalances/pages/TestBalancesIndex";
import GeneralBalanceIndex from "../../generalBalance/pages/GeneralBalanceIndex";
import CloseThirdPartiesIndex from "../../CloseThirdParties/pages/CloseThirdPartiesIndex";
import LedgerNBalanceSheetIndex from "../../ledgerNBalanceSheet/pages/LedgerNBalanceSheetIndex";
import InventoriesNBalanceBookIndex from "../../InventoriesNBalanceBook/pages/InventoriesNBalanceBookIndex";
import StatementsChangesEquityIndex from "../../StatementsChangesEquity/pages/StatementsChangesEquityIndes";
import IncomeNExpenseAnalysisIndes from "../../IncomeNExpenseAnalysis/pages/IncomeNExpenseAnalysisIndex";

export default function AuthenticatedLayout() {
  /*
  ---
   este fragmento requiere realizar una peticion a la api para obtener la sucursal, por tanto esta deshabilitada hasta que pueda montarse la api
  y funcione correctamente 
  ---

  ---
  cuando funcione la api, agregar este fragmento debajo del componente Header:
  
        <BranchSelectorModal branches={branches} show={!IoCContainer.getAuthManager().getSelectedBranch()} onHide={onHide} />
  ---
  
  const [branches] = useState<BranchOffice[]>(IoCContainer.getAuthManager().getUser().branches);

  const onHide = (selectedBranchId: number) => {
    IoCContainer.getAuthManager().setSelectedBranch(selectedBranchId);
    window.location.reload()
  }*/

  return (
    <>
      <MobileMenu />
      <div className="flex mt-[4.7rem] md:mt-0" style={{height:"100%"}}>
        <Header />
        <div className="content table_height">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/comprobantes" element={<ReceiptsIndexPage />} />
            <Route path="/comprobantes/crear" element={<CreateReceiptPage />} />
            <Route path="/comprobantes/:id" element={<EditReceiptPage />} />

            <Route path="/tipos-comprobantes" element={<ReceiptTypesIndexPage />} />
            <Route path="/tipos-comprobantes/crear" element={<CreateReceiptTypePage />} />
            <Route path="/tipos-comprobantes/:id" element={<EditReceiptTypePage />} /> 
            <Route path="/perfiles" element={<ProfilesIndexPage />} />
            <Route path="/perfiles/crear" element={<CreateProfilePage />} />
            <Route path="/perfiles/:id" element={<EditProfilePage />} /> 
            <Route path="/costoCentro" element={<CostCenterIndexPage />} />
            <Route path="/costoCentro/:id" element={<EditCostCenterPage />} />
            <Route path="/costoCentro/crear" element={<CreateCostCenterPage />} />
            <Route path="/empresa" element={<InfoCompanyPage />}/>
            <Route path="/editarEmpresa" element={<EditCompanyDate />}/>
            <Route path="/crearEmpresa" element={<CompanyNoExist />}/>
            <Route path="/notasAclaratorias" element={<ExplanatoryNotesIndexPage />} />
            <Route path="/notasAclaratorias/crear" element={<CreateExplanatoryNotePage />} />
            <Route path="/notasAclaratorias/:id" element={<EditExplanatoryNotesPage />} /> 
            <Route path="/notasAclaratorias/info/:id" element={<InfoExplanatoryNotePage />} /> 
            <Route path="/cuentasContables" element={<LedgerAccountsIndexPage />} />
            <Route path="/cuentasContables/crear" element={<CreateLedgerAccountPage />} />
            <Route path="/cuentasContables/:id" element={<EditLedgerAccountPage />} /> 
            <Route path="/foliarLibros" element={<FoliarBooksIndexPage />} />
            <Route path="/foliarLibros/crear" element={<CreateFoliarBookPage />} />
            <Route path="/PUC-Contable" element={<ChartOfAccountsIndex />}/>
            <Route path="/PUC-Contable/crear" element={<ChartOfAccountsCreate />}/>
            <Route path="/PUC-Contable/:id" element={<ChartOfAccountsEdit />}/>
            
            <Route path="/sucursales" element={<BranchesIndexPage />} />
            <Route path="/sucursales/crear" element={<CreateBranchPage />} />
            <Route path="/sucursales/:id" element={<EditBranchPage />} /> 
            <Route path="/sucursales/info/:id" element={<InfoBranchPage />} /> 
            <Route path="/usuarios" element={<UsersIndexPage />} />
            <Route path="/usuarios/crear" element={<CreateUserPage />} />
            <Route path="/usuarios/:id" element={<EditUserPage />} /> 
            <Route path="/usuarios/info/:id" element={<InfoUserPage />} /> 
            <Route path="/terceros" element={<ThirdIndex />} />
            <Route path="/terceros/:id" element={<EditThird/>} />
            <Route path="/terceros/info/:id" element={<InfoThirdPage/>} />
            <Route path="/terceros/crear" element={<CreateThird />} />
            <Route path="/entidades" element={<EntityIndex />} />
            <Route path="/entidades/crear" element={<CreateEntity />} />
            <Route path="/entidades/:id" element={<EditEntity />} />
            <Route path="/entidades/info/:id" element={<ViewEntity />} />
            <Route path="/cambiar-contraseña" element={<ChangePassword/>} />
            <Route path="/Consultas/Movimientos-Por-Terceros" element={<MovementsByThirdParties/>} />
            <Route path="/Consultas/Anexo-Por-Terceros" element={<ThirdPartiesAnnex/>} />
            <Route path="/Consultas/Cierre-De-Terceros" element={<ThirdPartyClosureIndex/>} />
            <Route path="/Consultas/Cierre-De-Terceros/crear" element={<CreateThirdPartyClosure/>} />
            <Route path="/Consultas/Certificado-De-Retenciones" element={<WithholdingCertificateindex/>} />
            <Route path="/Consultas/Libro-Auxiliar-Por-Rango-De-Cuenta" element={<ABookAccountRankIndex/>} />
            <Route path="/Consultas/Libro-Auxiliar-Resumido" element={<SummaryAuxiliaryBookIndex/>} />
            <Route path="/Consultas/Libro-Auxiliar-Detallado" element={<DetailedAuxiliaryBookIndex/>} />
            <Route path="/Consultas/Balance-De-Prueba" element={<TestBalancesIndex/>} />
            <Route path="/Consultas/Balance-General" element={<GeneralBalanceIndex/>} />
            <Route path="/cerrar-terceros" element={<CloseThirdPartiesIndex/>} />
            <Route path="/Consultas/Libro-Mayor-Y-Balances" element={<LedgerNBalanceSheetIndex/>} />
            <Route path="/Consultas/Libro-Inventario-Y-Balances" element={<InventoriesNBalanceBookIndex/>} />
            <Route path="/Consultas/Estados-De-Cambio-En-El-Patrimonio" element={<StatementsChangesEquityIndex/>} />
            <Route path="/Consultas/Analisis-De-Ingresos-Y-Gastos" element={<IncomeNExpenseAnalysisIndes/>} />
            <Route path="*" element={<ErrorPage/>} />
          </Routes>
        </div>
      </div>
    </>
  );
}
