import { useEffect, useState } from "react";
import Input from "./Input";
import "./modalTable.css";
import { AiOutlineCloseCircle } from "react-icons/ai";

export default function ModalTable({
  data1,
  data2,
  title,
  handler,
  datahandler,
}: {
  data1: string[];
  data2: string[];
  title: string;
  handler: () => void;
  datahandler: (data: string) => void;
}) {
  const [inputValue, setInputValue] = useState<string>("");
  const [data, setData] = useState<{ data1: string; data2: string }[]>([]);

  useEffect(() => {
    const newData = data1.map((item, index) => ({
      data1: item,
      data2: data2[index],
    }));
    setData(newData);
  }, []);

  return (
    <div className="disableBackgound">
      <h4 className="modalTitle">{title}</h4>
      <div className="modalContainer">
        <div className="p-5 flex flex-row justify-between items-center">
          <Input
            label="Buscar"
            onChange={() => null}
            value=""
            disabled={true}
          />
          <AiOutlineCloseCircle className="closeButton" onClick={handler} />
        </div>
        <div className="modalContent">
          <div className="modalTable">
            <div className="modalTable--title mb-6">
              <p>Codigo</p>
              <p className="justify-self-end">Nombre</p>
            </div>
            {data.map((item, index) => (
              <div
                className="modalTable--content"
                key={index}
                onClick={() => {
                  datahandler(item.data1);
                  handler();
                }}
              >
                <p>{item.data1}</p>
                <p className="justify-self-end">{item.data2}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
