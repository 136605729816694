import LoadingIcon from "../../icons/LoadingIcon";
import SaveIcon from "../../icons/SaveIcon";

interface Props {
  isSubmitting: boolean;
}

export default function SubmitButton({ isSubmitting }: Props) {
  return (
    <button
      disabled={isSubmitting}
      type="submit"
      className="btn btn-primary mr-2"
    >
      {isSubmitting ? "Guardando..." : "Guardar"}
      &nbsp;
      {isSubmitting && <LoadingIcon />}
      {!isSubmitting && <SaveIcon />}
    </button>
  );
}
