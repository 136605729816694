import { Link } from "react-router-dom";
import InactiveLabel from "../../shared/common/presentation/InactiveLabel";
import Table from "../../shared/common/presentation/Table";
import { ReceiptListItem } from "../models/receiptListItem";
import DateLabel from "../../shared/common/presentation/DateLabel";
import GridEditButton from "../../shared/filtered-query/GridEditButton";

interface Props {
  data: ReceiptListItem[];
  onSortingChange?: (columnName: string, sortOrder: "asc" | "desc") => void;
  sortedBy?: string | null;
  ascending?: boolean | null;
}

export default function LedgerAccountsTable({
  data: receipts,
  onSortingChange,
  sortedBy,
  ascending,
}: Props) {
  const triggerSortingChange = (
    columnName: string,
    sortOrder: "asc" | "desc"
  ) => {
    if (onSortingChange) onSortingChange(columnName, sortOrder);
  };

  return (
    <Table
      sortedBy={sortedBy}
      ascending={ascending}
      onSortingChange={triggerSortingChange}
      columns={[
        { isSortable: true, name: "Consecutive", title: "Consecutivo" },
        { isSortable: true, name: "ThirdParty.BusinessName", title: "Tercero" },
        { isSortable: true, name: "Year", title: "Año" },
        { isSortable: true, name: "Month", title: "Mes" },
        { isSortable: false, name: "Date", title: "Fecha" },
        { isSortable: false, name: "Actions", title: "" },
      ]}
    >
      {receipts.map((receipt) => (
        <tr className="intro-x" key={receipt.id}>
          <td className="w-40">
            <Link
              className="font-medium whitespace-nowrap"
              to={`/comprobantes/${receipt.id}`}
            >
              {receipt.consecutive}
              <div className="text-slate-500 text-xs whitespace-nowrap mt-0.5">
                {receipt.document}
              </div>
            </Link>{" "}
          </td>
          <td className="w-40">{receipt.thirdPartyName}</td>
          <td className="w-40">{receipt.year}</td>
          <td className="w-40">{receipt.month}</td>
          <td className="w-40">
            <DateLabel date={receipt.date} showTime={false} />
          </td>
          <td className="table-report__action w-56">
            <div className="flex justify-center items-center">
                <GridEditButton url={`/comprobantes/${receipt.id}`}/>
            </div>
          </td>
        </tr>
      ))}
    </Table>
  );
}
