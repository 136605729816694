import { Link } from "react-router-dom";

interface Props {
  url: string;
  onClick?: () => void;
}

export default function BackButton({ url, onClick }: Props) {
  return (
    <Link to={url} className="btn btn-outline-danger">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        icon-name="rewind"
        data-lucide="rewind"
        className="lucide lucide-rewind block mx-auto"
      >
        <polygon points="11 19 2 12 11 5 11 19"></polygon>
        <polygon points="22 19 13 12 22 5 22 19"></polygon>
      </svg>
      {" "}&nbsp; Atrás
    </Link>
  );
}
