import { useState, useEffect } from "react";
import { Receipt } from "../models/receipt";
import requests from "../../shared/api/agent";
import ReceiptTypeSelector from "../../receiptTypes/components/ReceiptTypeSelector";
import ThirdPartySelector from "../../people/components/ThirdPartySelector";
import DateField from "../../shared/common/presentation/DateField";
import TextField from "../../shared/common/forms/TextField";
import TextAreaField from "../../shared/common/forms/TextAreaField";
import ReceiptItemsEditableTable from "./ReceiptItemsEditableTable";
import { useNavigate } from "react-router-dom";
import ReceiptCategorySelector from "../../receiptCategories/components/ReceiptCategorySelector";
import SubmitButton from "../../shared/common/forms/SubmitButton";
import ErrorMessage from "../../shared/common/presentation/ErrorMessage";
import SuccessMessage from "../../shared/common/presentation/SuccessMessage";
import BackButton from "../../shared/common/forms/BackButton";
import { CostCenter } from "../../costCenters/models/costCenter";
import { ThirdParty } from "../../people/models/thirdParty";
import { PUC } from "../../general/models/puc";

interface Props {
  isNewReceipt: boolean;
  receipt?: Receipt;
}

export default function ReceiptForm({
  isNewReceipt,
  receipt: selectedReceipt,
}: Props) {
  const initialState = {
    id: 0,
    branchId:1,
    receiptTypeId: 0,
    receiptCategoryId: 0,
    thirdPartyId: 0,
    status: 'A',
    date: new Date().toISOString(),
    consecutive: 0,
    document: "",
    detail: "",
    items: [
      {
        costCenterId: 0,
        pucId: 0,
        thirdPartyId: 0,
        base: 0,
        rate: 0,
        debit: 0,
        credit: 0,
        detail: "",
      },
    ],
  };

  const [receipt, setReceipt] = useState<Receipt>(
    selectedReceipt ?? initialState
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onFieldChange = (name: string, value: string | number) => {
    setReceipt({ ...receipt, [name]: value });
  };

  const navigate = useNavigate();

  const calcTotal = (data: Receipt) => {
    let totalDebit = 0;
    let totalCredit = 0;
    data.items.forEach((item) => {
      totalDebit += item.debit;
      totalCredit += item.credit;
    });
    return totalCredit === totalDebit;
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");
    setSuccessMessage("");
    if(receipt.items.length >= 2 && calcTotal(receipt)){
      if (isNewReceipt) {
        console.log(receipt);
        requests
          .post("/receipts", receipt)
          .then((response) => navigate("/comprobantes"))
          .catch((e) => {
            setErrorMessage(e.message);
            window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
          })
          .finally(() => setIsSubmitting(false));
      } else {
        console.log(receipt);
        requests
          .put("/receipts/" + receipt.id, receipt)
          .then((response) => {
            setSuccessMessage("Se ha actualizado correctamente el comprobante.");
            window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
          })
          .catch((e) => {
            setErrorMessage(e.message);
            window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
          })
          .finally(() => setIsSubmitting(false));
      }
    }else if(calcTotal(receipt) === false){
      setErrorMessage("El total de los débitos y créditos no coinciden.");
      window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
      setIsSubmitting(false);
    }else{
      setErrorMessage("Debe agregar al menos dos items.");
      window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <div className="intro-y mb-3">
        <ErrorMessage message={errorMessage} />
        <SuccessMessage message={successMessage} />
      </div>
      <div className="intro-y box">
        <div id="form-validation" className="p-5">
          <div className="preview" style={{ display: "block" }}>
            <form
              className="validate-form"
              onSubmit={handleSubmit}
              method="POST"
            >
              <div className="grid grid-cols-3 gap-4 mb-4">
                <ReceiptCategorySelector
                  name="receiptCategoryId"
                  onChange={onFieldChange}
                  value={receipt.receiptCategoryId}
                  key="receiptCategoryId"
                  required={true}
                />
                <ReceiptTypeSelector
                  name="receiptTypeId"
                  receiptCategoryId={receipt.receiptCategoryId}
                  onChange={onFieldChange}
                  value={receipt.receiptTypeId}
                  key="receiptTypeId"
                  required={true}
                />
                <ThirdPartySelector
                  name="thirdPartyId"
                  onChange={onFieldChange}
                  value={receipt.thirdPartyId}
                  key="thirdPartyId"
                  required={true}
                />
              </div>
              <div className="grid grid-cols-2 gap-4 mb-4">
                <DateField
                  label="Fecha"
                  name="date"
                  onChange={onFieldChange}
                  value={receipt.date}
                  required={true}
                />
                <TextField
                  label="Documento"
                  name="document"
                  onChange={onFieldChange}
                  value={receipt.document}
                  key="document"
                  required={true}
                />
              </div>
              <div className="grid grid-cols-1 gap-4">
                <TextAreaField
                  label="Detalle"
                  name="detail"
                  onChange={onFieldChange}
                  value={receipt.detail}
                  key="detail"
                  required={false}
                />
              </div>
              <ReceiptItemsEditableTable
                items={receipt.items}
                onItemsChange={(e) => setReceipt({ ...receipt, ["items"]: e})}
                isNewReceipt={isNewReceipt}
              />
              <div className="flex flex-col sm:flex-row items-center pt-5 ">
                <SubmitButton isSubmitting={isSubmitting} />
                <BackButton url="/comprobantes" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
