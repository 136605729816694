import { useEffect, useState } from "react";
import DateField from "../presentation/DateField";

interface DateFieldPackProps {
  dateHandler: (date: string[]) => void;
}

export default function DateFieldPack(props: DateFieldPackProps) {

  const [dateFrom, setDateFrom] = useState('');
  const [dateUntil, setDateUntil] = useState('');

  const handleFrom = (name: string, value: string) => {
    if (name === "Desde") {
      setDateFrom(value);
      if (dateUntil !== "") {
        const date1 = new Date(value);
        const date2 = new Date(dateUntil);
        const milisecondsByDay: number = 86400000;
        const days: number = Math.floor(
          (date2.getTime() - date1.getTime()) / milisecondsByDay
        );
        if (days > 365) {
          const date = new Date(date1.getTime() + milisecondsByDay * 366);
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();
          setDateUntil(
            `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day
            }`
          );
        }
      }
    } else {
      setDateUntil(value);
      if (dateFrom !== "") {
        const date1 = new Date(dateFrom);
        const date2 = new Date(value);
        const milisecondsByDay: number = 86400000;
        const days: number = Math.floor(
          (date2.getTime() - date1.getTime()) / milisecondsByDay
        );
        if (days > 365) {
          const date = new Date(date1.getTime() + milisecondsByDay * 366);
          const year = date.getFullYear();
          const month = date.getMonth() + 1;
          const day = date.getDate();
          setDateUntil(
            `${year}-${month < 10 ? "0" + month : month}-${day < 10 ? "0" + day : day
            }`
          );
        }
      }
    }
  };

  useEffect(() => {
    props.dateHandler([dateFrom, dateUntil]);
  }, [dateFrom, dateUntil]);

  return (
    <div className="grid grid-cols-2 mb-4 w-full">
      <div className="w-9/10">
        <DateField
          name="Desde"
          label="Desde"
          value={dateFrom}
          onChange={(name: string, value: string) => handleFrom('Desde', value)}
        />
      </div>
      <DateField
        name="Hasta"
        label="Hasta"
        value={dateUntil}
        onChange={(name: string, value: string) => handleFrom('Hasta', value)}
      />
    </div>
  )
}