import { useEffect, useState } from "react";
import requests from "../../shared/api/agent";
import TextField from "../../shared/common/forms/TextField";
import InputSelect from "../../shared/common/forms/InputSelect";
import { UserCreated } from "../models/UserCreated";
import DepartmentSelector from "../../general/departmentCity/components/DepartmentSelector";
import CitySelector from "../../general/departmentCity/components/CitySelector";
import GenderSelector from "../../general/gender/components/GenderSelector";
import Profile from "../../general/models/Profile";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../shared/common/presentation/ErrorMessage";
import SuccessMessage from "../../shared/common/presentation/SuccessMessage";
import BackButton from "../../shared/common/forms/BackButton";
import SubmitButton from "../../shared/common/forms/SubmitButton";
import DocumentTypePerson from "../../general/documentTypePerson/components/DocumentTypePersonSelector";


interface Props {
  isNew: boolean;
  InitialData?: UserCreated;
  id?:number|string;
  disabled?:boolean;
}

export default function UsersForm({
  isNew,
  InitialData,
  id,
  disabled
}: Props) {
  const initialState = {
    genderId: 0,
    documentTypeId: 0,
    stateId: 0,
    cityId: 0,
    roleId: 0,
    document: "",
    firstLastName: "",
    secondName: "",
    firstName: "",
    surName: "",
    email: "",
    phoneNumber: "",
    username: "",
    address: "",
    isSupervisor: false,
    cityName:"",
    stateName:"",
    roleName:"",
    documentTypeName:"",
    genderName:""
  };
  const [data, setData] = useState<UserCreated>(
    InitialData ?? initialState
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [roles, setRoles] = useState<Profile[]>([]);


  const onFieldChange = (name: string, value: string | number |boolean) => {
    setData({ ...data, [name]: value });
  };

  const navigate = useNavigate();
  const onSubmitInfo = ()=>{
    navigate("/usuarios")
  }
  const onSubmit = (event: React.FormEvent) => {
  
   
    const {
      
    genderId,
    documentTypeId,
    cityId,
    roleId,
    document,
    firstLastName,
    secondName,
    firstName,
    surName,
    email,
    phoneNumber,
    username,
    address,
    isSupervisor,
    cityName,
    stateName,
    roleName,
    documentTypeName,
    genderName 
  }
    = data
    const dataPost = {
    genderId,
    documentTypeId,
    cityId,
    roleId,
    document,
    firstLastName,
    secondName,
    firstName,
    surName,
    email,
    phoneNumber,
    username,
    address,
    isSupervisor
   
    }
    const dataPut ={
      firstName,
      email,
      secondName,
      firstLastName,
      secondLastName:surName,
      phoneNumber,
      cityId,
      roleId,
      genderId,
      address,
      documentTypeId,
      document
    }
    event.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");
    setSuccessMessage("");  
    
    if (isNew) {
      requests
      .post("/accounts/register", dataPost)
      .then((response) => navigate("/usuarios"))
      .catch((e) => {
        setErrorMessage(e.message);
        window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
      })
     .finally(() => setIsSubmitting(false));
      } else {
          requests
          .put("/accounts/edit/"+id, dataPut)
          .then((response) =>{
              setSuccessMessage("Se ha actualizado correctamente el usuario.");
              window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
            })
            .catch((e) => {
              setErrorMessage(e.message);
              window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
            })
            .finally(() => setIsSubmitting(false));
      }
                                             
    };
    useEffect(() => {
      const getData = async () => {
  
    
   
    
      await requests.get("/profiles")
      .then((response)=>{
        setRoles(response.data.data
          .map((elm:Profile)=>
            (
              {
                id:elm.id,
                name:elm.name,
                state:elm.state
              }
            )
          )
        )
      })
      .catch((e) => {
        setErrorMessage(e.message);
        window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
      })
      .finally(() => setIsSubmitting(false));
    
};
getData().catch(console.error);
}, [data.stateId])

   
  
  return (
   <>
      <div className="intro-y mb-3">
        <ErrorMessage message={errorMessage} />
        <SuccessMessage message={successMessage} />
      </div>

       <div className="intro-y box ">
      <div id="form-validation" className="p-5" onSubmit={disabled?onSubmitInfo:onSubmit}>
        <div className="preview" style={{ display: "block" }}>
          <form className="validate-form" onSubmit={disabled?onSubmitInfo:onSubmit} method="POST">
            <div className="grid grid-cols-2 gap-4 mb-1">
            <TextField
                label="Documento"
                name="document"
                onChange={onFieldChange}
                value={data.document}
                key="name"
                maxLength={12}
                disabled={disabled}
                required={true}
              />
              {InitialData?

             
                  <TextField
                    label="Tipo de documento"
                    name="documentTypeName"
                    onChange={onFieldChange}
                    value={data.documentTypeName}
                    key="documentTypeName"
                    disabled={disabled}
                    required={true}
                    />
                    :
                    <DocumentTypePerson
                      name="documentTypeId"
                      onChange={onFieldChange}
                      value={data.documentTypeId}
                      key="documentTypeId"
                      required={true}
                    />
              }
              

           

            </div>
            <div className="grid grid-cols-2 gap-4 mb-1">
              <TextField
                label="Primer nombre"
                name="firstName"
                onChange={onFieldChange}
                value={data.firstName}
                key="code"
                disabled={disabled}
                required={true}
              />
            <TextField
                label="Segundo nombre"
                name="secondName"
                onChange={onFieldChange}
                value={data.secondName}
                key="code"
                disabled={disabled}
                required={true}
              />
            
           </div>  
           <div className="grid grid-cols-2 gap-4 mb-1">
             <TextField
                label="Primer apellido"
                name="firstLastName"
                onChange={onFieldChange}
                value={data.firstLastName}
                key="code"
                required={true}
                disabled={disabled}
              />
              <TextField
                label="Segundo apellido"
                name="surName"
                onChange={onFieldChange}
                value={data.surName}
                key="name"
                disabled={disabled}
              />
            </div>
            
            <div className="grid grid-cols-2 gap-4 mb-1">
              {
                InitialData?
                <TextField
                  label="Departamento"
                  name="stateName"
                  onChange={onFieldChange}
                  value={data.stateName}
                  key="stateName"
                  disabled={disabled}
                  required={true}
                />:
                 <DepartmentSelector 
                    name="departmentId"
                    onChange={onFieldChange}
                    value={data.stateId}
                    key="departmentId"
                    required={true}
                  />

              }
           

              {
                InitialData?
                <TextField
                  label="Ciudad"
                  name="cityName"
                  onChange={onFieldChange}
                  value={data.cityName}
                  key="cityName"
                  disabled={disabled}
                  required={true}
                />:

                <CitySelector 
                dpto={data.stateId}
                name="cityId"
                onChange={onFieldChange}
                value={data.cityId}
                key="cityId"
                required={true}
             />

            
              }  
            
            </div>
            <div className="grid grid-cols-1 gap-4 mb-1">
              <TextField
                label="Direccion"
                name="address"
                onChange={onFieldChange}
                value={data.address}
                key="name"
                required={true}
                disabled={disabled}
              />
            
            </div>  
 
            
            <div className="grid grid-cols-3 gap-4 mb-1">
             

              <TextField
                label="Telefono"
                name="phoneNumber"
                onChange={onFieldChange}
                value={data.phoneNumber}
                key="code"
                maxLength={10}
                required={true}
                disabled={disabled}
              />
              <TextField
                label="Email"
                name="email"
                onChange={onFieldChange}
                value={data.email}
                key="name"
                type="email"
                required={true}
                disabled={disabled}
              />

{InitialData?
                <TextField
                  label="Genero"
                  name="genderName"
                  onChange={onFieldChange}
                  value={data.genderName}
                  key="genderName"
                  required={true}
                  disabled={disabled}
              />
                :
                <GenderSelector
                  name="genderId"
                  onChange={onFieldChange}
                  value={data.genderId}
                  key="genderId"
                  required={true}
                />
            }
              
            </div>
             <div className="grid grid-cols-3 gap-4 mb-1">
             <TextField
                label="Nombre de usuario"
                name="username"
                onChange={onFieldChange}
                value={data.username}
                key="name"
                required={true}
                disabled={disabled}
              />
            {InitialData?
              <TextField
                label="Rol"
                name="rolName"
                onChange={onFieldChange}
                value={data.roleName}
                key="rolName"
                required={true}
                disabled={disabled}
          />
                :
                <InputSelect
                data={roles.map((elm)=>({label:elm.name,value:elm.id}))}
                name="Rol"
                index="roleId"
                key="roleId"
                onChange={
                  (x:string,y:number|string)=>onFieldChange(x,y)
                }
                />
            }

             <div className="d-flex alignI-start justifyC-center" style={{flexDirection:"column"}}>
                <label htmlFor="isSupervisor" className="mb-1">Supervisor</label>
                <div className="h100 d-flex alignI-center justifyC-center">
                  <input type="checkbox" id="isSupervisor" 
                  disabled={disabled}
                  checked={data.isSupervisor}
                  name="isSupervisor"
                  onChange={()=>onFieldChange("isSupervisor",!data.isSupervisor)}></input>
                </div>
              </div>
             </div>

            

          <div>  
           
             
            </div>
           
            <div pt-5>
              {disabled?<BackButton url="/usuarios" />
              :<div className="flex flex-col sm:flex-row items-center pt-5">
                    <SubmitButton isSubmitting={isSubmitting} />
                    <BackButton url="/usuarios" />
                </div>}
              
            </div>
          </form>
        
        </div>
      </div>
   
    </div>
   </>
  );
}
