import {useEffect, useState } from "react";
import{Ciiu} from '../models/ciiu'
import requests from "../../../shared/api/agent";
import Select, { SingleValue } from "react-select";

interface Props {
  name: string;
  value: number | undefined;
  onChange: (name: string, value: number) => void;
  required?: boolean | null;
}

export default function CiiuSelector({
  name,
  value,
  onChange,
  required,
}: Props) {
  const [ciiu, setCiiu] = useState<Ciiu[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    requests
      .get("/economic-activities/search")
      .then((response) => setCiiu(response.data.data))
      .finally(() => setIsLoading(false));
  }, []);

  const handleChange = (newValue: SingleValue<{label: string, value: number}>) => {
    onChange(name, newValue?.value ?? 0)
  }

  return (
    <div className="input-form">
      <label
        htmlFor={name}
        className="form-label w-full flex flex-col sm:flex-row"
      >
        {" "}
          Actividad Economica
      </label>
      <Select
        name={name}
        options={ciiu.map((ciiu) => ({
          label: `${ciiu.name.toUpperCase()} (${ciiu.code})`,
          value: ciiu.id,
        }))}
        className=" "
        isLoading={isLoading}
        isSearchable={true}
        placeholder="- Seleccione -"
        onChange={handleChange}
        required={required ?? false}
        value={ciiu.filter(ciiu => ciiu.id === value).map(ciiu => { return {
          label: `${ciiu.name.toUpperCase()} (${ciiu.code})`,
          value:ciiu.id,
        } as SingleValue<{label: string, value: number}>})}
      />
    </div>
  );
}
