interface Props {
  name: string;
  label: string;
  value: string;
  onChange: (name: string, value: Date | null) => void;

}

export default function DateFilter({ name, label, value, onChange}: Props) {

  value = value.split('T')[0];

  return (
    <div className="w-48 relative text-slate-500 mr-5">
        <label htmlFor={name}>{label}</label>
      <input
        type="date"
        className="form-control w-48 box pr-10"
        placeholder={label}
        value={value}
        onChange={e => onChange(name, e.target.valueAsDate)}
   
      />
    </div>
  );
}
