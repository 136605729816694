import PageTitle from "../../shared/common/presentation/PageTitle";
import TopBar from "../../shared/layouts/TopBar";
import ProfilesForm from "../components/UsersForm";

export default function CreateUserPage(){
    return (
        <>
          <TopBar firstLevelPageTitle={'Usuarios'} firstLevelPageUrl={'/usuarios'} currentPageTitle="Crear usuario" />
          <PageTitle title="Crear usuario">
          </PageTitle>
          <ProfilesForm isNew={true}/>
        </>
      );
}