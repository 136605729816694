import { Link } from "react-router-dom";
import PageTitle from "../../shared/common/presentation/PageTitle";
import { AiOutlinePrinter, AiOutlineSearch } from "react-icons/ai";
import { useState, useEffect } from "react";
import useGetBranch from "../../shared/hooks/useGetBranch";
import DateSelector from "../../generalBalance/components/dateSelector";
import { years } from "../../generalBalance/data/date";
import InputSelect from "../../chartOfAccounts/components/InputSelect2";
import StatementsChangesEquityTable, { StatementsChangesEquityProps } from "./StatementsChangesEquityTable";
import CostCenters from "../../shared/components/CostCenters";
import requests from "../../shared/api/agent";

export default function StatementsChangesEquityForm() {
  const [sendRequest, setSendRequest] = useState<boolean>(false);
  const branches = useGetBranch();
  const [costCenterId, setCostCenterId] = useState<string>("");
  const [year, setYear] = useState<string>("");
  const [data, setData] = useState<StatementsChangesEquityProps[]>([]);

  useEffect(() => {
    if (sendRequest) {
      if (branches.selectedBranch.id !== undefined && costCenterId !== "") {
        requests
          .get(
            `/reports/statement-assets?BranchId=${branches.selectedBranch.id}&CostCenterId=${costCenterId}&Year=${year}`
          )
          .then((response) => setData(response.data))
          .catch((error) => console.log(error));
      } else if (
        branches.selectedBranch.id !== undefined &&
        costCenterId === ""
      ) {
        requests
          .get(
            `/reports/statement-assets?BranchId=${branches.selectedBranch.id}&Year=${year}`
          )
          .then((response) => setData(response.data))
          .catch((error) => console.log(error));
      } else if (
        branches.selectedBranch.id === undefined &&
        costCenterId !== ""
      ) {
        requests
          .get(
            `/reports/statement-assets?CostCenterId=${costCenterId}&Year=${year}`
          )
          .then((response) => setData(response.data))
          .catch((error) => console.log(error));
      }
      setSendRequest(false);
    }
  }, [sendRequest]);

  return (
    <>
      <PageTitle title="Estados de cambios en el patrimonio">
        <Link
          to={``}
          className="btn btn-outline-secondary w-24 inline-block mr-2 row"
        >
          <AiOutlinePrinter className="text-lg" />
          Imprimir
        </Link>
        <Link
          to=""
          className="btn btn-warning w-40 mr-2 row"
          onClick={() => setSendRequest(true)}
        >
          <AiOutlineSearch className="text-lg" />
          Consultar
        </Link>
      </PageTitle>
      <div className="box px-8 py-4">
        <div className="flex flex-row gap-4">
          <div className="w-2/5">
            <InputSelect
              data={branches.branch}
              key={1}
              name="Sucursal"
              onChange={(value) => branches.handleSelectedBranch(value)}
            />
          </div>
          <DateSelector
            data={years}
            handler={(value) => setYear(value)}
            title="year"
          />
          <div className="w-1/4">
            <CostCenters handleId={(id) => setCostCenterId(id)} />
          </div>
        </div>
        <StatementsChangesEquityTable data={data} />
      </div>
    </>
  );
}
