import { useState, useEffect } from "react";
import toCash from "../../ABook-accountRank/functions/toCash";
import { roundDecimals } from "../../generalBalance/components/GeneralBalanceTable";
import "../../ABook-accountRank/styles/ScrollableTable.css";

export interface ICloseThirdParties {
  thirdPartyAccounts: IThirdPartyAccount[];
  debitBalance: number;
  creditBalance: number;
}

interface IThirdPartyAccount {
  pucId: number;
  code: string;
  account: string;
  thirdPartyId: number;
  thirdPartyName: string;
  document: string;
  debit: number;
  credit: number;
}

export default function CloseThirdPartiesTable({
  data,
}: {
  data: ICloseThirdParties;
}) {
  return (
    <div className="mt-8 max-height-30vh">
      <div className=" w-full CloseThirdParties-structure table-header special-row text-center">
        <p>Codigo</p>
        <p>Cuenta</p>
        <p>Documento</p>
        <p>Nombre</p>
        <p>Debito</p>
        <p>Credito</p>
      </div>
      <div className="max-w-100 flex flex-col">
        {data.thirdPartyAccounts !== undefined ? (
          data.thirdPartyAccounts.map((item, index) => {
            return(
              <div className="text-center border-t-2 py-2 CloseThirdParties-structure">
                  <p>{item.code}</p>
                  <p>{item.account}</p>
                  <p>{item.document}</p>
                  <p>{item.thirdPartyName}</p>
                  <p>{toCash(roundDecimals(item.debit).toString())}</p>
                  <p>{toCash(roundDecimals(item.credit).toString())}</p>
              </div>
            )
          })
        ) : (
          <div className="flex flex-row items-center justify-center py-2 border-t-2">
            <p>No hay datos para mostrar</p>
          </div>
        )}
      </div>
    </div>
  );
}
