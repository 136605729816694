import { Link } from "react-router-dom";
import PageTitle from "../../shared/common/presentation/PageTitle";
import { AiOutlinePrinter, AiOutlineSearch } from "react-icons/ai";
import { CiExport } from "react-icons/ci";
import InputSelect from "../../chartOfAccounts/components/InputSelect2";
import ReverseInlineCheckBox from "./../../summaryAuxiliaryBook/components/ReverseInlineCheckBox";
import useGetBranch from "../../shared/hooks/useGetBranch";
import { useState, useEffect } from "react";
import requests from "../../shared/api/agent";
import DateField from "../../shared/common/presentation/DateField";
import DetailedAuxiliaryBookTable, { IDetailedAuxiliaryBookReport } from "./DetailedAuxiliaryBookTable";

export default function DetailedAuxiliaryBookForm() {

    const [initialDate, setInitialDate] = useState<string>('')
    const [endDate, setEndDate] = useState<string>('')
    const [showClose, setShowClose] = useState<boolean>(false)
    const [showReversion, setShowReversion] = useState<boolean>(false)
    const [sendRequest, setSendRequest] = useState<boolean>(false)
    const branches = useGetBranch()
    const [data, setData] = useState<IDetailedAuxiliaryBookReport>({} as IDetailedAuxiliaryBookReport)

    useEffect(() => {
        if (sendRequest) {
            if(initialDate === '' || endDate === '') {
                setSendRequest(false)
                return
            }else if(branches.selectedBranch.id){
                requests.get(`/reports/detailed-journal?InitialDate=${initialDate}&FinalDate=${endDate}&BranchId=${branches.selectedBranch.id}&ShowClosure=${showClose}&ShowReversals=${showReversion}`)   
                .then(response => setData(response.data))
                .catch(error => console.log(error))
                .finally(() => setSendRequest(false))
            } else {
                requests.get(`/reports/detailed-journal?InitialDate=${initialDate}&FinalDate=${endDate}&ShowClosure=${showClose}&ShowReversals=${showReversion}`)
                    .then(response => setData(response.data))
                    .catch(error => console.log(error))
                    .finally(() => setSendRequest(false))
            }
        }
    }, [sendRequest])

    useEffect(() => {
        if (initialDate !== '' && endDate !== '') {
            let init = initialDate.substring(0, 4)
            let end = endDate.substring(0, 4)
            if (init !== end) {
                alert('Las fechas deben ser del mismo año')
            }
        }
    }, [initialDate, endDate])

    return (
        <>
            <PageTitle title="Libro auxiliar detallado">
                <Link
                    to={``}
                    className="btn btn-outline-secondary w-24 inline-block mr-2 row"
                >
                    <AiOutlinePrinter className="text-lg" />
                    Imprimir
                </Link>
                <Link
                    to={``}
                    className="btn btn-outline-secondary w-24 inline-block mr-2 row"
                >
                    <CiExport className="text-lg" />
                    Exportar
                </Link>
                <Link to={``} className="btn btn-warning w-40 mr-2 row" onClick={() => setSendRequest(true)}>
                    <AiOutlineSearch className="text-lg" />
                    Consultar
                </Link>
            </PageTitle>
            <div className="box px-8 py-4">
                <div className="flex flex-row w-full">
                    <InputSelect data={branches.branch} key={1} name="Sucursal" onChange={(value) => branches.setSelectedBranch(branches.branch.find(branch => branch.name === value)!)} />
                    <DateField label="fecha inicial" name="InitialDate" onChange={(name, value) => setInitialDate(value)} value={initialDate} />
                    <DateField label="fecha final" name="EndDate" onChange={(name, value) => setEndDate(value)} value={endDate} />
                    <div className="flex flex-col w-full gap-6 mt-2">
                        <ReverseInlineCheckBox name="Mostrar cierre" handler={() => setShowClose(!showClose)} />
                        <ReverseInlineCheckBox name="Mostrar reversiones" handler={() => setShowClose(!showClose)} />
                    </div>
                </div>
                <DetailedAuxiliaryBookTable data={data} />
            </div>
        </>
    )
}