import { useEffect, useState } from "react";
import { ReceiptType } from "../models/receiptType";
import requests from "../../shared/api/agent";
import Select, { SingleValue } from "react-select";

interface Props {
  name: string;
  receiptCategoryId: number | undefined;
  value: number | undefined;
  onChange: (name: string, value: number) => void;
  required?: boolean | null;
}

export default function ReceiptTypeSelector({
  name,
  value,
  receiptCategoryId,
  onChange,
  required,
}: Props) {
  const [receiptTypes, setReceiptTypes] = useState<ReceiptType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (receiptCategoryId) {
      setIsLoading(true);
      requests
        .get("/receipt-types?receiptCategoryId=" + receiptCategoryId)
        .then((response) => setReceiptTypes(response.data))
        .finally(() => setIsLoading(false));
    }
  }, [receiptCategoryId]);

  const handleChange = (
    newValue: SingleValue<{ label: string; value: number }>
  ) => {
    onChange(name, newValue?.value ?? 0);
  };

  return (
    <div className="input-form">
      <label
        htmlFor={name}
        className="form-label w-full flex flex-col sm:flex-row"
      >
        {" "}
        Tipo de comprobante
      </label>
      <Select
        name={name}
        options={receiptTypes.map((receiptType) => ({
          label: `${receiptType.name.toUpperCase()} (${receiptType.code})`,
          value: receiptType.id,
        }))}
        className=" "
        isLoading={isLoading}
        isSearchable={true}
        isDisabled={!receiptCategoryId}
        placeholder="- Seleccione -"
        onChange={handleChange}
        required={required ?? false}
        value={receiptTypes
          .filter((rt) => rt.id === value)
          .map((receiptType) => {
            return {
              label: `${receiptType.name.toUpperCase()} (${receiptType.code})`,
              value: receiptType.id,
            } as SingleValue<{ label: string; value: number }>;
          })}
      />
    </div>
  );
}
