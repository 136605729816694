import Table from "../../shared/common/presentation/Table";
import GridEditButton from "../../shared/filtered-query/GridEditButton";
import { Branch } from "../models/Branch";
import GridDeleteButton from "../../shared/filtered-query/GridDeleteButton";
import GridEditInabilite from "../../shared/filtered-query/GridEditInabilite";
import GridDeleteInabilite from "../../shared/filtered-query/GridDeleteInabilite";
import GridStateGreen from "../../shared/filtered-query/GridStateGreen";
import GridStateRed from "../../shared/filtered-query/GridStateRed";
import GridViewButton from "../../shared/filtered-query/GridViewButton";
import GridViewInabilite from "../../shared/filtered-query/GridViewInabilite";

interface Props {
  data: Branch[];
  onSortingChange?: (columnName: string, sortOrder: "asc" | "desc") => void;
  sortedBy?: string | null;
  ascending?: boolean | null;
}

export default function BranchesTable({
  data,
  onSortingChange,
  sortedBy,
  ascending,
}: Props) {
  const triggerSortingChange = (
    columnName: string,
    sortOrder: "asc" | "desc"
  ) => {
    if (onSortingChange) onSortingChange(columnName, sortOrder);
  };

  return (
    <Table
      sortedBy={sortedBy}
      ascending={ascending}
      onSortingChange={triggerSortingChange}
      columns={[
        { isSortable: true, name: "Consecutive", title: "Id" },
        { isSortable: true, name: "ThirdParty.BusinessName", title: "Codigo" },
        { isSortable: true, name: "Year", title: "Descripcion" },
        { isSortable: true, name: "Month", title: "Telefono" },
        { isSortable: false, name: "Date", title: "Estado" },
        { isSortable: false, name: "Actions", title: "Acciones" },
      ]}
    >
      {data.map((dt) => (
        <tr key={dt.id}>
          <td className="w-40">
            <div>
              {dt.id}
            </div>
          </td>
          <td className="w-40"><div>{dt.code}</div></td>
          <td className="w-40"><div>{dt.name}</div></td>
          <td className="w-40"><div>{dt.phoneNumber}</div></td>
          <td className="w-40">
              {dt.status? 
              <div><GridStateGreen url={`Branches/${dt.id}/status/false`}/></div>
              :
              <div><GridStateRed url={`Branches/${dt.id}/status/true`}/></div>}
          </td>
          <td className="table-report__action w-56">
            {
              dt.status?
              <div className="flex justify-center items-center">
                <GridEditButton url={`/sucursales/${dt.id}`}/>
               
                <GridViewButton  url={`/sucursales/info/${dt.id}`}/>
                <GridDeleteButton url={`/Branches/${dt.id}`} />
              </div> :
              <div className="flex justify-center items-center">
                <GridEditInabilite />
                <GridViewInabilite />
                <GridDeleteInabilite />
            </div>
            }
          </td>
        </tr>
      ))}
    </Table>
  );
}
