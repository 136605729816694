import Table from "../../shared/common/presentation/Table";
import { IThirdPartyClosureData } from "../pages/ThirdPartyClosureIndex";

interface Props {
  data: IThirdPartyClosureData[];
  onSortingChange?: (columnName: string, sortOrder: "asc" | "desc") => void;
  sortedBy?: string | null;
  ascending?: boolean | null;
}

export default function ThirdPartyClosureTable({
  data,
  onSortingChange,
  sortedBy,
  ascending,
}: Props) {
  const triggerSortingChange = (
    columnName: string,
    sortOrder: "asc" | "desc"
  ) => {
    if (onSortingChange) onSortingChange(columnName, sortOrder);
  };

  return (
    <Table
      sortedBy={sortedBy}
      ascending={ascending}
      onSortingChange={triggerSortingChange}
      columns={[
        { isSortable: true, name: "code", title: "Codigo" },
        { isSortable: true, name: "account", title: "Cuenta" },
        { isSortable: true, name: "thirdPartyId", title: "ID Tercero" },
        { isSortable: false, name: "thirdPartyName", title: "Nombre Tercero" },
        { isSortable: true, name: "document", title: "Documento" },
        { isSortable: true, name: "debit", title: "Debito" },
        { isSortable: false, name: "credit", title: "Credito" },
      ]}
    >
      {data.map((dt, index) => (
        <tr key={index}>
          <td className="w-60">{dt.code}</td>
          <td className="w-60">{dt.account}</td>
          <td className="w-20">{dt.thirdPartyId}</td>
          <td className="w-60">{dt.thirdPartyName}</td>
          <td className="w-40">{dt.document}</td>
          <td className="w-40">{dt.debit}</td>
          <td className="w-40">{dt.credit}</td>
        </tr>
      ))}
    </Table>
  );
}
