interface Props {
  errorDescription: string;
}

export default function ErrorMessage({ errorDescription }: Props) {
  return (
    <div className="alert alert-danger show flex items-center mb-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        icon-name="alert-octagon"
        data-lucide="alert-octagon"
        className="lucide lucide-alert-octagon w-6 h-6 mr-2"
      >
        <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
        <line x1="12" y1="8" x2="12" y2="12"></line>
        <line x1="12" y1="16" x2="12.01" y2="16"></line>
      </svg>
      {errorDescription}
    </div>
  );
}
