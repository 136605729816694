import TopBar from "../../shared/layouts/TopBar";
import InventoriesNBalanceBookForm from "../components/InventoriesNBalanceBookForm";

export default function InventoriesNBalanceBookIndex() {
  return (
    <>
      <TopBar currentPageTitle="Libro inventario y balance" />
      <InventoriesNBalanceBookForm />
    </>
  );
}