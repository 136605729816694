import {useState} from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import '../SubMenuGroup.css'


type SubMenuGroupProps = {
    title: string;
    isActive?: boolean;
    icon?: JSX.Element;
    children?: JSX.Element | JSX.Element[];
    handleActivate?: () => void;
}

export default function SubMenuGroup({title, isActive, children, icon, handleActivate}: SubMenuGroupProps){

    const [active, setActive] = useState(isActive || false);

    const handleFatherActivate = () => {
        setActive(!active);
        if(handleActivate){
            handleActivate();
        }
    }

    return(
        <>
            <div className="father"
            onClick={handleFatherActivate}>
                {icon}
                <p>{title}</p>
                {active ? <IoIosArrowUp/> : <IoIosArrowDown/> }
            </div>
            <div className="subChildrens">
                {active && children}
            </div>
        </>
    )
}