import { useState } from "react";
import { Company } from "../models/company";
import { useNavigate } from "react-router-dom";
import requests from "../../shared/api/agent";
import TextField from "../../shared/common/forms/TextField";
import SuccessButton from "../../shared/common/forms/SuccessButton";
import SubmitButton from "../../shared/common/forms/SubmitButton";
import BackButton from "../../shared/common/forms/BackButton";
import ThirdPartySelector from "../../people/components/ThirdPartySelector";
import ErrorMessage from "../../shared/common/presentation/ErrorMessage";
import SuccessMessage from "../../shared/common/presentation/SuccessMessage";


interface Props {
  isNewCompany: boolean;
  company?: Company;
  disabled?: boolean;
}

export default function CompanyForm({
  isNewCompany,
  company: selectCompany,
  disabled

}: Props) {
  const initialState = {
    id:0,
    managerId:0,
    managerName: "",
    documentNumber: "",
    commercialName: "",
    phoneNumber: "",
    address:"",
    accountant:false,
    
   };
  
 
  const [company, setCompany] = useState<Company>(
    selectCompany ?? initialState
  );



  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onFieldChange = (name: string, value: string | number) => {
    setCompany({ ...company, [name]: value });
  }; 

  const navigate = useNavigate();
  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");
    setSuccessMessage(""); 
    
    if (isNewCompany) {
      requests
        .post("/cost-centers", company)
        .then((response) => navigate("/tipos-comprobantes"))
        .catch((e) => {
          setErrorMessage(e.message);
          window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
        })
        .finally(() => setIsSubmitting(false));
    } else {
     console.log(company)
      requests
        .put(`/company/${company.id}`, company)
        .then((response) => {
          setSuccessMessage(
            "Se han actualizado los datos correctamente."
          );
          window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
        })
        .catch((e) => {
          setErrorMessage(e.message);
          window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  return (
    <>
      <div className="intro-y mb-3">
        <ErrorMessage message={errorMessage} />
        <SuccessMessage message={successMessage} />
      </div>
    <div className="intro-y box">
      <div id="form-validation" className="p-5" onSubmit={onSubmit}>
        <div className="preview" style={{ display: "block" }}>
          <form className="validate-form" onSubmit={onSubmit} method="POST">
            <div className="grid grid-cols-2 gap-4 mb-4">
             
              <TextField
                label="Nit"
                name="documentNumber"
                onChange={onFieldChange}
                value={company.documentNumber}
                key="documentNumber"
                required={true}
                disabled={disabled}
              />

              <TextField
                label="Razón Social"
                name="commercialName"
                onChange={onFieldChange}
                value={company.commercialName}
                key="commercialName"
                disabled={disabled}
              />

              <TextField
                label="Telefono"
                name=" phoneNumber"
                onChange={onFieldChange}
                value={company.phoneNumber}
                key="phoneNumber"
                disabled={disabled}
              />

              <TextField
                label="Dirección"
                name=" address"
                onChange={onFieldChange}
                value={company.address}
                key="address"
                disabled={disabled}
              />
            
               {disabled?
                  <TextField
                    label="Gerente"
                    name="managerName"
                    onChange={onFieldChange}
                    value={company.managerName}
                    key="managerName"
                    disabled={disabled}
                  />    
                  :<ThirdPartySelector 
               
                    name="managerId"
                    onChange={onFieldChange}
                    value={company.managerId}
                    key="managerId"
                  
                  
              />
               } 
              



              
            </div>
            
            <div >
              {disabled?<SuccessButton url="/editarEmpresa" />
              :<div className="flex flex-col sm:flex-row items-center pt-5 ">
                <SubmitButton isSubmitting={isSubmitting} />
                <BackButton url="/empresa" />
              </div>}
            </div>
              
          </form>
        </div>
      </div>
    </div>
    </>
    
  );
}
