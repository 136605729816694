import { Link } from "react-router-dom";
import withFilteredQueryRequest from "../../shared/filtered-query/withFilteredQueryRequest";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import LedgerAccountsTable from "../components/LedgerAccountsTable";

export default function LedgerAccountsIndexPage() {
  const LedgerAccountsList = withFilteredQueryRequest(
    "/receipts/search",
    LedgerAccountsTable
  );

  return (
    <>
      <TopBar currentPageTitle="Cuentas contables" />
      <PageTitle title="Cuentas contables">
        <Link
          to="/cuentasContables/crear"
          className="btn btn-primary shadow-md mr-2"
        >
          Agregar Cuenta contable
        </Link>
      </PageTitle>
      <LedgerAccountsList />
    </>
  );
}
