interface Props {
  isVisible: boolean;
  onHide: () => void;
  onOk: () => void;
}

export default function VoidReceiptConfirmModal({
  isVisible,
  onHide,
  onOk,
}: Props) {
  if (isVisible) {
    return (
      <div
        id="static-backdrop-modal-preview"
        className={`modal overflow-y-auto show`}
        style={{ marginTop: 0, marginLeft: 0, paddingLeft: 0, zIndex: 10000 }}
        data-tw-backdrop="static"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body px-5 py-10">
              <div className="text-center">
                <div className="mb-5">
                  ¿Realmente desea anular este comprobante?
                </div>{" "}
                <button
                  type="button"
                  data-tw-dismiss="modal"
                  className="btn btn-secondary w-24 mr-2"
                  onClick={(e) => onHide()}
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  data-tw-dismiss="modal"
                  className="btn btn-primary w-24"
                  onClick={(e) => onOk()}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  else {
    return(<></>);
  }

}
