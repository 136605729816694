import { IPUCTree } from "../pages/ChartOfAccountsCreate";
import { useEffect, useState } from "react";

export default function TreeByLevel({ items, handler, onClick }: { items: IPUCTree[], handler?: (item: IPUCTree) => void, onClick?: (item:IPUCTree)=> void }) {
  const [data, setData] = useState<IPUCTree[]>([]);

  useEffect(() => {
    setData(items);
  }, [items]);

  return (
    <>
      {data.map((item, index) => {
        return (
          <div className="flexDirectionColumn" onClick={(e)=> {
            if(onClick) onClick(item);
            if(handler) handler(item);
          }}>
            <div key={index} className="treeTable__data">
              <p
                className={`treeTable__data--first ${
                  item.code.length === 2
                    ? "treeTable__data--2"
                    : item.code.length === 4
                    ? "treeTable__data--3"
                    : item.code.length === 6
                    ? "treeTable__data--4"
                    : item.code.length === 8
                    ? "treeTable__data--5"
                    : ""
                }`}
              >
                {item.code}
              </p>
              <p>{item.name}</p>
            </div>
          </div>
        );
      })}
    </>
  );
}
