interface Props {
  type?: string;
  name: string;
  label?: string;
  value: string;
  onChange?: (name: string, value: string) => void;
  onClick?: ()=> void;
  required?: boolean | null;
  maxLength?: number | undefined;
  placeholder?: string | null;
  disabled?:boolean;
}

export default function TextField({
  type = "text",
  name,
  label,
  value,
  onChange,
  onClick,
  required = false,
  maxLength,
  placeholder,
  disabled
}: Props) {
  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.classList.add("touched");
  };

  return (
    <div className="input-form">
      <label
        htmlFor={name}
        className="form-label w-full flex flex-col sm:flex-row"
      >
        {" "}
        {label}
      </label>
      <input
        id={name}
        type={type}
        name={name}
        onBlur={onFocus}
        className="form-control"
        placeholder={placeholder ?? ""}
        required={required ?? false}
        maxLength={maxLength}
        value={value}
        disabled={disabled}
        onClick={onClick}
        onChange={(e) => {if(onChange)  onChange(e.target.name, e.target.value)}}
      />
    </div>
  );
}
