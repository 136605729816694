import { IPUCTree } from "../pages/ChartOfAccountsCreate";
import "../styles/treeAccounts.css";
import requests from "../../shared/api/agent";
import { useEffect, useState } from "react";
import TreeByLevel from "./TreeByLevel";

export default function ToCreateTree({
  data,
  handler,
}: {
  data: IPUCTree[];
  handler: (item: IPUCTree) => void;
}) {
  const [searchByCode, setSearchByCode] = useState<string>("");
  const [searchByName, setSearchByName] = useState<string>("");
  const [auxiliary, setAuxiliary] = useState<IPUCTree[]>([]);
  const [dataToShow, setDataToShow] = useState<IPUCTree[]>([]);
  const [activeMother, setActiveMother] = useState<IPUCTree>({
    id: 0,
    code: "",
    name: "",
  });
  const [dataByCode, setDataByCode] = useState<IPUCTree[]>([]);

  useEffect(() => {
    requests
      .get("/pucs/auxiliary?Top=5000&Ascending=true")
      .then((response) => {
        setAuxiliary(response.data.data);
        setDataToShow(
          data.filter((item: IPUCTree) => {
            return item.code.length === 1;
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [data]);

  useEffect(() => {
    let aux: IPUCTree[] = [];
    let temp = data.filter((val) => val.code.startsWith(searchByCode));
    aux = temp;
    if (auxiliary.length > 0) {
      temp = auxiliary.filter((val) => val.code.startsWith(searchByCode));
      aux = aux.concat(temp);
    }
    setDataByCode(aux);
  }, [searchByCode]);

  useEffect(() => {
    let aux: IPUCTree[] = [];
    let temp = data.filter((val) => val.name.includes(searchByName));
    aux = temp;
    if (auxiliary.length > 0) {
      temp = auxiliary.filter((val) => val.name.includes(searchByName));
      aux = aux.concat(temp);
    }
    setDataToShow(aux);
  }, [searchByName]);

  const getDataToShow = () => {
    if (activeMother?.code.length === 0) {
      setDataToShow(data.filter((item: IPUCTree) => item.code.length === 1));
    } else if (activeMother?.code.length === 1) {
      let aux = dataToShow;
      let temp = aux.slice(0, aux.indexOf(activeMother) + 1);
      let aux2 = data.filter((item: IPUCTree) => {
        return (
          item.code.length === 2 && item.code.startsWith(activeMother.code)
        );
      });
      let temp2 = aux.slice(aux.indexOf(activeMother) + 1, aux.length);
      temp = temp.concat(aux2);
      temp = temp.concat(temp2);
      setDataToShow(temp);
    } else if (activeMother?.code.length === 2) {
      let aux = dataToShow;
      let temp = aux.slice(0, aux.indexOf(activeMother) + 1);
      let aux2 = data.filter((item: IPUCTree) => {
        return (
          item.code.length === 4 && item.code.startsWith(activeMother.code)
        );
      });
      let temp2 = aux.slice(aux.indexOf(activeMother) + 1, aux.length);
      temp = temp.concat(aux2);
      temp = temp.concat(temp2);
      setDataToShow(temp);
    } else if (activeMother?.code.length === 4) {
      let aux = dataToShow;
      let temp = aux.slice(0, aux.indexOf(activeMother) + 1);
      let aux2 = data.filter((item: IPUCTree) => {
        return (
          item.code.length === 6 && item.code.startsWith(activeMother.code)
        );
      });
      let temp2 = aux.slice(aux.indexOf(activeMother) + 1, aux.length);
      temp = temp.concat(aux2);
      temp = temp.concat(temp2);
      setDataToShow(temp);
    } else if (activeMother?.code.length === 6) {
      let aux = dataToShow;
      let temp = aux.slice(0, aux.indexOf(activeMother) + 1);
      let aux2 = auxiliary.filter((item: IPUCTree) => {
        return (
          item.code.length === 8 && item.code.startsWith(activeMother.code)
        );
      });
      let temp2 = aux.slice(aux.indexOf(activeMother) + 1, aux.length);
      temp = temp.concat(aux2);
      temp = temp.concat(temp2);
      setDataToShow(temp);
    }
  };

  useEffect(() => {
    getDataToShow();
  }, [activeMother]);

  const onclick = (item: IPUCTree) => {
    if (item.code.length < 7) {
      if (item.code === activeMother?.code) {
        let aux = activeMother;
        if (aux?.code.length === 1) {
          setActiveMother({ id: 0, code: "", name: "" });
          return
        } else if (aux?.code.length === 2) {
          aux = dataToShow[dataToShow.indexOf(aux) - 1];
        } else if (aux?.code.length === 4) {
          aux = dataToShow[dataToShow.indexOf(aux) - 2];
        } else if (aux?.code.length === 6) {
          aux = dataToShow[dataToShow.indexOf(aux) - 2];
        }
        setActiveMother(aux);
      } else {
        setActiveMother(item);
      }
    }
  };

  return (
    <div className="treeTable">
      <div className="treeTable--title treeTable--title">
        <p className="treeTable__data--first">Código</p>
        <p>Nombre</p>
      </div>
      <div className="treeTable--row">
        <input
          className="treeTable--input"
          type="text"
          placeholder="Buscar por código"
          onChange={(e) => setSearchByCode(e.target.value)}
        />
        <input
          className="treeTable--input"
          type="text"
          placeholder="Buscar por nombre"
          onChange={(e) => setSearchByName(e.target.value.toUpperCase())}
        />
      </div>
      {searchByCode.length > 0 && searchByName.length === 0 ? (
        <TreeByLevel items={dataByCode} handler={handler} />
      ) : (
        <TreeByLevel items={dataToShow} handler={handler} onClick={onclick} />
      )}
      {searchByName.length > 0 && searchByCode.length === 0 ? (
        <TreeByLevel items={dataToShow} />
      ) : null}
    </div>
  );
}
