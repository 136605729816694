import {useState, useEffect} from "react";
import TopBar from "../../shared/layouts/TopBar"
import PageTitle from "../../shared/common/presentation/PageTitle"
import CreateClosureForm from "../components/CreateClosureForm";
import '../styles/thirdPartyClosureForm.css'

export default function CreateThirdPartyClosure(){
    return(
        <>
            <TopBar currentPageTitle="Cierre de terceros" />
            <PageTitle title="Proceso de cierre de terceros"></PageTitle>
            <CreateClosureForm/>
        </>
    )
}