import { useState, useEffect } from "react"
import PageTitle from "../../shared/common/presentation/PageTitle"
import { Link } from "react-router-dom"
import DateFieldPack from "../../shared/common/forms/DateFieldPack"
import InputSelect from "../../chartOfAccounts/components/InputSelect2"
import BitSelect from "../../shared/common/forms/BitSelect"
import TextField2 from "../../chartOfAccounts/components/TextField2"
import { IBranches } from "../../movementsByThirdParties/components/MovementsForm"
import requests from "../../shared/api/agent"
import { IPropItem } from "../../chartOfAccounts/components/ChartOfAccountsForm"
import WithholdingTable from "./WithholdingTable"
import { IWithholdingReport } from "./WithholdingTable"
import ModalTable from "../../shared/layouts/ModalTable"
import { AiOutlineSearch, AiOutlinePrinter } from 'react-icons/ai'
import Pagination from "../../movementsByThirdParties/components/Pagination"
import '../styles/withholdingForm.css'

export interface IThirdParties {
    id: number
    document: string
    name: string
}

export default function WithholdingForm() {

    //All Data
    const [branches, setBranches] = useState<IBranches[]>([]);
    const [thirdParties, setThirdParties] = useState<IThirdParties[]>([]);
    //Data to show in form
    const [showedBranches, setShowedBranches] = useState<IPropItem[]>([]);
    const [reports, setReports] = useState<IWithholdingReport[]>([]);
    //Data for the form
    const [sendRequest, setSendRequest] = useState(false);
    const [branch, setBranch] = useState<IBranches>();
    const [thirdParty, setThirdParty] = useState<IThirdParties>();
    const [allThirdParties, setAllThirdParties] = useState<boolean>(false);
    const [dateFrom, setDateFrom] = useState<string>();
    const [dateTo, setDateTo] = useState<string>();
    const [city, setCity] = useState<string>('');
    //Modal
    const [showModal, setShowModal] = useState(false);
    //table
    const [maxSize, setMaxSize] = useState<number>(5);
    const [currentIndex, setCurrentIndex] = useState<number>(1);
    const [reportsToShow, setReportsToShow] = useState<IWithholdingReport[]>([]);

    //Get Form data
    useEffect(() => {
        requests.get('/Branches/search')
            .then(response => {
                let aux: IPropItem[] = [];
                aux.push({ code: '0', name: 'Todas las sucursales' });
                response.data.data.forEach((branch: IBranches) => {
                    aux.push({ code: branch.code, name: branch.name })
                });
                setShowedBranches(aux);
                setBranches(response.data.data);
            }).catch(error => console.log(error));
    }, [])

    useEffect(() => {
        requests.get('/third-parties/search')
            .then(response => {
                setThirdParties(response.data.data);
            }).catch(error => console.log(error));
    }, [])

    //Get table data
    useEffect(() => {
        if (sendRequest === true) {
            if (dateFrom === '' || dateTo === '') {
                alert('Debe seleccionar un rango de fechas');
            } else if (branch === undefined || branch.code === undefined) {
                if (allThirdParties === true || thirdParty === undefined || thirdParty.id === undefined) {
                    requests.get(`/reports/certificate-retention?initialDate=${dateFrom}&EndDate=${dateTo}`)
                        .then((response) => {
                            setReports(response.data.data);
                        }).catch((error) => console.log(error));
                } else {
                    requests.get(`/reports/certificate-retention?PersonId=${thirdParty.id}&InitialDate=${dateFrom}&EndDate=${dateTo}`)
                        .then((response) => {
                            setReports(response.data.data);
                        }).catch(error => console.log(error));
                }
            } else if (allThirdParties === true || thirdParty === undefined) {
                requests.get(`/reports/certificate-retention?BranchId=${branch.code}&InitialDate=${dateFrom}&EndDate=${dateTo}`)
                    .then(response => {
                        setReports(response.data.data);
                    }).catch(error => console.log(error));
            } else {
                requests.get(`/reports/certificate-retention?BranchId=${branch.code}&PersonId=${thirdParty.id}&InitialDate=${dateFrom}&EndDate=${dateTo}`)
                    .then(response => {
                        setReports(response.data.data);
                    }).catch(error => console.log(error));
            }
        }
        setSendRequest(false);
    }, [sendRequest])

    useEffect(() => {
        if(reports.length > 0 && reports.length <= maxSize){
            setReportsToShow(reports);
        }else if(reports.length > maxSize){
            if(currentIndex === 1){
                setReportsToShow(reports.slice(0, maxSize));
            }else{
                setReportsToShow(reports.slice(currentIndex * maxSize, (currentIndex * maxSize) + maxSize));
            }
        }
    }, [reports, maxSize, currentIndex])

    const validate = (input: string) => {
        const regex = /^[a-zA-Z\s]*$/;
        if (!regex.test(input)) {
            setCity(input.replace(/[^a-zA-Z\s]/g, ''));
        } else {
            setCity(input);
        }
    };

    return (
        <>
            {showModal && <ModalTable modalData={{
                title: 'Terceros',
                data: thirdParties,
                index: 2,
                anyHandler: (value: IThirdParties) => { setThirdParty(value); setShowModal(false) }
            }} modalHandler={() => setShowModal(false)} />}
            <PageTitle title="Certificado de retenciones" >
                <Link
                    to={``}
                    className="btn btn-outline-secondary w-24 inline-block mr-2 row"
                >
                    <AiOutlinePrinter className="text-lg" />
                    Imprimir
                </Link>
                <Link to="" className="btn btn-warning w-40 mr-2 row" onClick={() => setSendRequest(true)}>
                    <AiOutlineSearch className="text-lg" />
                    Consultar
                </Link>
            </PageTitle>
            <div className="grid grid-cols-3 gap-4 mb-4 w-4/5">
                <InputSelect data={showedBranches} key={1} name="Sucursal" onChange={(value: string) => {
                    console.log(value);
                    if (value === 'Todas las sucursales') {
                        setBranch({} as IBranches);
                    } else {
                        const aux = branches.find(branch => branch.name === value);
                        setBranch(aux);
                    }
                }} defaultValue="Todas las sucursales" />
                <div className="wfRow">
                    <TextField2 label="Tercero" placeholder="Haga click para elegir un tercero" value={allThirdParties ? '' : thirdParty?.name || ''} disable={allThirdParties} onClick={() => setShowModal(true)} />
                    <BitSelect title="Todos los terceros" value={false} handler={(value: boolean) => { setAllThirdParties(value); setThirdParty({} as IThirdParties) }} />
                </div>
            </div>
            <div className="mb-4 w-3/5">
                <TextField2 label="Ciudad de expedición" placeholder="Ingrese el nombre de una ciudad" value={city} onChange={(value: string) => validate(value)} />
            </div>
            <div className="w-1/4 mb-4">
                <DateFieldPack dateHandler={(date: string[]) => { setDateFrom(date[0]); setDateTo(date[1]) }} />
            </div>
            <WithholdingTable data={reportsToShow} />
            <Pagination currentIndex={1} handlePagination={() => console.log} handleSize={(pageSize) => setMaxSize(pageSize)} pageSize={maxSize} totalData={reports.length || 5} />
        </>
    )
}