import {useState} from "react";
import { Bs123, BsCircle } from "react-icons/bs";
import MenuGroup from "../navigation/components/MenuGroup"
import MenuItem from "../navigation/components/MenuItem";
import SubMenuGroup from "../navigation/components/SubMenuGroup";
import Grid from "../icons/Grid";
import Tools from "../icons/Tools";
import Circle from "../icons/Circle";
import Calculator from "../icons/Calculator";
import MagnifyingGlass from "../icons/MagnifyingGlass";
import SubMenuItems from "../navigation/components/SubMenuItems";

export default function Header() {

  const [activeAccounting, setActiveAccounting] = useState(false);

  return (
    <nav className="side-nav">
      <a href="" className="intro-x flex items-center pl-5 pt-4">
        <img
          alt="IGD Plus"
          className="w-6"
          src="/images/logo.png"
        />
        <span className="hidden xl:block text-white text-lg ml-3">
          {" "}
          IGD{" "}
        </span>
      </a>
      <div className="side-nav__devider my-6"></div>
      <ul>
        <MenuGroup title="Dashboard" isDefaultActive={true}>
            <MenuItem title="Configuracion" icon={<Tools/>}>
              <MenuItem title="Perfiles" url="/perfiles"/>
              <MenuItem title="Datos de empresa" url="/empresa"/>
              <MenuItem title="Contraseña" url="/cambiar-contraseña" />
              <MenuItem title="Usuarios" url="/usuarios"/>
              <MenuItem title="Terceros" url="/terceros"/>
              <MenuItem title="Sucursales" url="/sucursales"/>
              <MenuItem title="Centros de costos" url="/costoCentro"/>
              <MenuItem title="Entidades - Impuestos" url="/entidades"/>
            </MenuItem>
            <MenuItem title="Contabilidad" icon={<Calculator/>}>
              <MenuItem title="Comprobantes" url="/comprobantes" />
              <MenuItem title="Tipos de comprobantes" url="/tipos-comprobantes" />
              <MenuItem title="Foliar libros" url="/foliarLibros" />
              <MenuItem title="Notas aclaratorias" url="/notasAclaratorias"/>
              <MenuItem title="PUC Contable" url="/PUC-Contable" />
              <MenuItem title="Cerrar Terceros" url="/cerrar-terceros" />
              <SubMenuGroup title="Consultas" icon={<MagnifyingGlass/>}>
                <SubMenuItems title="Movimientos por terceros" url="/Consultas/Movimientos-Por-Terceros" />
                <SubMenuItems title="Anexo por terceros" url="/Consultas/Anexo-Por-Terceros" />
                <SubMenuItems title="Cierre de terceros" url="/Consultas/Cierre-De-Terceros" />
                <SubMenuItems title="Certificado de retenciones" url="/Consultas/Certificado-De-Retenciones" />
                <SubMenuItems title="Libro auxiliar por rango de cuenta" url="/Consultas/Libro-Auxiliar-Por-Rango-De-Cuenta" />
                <SubMenuItems title="Libro auxiliar resumido" url="/Consultas/Libro-Auxiliar-Resumido" />
                <SubMenuItems title="Libro auxiliar detallado" url="/Consultas/Libro-Auxiliar-Detallado" />
                <SubMenuItems title="Balance de prueba" url="/Consultas/Balance-De-Prueba" />
                <SubMenuItems title="Balance general" url="/Consultas/Balance-General" />
                <SubMenuItems title="Libro mayor y balances" url="/Consultas/Libro-Mayor-Y-Balances" />
                <SubMenuItems title="Libro inventario y balances" url="/Consultas/Libro-Inventario-Y-Balances" />
                <SubMenuItems title="Estados de cambio en el patrimonio" url="/Consultas/Estados-De-Cambio-En-El-Patrimonio" />
                <SubMenuItems title="Analisis de ingresos y gastos" url="/Consultas/Analisis-De-Ingresos-Y-Gastos" />
              </SubMenuGroup>
            </MenuItem>
        </MenuGroup>
      </ul>
    </nav>
  );
}
