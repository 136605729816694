import { Children} from "react";
import { useEffect, useState } from "react";
import requests from "../../shared/api/agent";
import withFilteredQueryRequest from "../../shared/filtered-query/withFilteredQueryRequest";
import Table from "../../shared/common/presentation/Table";
import { useNavigate } from "react-router-dom";
import BackButton from "../../shared/common/forms/BackButton";
import SubmitButton from "../../shared/common/forms/SubmitButton";

  interface Branch{
    id: number;
    codigo:string;
    descripcion: string;
    estado: boolean;
  }    
  interface Props{
    funcion:()=>void;
    idUser:number;
  }
  export default function AssociateBranch({funcion, idUser}:Props) {

    const [submitting, setSubmitting] = useState(false);
    const [branches,setBranches] = useState<Branch[]>([])

    const onSubmit = ()=>{
      setSubmitting(true);
      const idBranches: number[] = branches.filter((elm)=>elm.estado).map((elm)=>elm.id)
      const body = {
        userId: idUser,
        branchesId: idBranches
      }
      console.log(body)
      requests.post("/Branches/user",body)
      .then((response)=>{
        console.log("asociados exitosamente");
        funcion()
      })
      .catch((error)=>alert("Hemos tenido un error, intente de nuevo mas tarde")); setSubmitting(false);
    }
    const onFieldChange=(id:number)=>{
      const ind = branches.indexOf(branches.filter((elm)=>elm.id===id)[0])
      const value = branches[ind]
      value.estado = !value.estado
      const values = branches
      values[ind] = value
      const branchs = [...values]
      setBranches(branchs)
    }
    useEffect(() => {
      var brnchsU : number[]  = JSON.parse(localStorage.getItem('user') || '{}').branches.map((elm:any)=>(elm.id));
      const asyn = async ()=>{
        requests.get("/Branches/search").then((response)=>{
          const dtos:Branch[] = response.data.data.map((elm1:any)=>{
                if(elm1.status !== false){
                  if(brnchsU.includes(elm1.id)){
                    return{id:elm1.id,codigo:elm1.code,descripcion:elm1.name,estado:true}
                  }else{
                    return{id:elm1.id,codigo:elm1.code,descripcion:elm1.name,estado:false}
                  }
                }
              }
            ).filter((elm:Branch|undefined)=>elm !== undefined)
          setBranches(dtos)
        }).catch((error)=>console.log(error))
      }
      asyn()
    }, []);
      return (
        <div className="d-flex justifyC-center alignI-center vh100 vw100 sMContainer">
          <div className="d-flex justifyC-center alignI-center sMMensaje" style={{width:"600px",height:"500px"}}>
            <h1 className="mb-3 fz-5 text-align-center">Asignar sucursales a usuarios</h1>
              <div style={{width:"500px",height:"500px"}}>
                {branches.map((dt) => (
                  <tr key={dt.id}>
                    <td className="w-40"><div>{dt.codigo}</div></td>
                    <td className="w-40"><div>{dt.descripcion}</div></td>
                    <td className="w-40">
                      <div>
                        <input type="checkbox" id="isSupervisor" 
                          checked={dt.estado}
                          onChange={()=>onFieldChange(dt.id)}></input>
                      </div>
                    </td>
                  </tr>
                ))}
              </div>
            <div className="sMBtnsContainer">
              <div onClick={()=>onSubmit()}><SubmitButton isSubmitting={submitting}></SubmitButton></div>
              <div onClick={funcion}><BackButton url="/usuarios"></BackButton></div>
              </div>
          </div>
        </div>
      );
  }
  