import Select, { SingleValue } from "react-select";

interface Props {
  name: string;
  value: string | undefined;
  onChange: (name: string, value: string) => void;
  required?: boolean | null;
}

export default function ConsecutiveTypeSelector({
  name,
  value,
  onChange,
  required,
}: Props) {

    const options = [
        {
            label: 'Anual',
            value: 'A'
        },
        {
            label: 'Mensual',
            value: 'M'
        },
        {
            label: 'Único',
            value: 'U'
        }
    ];

    const handleChange = (
    newValue: SingleValue<{ label: string; value: string }>
  ) => {
    onChange(name, newValue?.value ?? '');
  };

  return (
    <div className="input-form">
      <label
        htmlFor={name}
        className="form-label w-full flex flex-col sm:flex-row"
      >
        {" "}
        Tipo de consecutivo
      </label>
      <Select
        name={name}
        options={options.map((opt) => ({
          label: `${opt.label.toUpperCase()}`,
          value: opt.value,
        }))}
        className=" "
        placeholder="- Seleccione -"
        onChange={handleChange}
        required={required ?? false}
        value={options
          .filter((rt) => rt.value === value)
          .map((opt) => {
            return {
              label: `${opt.label.toUpperCase()}`,
              value: opt.value,
            } 
          })}
      />
    </div>
  );
}
