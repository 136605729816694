import { useEffect, useState } from "react";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";

const convertMonth = (month: number) => {
  switch (month) {
    case 0:
      return "Enero";
    case 1:
      return "Febrero";
    case 2:
      return "Marzo";
    case 3:
      return "Abril";
    case 4:
      return "Mayo";
    case 5:
      return "Junio";
    case 6:
      return "Julio";
    case 7:
      return "Agosto";
    case 8:
      return "Septiembre";
    case 9:
      return "Octubre";
    case 10:
      return "Noviembre";
    case 11:
      return "Diciembre";
    default:
      return "";
  }
};

const convertMonthNumber = (month: string) => {
  switch (month) {
    case "Enero":
      return "1";
    case "Febrero":
      return "2";
    case "Marzo":
      return "3";
    case "Abril":
      return "4";
    case "Mayo":
      return "5";
    case "Junio":
      return "6";
    case "Julio":
      return "7";
    case "Agosto":
      return "8";
    case "Septiembre":
      return "9";
    case "Octubre":
      return "10";
    case "Noviembre":
      return "11";
    case "Diciembre":
      return "12";
    default:
      return "";
  }
};

export default function DateSelector({
  data,
  title,
  handler,
}: {
  data: string[];
  title: string;
  handler: (value: string) => void;
}) {
  const [date, setDate] = useState<string>("");
  const [dataDate, setDataDate] = useState<string[]>([]);
  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    setDataDate(data);
  }, [data]);

  useEffect(() => {
    let now = new Date();
    if (title === "year") {
      setDate(now.getFullYear().toString());
    } else if (title === "month") {
      setDate(convertMonth(now.getMonth()));
    } else if (title === "day") {
      setDate(now.getDate().toString());
    }
  }, []);

  useEffect(() => {
    if (title === "month") {
      handler(convertMonthNumber(date));
    } else {
      handler(date);
    }
  }, [date]);

  return (
    <div className="flex flex-col gap-2 dropdown w-40">
      {title === "month" ? "Mes" : "Año"}
      <button
        className="dropdown-toggle btn btn-outline-secondary"
        aria-expanded="false"
        data-tw-toggle="dropdown"
        onClick={() => setActive(!active)}
      >
        {date}
      </button>
      {active && (
        <div className="w-40 mt-045 absolute">
          <ul className="flex flex-col gap-2 h-32 dropdown-content overflow-y-auto z-50 text-center absolute w-40 border rounded border-slate-200">
            {dataDate.map((item) => (
              <li onClick={()=>{
                setDate(item)
                setActive(false)
              }} className="ml-4 border-b-2 py-2 cursor-pointer">{item}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
