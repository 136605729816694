import TopBar from "../../shared/layouts/TopBar";
import IncomeNExpenseAnalysisForm from "../components/IncomeNExpenseAnalysisForm";

export default function IncomeNExpenseAnalysisIndes(){
    return(
        <>
            <TopBar currentPageTitle="Analisis de ingreso y gastos"/>
            <IncomeNExpenseAnalysisForm/>
        </>
    )
}