import { useEffect, useState } from "react";
import { ReceiptCategory } from "../models/receiptCategory";
import requests from "../../shared/api/agent";
import Select, { SingleValue } from "react-select";

interface Props {
  name: string;
  value: number | undefined;
  onChange: (name: string, value: number) => void;
  required?: boolean | null;
  disabled?: boolean;
}

export default function ReceiptCategorySelector({
  name,
  value,
  onChange,
  required,
  disabled
}: Props) {
  const [receiptCategories, setReceiptCategories] = useState<ReceiptCategory[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    requests
      .get("/receipt-categories")
      .then((response) => setReceiptCategories(response.data))
      .finally(() => setIsLoading(false));
  }, []);

  const handleChange = (newValue: SingleValue<{label: string, value: number}>) => {
    onChange(name, newValue?.value ?? 0)
  }

  return (
    <div className="input-form">
      <label
        htmlFor={name}
        className="form-label w-full flex flex-col sm:flex-row"
      >
        {" "}
        Categoría de comprobante
      </label>
      <Select
        isDisabled={disabled}
        name={name}
        options={receiptCategories.map((receiptCategory) => ({
          label: `${receiptCategory.name.toUpperCase()} (${receiptCategory.code})`,
          value: receiptCategory.id,
        }))}
        className=" "
        isLoading={isLoading}
        isSearchable={true}
        placeholder="- Seleccione -"
        onChange={handleChange}
        required={required ?? false}
        value={receiptCategories.filter(rt => rt.id === value).map(receiptCategory => { return {
          label: `${receiptCategory.name.toUpperCase()} (${receiptCategory.code})`,
          value: receiptCategory.id,
        } as SingleValue<{label: string, value: number}>})}
      />
    </div>
  );
}
