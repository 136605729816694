import { Link } from "react-router-dom";
import withFilteredQueryRequest from "../../shared/filtered-query/withFilteredQueryRequest";
import ReceiptTypesTable from "../components/ReceiptTypesTable";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";

export default function ReceiptTypesIndexPage() {
  
    const ReceiptTypesList = withFilteredQueryRequest(
      "/receipt-types/search",
      ReceiptTypesTable 
    );

  return (
    <>
      <TopBar currentPageTitle="Tipos de comprobantes" />
      <PageTitle title="Tipos de comprobantes">
        <Link
          to="/tipos-comprobantes/crear"
          className="btn btn-primary shadow-md mr-2"
        >
          Agregar Tipo de Comprobante
        </Link>
      </PageTitle>
      <ReceiptTypesList />
    </>
  );
}
