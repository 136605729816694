import { Link } from "react-router-dom";
import withFilteredQueryRequest from "../../shared/filtered-query/withFilteredQueryRequest";
import {useState, useEffect } from 'react'
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import ExplanatoryNotesTable from "../components/ExplanatoryNotesTable";
import FiltersContainer from "../../shared/common/forms/FiltersContainer";
import DateFilter from "../../shared/common/filters/DateFilter";

export default function ExplanatoryNotesIndexPage() {
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [consecutive, setConsecutive] = useState<string>("");
  const [filtersUrl, setFiltersUrl] = useState<string>("/receipts/search");

  const NotesList = withFilteredQueryRequest(filtersUrl, ExplanatoryNotesTable);

  useEffect(() => {
    const url = buildUrl();
    setFiltersUrl(url);
  }, [fromDate, toDate, consecutive]);

  const buildUrl = () => {
    let urlBase = "/clarification-notes";

    if (fromDate || toDate || consecutive) {
      urlBase += `?InitialDate=${fromDate}&FinalDate=${toDate}`;
    }
    return urlBase;
  };

  return (
    <>
      <TopBar currentPageTitle="Notas aclaratorias" />
      <PageTitle title="Notas aclaratorias">
       
        <Link
          to="/notasAclaratorias/crear"
          className="btn btn-primary shadow-md mr-2"
        >
          Crear una nota
        </Link>
        <Link
          to="/notasAclaratorias/crear"
          className="btn btn-pending shadow-md mr-2"
        >
          Imprimir Notas
        </Link>
      </PageTitle>
      <FiltersContainer>
        <DateFilter
          key='fromDate'
          value={fromDate}
          name="fromDate"
          onChange={(name, value) => {
            setFromDate(value?.toISOString() ?? "");
          }}
          label="Desde:"
        />
        <DateFilter
          value={toDate}
          key='toDate'
          name="toDate"
          onChange={(name, value) => {
            setToDate(value?.toISOString() ?? "");
          }}
          label="Hasta:"
        />
      </FiltersContainer> 
      <NotesList />
    </>
  );
}
