import { useState } from "react";
import { CostCenter } from "../models/costCenter";
import requests from "../../shared/api/agent";
import TextField from "../../shared/common/forms/TextField";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../../shared/common/presentation/ErrorMessage";
import SuccessMessage from "../../shared/common/presentation/SuccessMessage";
import BackButton from "../../shared/common/forms/BackButton";
import SubmitButton from "../../shared/common/forms/SubmitButton";

interface Props {
  isNewCostCenter: boolean;
  costCenter?: CostCenter;
}

export default function CostCenterForm({
  isNewCostCenter,
  costCenter: selectCostCenter,
}: Props) {
  const initialState = {
    id: 0,
    code: "",
    name: "",
    isActive: "",
  };

  const [costCenter, setCostCenter] = useState<CostCenter>(
    selectCostCenter ?? initialState
  );

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onFieldChange = (name: string, value: string | number) => {
    setCostCenter({ ...costCenter, [name]: value });
  };

  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");
    setSuccessMessage("");

    if (isNewCostCenter) {
      requests
        .post("/cost-centers", costCenter)
        .then((response) => navigate("/costoCentro"))
        .catch((e) => {
          setErrorMessage(e.message);
          window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
        })
        .finally(() => setIsSubmitting(false));
    } else {
      requests
        .put(`/cost-centers/${costCenter.id}`, costCenter)
        .then((response) => {
          setSuccessMessage(
            "Se ha actualizado correctamente el centro de costo."
          );
          window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
        })
        .catch((e) => {
          setErrorMessage(e.message);
          window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  return (
    <>
      <div className="intro-y mb-3">
        <ErrorMessage message={errorMessage} />
        <SuccessMessage message={successMessage} />
      </div>

      <div className="intro-y box">
        <div id="form-validation" className="p-5">
          <div className="preview" style={{ display: "block" }}>
            <form
              className="validate-form"
              onSubmit={handleSubmit}
              method="POST"
            >
              <div className="grid grid-cols-2 gap-4 mb-4">
                <TextField
                  label="Codigo"
                  name="code"
                  onChange={onFieldChange}
                  value={costCenter.code}
                  key="code"
                  required={true}
                />
                <TextField
                  label="Descripción"
                  name="name"
                  onChange={onFieldChange}
                  value={costCenter.name}
                  key="name"
                  required={true}
                />
              </div>
              <div className="flex flex-col sm:flex-row items-center pt-5 ">
                <SubmitButton isSubmitting={isSubmitting} />
                <BackButton url="/costoCentro" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
