import { useState } from "react";
import Login from "../components/Login";
import SystemMessage from "../../shared/common/presentation/SystemMessage";
import ErrorIcon from "../../shared/icons/ErrorIcon";
import Cancel from "../../shared/icons/Cancel";
import CancelRed from "../../shared/icons/CancelRed";

export default function LoginPage() {
  const [estado,setEstado] = useState(false);
  return (
    <div className="login">
      <div className="container sm:px-10 h100">
        <div className="block xl:grid grid-cols-2 gap-4 h100">
          <div className="hidden xl:flex flex-col min-h-screen">
            <a href="" className="-intro-x flex items-center pt-5">
              <img
                alt="Midone - HTML Admin Template"
                className="w-6"
                src="/images/logo.png"
              />
              <span className="text-white text-lg ml-3"> IGD Plus </span>
            </a>
            <div className="my-auto">
              <img
                alt="Midone - HTML Admin Template"
                className="-intro-x w-1/2 -mt-16"
                src="/images/illustration.svg"
              />
              <div className="-intro-x text-white font-medium text-4xl leading-tight mt-10">
                Unos cuantos clics más para
                <br />
                iniciar sesión.
              </div>
              <div className="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-slate-400">
                Manage all your e-commerce accounts in one place
              </div>
            </div>
          </div>
          <Login/>
        </div>
      </div>
      {estado?<SystemMessage icon={
        <div style={{transform:"scale(.57)"}}><CancelRed/></div>
      } 
      message="¿Realmente deseas eliminar estos registros?, el proceso no puede desacerce"
      title="¿Estas seguro?" buttons={[
        <button 
        type="button"
        className="btn btn-danger py-3 px-4 w-full xl:w-32 xl:mr-3 align-top" onClick={()=>setEstado(!estado)}>Borrar</button>,
        <button 
        type="button"
        className="btn btn-outline-danger py-3 px-4 w-full xl:w-32 xl:mr-3 align-top" onClick={()=>setEstado(!estado)}>Cerrar</button>
      ]}/>
      :
      null}
    </div>
  );
}
