import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import requests from "../../shared/api/agent";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import ExplanatoryNotesForm from "../components/ExplanatoryNotesForm";
import { ExplanatoryNote } from "../models/explanatoryNote";

export default function EditExplanatoryNotesPage() {
  const [note, setNote] = useState<ExplanatoryNote>();

  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (id) {
      requests.get(`/clarification-notes/${id}`).then((response) => {
        setNote(response.data);
      });
    }
  }, [id]);

  return (
   
    <>
      <TopBar
        currentPageTitle={`Editar Nota #${id}`}
        firstLevelPageTitle="Tipos de comprobantes"
        firstLevelPageUrl="/tipos-comprobantes"
      />
      <PageTitle title={`Editar Nota#${id}`} />
      {note && <ExplanatoryNotesForm isNewNote={false}  explanatoryNote={note} id={id}/>}
    </>
  );
}
