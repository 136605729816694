import { useState } from "react";
import { FoliarBook} from "../models/foliarBook";
import requests from "../../shared/api/agent";
import ReceiptTypeSelector from "../../receiptTypes/components/ReceiptTypeSelector";
import ThirdPartySelector from "../../people/components/ThirdPartySelector";
import TextField from "../../shared/common/forms/TextField";
import TextAreaField from "../../shared/common/forms/TextAreaField";
import NumericField from "../../shared/common/forms/NumericField";
import CurrencyField from "../../shared/common/forms/CurrencyField";
import InputSelect from "../../shared/common/forms/InputSelect";

interface Props {
  isNewReceipt: boolean;
  receipt?: FoliarBook;
}

export default function FoliarBooksForm({
  isNewReceipt,
  receipt: selectedReceipt,
}: Props) {
  const initialState = {
    nombre:"",
    palabra:"",
    nInicial:0,
    nFinal:0,
    nEmpresa:"",
    NIT:0
  };

  const [foliarBook, setFoliarBook] = useState<FoliarBook>(
    selectedReceipt ?? initialState
  );
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onFieldChange = (name: string, value: string | number) => {
    setFoliarBook({ ...foliarBook, [name]: value });
  };

  const onSubmit = (event: React.FormEvent) => {
    console.log("requiero el modelo del pdf");
  };

  return (
    <div className="intro-y box">
      <div id="form-validation" className="p-5" onSubmit={onSubmit}>
        <div className="preview" style={{ display: "block" }}>
          <form className="validate-form" onSubmit={onSubmit} method="POST">
            <div className="grid grid-cols-2 gap-4 mb-4">
              <TextField
                label="Nomcre de la empresa"
                name="nombre"
                onChange={onFieldChange}
                value={foliarBook.nombre}
                key="nombre"
                required={true}
              />
              <NumericField
                label="Numero de pagina inicial"
                name="nInicial"
                onChange={onFieldChange}
                value={foliarBook.nInicial}
                key="nInicial"
                required={true}
              />
              <NumericField
                label="Numero de pagina final"
                name="nFinal"
                onChange={onFieldChange}
                value={foliarBook.nInicial}
                key="nFinal"
                required={true}
              />
              <InputSelect name="Antes del numero imprimir la palabra" index="palabra"
              key="palabra" 
              data={
                [{label:"Folio", value:"Folio"},{label:"Pág", value:"Pág"},{label:"Sin palabra", value:"Sin palabra"}]
              } 
              onChange={
                (x:string,y:number|string)=>onFieldChange(x,y)
              }/>

              <InputSelect name="Imprimir nombre de la empresa" index="nombre" key="nombre" data={
                [{label:"si", value:"si"},{label:"no", value:"no"}]
              } 
              onChange={
                (x:string,y:number|string)=>onFieldChange(x,y)
              }/>
              
              <InputSelect name="Imprimir NIT" index="NIT" key="NIT" data={
                [{label:"si", value:"si"},{label:"no", value:"no"}]
              } 
              onChange={
                (x:string,y:number|string)=>onFieldChange(x,y)
              }/>
            </div>
            <button type="submit" className="btn btn-primary mt-5">
              Guardar
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
