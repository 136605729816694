
export default function ItemAnulado() {
  return (
    <div className="flex items-center mr-3">
      <svg 
        width="24" 
        height="24" 
        viewBox="0 0 106 106" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
        <path d="M53 103C80.6142 103 103 80.6142 103 53C103 25.3858 80.6142 3 53 3C25.3858 3 3 25.3858 3 53C3 80.6142 25.3858 103 53 103Z" stroke="#DC2626" stroke-width="6" stroke-miterlimit="10"/>
        <path d="M34.25 34.25L71.75 71.75" stroke="#DC2626" stroke-width="6" stroke-miterlimit="10"/>
        <path d="M71.75 34.25L34.25 71.75" stroke="#DC2626" stroke-width="6" stroke-miterlimit="10"/>
      </svg>
      <p className="state--inactive">Anulado</p>
    </div>
  );
}
