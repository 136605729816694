// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputContainer{
    display: flex;
    align-items: center;
    width: 70%;
    height: 40px;
    border: 1px solid #cccccc ;
    border-radius: 15px;
    padding-left: 1%;
    outline: none;
}
.inputContainer:hover{
    border: 1px solid black ;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.5);
    cursor: text;
}
.inputContainer:focus{
    border: 1px solid black ;
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.5);
    outline: none;
}
.inputLabel{
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 5px;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/input.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,YAAY;IACZ,0BAA0B;IAC1B,mBAAmB;IACnB,gBAAgB;IAChB,aAAa;AACjB;AACA;IACI,wBAAwB;IACxB,wCAAwC;IACxC,YAAY;AAChB;AACA;IACI,wBAAwB;IACxB,wCAAwC;IACxC,aAAa;AACjB;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".inputContainer{\n    display: flex;\n    align-items: center;\n    width: 70%;\n    height: 40px;\n    border: 1px solid #cccccc ;\n    border-radius: 15px;\n    padding-left: 1%;\n    outline: none;\n}\n.inputContainer:hover{\n    border: 1px solid black ;\n    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.5);\n    cursor: text;\n}\n.inputContainer:focus{\n    border: 1px solid black ;\n    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.5);\n    outline: none;\n}\n.inputLabel{\n    font-size: 1.2rem;\n    font-weight: 400;\n    margin-bottom: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
