import React from "react";

interface Props {
  initialPageSize: number;
  handleChange: (event: React.ChangeEvent) => void;
}

export default function PageSizeSelector({
  initialPageSize,
  handleChange,
}: Props) {
  const pageSizes = [5, 10, 20, 50, 100, 500];

  return (
    <div className="flex justify-end">
      {/* <label className="w-12 flex-none xl:w-auto xl:flex-initial mr-2 justify-end">
        Mostrar:{"  "}
      </label> */}
      <select
        className="w-20 form-select box mt-3 sm:mt-0"
        onChange={handleChange}
        value={initialPageSize}
      >
        {pageSizes.map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            {pageSize}
          </option>
        ))}
      </select>
    </div>
  );
}
