import PageTitle from "../../shared/common/presentation/PageTitle";
import TopBar from "../../shared/layouts/TopBar";
import ReceiptTypeForm from "../components/ReceiptTypeForm";

export default function CreateReceiptTypePage(){
    return (
        <>
          <TopBar firstLevelPageTitle={'Tipos de comprobantes'} firstLevelPageUrl={'/tipos-comprobantes'} currentPageTitle="Nuevo tipo de comprobante" />
          <PageTitle title="Nuevo tipo de comprobante">
          </PageTitle>
          <ReceiptTypeForm isNewReceiptType={true}/>
        </>
      );
}