import {useState, useEffect} from 'react'
import {MdCheckBoxOutlineBlank} from 'react-icons/md'
import {IoMdCheckboxOutline} from 'react-icons/io'
import '../../ABook-accountRank/styles/ScrollableTable.css'

export default function ReverseInlineCheckBox({name, handler, value}:{name:string, handler: ()=>void, value?:boolean}){
    const [state, setState] = useState<boolean>(false)

    useEffect(() => {
        if(value !== undefined){
            setState(value)
        }
    }, [value])

    return(
        <div className='flex flex-row items-center'>
            {state ? <IoMdCheckboxOutline className='scale-150 text-nowrap' onClick={() => {setState(!state);handler()}} /> : <MdCheckBoxOutlineBlank className='scale-150'  onClick={() => {setState(!state);handler()}} />}
            <p className='text-sm noselect'>{name}</p>
        </div>
    )
}