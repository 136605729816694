import { Link } from "react-router-dom";
import withFilteredQueryRequest from "../../shared/filtered-query/withFilteredQueryRequest";
import CostCenterTable from "../components/CostCenterTable";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";


export default function CostCenterIndexPage() {
  const CostCenterList = withFilteredQueryRequest(
    "/cost-centers/search",
    CostCenterTable
  );





  return (
    <>
      <TopBar currentPageTitle="Centros de costos" />
      <PageTitle title="Centro de costos">
        <Link
          to="/costoCentro/crear"
          className="btn btn-primary shadow-md mr-2"
        >
          Agregar un centro de costos
        </Link>
      </PageTitle>
      <CostCenterList />
    </>
  );
}
