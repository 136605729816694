import { useEffect, useState } from "react";
import toCash from "../../ABook-accountRank/functions/toCash";
import "../../ABook-accountRank/styles/ScrollableTable.css";

export interface IGeneralBalanceTableProps {
  className: string;
  total: number;
  accounts: IAccount[];
}

interface IAccount {
  classId: number;
  code: string;
  name: string;
  balance: number;
  nature: string;
}

export interface ICumulativeResult {
  cumulativeResult: number;
  nature: string;
}

export interface IExerciseResults {
  accounts: IExerciseAccounts[];
  totalBalance: number;
}

interface IExerciseAccounts {
  branchId: number;
  code: string;
  name: string;
  balance: number;
  nature: string;
}

export function roundDecimals(value: number){
  return Math.round(value);
};

export default function GeneralBalanceTable({
  data,
  cumulativeResult,
  exerciseResults,
}: {
  data: IGeneralBalanceTableProps[];
  cumulativeResult: ICumulativeResult;
  exerciseResults: IExerciseResults;
}) {
  const [heritage, setHeritage] = useState<number>(0);
  const [passive, setPassive] = useState<number>(0);


  useEffect(() => {
    let heritage = 0;
    let passive = 0;
    data.forEach((item) => {
      if (item.className === "PATRIMONIO") {
        heritage = item.total;
      } else if (item.className === "PASIVO") {
        passive = item.total;
      }
    });
    setHeritage(roundDecimals(heritage));
    setPassive(roundDecimals(passive));
  }, [data]);

  useEffect(() => {}, [data]);

  return (
    <div className="mt-8 max-height-60vh">
      <div className="GeneralBalance-structure table-header special-row">
        <p>Codigo</p>
        <p>Detalles</p>
        <p className="text-center">Saldo</p>
        <p className="text-center">Naturaleza</p>
      </div>
      <div className="max-w-100 flex flex-col">
        {data.length > 0 ? (
          data.map((item, index) => {
            return (
              <>
                <div className="GeneralBalance-structure border-t-2 py-2 px-4 special-row">
                  <p></p>
                  <p>{item.className}</p>
                  <p></p>
                  <p></p>
                </div>
                {item.accounts.map((account, index) => {
                  return (
                    <div className="GeneralBalance-structure border-t-2 py-2 px-4">
                      <p>{account.code}</p>
                      <p>{account.name}</p>
                      <p className="text-center">
                        {toCash(roundDecimals(account.balance).toString())}
                      </p>
                      <p className="text-center">{account.nature}</p>
                    </div>
                  );
                })}
                <div className="GeneralBalance-structure border-t-2 py-2 px-4 special-row">
                  <p></p>
                  <p>Total {item.className}</p>
                  <p className="text-center">
                    {toCash(roundDecimals(item.total).toString())}
                  </p>
                  <p></p>
                </div>
                <div className="GeneralBalance-structure border-t-2 py-2 px-4">
                  <p></p>
                  <p className="hide">.</p>
                  <p></p>
                  <p></p>
                </div>
                {data.length - 1 === index ? (
                  <>
                    <div className="GeneralBalance-structure border-t-2 py-2 px-4 special-row">
                      <p></p>
                      <p>Patrimonio + Pasivo</p>
                      <p className="text-center">
                        {toCash((heritage + passive).toString())}
                      </p>
                      <p></p>
                    </div>
                    {cumulativeResult.cumulativeResult !== 0 ? (
                      <div className="GeneralBalance-structure border-t-2 py-2 px-4 special-row">
                        <p></p>
                        <p>Resultado Acumulado</p>
                        <p className="text-center">
                          {toCash(cumulativeResult.cumulativeResult.toString())}
                        </p>
                        <p className="text-center">{cumulativeResult.nature}</p>
                      </div>
                    ) : null}
                    {cumulativeResult.cumulativeResult !== 0 ? (
                      <div className="GeneralBalance-structure border-t-2 py-2 px-4 special-row">
                        <p></p>
                        <p>Patrimonio + Pasivo</p>
                        <p className="text-center">
                          {toCash((heritage +
                            passive +
                            cumulativeResult.cumulativeResult).toString()) || toCash("0")}
                        </p>
                        <p></p>
                      </div>
                    ) : null}
                  </>
                ) : null}
              </>
            );
          })
        ) : (
          <div className="flex flex-row items-center justify-center py-2 border-t-2">
            <p>No hay datos para mostrar</p>
          </div>
        )}
        {data.length > 0 ? (
          <>
            <div className="GeneralBalance-structure border-t-2 py-2 px-4">
              <p></p>
              <p className="hide">.</p>
              <p></p>
              <p></p>
            </div>
            <div className="GeneralBalance-structure border-t-2 py-2 px-4 special-row">
              <p></p>
              <p>Resultados del ejercicio actual</p>
              <p></p>
              <p></p>
            </div>
            <div className="GeneralBalance-structure border-t-2 py-2 px-4 special-row">
              <p></p>
              <p>Total Resultados del ejercicio actual</p>
              <p className="text-center">
                {toCash(
                  roundDecimals(exerciseResults.totalBalance || 0).toString()
                )}
              </p>
              <p></p>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}