import { useEffect, useState } from "react";
import requests from "../../shared/api/agent";
import InputSelect from "../../chartOfAccounts/components/InputSelect2";
import TextField2 from "../../chartOfAccounts/components/TextField2";
import DateField from "../../shared/common/presentation/DateField";
import SearchButton from "../../movementsByThirdParties/components/SearchButton";
import "../../movementsByThirdParties/styles/mbtp.css";
import AnnexItems from "./AnnexItems";
import { IPropItem } from "../../chartOfAccounts/components/ChartOfAccountsForm";
import { Branch } from "../../branches/models/Branch";
import { ReceiptCategory } from "../../receiptCategories/models/receiptCategory";
import { ReceiptType } from "../../receiptTypes/models/receiptType";
import { IPUCTree } from "../../chartOfAccounts/pages/ChartOfAccountsCreate";
import ModalTable from "../../shared/layouts/ModalTable";
import AnnexTable from "./AneexTable";
import Pagination from "../../movementsByThirdParties/components/Pagination";
import PageTitle from "../../shared/common/presentation/PageTitle";
import { Link } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";

export interface IAnnexReport {
  nit: string;
  name: string;
  previous: string;
  debit: number;
  credit: number;
  current: number;
}

export interface IAnnexItems {
  document: string;
  name: string;
  debit?: number;
  credit?: number;
  previousBalance?: number;
  currentBalance?: number;
}

const data: IAnnexItems[] = [
  {
    document: "",
    name: "",
  },
];

export default function AnnexForm() {
  const [sendRequest, setSendRequest] = useState<boolean>(false);
  const [branches, setBranches] = useState<Branch[]>([]);
  const [receipts, setReceipts] = useState<ReceiptCategory[]>([]);
  const [receiptTypes, setReceiptTypes] = useState<ReceiptType[]>([]);
  const [accounts, setAccounts] = useState<IPUCTree[]>([]);

  const [selectedAccount, setSelectedAccount] = useState<IPUCTree>();
  const [selectedBranch, setSelectedBranch] = useState<Branch>();
  const [selectedReceipt, setSelectedReceipt] = useState<ReceiptCategory>();
  const [selectedReceiptType, setSelectedReceiptType] = useState<ReceiptType>();
  const [selectedDateFrom, setSelectedDateFrom] = useState<string>();
  const [selectedDateTo, setSelectedDateTo] = useState<string>();

  const [debitSum, setDebitSum] = useState<number>(0);
  const [currntSum, setCurrentSum] = useState<number>(0);

  const [items, setItems] = useState<IAnnexItems[]>(data);

  const [modalData, setModalData] = useState<IPropItem[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [toShow, setToShow] = useState<number>(5);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const handler = (data: any) => {
    let aux: IPUCTree = accounts.find((item) => item.name === data.name)!;
    setSelectedAccount(aux);
  };

  const handleBranch = (value: string) => {
    setSelectedBranch(branches.find((item) => item.name === value));
  };

  const handleReceipt = (value: string) => {
    setSelectedReceipt(receipts.find((item) => item.name === value));
  };

  const handleReceiptType = (value: string) => {
    setSelectedReceiptType(receiptTypes.find((item) => item.name === value));
  };

  const handleDate = (name: string, value: string) => {
    if (name === "Desde") {
      setSelectedDateFrom(value);
    } else {
      setSelectedDateTo(value);
    }
  };

  useEffect(() => {
    requests
      .get("/Branches/search")
      .then((response) => {
        setBranches(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    requests
      .get("/receipt-categories")
      .then((response) => {
        setReceipts(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    requests
      .get("/receipt-types/search")
      .then((response) => {
        setReceiptTypes(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    requests
      .get("/pucs/auxiliary")
      .then((response) => {
        setAccounts(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (accounts.length > 0) {
      let data: IPropItem[] = [];
      accounts.forEach((item) => {
        data.push({
          code: item.code,
          name: item.name,
          id: item.id,
        });
      });
      setModalData(data);
    }
  }, [accounts]);

  useEffect(() => {
    if (sendRequest) {
      if (
        selectedBranch === undefined &&
        selectedReceipt === undefined &&
        selectedReceiptType === undefined
      ) {
        requests
          .get(
            `/reports/annex-third-parties?AccountId=${selectedAccount?.id}&InitialDate=${selectedDateFrom}&FinalDate=${selectedDateTo}`
          )
          .then((response) => {
            if (response.data.thirdParties.length < 1) {
              setItems(data);
            } else {
              setItems(response.data.thirdParties);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if(selectedBranch === undefined) {
        requests
          .get(
            `/reports/annex-third-parties?AccountId=${selectedAccount?.id}&ReceiptCategoryId=${selectedReceipt?.id}&ReceiptTypeId=${selectedReceiptType?.id}&InitialDate=${selectedDateFrom}&FinalDate=${selectedDateTo}`
          )
          .then((response) => {
            console.log(response.data.thirdParties.length);
            if (response.data.thirdParties.length < 1) {
              setItems(data);
            } else {
              setItems(response.data.thirdParties);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }else {
        requests
          .get(
            `/reports/annex-third-parties?BranchId=${selectedBranch?.id}&AccountId=${selectedAccount?.id}&ReceiptCategoryId=${selectedReceipt?.id}&ReceiptTypeId=${selectedReceiptType?.id}&InitialDate=${selectedDateFrom}&FinalDate=${selectedDateTo}`
          )
          .then((response) => {
            if (response.data.thirdParties.length < 1) {
              setItems(data);
            } else {
              setItems(response.data.thirdParties);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
    setSendRequest(false);
  }, [sendRequest]);

  return (
    <div className="mbtpForm">
      {showModal && (
        <ModalTable
          modalData={{
            title: "Cuenta ",
            data: modalData,
            index: 2,
            thirdHandler: handler,
          }}
          modalHandler={() => setShowModal(false)}
        />
      )}
      <PageTitle title="Anexo de terceros">
        <Link
          to={""}
          className="btn btn-warning w-40 mr-1 mb-2 row"
          onClick={() => setSendRequest(!sendRequest)}
        >
          <AiOutlineSearch className="text-lg" />
          Consultar
        </Link>
      </PageTitle>
      <div className="mbtpForm--flex">
        <InputSelect
          key={1}
          name="Sucursal"
          data={branches}
          onChange={handleBranch}
          defaultValue="Todas las sucursales"
        />
        <TextField2
          placeholder="Haga click para seleccionar"
          label="Cuenta"
          value={selectedAccount?.name || ""}
          onClick={() => setShowModal(true)}
        />
      </div>
      <div className="mbtpForm--flex">
        <InputSelect
          key={2}
          name="Categoria comprobante"
          data={receipts}
          onChange={handleReceipt}
        />
        <InputSelect
          key={3}
          name="Tipo comprobante"
          data={receiptTypes}
          onChange={handleReceiptType}
        />
      </div>
      <div className="mbtpForm--flex">
        <DateField
          label="Desde"
          value={selectedDateFrom || ""}
          onChange={handleDate}
          name="Desde"
        />
        <DateField
          label="Hasta"
          value={selectedDateTo || ""}
          onChange={handleDate}
          name="Hasta"
        />
      </div>
      <AnnexTable data={items} />
      <Pagination
        currentIndex={currentIndex}
        handlePagination={(pageIndex: string) => {
          setCurrentIndex(parseInt(pageIndex));
        }}
        handleSize={(e: any) => setToShow(parseInt(e))}
        pageSize={toShow}
        totalData={items.length}
      />
    </div>
  );
}
