import Table from "../../shared/common/presentation/Table";
import { IReport } from "./MovementsForm";
import { AiOutlinePrinter } from "react-icons/ai";
import { AiOutlineFileSearch } from "react-icons/ai";
import GridEditButton from "../../shared/filtered-query/GridEditButton";

interface Props {
  data: IReport[];
  onSortingChange?: (columnName: string, sortOrder: "asc" | "desc") => void;
  sortedBy?: string | null;
  ascending?: boolean | null;
}

export default function FormTable({
  data,
  onSortingChange,
  sortedBy,
  ascending,
}: Props) {
  const triggerSortingChange = (
    columnName: string,
    sortOrder: "asc" | "desc"
  ) => {
    if (onSortingChange) onSortingChange(columnName, sortOrder);
  };

  return (
    <Table
      sortedBy={sortedBy}
      ascending={ascending}
      onSortingChange={triggerSortingChange}
      columns={[
        { isSortable: true, name: "Auxiliary", title: "Cuenta" },
        { isSortable: true, name: "Branch", title: "Sucursal" },
        { isSortable: true, name: "Date", title: "Fecha" },
        { isSortable: true, name: "Type", title: "Tipo" },
        { isSortable: false, name: "Description", title: "Descripción" },
        { isSortable: true, name: "Debit", title: "Debito" },
        { isSortable: true, name: "Credit", title: "Credito" },
        { isSortable: false, name: "Actions", title: "Acciones" },
      ]}
    >
      {data.map((dt, index) => (
        <tr key={index}>
          <td className="w-40">{dt.account}</td>
          <td className="w-60">{dt.branch}</td>
          <td className="w-40">{dt.date}</td>
          <td className="w-40">{dt.type}</td>
          <td className="w-1/4">{dt.description}</td>
          <td className="w-40">{dt.debit}</td>
          <td className="w-40">{dt.credit}</td>
          <td className="flex-row w-40 mbtpForm--TableFlex">
            <div className="mbtpForm--TableFlex3 mbtpForm--printer">
              <AiOutlinePrinter />
            </div>
            <GridEditButton url={`/comprobantes/`}/>
          </td>
        </tr>
      ))}
    </Table>
  );
}
