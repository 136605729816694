import { Link } from "react-router-dom";
import Table from "../../shared/common/presentation/Table";
import { EntityList} from "../models/entityList";
import GridEditButton from "../../shared/filtered-query/GridEditButton";
import GridDeleteButton from "../../shared/filtered-query/GridDeleteButton";
import GridEditInabilite from "../../shared/filtered-query/GridEditInabilite";
import GridDeleteInabilite from "../../shared/filtered-query/GridDeleteInabilite";
import GridViewButton from "../../shared/filtered-query/GridViewButton";
import GridViewInabilite from "../../shared/filtered-query/GridViewInabilite";


interface Props {
  data: EntityList[];
  onSortingChange?: (columnName: string, sortOrder: "asc" | "desc") => void;
  sortedBy?: string | null;
  ascending?: boolean | null;
}

export default function EntityTables({
  data,
  onSortingChange,
  sortedBy,
  ascending,
}: Props) {
  const triggerSortingChange = (
    columnName: string,
    sortOrder: "asc" | "desc"
  ) => {
    if (onSortingChange) onSortingChange(columnName, sortOrder);
  };

  return (
    <Table
      sortedBy={sortedBy}
      ascending={ascending}
      onSortingChange={triggerSortingChange}
      columns={[
        { isSortable: true, name: "document", title: "NIT" },
        { isSortable: true, name: "businessName", title: "Razón social" },
        { isSortable: true, name: "taxTypeName", title: "Tipo de impuesto" },
        { isSortable: false, name: "Actions", title: "" },
      ]}
    >
      {data.map((entity) => (
        <tr className="intro-x" key={entity.id}>
           <td className="w-40">
            <Link
              className="font-medium whitespace-nowrap"
              to={`/profiles/${entity.id}`}
            >
              {entity.document}
            </Link>{" "}
          </td>
          <td className="w-40">{entity.businessName}</td>
          <td className="w-40">{entity.taxTypeName}</td>

          <td className="table-report__action w-56">
            {
              entity.id?
              <div className="flex justify-center items-center">
                <GridEditButton url={`/entidades/${entity.id}`}/>
                <GridViewButton url={`/entidades/info/${entity.id}`} />
                <GridDeleteButton url={`/Entities/${entity.id}`} />
              </div> :
              <div className="flex justify-center items-center">
                <GridEditInabilite />
                <GridViewInabilite />
                <GridDeleteInabilite />
            </div>

            }
            
           
          </td>
        </tr>
      ))}
    </Table>
  );
}
