// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputSelect-form{
    width: 90%;
}

.inputSelect-item{
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.inputSelect-item--option{
    border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/chartOfAccounts/styles/InputSelect2.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,2CAA2C;AAC/C;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".inputSelect-form{\n    width: 90%;\n}\n\n.inputSelect-item{\n    max-width: 100%;\n    border-radius: 10px;\n    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);\n}\n\n.inputSelect-item--option{\n    border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
