import { useEffect, useState } from "react";
import axios from "axios";
import SearchInput from "./SearchInput";
import PageSizeSelector from "./PageSizeSelector";
import LoadingTable from "./LoadingTable";
import ErrorMessage from "./ErrorMessage";
import Pager from "./Pager";
import PagingInfo from "./PagingInfo";
import requests from "../api/agent";

interface FilteredQuery {
  totalRecordsCount: number;
  filteredRecordsCount: number;
  data: any[];
}

interface FilteredQueryResponse {
  data: FilteredQuery;
}

interface TableComponentWithDataProps {
  data: any[];
  requestReload?: () => void;
  onSortingChange?: (columnName: string, sortOrder: "asc" | "desc") => void;
  sortedBy?: string | null;
  ascending?: boolean | null;
}

export default function withFilteredQueryRequest(
  url: string,
  Component: React.ComponentType<TableComponentWithDataProps>
) {
  return function () {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(5);
    const [filteredRecords, setFilteredRecords] = useState<number>(0);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [hasErrors, setHasErrors] = useState<boolean>(false);
    const [errorDescription, setErrorDescription] = useState<string>("");
    const [dataReloadedCount, setDataReloadedCount] = useState(0);
    const [sortingColumn, setSortingColumn] = useState<string>("");
    const [ascending, setAscending] = useState<boolean>(true);

    function buildRequestUrl(url: string) {
      let requestUrl = `${url}`;

      if (requestUrl.indexOf("=") > -1) {
        requestUrl = `${requestUrl}&searchTerm=${searchTerm}&offset=${
          pageIndex * pageSize
        }&top=${pageSize}&sortBy=${sortingColumn}&ascending=${ascending}`;
        console.log(requestUrl)
        return requestUrl;
      }

      requestUrl = `${requestUrl}?searchTerm=${searchTerm}&offset=${
        pageIndex * pageSize
      }&top=${pageSize}&sortBy=${sortingColumn}&ascending=${ascending}`;
      return requestUrl;
    }

    const handleSortingChange = (
      columnName: string,
      sortOrder: "asc" | "desc"
    ) => {
      setAscending(sortOrder === "asc");
      setSortingColumn(columnName);
    };

    const requestReloadHandler = () => {
      setDataReloadedCount(dataReloadedCount + 1);
    };

    useEffect(() => {
      setIsLoading(true);
      setHasErrors(false);
      setErrorDescription("");
      const requestUrl: string = buildRequestUrl(url);
      requests
        .get(requestUrl)
        .then((response) => {
          setData(response.data.data);
          setFilteredRecords(response.data.filteredRecordsCount);
          setTotalRecords(response.data.totalRecordsCount);
       
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error.message);
          setHasErrors(true);
          setIsLoading(false);
          setErrorDescription(error.message);
        });
    }, [
      searchTerm,
      pageIndex,
      pageSize,
      dataReloadedCount,
      sortingColumn,
      ascending,
    ]);

    function handleSearchTermChange(e: any) {
      setPageIndex(0);
      setSearchTerm(e.target.value);
    }

    function handlePageIndexChange(page: number) {
      setPageIndex(page);
    }

    function handlePageSizeChange(e: any) {
      setPageIndex(0);
      setPageSize(parseInt(e.target.value));
    }

    return (
      <>
        <div
          className="col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
        >
          <PagingInfo
            currentPageIndex={pageIndex}
            filteredRecords={filteredRecords}
            totalRecords={totalRecords}
            pageSize={pageSize}
            visible={!isLoading && !hasErrors}
            key={"pagingInfo"}
          />
          <div className="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
            <div className="w-56 relative text-slate-500">
              <SearchInput
                value={searchTerm}
                handleChange={handleSearchTermChange}
              />
              <i
                className="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
                data-lucide="search"
              ></i>
            </div>
          </div>
        </div>

        <div className="col-span-12 overflow-auto lg:overflow-visible">
          {isLoading ? (
            <LoadingTable />
          ) : hasErrors ? (
            <ErrorMessage errorDescription={errorDescription} />
          ) : (
            <Component
              sortedBy={sortingColumn}
              ascending={ascending}
              onSortingChange={handleSortingChange}
              data={data}
              requestReload={requestReloadHandler}
            />
          )}
        </div>
        <div className="grid grid-cols-4 gap-4">
          <Pager
            visible={!isLoading && !hasErrors}
            handleChange={handlePageIndexChange}
            currentPageIndex={pageIndex}
            pageSize={pageSize}
            filteredRecords={filteredRecords}
            totalRecords={totalRecords}
          />
          <PageSizeSelector
                initialPageSize={pageSize}
                handleChange={handlePageSizeChange}
              />
        </div>
      </>
    );
  };
}
