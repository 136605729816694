import { useEffect, useState } from "react";
import { Entity } from "../models/entity";
import { useParams } from "react-router-dom";
import requests from "../../shared/api/agent";
import TopBar from "../../shared/layouts/TopBar";
import PageTitle from "../../shared/common/presentation/PageTitle";
import EntityForm from "../components/EntityForm";

export default function ViewEntity() {
  const [entity, setEntity] = useState<Entity>();

  const { id } = useParams<{ id: string }>();
  
  const loadEntity = () => {
 
    if (id) {
      requests.get(`/Entities/${id}`).then((response) => {
      setEntity(response.data);
      
      
      });
    }
  };

  useEffect(() => {
    loadEntity();
  }, [id]);

  return (
    <>
      <TopBar
        currentPageTitle={`Información de una entidad #${id}`}
        firstLevelPageTitle="Entidad"
        firstLevelPageUrl="/entidades"
      />
      <PageTitle title={`Información de la Entidad con NIT # ${entity?.document}`} />
      {entity && (
        <EntityForm isNewEntity={false} entity={entity} id={id} disabled/>
      )}
      
    </>
  );
}
