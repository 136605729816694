import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import IoCContainer from "../common/IoC/IoCContainer";
import { useState } from "react";
import UserProfileMenu from "./UserProfileMenu";
import { BiBell } from "react-icons/bi";

interface Props {
  firstLevelPageTitle?: string | null;
  firstLevelPageUrl?: string | null;
  currentPageTitle: string;
}

export default function TopBar({
  firstLevelPageTitle,
  firstLevelPageUrl,
  currentPageTitle,
}: Props) {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);
  return (
    <div className="top-bar">
      <nav aria-label="breadcrumb" className="-intro-x mr-auto hidden sm:flex">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">IGD Plus</Link>
          </li>
          {firstLevelPageTitle && firstLevelPageUrl && (
            <li className="breadcrumb-item">
              <Link to={firstLevelPageUrl}>{firstLevelPageTitle}</Link>
            </li>
          )}
          <li className="breadcrumb-item active" aria-current="page">
            {currentPageTitle}
          </li>
        </ol>
      </nav>
      <div className="intro-x relative mr-3 sm:mr-6">
        <div className="search hidden sm:block">
          <input
            type="text"
            className="search__input form-control border-transparent"
            placeholder="Search..."
          />
        </div>

        <div className="search-result">
          <div className="search-result__content">
            <div className="search-result__content__title">Pages</div>
            <div className="mb-5">
              <a href="" className="flex items-center">
                <div className="w-8 h-8 bg-success/20 dark:bg-success/10 text-success flex items-center justify-center rounded-full">
                  {" "}
                  <i className="w-4 h-4" data-lucide="inbox"></i>{" "}
                </div>
                <div className="ml-3">Mail Settings</div>
              </a>
              <a href="" className="flex items-center mt-2">
                <div className="w-8 h-8 bg-pending/10 text-pending flex items-center justify-center rounded-full">
                  {" "}
                  <i className="w-4 h-4" data-lucide="users"></i>{" "}
                </div>
                <div className="ml-3">Users & Permissions</div>
              </a>
              <a href="" className="flex items-center mt-2">
                <div className="w-8 h-8 bg-primary/10 dark:bg-primary/20 text-primary/80 flex items-center justify-center rounded-full">
                  {" "}
                  <i className="w-4 h-4" data-lucide="credit-card"></i>{" "}
                </div>
                <div className="ml-3">Transactions Report</div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="intro-x dropdown mr-auto sm:mr-6">
        <div
          className="dropdown-toggle notification notification--bullet cursor-pointer"
          role="button"
          aria-expanded="false"
          data-tw-toggle="dropdown"
        >
          {" "}
          <BiBell style={{transform:"scale(1.75)"}} color="#AFAFAF"/>
        </div>
        <div className="notification-content pt-2 dropdown-menu">
          <div className="notification-content__box dropdown-content">
            <div className="notification-content__title">Notifications</div>
          </div>
        </div>
      </div>
      <UserProfileMenu />
    </div>

  );
}
