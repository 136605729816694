import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function LoadingTable(){
    return(
        <table className='table table-report -mt-2'>
        <tbody>
        <tr>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
        </tr>
        <tr>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
        </tr>
        <tr>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
        </tr>
        <tr>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
        </tr>
        <tr>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
        </tr>
        <tr>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
        </tr>
        <tr>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
        </tr>
        <tr>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
        </tr>
        <tr>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
        </tr>
        <tr>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
            <td>
                <Skeleton/>
            </td>
        </tr>
        </tbody>
    </table>
    )
}