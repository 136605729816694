import {useState, useEffect} from 'react'
import { IPUCTree } from '../../chartOfAccounts/pages/ChartOfAccountsCreate'
import requests from '../../shared/api/agent'

export default function useTwoAccount() {
    const [initialAccount, setInitialAccount] = useState<IPUCTree[]>()
    const [selectedInitial, setSelectedInitial] = useState<IPUCTree>()
    const [finalAccount, setFinalAccount] = useState<IPUCTree[]>()
    const [selectedFinal, setSelectedFinal] = useState<IPUCTree>()

    const handleSelectedInitial = (value: IPUCTree) => setSelectedInitial(value);
    const handleSelectedFinal = (value: IPUCTree) => setSelectedFinal(value);

    useEffect(() => {
        requests.get(`/pucs/auxiliary?Top=10000`)
            .then((response) => {
                setInitialAccount(response.data.data)
                setFinalAccount(response.data.data)
            }).catch((error) => console.log(error))
    }, [])

    useEffect(() => {
        if (selectedInitial === undefined && selectedFinal === undefined) {
            setFinalAccount(initialAccount)
        } else if (selectedInitial !== undefined && selectedFinal === undefined) {
            let aux = finalAccount
            let index: number = aux?.findIndex((item) => item.id === selectedInitial.id)!
            aux = aux?.slice(index + 1, aux.length)
            setFinalAccount(aux)
        } else if (selectedFinal !== undefined && selectedInitial === undefined) {
            let aux = initialAccount
            let index: number = aux?.findIndex((item) => item.id === selectedFinal.id)!
            aux = aux?.slice(0, index)
            setInitialAccount(aux)
        }
    }, [selectedInitial, selectedFinal])

    return {initialAccount, selectedInitial, handleSelectedInitial, finalAccount, selectedFinal, handleSelectedFinal}
}