import PageTitle from "../../shared/common/presentation/PageTitle";
import TopBar from "../../shared/layouts/TopBar";
import ReceiptForm from "../components/ReceiptForm";

export default function CreateReceiptPage(){
    return (
        <>
          <TopBar firstLevelPageTitle={'Comprobantes'} firstLevelPageUrl={'/comprobantes'} currentPageTitle="Nuevo comprobante" />
          <PageTitle title="Nuevo comprobante">
          </PageTitle>
          <ReceiptForm isNewReceipt={true}/>
        </>
      );
}
