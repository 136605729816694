import { useState } from "react"; 
import { ReceiptType } from "../models/receiptType";
import requests from "../../shared/api/agent";
import TextField from "../../shared/common/forms/TextField";
import { useNavigate } from "react-router-dom";
import ReceiptCategorySelector from "../../receiptCategories/components/ReceiptCategorySelector";
import SubmitButton from "../../shared/common/forms/SubmitButton";
import ErrorMessage from "../../shared/common/presentation/ErrorMessage";
import SuccessMessage from "../../shared/common/presentation/SuccessMessage";
import BackButton from "../../shared/common/forms/BackButton";
import ConsecutiveTypeSelector from "../../consecutives/components/ConsecutiveTypeSelector";
import DocumentTypeSelector from "../../general/documentTypes/components/DocumentTypeSelector";

interface Props {
  isNewReceiptType: boolean;
  receiptType?: ReceiptType;
}

export default function ReceiptForm({
  isNewReceiptType,
  receiptType: selectedReceiptType,
}: Props) {
  const initialState = {
    id: 0,
    categoryId: 0,
    code: "",
    name: "",
    documentType: "",
    consecutive: 0,
    consecutiveType: "",
  };

  const [receiptType, setReceiptType] = useState<ReceiptType>(
    selectedReceiptType ?? initialState
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onFieldChange = (name: string, value: string | number) => {
    setReceiptType({ ...receiptType, [name]: value });
  };

  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    setErrorMessage("");
    setSuccessMessage("");  
    if (isNewReceiptType) {
      requests
        .post("/receipt-types", receiptType)
        .then((response) => navigate("/tipos-comprobantes"))
        .catch((e) => {
          setErrorMessage(e.message);
          window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
        })
        .finally(() => setIsSubmitting(false));
    } else {
      requests
        .put("/receipt-types/" + receiptType.id, receiptType)
        .then((response) => {
          setSuccessMessage(
            "Se ha actualizado correctamente el tipo de comprobante."
          );
          window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
        })
        .catch((e) => {
          setErrorMessage(e.message);
          window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  return (
    <>
      <div className="intro-y mb-3">
        <ErrorMessage message={errorMessage} />
        <SuccessMessage message={successMessage} />
      </div>
      <div className="intro-y box">
        <div id="form-validation" className="p-5">
          <div className="preview" style={{ display: "block" }}>
            <form
              className="validate-form"
              onSubmit={handleSubmit}
              method="POST"
            >
              <div className="grid grid-cols-2 gap-4 mb-4">
                <ReceiptCategorySelector
                  name="categoryId"
                  onChange={onFieldChange}
                  value={receiptType.categoryId}
                  key="categoryId"
                  required={true}
                />
                <TextField
                  name="code"
                  onChange={onFieldChange}
                  value={receiptType.code}
                  key="code"
                  required={true}
                  label={"Código"}
                />
                <TextField
                  name="name"
                  onChange={onFieldChange}
                  value={receiptType.name}
                  key="name"
                  required={true}
                  label={"Nombre"}
                />
                <DocumentTypeSelector
                  name="documentType"
                  value={receiptType.documentType}
                  onChange={onFieldChange} 
                />
                <TextField
                  name="consecutive"
                  onChange={onFieldChange}
                  value={receiptType.consecutive.toString()}
                  key="consecutive"
                  required={true}
                  label={"Consecutivo"}
                />
                <ConsecutiveTypeSelector
                  name="consecutiveType"
                  value={receiptType.consecutiveType}
                  onChange={onFieldChange}
                />
                
              </div>
              <div className="flex flex-col sm:flex-row items-center pt-5 ">
                <SubmitButton isSubmitting={isSubmitting} />
                <BackButton url="/tipos-comprobantes" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
